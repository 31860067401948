<template>
  <div class="cliendtSelete">
    <el-row class="tabsRow">
      <el-col :span="3"
        style="padding-left:0px;">
        <znl-input form-type="input"
          :disabled="false"
          placeholder="（型号）"
          width='100%'
          size="mini"
          layout="left"
          v-model="modelTxt"
          @clear="()=>{modelTxt=''}"
          :border="false"
          :clearable="true"
          @keyup.enter.native="pageSearch"
          type="text">
        </znl-input>
      </el-col>
      <el-col :span="3">
        <znl-input form-type="input"
          :disabled="false"
          placeholder="（品牌）"
          width='100%'
          size="mini"
          layout="left"
          @change="(val)=>{brandTxt=val}"
          @clear="()=>{brandTxt=''}"
          v-model="brandTxt"
          :border="false"
          :clearable="true"
          @keyup.enter.native="pageSearch"
          type="text">
        </znl-input>
      </el-col>
      <el-col :span="3">
        <znl-input form-type="input"
          :disabled="false"
          placeholder="（封装）"
          width='100%'
          size="mini"
          layout="left"
          @change="(val)=>{packagingTxt=val}"
          @clear="()=>{packagingTxt=''}"
          v-model="packagingTxt"
          :border="false"
          :clearable="true"
          @keyup.enter.native="pageSearch"
          type="text">
        </znl-input>
      </el-col>
      <el-col :span="3">
        <znl-input form-type="input"
          :disabled="false"
          placeholder="（客户名称）"
          width='100%'
          size="mini"
          layout="left"
          @change="(val)=>{customerName=val}"
          @clear="()=>{customerName=''}"
          v-model="customerName"
          :border="false"
          :clearable="true"
          @keyup.enter.native="pageSearch"
          type="text">
        </znl-input>
      </el-col>
      <el-col :span="7"
        style="padding-left: 0 !important;">
        <el-row>
          <el-col :span="12"
            style="width:50%">
            <znl-input layout="left"
              form-type="select"
              :disabled="false"
              width='100%'
              placeholder="请选择搜索条件"
              popper-class="popperClass"
              :clearable="true"
              inp-perc="100%"
              :select-options="selectStockSearchField"
              v-model="fieldTxt">
            </znl-input>
          </el-col>
          <el-col :span="12"
            style="width:50%">
            <znl-input form-type="input"
              :disabled="false"
              placeholder="请输入搜索条件"
              width='100%'
              size="mini"
              layout="left"
              @clear="()=>{fieldSearchTxt=''}"
              @change="(val)=>{fieldSearchTxt=val}"
              v-model="fieldSearchTxt"
              :border="false"
              :clearable="true"
              @keyup.enter.native="pageSearch"
              type="text">
            </znl-input>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="5">
        <znl-button class="box-left-btn"
          :height='22'
          @click="pageSearch"
          :style="{'margin-right': '5px'}">
          <span><i class="iconfont icon-search_ic"></i>搜索</span>
        </znl-button>
        <el-checkbox v-model="isZero"
          @change="pageSearch">包含零库存</el-checkbox>
      </el-col>
    </el-row>
    <el-tabs v-model="activeName"
      value="StockQuery"
      @tab-click="pageSearch"
      class="znl-bottom-tab"
      type="card">
      <el-tab-pane label="我的库存"
        v-if='hasRight("StockQuery")&&isShowLabel("StockQuery")'
        name="StockQuery">
        <znl-stock ref="stockMini"
          :init-data="false"
          :is-mini="true"
          v-if="isShowStockQuery"
          :is-init="true"
          :on-init="true"
          :is-fields-search="false"
          :isVisibleAdopt="true"
          :searchModel="stockSearchModel"
          class="StockQuery_mini"
          :has-operation-column="hasOperationColumn.StockQuery"
          @pageloading="(val)=>{$emit('pageloading', val)}"
          @ChkStkGUID="(val)=>{this.onChkStkGUID(val)}"
          @ondblclick="row=>{$emit('ondblclick', row, 'StockQuery')}">
        </znl-stock>
      </el-tab-pane>
      <el-tab-pane label="供应商库存"
        v-if='hasRight("StockVMIMini")&&isShowLabel("StockVMIMini")'
        name="StockVMIMini">
        <znl-stock-vmi ref="stockvmiMini"
          :init-data="true"
          v-if="isShowStockVMIMini"
          :is-mini="true"
          class="StockVMI_mini"
          :is-fields-search="false"
          :isVisibleAdopt="true"
          :has-operation-column="hasOperationColumn.StockVMIMini"
          :searchModel="stockVMISearchModel"
          @ChkVMIGUID="(val)=>{this.onChkVMIGUID(val)}"
          @ondblclick="row=>{$emit('ondblclick', row, 'StockVMIMini')}">
        </znl-stock-vmi>
      </el-tab-pane>
      <el-tab-pane label="询价记录"
        v-if='hasRight("PastInquire")&&isShowLabel("PastInquire")'
        name="Inquire">
        <znl-inquire ref="stockInquire"
          class="min-pastinquire-box"
          :init-data="true"
          v-if="isShowInquire"
          :is-fields-search="false"
          :isVisibleAdopt="true"
          :isShowBtn="true"
          :is-mini="true"
          :is-init="true"
          :has-operation-column="hasOperationColumn.PastInquire"
          :is-multi-rows-check="false"
          @ChkIQGUID="(val)=>{this.onChkIQGUID(val)}"
          @ondblclick="row=>{$emit('ondblclick', row, 'Inquire')}"
          :searchModel="InquireSearchModel">
        </znl-inquire>
      </el-tab-pane>
      <el-tab-pane label="发货记录"
        v-if='hasRight("PastStkOut")&&isShowLabel("PastStkOut")'
        name="StkOutByModel">
        <znl-stk-out-by-model :init-data="true"
          v-if="isShowStkOutByModel"
          :is-mini="true"
          :is-init="true"
          :searchModel="stkOutSearchByModel"
          @ondblclick="row=>{$emit('ondblclick', row, 'StkOutByModel')}">
        </znl-stk-out-by-model>
      </el-tab-pane>
      <el-tab-pane label="入库记录"
        v-if='hasRight("PastStkIn")&&isShowLabel("PastStkIn")'
        name="StkInRecord">
        <znl-purchase :init-data="true"
          v-if="isShowStkInRecord"
          :is-fields-search="false"
          :is-mini="true"
          :is-init="true"
          :is-multi-rows-check="false"
          :searchModel="purchaseSearchModel"
          @ondblclick="row=>{$emit('ondblclick', row, 'StkInRecord')}">
        </znl-purchase>
      </el-tab-pane>
      <el-tab-pane label="报价记录"
        v-if='hasRight("PastQuote")&&isShowLabel("PastQuote")'
        name="HisOfferPriceByModel">
        <znl-hisofferpricebymodel :is-fields-search="false"
          :is-mini="true"
          :init-data="true"
          v-if="isShowHisOfferPriceByModel"
          :searchModel="HisofferpriceByModel"
          @ondblclick="row=>{$emit('ondblclick', row, 'HisOfferPriceByModel')}">
        </znl-hisofferpricebymodel>
      </el-tab-pane>
      <el-tab-pane label="销售记录"
        v-if='hasRight("StkSalesRecord")&&isShowLabel("StkSalesRecord")'
        name="StkSalesRecord">
        <znl-sales-record :init-data="true"
          v-if="isShowStkSalesRecord"
          :is-mini="true"
          :is-init="true"
          :searchModel="salesSearchModel"
          @ondblclick="row=>{$emit('ondblclick', row, 'StkSalesRecord')}">
        </znl-sales-record>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import ZnlStock from '@c_modules/Stk/StkStock_Mini'
import ZnlStockVmi from '@c_modules/Stk/StockVMI_Mini'
import ZnlInquire from '@c_modules/Stk/StkInquireRecord'
import ZnlStkOutByModel from '@c_modules/Stk/StkOrderByModel'
import ZnlPurchase from '@c_modules/Stk/StkInRecord'
import ZnlHisofferpricebymodel from '@c_modules/Ord/HisOfferPriceByModel'
import ZnlSalesRecord from '@c_modules/Stk/StkSalesRecord'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { setTimeout } from 'timers'
export default {
  name: 'ClientSelect',
  mixins: [getCommonDataMixin],
  components: {
    ZnlStock,
    ZnlStockVmi,
    ZnlInquire,
    ZnlStkOutByModel,
    ZnlPurchase,
    ZnlHisofferpricebymodel,
    ZnlSalesRecord
  },

  data () {
    return {
      config: {
        DictItemURL: 'DictItem/GetItemValue'
      },
      modelTxt: '',
      brandTxt: '',
      packagingTxt: '',
      isZero: true,
      customerName: '',
      AdoptstkGUID: '',
      IQGUID: '',
      fieldTxt: '',
      fieldSearchTxt: '',
      pageData: [],
      hearShow: false,
      isShowStockQuery: false,
      isShowStockVMIMini: false,
      isShowInquire: false,
      isShowStkOutByModel: false,
      isShowStkInRecord: false,
      isShowHisOfferPriceByModel: false,
      isShowStkSalesRecord: false,
      StkInSearchFields: [],
      stockSearchModel: {},
      stockVMISearchModel: {},
      stkOutSearchByCust: {},
      stkOutSearchByModel: {},
      purchaseSearchModel: {},
      InquireSearchModel: {}, // 询价查询
      Hisofferprice: {}, // 报价记录（按客户）
      HisofferpriceByModel: {}, // 报价记录（按型号）
      salesSearchModel: {},
      activeName: 'StockQuery',
      permission: {
        viewBuyPrice: true, // 看采购价
        viewSalesPrice: true, // 看销售价
        noSeeSupplier: false // 不看供应商
      }
    }
  },
  props: {
    showModels: {
      type: Array,
      default () {
        return []
      }
    },
    hasOperationColumn: {
      type: Object,
      default () {
        return {
          StockQuery: true,
          StockVMIMini: true,
          PastInquire: true
        }
      }
    }
  },
  methods: {
    async onInit () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      await this.$post(pageUrl, { PageCode: 'VBomMasterDetail' }, data => {
        this.pageData = data
        if (data && data[1] && data[1].Code) {
          this.activeName = data[1].Code
        }
        if (this.activeName === 'StockQuery') {
          this.isShowStockQuery = true
          setTimeout(() => {
            this.stockSearchModel = { Model: '' }
          }, 100)
        }
        if (this.activeName === 'StockVMIMini') {
          this.isShowStockVMIMini = true
        }
        if (this.activeName === 'Inquire') {
          this.isShowInquire = true
        }
        if (this.activeName === 'StkOutByModel') {
          this.isShowStkOutByModel = true
        }
        if (this.activeName === 'StkInRecord') {
          this.isShowStkInRecord = true
        }
        if (this.activeName === 'HisOfferPriceByModel') {
          this.isShowHisOfferPriceByModel = true
        }
        if (this.activeName === 'StkSalesRecord') {
          this.isShowStkSalesRecord = true
        }
        this.p_loading = true
      })

      if (!this.hasRight('StockQuery')) {
        this.$emit('pageloading', false)
      }
    },

    isShowLabel (name) {
      if (this.showModels.length === 0) {
        return true
      }
      return _.indexOf(this.showModels, name) > -1
    },
    async getPageData () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      return await this.$post(pageUrl, { PageCode: 'VBomMasterDetail' })
    },
    hasRight (code) {
      let show = _.some(this.pageData, page => page.Code === code)
      if (show) {
        this.hearShow = true
      }
      return show
    },
    onChkStkGUID (stkGUID) {
      this.$emit('selecte:end', 'stkGUID', stkGUID)
    },
    onChkIQGUID (IQGUID) {
      // 选择询价采购
      // this.$refs.bomDetail.onInquire(IQGUID)
      this.$emit('selecte:end', 'IQGUID', IQGUID)
    },
    onChkVMIGUID (VMGUID) {
      // 采纳操作
      // this.$refs.bomDetail.onStockVMI(VMGUID)
      this.$emit('selecte:end', 'VMGUID', VMGUID)
    },
    pageSearch () {
      let searchModel = {
        Model: this.modelTxt,
        Brand: this.brandTxt,
        Packaging: this.packagingTxt,
        IsZero: this.isZero,
        CustomerName: this.customerName,
        AdoptstkGUID: this.AdoptstkGUID,
        IQGUID: this.IQGUID
      }
      _.each(this.StkInSearchFields, item => {
        if (item.key === this.fieldTxt) {
          searchModel[this.fieldTxt] = this.fieldSearchTxt
        } else if (this.stockSearchModel.hasOwnProperty(item.ItemValue)) {
          searchModel[item.key] = null
        }
      })
      //  _.isEmpty(this.stockSearchModel) && (this.stockSearchModel = searchModel)
      // _.isEmpty(this.InquireSearchModel) && (this.InquireSearchModel = searchModel)
      if (
        this.activeName === 'StockQuery' &&
        !_.isEqual(this.stockSearchModel, searchModel)
      ) {
        this.customerName = searchModel.CustomerName
        if (!this.isShowStockQuery) {
          this.isShowStockQuery = true
        }
        this.stockSearchModel = searchModel
      } else if (
        this.activeName === 'StockVMIMini' &&
        !_.isEqual(this.stockVMISearchModel, searchModel)
      ) {
        this.customerName = searchModel.CustomerName
        if (!this.isShowStockVMIMini) {
          this.isShowStockVMIMini = true
        }
        this.stockVMISearchModel = searchModel
      } else if (
        this.activeName === 'stock2' &&
        (_.isEmpty(this.stkOutSearchByCust) ||
          (this.customerName &&
            !_.isEqual(this.stkOutSearchByCust, searchModel)))
      ) {
        this.customerName = this.searchModel.CustomerName
        this.stkOutSearchByCust = searchModel
      } else if (
        this.activeName === 'StkOutByModel' &&
        !_.isEqual(this.stkOutSearchByModel, searchModel)
      ) {
        // this.stkOutSearchByModel = _.extend({}, searchModel, { CustomerName: '' })
        this.customerName = this.searchModel && this.searchModel.CustomerName
        if (!this.isShowStkOutByModel) {
          this.isShowStkOutByModel = true
        }
        this.stkOutSearchByModel = searchModel
      } else if (
        this.activeName === 'StkInRecord' &&
        !_.isEqual(this.purchaseSearchModel, searchModel)
      ) {
        this.customerName = this.searchModel && this.searchModel.CustomerName
        if (!this.isShowStkInRecord) {
          this.isShowStkInRecord = true
        }
        this.purchaseSearchModel = searchModel
      } else if (
        this.activeName === 'Inquire' &&
        !_.isEqual(this.InquireSearchModel, searchModel)
      ) {
        if (!this.isShowInquire) {
          this.isShowInquire = true
        }
        this.InquireSearchModel = searchModel
        // this.customerName = ''
      } else if (
        this.activeName === 'QuotePriceByCustomer' &&
        !_.isEqual(this.Hisofferprice, searchModel)
      ) {
        this.customerName = this.searchModel && this.searchModel.CustomerName
        this.Hisofferprice = searchModel
        //    this.$set(this.Hisofferprice, 'SearchType', 'ByCustomer') // 标识按客户查询报价记录
      } else if (
        this.activeName === 'HisOfferPriceByModel' &&
        !_.isEqual(this.HisofferpriceByModel, searchModel)
      ) {
        this.customerName = this.searchModel && this.searchModel.CustomerName
        if (!this.isShowHisOfferPriceByModel) {
          this.isShowHisOfferPriceByModel = true
        }
        this.HisofferpriceByModel = searchModel
        // this.$set(this.HisofferpriceByModel, 'SearchType', 'ByModel') // 标识按型号查询报价记录
      } else if (
        this.activeName === 'StkSalesRecord' &&
        !_.isEqual(this.salesSearchModel, searchModel)
      ) {
        this.customerName = this.searchModel && this.searchModel.CustomerName
        if (!this.isShowStkSalesRecord) {
          this.isShowStkSalesRecord = true
        }
        this.salesSearchModel = searchModel
        // this.$set(this.salesSearchModel, 'SearchType', 'ByModel') // 标识按型号查询销售记录
      }
    }
  },
  async created () {
    const initData = component => {
      if (component.$options.config && component.$options.config.configURL) {
        const moduleName = component.$options.config.configURL.split('/')[0]
        const config = _.find(this.pageData, page => page.Code === moduleName)
        if (config && component.onInit) {
          component.onInit(config.UICPageGridConfig)
        } else {
          component.onInit()
        }
      }
      _.each(component.$children, child => {
        initData(child)
      })
    }
    // 提取到view
    this.onInit().then(e => {
      // this.p_loading = false
    })
  }
}
</script>
<style lang="scss" scoped>
.tab-btnstyle-box
  .cliendtSelete
  .el-tabs__content
  .el-tab-pane
  .znl-grid-component {
  background: transparent !important;
}
.cliendtSelete {
  padding-bottom: 5px;
  .el-loading-mask {
    background-color: transparent !important;
    background: transparent !important;
  }

  border: 1px solid #c0c0c0;
  .el-tabs__header {
    padding-left: 0 !important;
  }
  // .znlBottom{
  //   padding-bottom: 10px;
  // }
  .tabsRow {
    top: 37px !important;
  }
  .el-table--border th,
  .el-table__fixed-right-patch {
    background-color: #ebeef5;
  }

  .znl-grid-component {
    background: transparent !important;
  }
  .znl-grid-component .el-table--border {
    border-left: none !important;
    border-right: none !important;
  }

  & > .el-tabs.znl-bottom-tab {
    flex: 1;
    display: flex !important;
    flex-direction: column !important;
    background-color: #f5f5f5 !important;
    > .el-tabs__header {
      height: 30px !important;
      line-height: 30px !important;
      border-bottom: none !important;
      margin: 0 !important;
      .el-tabs__nav-wrap {
        height: 100%;
        overflow: inherit;
        .el-tabs__nav-scroll {
          height: 100%;
          overflow: initial;
          background-color: #f5f5f5 !important;
          border-bottom: none;
          .el-tabs__nav-scroll {
            overflow: initial;
          }
          .el-tabs__nav {
            height: 100%;
            padding: 0 5px 0 0;
            display: flex;
            align-items: center;
            border: 1px solid transparent;
            display: flex;
            align-items: flex-end;
            transform: none !important;
            transition: unset;
            left: 5px;
            .el-tabs__item {
              height: 24px;
              line-height: 24px;
              font-size: 12px;
              margin: 0;
              padding: 0 10px;
              transition: unset;
              border-left: none;
            }
            .el-tabs__item:hover {
              color: #333133;
              cursor: pointer;
            }
            .el-tabs__item.is-active {
              border-bottom: none;
              background: #969696;
              color: #fff;
              border-radius: 4px;
              transition: unset;
            }
          }
        }
      }
    }
    & > .el-tabs__content {
      & > .el-tab-pane > .znl-grid-component > .znl-content {
        height: 210px;
        .baseStyleDefalut.base-flex-grid > .footer {
          display: block;
        }
        .el-table {
          display: block;
          .el-table__body-wrapper {
            height: 110px;
          }
        }
      }
    }
  }
  .min-pastinquire-box .znl-gridModuleHeader {
    display: none;
  }
}
</style>
