<template>
  <div>
    <znl-dialog
      :title="title"
      :visible="selType && show"
      :height="isCommonImport ? 708 : 646"
      width="1000"
      :close-on-click-modal="false"
      :loading.sync="loading"
      :loading-text="elementLoadingText"
      :footer-height="50"
      :header-height="40"
      :is-footer-show="false"
      @close="
        (val) => {
          if (!importing) $emit('close', val);
        }
      "
      class="znl-data-import"
    >
      <div class="wp" :loading="loading">
        <el-row v-show="isCommonImport" style="margin-bottom: 8px">
          <el-col>
            <fieldset>
              <legend>选择数据导入的类型</legend>
              <el-radio-group v-model="typeKey" @change="typeKeyChange">
                <el-radio
                  v-for="(item, index) in types"
                  :key="index"
                  style="margin-left: 6px"
                  :label="item.key"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </fieldset>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <fieldset>
              <legend>方式一（从文件导入）</legend>
              <!-- 导入文件： -->
              <el-upload
                class="upload-demo"
                :action="actionUrl"
                accept=".xls,.xlsx"
                :headers="uploadHeaders"
                :multiple="false"
                :show-file-list="false"
                :before-upload="beforeExcelUpload"
                :on-progress="onUploadProgress"
                :on-change="onUploadChange"
                :on-success="onUploadSuccess"
                :on-error="onUploadError"
              >
                导入文件：
                <znl-input
                  from-type="input"
                  :disable="false"
                  placeholder=""
                  size="mini"
                  layout="left"
                  width="200"
                  :height="22"
                  inp-perc="100%"
                  :clearable="false"
                  :border="true"
                  disabled
                  type="text"
                  v-model="uploadName"
                />
                <znl-button
                  style="margin-left: 2px"
                  :height="22"
                  style-type="main"
                  tip="选择文件"
                >
                  选择文件
                </znl-button>
                <div slot="tip" class="el-upload__tip">
                  <div>
                    操作方法：[选择Excel文件]->[对应列头并校验数据]->[点击“开始导入”]
                  </div>
                  <div>提示：采用文件上传方式时，建议Excel数据行数不要</div>
                </div>
              </el-upload>
            </fieldset>
          </el-col>
          <el-col :span="12">
            <fieldset>
              <legend>方式二（从Excel粘贴导入）</legend>
              <div style="margin: 4px 0">
                <znl-button
                  style="margin-left: 2px"
                  :height="22"
                  style-type="main"
                  tip="客户资料导入"
                  @click="pasteExcel"
                >
                  从Excel粘贴
                </znl-button>
                为避免异常，当粘贴内容比较多时建议用方式一（文件导入）！
              </div>
              <div>
                操作方法：[打开Excel表]->[复制Excel表，不含标题行]->[点击"从Excel粘贴"]
                ->[对应列头并校验数据]->[点击”开始导入”]
              </div>
            </fieldset>
          </el-col>
        </el-row>
        <el-row style="margin-top: 8px">
          <el-col>
            <fieldset>
              <legend>选择导入列</legend>
              <el-checkbox-group v-model="selFields">
                <div class="f-list" style="height: 66px; overflow-y: auto">
                  <el-checkbox
                    class="fix-width"
                    v-for="(item, idx) in this.tableFields"
                    :key="idx"
                    :label="item.FieldName"
                    @change="fieldChange($event, item.FieldName)"
                  >
                    {{ item.FieldCName }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </fieldset>
          </el-col>
        </el-row>
        <el-row style="margin-top: 8px">
          <el-col class="" style="">
            <fieldset>
              <legend>数据导入</legend>
              <!-- <div class="sel-scroll">
                <znl-input
                  form-type="select"
                  placeholder="请选择"
                  width="80"
                  size="mini"
                  layout="left"
                  inp-perc="100%"
                  :select-options="fieldOpts"
                  :border="true"
                  v-for="(item, idx) in fieldConfigs" :key="idx"
                  v-model="fieldConfigs[idx]"
                />
              </div> -->
              <el-row>
                <el-col
                  :span="21"
                  class="wp-data-import-table"
                  style="height: 330px; padding-top: 2px"
                >
                  <znl-table
                    ref="table"
                    :showCheck="true"
                    :columns="columns"
                    :item-source="itemSource"
                    :show-title-menus="false"
                    :on-page-changed="onBindData"
                    :page-index="pageIndex"
                    :page-size="pageSize"
                    :total-count="totalCount"
                    box-class="wp-data-import-table"
                    element-loading-text="数据加载中,请稍后..."
                    gridtype="action"
                    :is-fields-search="false"
                    @dbclick="
                      (e) => {
                        $emit('dbl-click', e.rowData);
                      }
                    "
                    @select-change="selectChange"
                    @table-header-change="tableHeaderSelectChange"
                    @current-row-change="currentRowChange"
                    @cell-edit-done="cellEditDone"
                  ></znl-table>
                </el-col>
                <el-col :span="3" class="wp-err">
                  <div class="title">错误列表</div>
                  <ul>
                    <li
                      v-for="(item, index) in this.errList"
                      :key="index"
                      @click="errListClick(item)"
                    >
                      {{ item.desc }}
                    </li>
                  </ul>
                </el-col>
              </el-row>
              <!-- <div class="wp-data-import-table" style="height: 330px;padding-top: 2px;"
                :style="{
                  widtha: (errList.length>0 ? 760 : 960) + 'px'
                }"
              >

              </div> -->
              <el-row style="margin-top: 10px">
                <el-col :span="4">
                  <znl-button
                    :height="22"
                    style-type="mac"
                    tip="删除选中行"
                    @click="delRow"
                  >
                    删除选中行
                  </znl-button>
                  <znl-button
                    :height="22"
                    style-type="main"
                    tip="添加行"
                    @click="addRow"
                  >
                    添加行
                  </znl-button>
                </el-col>
                <el-col
                  :span="14"
                  style="
                    margin-top: 3px;
                    margin-left: -20px;
                    margin-right: 20px;
                  "
                >
                  <div v-html="txtStatus" />
                  <!-- <span>当先共 {{upladedData.length}} 数据待导入</span> -->
                  <!-- <span>{{txtStatus}}</span> -->
                </el-col>
                <el-col :span="4" style="height: 22px">
                  <div></div>
                  <el-progress
                    v-show="importing | showProgess"
                    :percentage="importPercent"
                    class="progress-bar"
                  >
                  </el-progress>
                </el-col>
                <el-col :span="2">
                  <znl-button
                    :height="22"
                    style-type="main"
                    style="width: 100%"
                    :tip="importTitle"
                    @click="startImport"
                    :disabled="importCancel"
                  >
                    {{ importTitle }}
                  </znl-button>
                </el-col>
              </el-row>
            </fieldset>
          </el-col>
        </el-row>
      </div>
    </znl-dialog>

    <znl-dialog
      title="粘贴数据到此"
      :visible="showPaste"
      height="296"
      width="600"
      :close-on-click-modal="false"
      loading-text="载入中..."
      :footer-height="50"
      :header-height="40"
      :is-footer-show="false"
      @close="
        (val) => {
          showPaste = false;
        }
      "
      class="znl-data-import-paste"
    >
      <el-row>
        <el-col style="height: 228px">
          <znl-input
            from-type="input"
            placeholder="请粘贴要导入到表格的数据"
            size="mini"
            layout="left"
            width="100%"
            inp-perc="100%"
            :clearable="true"
            :border="true"
            type="textarea"
            :rows="12"
            v-model="txtPaste"
          />
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" :gutter="10">
        <el-col :span="6">
          <znl-button
            :height="28"
            style="width: 100%"
            style-type="main"
            tip="确定"
            @click="onPasteExcel(txtPaste)"
          >
            确定
          </znl-button>
        </el-col>
      </el-row>
    </znl-dialog>
  </div>
</template>

<script>
// import {getErpApiBaseUrl} from "~/lib/urls";
import { isClient } from "~/lib/runtime";
import * as urls from "~/lib/urls";
import ClientSelectVue from "../../modules/Stk/ClientSelect.vue";

const apiDomain = urls.getErpApiBaseUrl();
// const types = [
//   {key: "stock", label: "库存", table: "StockField"},
//   {key: "stkvmi", label: "供应商库存", table: "StockVMIField"},
//   {key: "bom", label: "客户需求", table: "ImportCustomerRequestField"},
//   {key: "pubstkstock", label: "推广库存", table: "PUB_PStockField"},
//   {key: "stkreserve", label: "销售备货", table: "ReserveField"},
//   {key: "stkpurchase", label: "采购", table: "PurchaseField"},
//   {key: "stkout", label: "发货单", table: "ImportStkOutField"},
//   {key: "stkin", label: "入库单", table: "ImportStkInField"},
//   {key: "customer", label: "客户信息", table: "CustomerField"},
//   {key: "supplier", label: "供应商信息", table: "SupplierField"},
//   {key: "inquire", label: "询价信息", table: "RFQInquireField"},
// ]

import { getCookie2 } from "~/assets/scripts/utils/dom";

export default {
  name: "DataImport",
  props: {
    // title: {
    //   type: String,
    //   default: "数据导入"
    // },
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "stock",
    },
  },
  data() {
    return {
      loading: true, //载入中
      elementLoadingText: "正在载入数据,请稍后...", //载入中文字
      showPage: false, //显示内容页
      title: "数据导入",

      showPaste: false, //是否显示粘贴窗口
      txtPaste: "", //粘贴的文字

      types: [
        { key: "stock", label: "库存", table: "StockField" },
        { key: "stkvmi", label: "供应商库存", table: "StockVMIField" },
        { key: "bom", label: "客户需求", table: "ImportCustomerRequestField" },
        { key: "pubstkstock", label: "推广库存", table: "PUB_PStockField" },
        { key: "stkreserve", label: "销售备货", table: "ReserveField" },
        { key: "stkpurchase", label: "采购", table: "PurchaseField" },
        { key: "stkout", label: "发货单", table: "ImportStkOutField" },
        { key: "stkin", label: "入库单", table: "ImportStkInField" },
        { key: "customer", label: "客户信息", table: "CustomerField" },
        { key: "supplier", label: "供应商信息", table: "SupplierField" },
        { key: "inquire", label: "询价信息", table: "RFQInquireField" },
      ], //数据导入的类型列表
      typeKey: "stock", //类型key
      isCommonImport: false, //是否统一数据导入，如果是的话需显示选择导入类型的单选框列表
      selType: null, //选择的类型
      tableFields: [], //表格字段信息
      uploadName: "", //上传文件的名字
      actionUrl: "", //上传Url
      uploadHeaders: {}, //上传头信息
      uploadLt: 4, //上传最大多少M
      apiDomain: apiDomain,

      originData: [], //上传的原始数据
      upladedData: [], //已上传的数据
      selFields: [], //选择的字段
      fieldOpts: [], //字段可选值
      fieldConfigs: [], //字段设置
      errList: [], //校验错误列表

      pageIndex: 1,
      totalCount: 0,
      pageSize: 100,
      columns: [], //列
      itemSource: [], //表格数据
      selectedRows: [], //已选行

      txtProgress: "", //进度文字
      showProgess: false, //显示进度条
      importObjs: [], //导入的数据
      importing: false, //是否导入
      importCancel: false, //取消导入中...
      importTitle: "开始导入", //导入按钮标题
      importPercent: 0, //导入进度
      importPageIndex: 1, //导入页
      importPageSize: 2000, //导入分页大小
      importPageCount: 1, //导入分页总页数
    };
  },
  created() {
    const baseUrl = apiDomain;
    this.actionUrl = `${baseUrl}/DataImport/ImportDataFromFile`;
    let cookie = isClient() ? this.getErpTokenID : getCookie2("login_token");
    this.uploadHeaders = {
      Authorization: cookie,
    };
  },
  watch: {
    async show(val, oldVal) {
      if (val) {
        await this.showImportWin(this.type);
      } else {
        this.clearResouse();
        this.isCommonImport = false;
      }
    },
    async selFields(val, oldVal) {
      const sel = this.tableFields.filter(
        (item) => val.findIndex((it) => it == item.FieldName) > -1
      );
      //设置字段可选值
      this.fieldOpts = sel.map((item) => {
        return {
          value: item.FieldName,
          key: item.FieldCName,
        };
      });
      //设置字段映射设置
      if (this.upladedData.length < 1) {
        //没有数据时
        this.fieldConfigs = sel.map((item) => item.FieldName);
      } else {
        //有数据时
        //如果fieldConfigs的长度小于selFields的长度需要补足长度
        if (val.length > this.fieldConfigs.length) {
          const l = val.length - this.fieldConfigs.length;
          for (var i = 0; i < l; i++) {
            this.fieldConfigs.push("");
          }
        }

        this.fieldConfigs.forEach((item, idx) => {
          //查找Index，找不到的设为''
          if (item != "") {
            const fi = sel.findIndex((it) => it.FieldName == item);
            if (fi <= -1) this.fieldConfigs[idx] = "";
          }
        });
        sel.map((item) => {
          //查找没有的并尽可能加上
          const idx = this.fieldConfigs.findIndex((it) => it == item.FieldName);
          if (idx <= -1) {
            const blankIdx = this.fieldConfigs.findIndex((it) => it == "");
            if (blankIdx > -1) this.fieldConfigs[blankIdx] = item.FieldName;
          }
        });
      }

      // this.fieldConfigs = this.tableFields.map(item=>item.FieldName)
      //根据字段映射渲染ZnlTable
      // this.upladedData = []
      this.errList = [];
      if (!this.checkFeildConfig()) {
        this.$message({
          message: "数据映射有相同的字段!",
          type: "error",
        });
      }
      await this.onInit();
      this.checkData();
    },
  },
  computed: {
    txtStatus() {
      return `当前共 <span style="color: darkblue">${
        this.upladedData.length
      }</span> 条数据，
      已导入 ${this.upladedData.filter((item) => item._imported).length} 条。 ${
        this.txtProgress
      }`;
    },
    // selFields(){
    //   this.tableFields.filter(item=>item.IsChecked).map(item=>item.FieldName)
    // },
    // fieldOpts(){
    //   return this.tableFields.map(item=>{
    //       return {
    //         key: item.FieldName,
    //         value: item.FieldCName,
    //       }
    //     })
    // },
  },
  methods: {
    //显示导入窗口
    async showImportWin(type) {
      this.loading = true;
      //显示，有些界面上的初始化
      //获取系统默认值
      let typeIdx = this.types.findIndex((item) => item.key === type);
      if (typeIdx < 0) {
        typeIdx = 0;
        this.isCommonImport = true;
        this.typeKey = "stock";
      }
      // if(typeIdx < 0){
      //   this.selType = null;
      //   this.$message({
      //   message: '选择的导入数据分类错误！',
      //     type: "error"
      //   });
      // } else {
      this.selType = this.types[typeIdx];
      this.typeKey = this.selType.key; //更新typeKey
      this.title = this.selType.label + "导入";
      this.uploadName = "";
      try {
        //获取表格信息
        this.tableFields = await this.$post("DataImport/GetTableFields", {
          TableName: this.selType.table,
        });
        //设置选择的字段
        this.selFields = this.tableFields
          .filter((item) => item.IsChecked)
          .map((item) => item.FieldName);
      } catch {
        this.$message({
          message: "获取数据导出表格字段信息失败！",
          type: "error",
        });
      }
      // }
      this.loading = false;
    },
    //清除数据
    clearResouse() {
      this.originData = [];
      this.upladedData = [];
      this.columns = [];
      this.itemSource = [];
      this.errList = [];
      this.importing = false;
      this.importObjs = [];
    },
    async typeKeyChange() {
      this.clearResouse();
      await this.showImportWin(this.typeKey);
    },
    async beforeExcelUpload(file) {
      const isLtM = file.size / 1024 / 1024 < this.uploadLt;
      if (!isLtM) {
        this.$message({
          message: `上传文件大小不能超过${this.uploadLt}MB!`,
          type: "error",
        });
      }
      return isLtM;
    },
    async onUploadChange(file, fileList) {
      this.uploadName = file.name;
    },
    async onUploadProgress(event, file, fileList) {
      this.loading = true;
    },
    async onUploadSuccess(res, file, fileList) {
      if (res.logiccode == 200) {
        this.originData = res.data;
        //上传的数据
        this.upladedData = [...this.originData].map((item, idx) => {
          return {
            ...item,
            ...{
              _status: "",
              _verified: false,
              _id: _.uniqueId(),
              _imported: false,
              _err: "",
            },
          };
        });

        //重新计算fieldConfigs
        if (this.originData.length > 0) {
          //计算最长的
          let maxL = 0;
          this.originData.forEach((item) => {
            if (Object.keys(item).length > maxL)
              maxL = Object.keys(item).length;
          });
          this.fieldConfigs.length = maxL;
          for (let i = 0; i < this.fieldConfigs.length; i++) {
            if (this.fieldConfigs[i] == null) this.fieldConfigs[i] = "";
          }
        }
        this.errList = [];
        if (!this.checkFeildConfig()) {
          this.$message({
            message: "数据映射有相同的字段!",
            type: "error",
          });
        }
        this.checkData();

        await this.onInit();

        this.$message({
          message: "文件解析成功!",
          type: "success",
        });
      } else {
        this.$message({
          message: `上传失败,${res.logicmsg}`,
          type: "error",
        });
      }
      this.loading = false;
    },
    checkFeildConfig() {
      //校验数据
      let cols = this.$refs.table.getTableHeaderSelect();
      //检查有没有字段重复设置
      let tmpCols = cols.filter((item) => item != ""); //去空

      if (tmpCols.length === _.uniq(tmpCols).length) {
        return true;
      } else {
        try {
          tmpCols.forEach((item, idx) => {
            const fi = tmpCols.findIndex(
              (it, fidx) => fidx != idx && it == item
            );
            if (fi > -1) {
              // this.tableFields.find(it=>it.FieldName==item).FieldCName
              this.errList.push({
                page: 0,
                row: 0,
                desc: `字段设置错误！字段"${
                  this.tableFields.find((it) => it.FieldName == item).FieldCName
                }"选择了多次。`,
              });
              throw new Error();
            }
          });
        } catch {}

        return false;
      }
    },
    checkData() {
      //校验数据
      let cols = this.$refs.table.getTableHeaderSelect();
      //检查数据
      this.upladedData.forEach((item, idx) => {
        // this.upladedData[idx]._status = pass ? '<span style="color: darkgreen">验证通过</span>' : '<span style="color: red">验证出错</span>'
        if (!item._imported) {
          //如果没有导入
          const c = this.checkDataRow(item);
          if (c !== true) {
            let pageIndex = Math.ceil((idx + 1) / this.pageSize);
            let count = idx + 1 - (pageIndex - 1) * this.pageSize;
            this.errList.push({
              page: pageIndex,
              row: count,
              desc: `第${pageIndex}页 第${count}行 ${c}`,
            });
          }
          this.upladedData[idx]._status = c === true ? "通过" : "验证出错！";
          this.upladedData[idx]._verified = c === true;
        } else {
          this.upladedData[idx]._status = "已导入";
          this.upladedData[idx]._verified = true;
        }
      });
    },
    async errListClick(err) {
      if (err.page && err.page > 0) {
        await this.onBindData(err.page);
        this.$refs.table.jumpRow(err.row);
      }
    },
    //校验每行数据，返回true或者出错信息
    checkDataRow(row) {
      //校验数据
      let cols = this.$refs.table.getTableHeaderSelect();
      let pass = true;

      //遍历表字段
      try {
        this.tableFields.forEach((field) => {
          const f = cols.findIndex((it) => it == field.FieldName);
          if (f <= -1) {
            //找不到
            //如果必须
            if (field.IsMust) {
              pass = `"${field.FieldCName}"不能为空！`;
              return pass;
            }
          } else {
            //找到
            let data = row[`column${f}`];
            if (field.IsMust) {
              pass =
                data && data != "" ? true : `"${field.FieldCName}"不能为空！`;
            } else if (field.RegStr != "" && data) {
              let re = new RegExp(field.RegStr);
              pass = re.test(data) ? true : `"${field.FieldCName}"不合法！`;
            }
          }
        });
      } catch {}
      return pass;
    },
    async onUploadError(err, file, fileList) {
      this.$message({
        message: "上传文件失败!" + err,
        type: "error",
      });
      this.loading = false;
    },
    async pasteExcel() {
      // const clipboardObj = navigator.clipboard;
      // if(clipboardObj){
      //   try{
      //     const text = await clipboardObj.readText();
      //   }catch{
      //   }
      // }
      this.showPaste = true;
      this.txtPaste = "";
    },
    async onPasteExcel(txt) {
      //处理
      const arr = txt.split("\n");
      arr.reverse().forEach((item) => {
        if (item.length > 0) {
          const larr = item.split("\t");
          let obj = {
            _status: "",
            _verified: false,
            _id: _.uniqueId(),
            _imported: false,
            _err: "",
          };
          larr.forEach((it, idx) => {
            obj["column" + idx] = it;
          });
          this.upladedData.unshift(obj);
        }
      });
      this.onInit(false);
      this.errList = [];
      this.checkData();
      this.showPaste = false;
    },
    async fieldChange(checked, fieldName) {},
    async startImport() {
      if (this.importing) {
        //取消...
        // this.importing = false;
        this.importCancel = true;
        // this.importTitle = '开始导入'
      } else {
        //开始导入

        //#region 校验数据
        if (
          this.upladedData.length < 1 ||
          this.upladedData.filter((item) => !item._imported).length < 1
        ) {
          //没数据或者都已经导入了
          this.$message({
            message: "没有需要导入的数据!",
            type: "error",
          });
          return;
        }
        let cols = this.$refs.table.getTableHeaderSelect();
        if (cols.filter((item) => item != "").length < 1) {
          this.$message({
            message: "请至少选择一个映射的字段!",
            type: "error",
          });
          return;
        }
        this.errList = [];
        if (!this.checkFeildConfig()) {
          this.$message({
            message: "数据映射有相同的字段!",
            type: "error",
          });
          return;
        }
        this.checkData();
        if (this.errList.length > 0) {
          //有错误，确定导入操作
          try {
            await this.$confirm(
              "需要导入的数据部分不合法，您确定执行导入吗？",
              "提示",
              {
                confirmButtonText: "确定导入",
                cancelButtonText: "取消",
                type: "warning",
                // dangerouslyUseHTMLString: true
              }
            );
          } catch {
            return;
          }
        }
        //#endregion

        //#region 准备数据
        this.importObjs = [];
        this.importObjs = this.upladedData
          .filter((item) => !item._imported)
          .map((item) => {
            let obj = {
              _status: item._status,
              _verified: item._verified,
              _id: item._id,
              _imported: item._imported,
              _err: "",
            };
            cols.forEach((it, idx) => {
              if (it != "") {
                obj[it] = item[`column${idx}`] ? item[`column${idx}`] : "";
              }
            });
            return obj;
          });
        //#endregion

        //开始导入
        this.importing = true;
        this.importTitle = "取消";
        this.showProgess = true;
        this.txtProgress = "开始导入...";
        this.importPercent = 10;
        this.txtProgress = "数据准备完成。";
        this.importPageCount = Math.ceil(
          this.importObjs.length / this.importPageSize
        );

        let importFail = false; //导入失败
        for (let i = 0; i < this.importPageCount; i++) {
          if (this.importCancel) {
            break;
          }
          this.importPercent = Math.floor(10 + (i * 90) / this.importPageCount);
          this.txtProgress = `正在处理第${i + 1}页 每页${
            this.importPageSize
          }条，导入数据提交...`;
          let start = this.importPageSize * i;
          let end = start + this.importPageSize - 1;
          if (end > this.importObjs.length) end = this.importObjs.length - 1;
          let pageImport = this.importObjs.filter(
            (item, idx) => idx >= start && idx <= end
          );
          const params = {
            ImportType: this.typeKey,
            Data: pageImport,
          };
          try {
            //提交数据
            await this.$post("DataImport/ImportData", params, (data, logic) => {
              if (logic && logic.code == 200) {
                let res = data;
                //处理返回的数据
                res.map((item) => {
                  const f = this.upladedData.find((it) => it._id == item._id);
                  if (f) {
                    f._imported = item._imported;
                    f._err = item._err;
                    f._status = item._imported ? "已导入" : item._err;
                    f._verified = item._imported;
                  }
                });
              } else {
                importFail = true;
                this.$message({
                  message: `处理第${i + 1}页，导入数据错误。${logic.msg}`,
                  type: "error",
                });
              }
            });
            if (this.importCancel) break;
          } catch {
            importFail = true;
            this.$message({
              message: `处理第${i + 1}页，导入数据错误。`,
              type: "error",
            });
            if (this.importCancel) break;
          }
          this.importPercent = Math.floor(
            10 + ((i + 1) * 90) / this.importPageCount
          );
          this.txtProgress = `第${i + 1}页导入数据导入完成。`;
        }
        this.txtProgress = "数据导入完成！";
        // this.importPercent = 100;
        this.importing = false;
        this.importCancel = false;
        this.importTitle = "开始导入";
        setTimeout(() => {
          this.showProgess = false;
          this.txtProgress = "";
        }, 2000);
        if (!importFail) {
          this.$message({
            message: "数据导入完成",
            type: "info",
          });
        }
      }
    },

    //#region table
    async tableHeaderSelectChange(e) {
      this.errList = [];
      if (!this.checkFeildConfig()) {
        this.$message({
          message: "数据映射有相同的字段!",
          type: "error",
        });
      }
      this.checkData();
    },
    async currentRowChange(e) {},
    async cellEditDone(newValue, oldValue, rowIndex, rowData, field) {
      if (newValue !== oldValue) {
        //如果修改了，则校验数据是否合格
        let data = this.upladedData[rowIndex];
        //data['_status'] = this.checkDataRow(data) ? '通过' : '验证出错！';
        data["_imported"] = false;
        this.$set(this.itemSource, rowIndex, data);
        this.$nextTick(() => {
          this.$refs.table.cleanEdit();
          this.errList = [];
          this.checkFeildConfig();
          this.checkData();
        });
      }
    },
    //生成空行数据
    blankRow(num) {
      if (num > 0) {
        let row = {};
        for (var i = 0; i < num; i++) {
          row["column" + i] = null;
        }
        return row;
      } else return {};
    },
    //补足数据
    completeData(num) {
      this.upladedData.forEach((item) => {
        // if(!)
      });
    },
    //添加一行
    async addRow() {
      this.upladedData.unshift({
        // ...this.blankRow(this.fieldConfigs.length),
        ...{
          _status: "",
          _verified: false,
          _id: _.uniqueId(),
          _imported: false,
          _err: "",
        },
      });
      // this.$refs.table.addRow()
      this.upladedData[0]["_status"] = this.checkDataRow(this.upladedData[0])
        ? "通过"
        : "验证出错！";
      this.errList = [];
      this.checkFeildConfig();
      this.checkData();
      await this.onBindData(1);
      this.$refs.table.jumpRow(1);
      // this.checkDataRow(this.upladedData[0]);
    },
    async onInit(initColumn = true) {
      this.loading = true;

      if (initColumn) {
        this.onInitColumn();
      }

      this.$refs.table.init();
      this.afterGridInit && this.afterGridInit();

      await this.onBindData(1);
      // this.itemSource = this.upladedData.map(item=>{
      //   return {...item, ...{
      //     _status: ""
      //   }}
      // })
      this.loading = false;
      // this.$emit("page-loading", false);
    },

    async onInitColumn() {
      //载入表头设置
      let columns = [];
      columns.push({
        // "GCGuid": "1481266d-2762-421f-9b7f-8f5911b2122e",
        // "PGGuid": "a41b9c88-3ab2-412d-a5ae-94e6aeebe434",
        CTitle: "状态",
        BindField: "_status",
        IsShow: true,
        IsReadonly: true,
        ColumnWidth: 100,
        // "DisplayIndex": 1,
        DataType: 1,
        IsSystem: false,
        // "ExtenValue": "{\"cellColors\":[]}",
        ExtenValue:
          '{"cellColors":[{"color":"red","expression":"{_verified}==false"},{"color":"darkgreen","expression":"{_imported}==true"}]}',
        // "CreatedTime": "2018-04-24 10:12:14",
        SCTitle: "状态",
        IsExprot: false,
        IsEditShow: false,
        isFrozen: true,
      });

      columns = columns.concat(
        this.fieldConfigs
          .map((item) => {
            return this.hasValue(item)
              ? this.tableFields.find((f) => f.FieldName == item).FieldCName
              : "";
          })
          .map((item, idx) => {
            return {
              CTitle: item ? item : "",
              BindField: `column${idx}`,
              IsShow: true,
              IsReadonly: false,
              ColumnWidth: 100,
              DataType: 1,
              IsSystem: false,
              SCTitle: item ? item : "",
              IsExprot: false,
              IsEditShow: false,
              //表头设置
              header: {
                options: [{ key: "<选择字段>", value: "" }].concat(
                  this.fieldOpts
                ),
                value: this.fieldConfigs[idx] ? this.fieldConfigs[idx] : "",
              },
              showOrderby: false,
            };
          })
      );

      this.columns = columns;
    },

    async onBindData(pageIndex = 1) {
      this.pageIndex = pageIndex;
      this.totalCount = this.upladedData.length;

      let start = (pageIndex - 1) * this.pageSize;
      let end = pageIndex * this.pageSize;
      let count = this.pageSize;
      if (end > this.totalCount) {
        end = this.totalCount;
        count = this.totalCount - pageIndex * this.pageSize;
      }
      this.itemSource = this.upladedData.filter(
        (item, idx) => idx >= start && idx < end
      );
    },
    async selectChange(rows) {
      this.selectedRows = rows;
    },
    async delRow() {
      if (this.selectedRows.length > 0) {
        try {
          await this.$confirm("您确定要删除这些数据吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            // dangerouslyUseHTMLString: true
          });
          //删除
          this.selectedRows.forEach((item) => {
            const f = this.upladedData.findIndex((it) => it._id === item._id);
            if (f > -1) {
              this.upladedData.splice(f, 1);
            }
          });
          this.$refs.table.cleanAllCheck();
          this.selectedRows = [];
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          this.errList = [];
          this.checkFeildConfig();
          this.checkData();
          this.onBindData();
        } catch {}
      } else {
        this.$message({
          message: "请至少选择一个数据！",
          type: "warning",
        });
      }
    },
    //#endregion
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.wp {
  * {
    font-size: 12px;
    line-height: 1.5;
  }
  fieldset {
    border-radius: 4px;
    border: 1px #bbb solid;
  }

  .f-list {
    label.el-checkbox {
      &:first-child {
        margin: 0 5px !important;
      }

      &.fix-width {
        width: 90px;
      }
    }
  }

  .sel-scroll {
    height: 29px;
    width: 960px;
    overflow-x: auto;
    white-space: nowrap;
  }

  .wp-err {
    border: #f8bbbb 1px solid;
    margin-top: 2px;
    height: 330px;
    .title {
      padding: 4px;
      background-color: #f8bbbb;
    }

    ul {
      height: 300px;
      overflow-y: auto;
      li {
        padding: 2px 4px;
        border-bottom: #ffd4d4 1px solid;
        cursor: pointer;

        &:hover {
          background-color: #ffd4d4;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.sel-scroll {
  &::-webkit-scrollbar {
    /* 滚动条整体部分 */
    width: 4px !important;
    height: 4px !important;
    /* background-color: #F5F5F5; */
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
  }
}
.znl-data-import {
  .znl-dialog__body {
    padding: 2px 8px !important;
  }

  .progress-bar {
    .el-progress__text {
      font-size: 12px !important;
    }
  }

  //radio样式调整
  .el-radio + .el-radio {
    margin-left: 0 !important;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #ee7700;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #ee7700;
    background: #ee7700;
  }

  .el-radio__inner {
    width: 10px;
    height: 10px;
  }

  .el-checkbox__inner:hover,
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #ee7700;
  }
  .el-radio__label {
    font-size: 12px;
    line-height: 22px;
    padding: 0 10px 0 4px;
  }
}
.znl-data-import-paste {
  .znl-dialog__body {
    padding: 2px 8px !important;
  }

  textarea {
    padding: 3px;
  }
}
</style>
