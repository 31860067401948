<template>
  <div class="stock-query-box" :style="{height:height}">
    <znl-table ref="table"
      gridtype="base"
      :header="header"
      box-class="stock-query-box"
      :columns="columns"
      :item-source="itemSource"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :on-page-changed="onBindData"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      @on-show-trigger="e => {hideShowColTrigger('StockVMIMini', e.col)}"
      :drag-done="col=>setTableColWidth('StockVMIMini', col)"
      @drag-col-position="(cols)=>{columns = cols, saveErpTableConfig('StockVMIMini', cols, true)}"
      @on-config-save="(cols)=>{columns = cols, saveErpTableConfig('StockVMIMini', cols, false)}"
      @on-reset-click="resetTableConfig('StockVMIMini')"
      @dbclick="e => {$emit('dbl-click', e.rowData)}"
      >
    </znl-table>
  </div>
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
const CONFIG = {
  configURL: 'StockVMIMini/GetConfig',
  saveConfigURL: 'StockVMIMini/SaveConfig',
  resetConfigURL: 'StockVMIMini/ResetConfig',
  searchURL: 'StockVMIMini/Search'
}
export default {
  name: 'StockVMIMini',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  config: CONFIG,
  components: {},
  data () {
    return {
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      loading: false,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1'
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    isMini: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    header: {
      type: String,
      default: () => {
        return ''
      }
    },
    isFieldsSearch: {
      type: Boolean,
      default: true
    },
    parentPageSize: {
      type: Number,
      default: 0
    },
    height: String,
    isVisibleAdopt: {
      // 是否显示采购列
      type: Boolean,
      default: false
    },
    hasOperationColumn: {
      type: Boolean,
      default: true
    },
    isAdopt: {
      type: Boolean,
      default: false
    },
    afterGridInit: { // 表格初始化完成后调用
      type: Function
    }
  },
  methods: {
    onInit: async function (item) {
      this.loading = true
      let config = await this.isSaveStorage('StockVMIMini', CONFIG.configURL)
      let columns = config.ColumnConfigs
      // 添加采纳列
      // this.hasOperationColumn &&
      //   columns.unshift({
      //     name: 'IsAdoptStkTxt',
      //     dataType: 5,
      //     binding: 'IsAdoptStkTxt',
      //     width: 50,
      //     header: '采纳',
      //     isFixed: false,
      //     visible: this.isVisibleAdopt,
      //     allowSorting: false,
      //     isFieldsSearch: false,
      //     isSystem: true,
      //     buttons: [
      //       {
      //         content: d => {
      //           return d.IsAdoptStk ? '已采纳' : '采纳'
      //         },
      //         className: '',
      //         click: row => {
      //           this.onAdopt(row.VMGUID)
      //           this.$emit('ondblclick', row)
      //         }
      //       }
      //     ]
      //   })

      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      let noSeeSupplier = !this.getSpecialResourceByCode('NoSeeSupplier')

      // 移除列
      _.remove(columns, item => {
        if (noSeeSupplier && item.BindField === 'SupplierName') {
          return true
        }
      })

      this.columns = columns
      this.defaultConfig = config
      if (this.parentPageSize > 0) {
        config.PageSize = this.parentPageSize
      }
      this.pageSize = config.PageSize
      this.role = config.Role
      this.$refs.table.init()
      this.afterGridInit && this.afterGridInit()
      this.initData && this.onBindData(1)
      this.loading = false
    },
    onBindData (pageIndex) {
      this.loading = true
      let fieldLast = _.extend({}, this.searchModel)
      const {Keywords, KeywordsField} = this.searchModel
      if (Keywords && KeywordsField) {
        fieldLast[KeywordsField] = Keywords
        delete fieldLast.Keywords
        delete fieldLast.KeywordsField
      }
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(fieldLast), // fieldsFilter
          AdoptstkVMIGUID: this.searchModel.AdoptstkVMIGUID,
          PageIndex: pageIndex,
          PageSize: this.pageSize
        }
      )
      return this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      }).finally(() => {
        this.loading = false
      })
    },
    onSearch (params) {
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },

    onHeadSearch (param = {}) {
      this.searchModel.Model = param.Model || ''
      this.searchModel.Brand = param.Brand || ''
      this.searchModel.Packaging = param.Packaging || ''
      this.searchModel.IsZero = param.IsZero || false
      this.searchModel.CustomerName = param.CustomerName || ''
      this.searchModel.Keywords = param.Keywords || ''
      this.searchModel.KeywordsField = param.KeywordsField || ''
      this.onBindData(1)
    },

    onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stockVMI_MIni')
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, CONFIG.default)
      )
    },
    onResetSetting () {
      localStorage.removeItem('stockVMI_MIni')
      return this.$post(CONFIG.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('stockVMI_MIni')
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, CONFIG.default)
      )
    },
    onAdopt (VMGUID) {
      this.$emit('ChkVMIGUID', VMGUID)
    },
    onAdoptEnd (VMGUID) {
      // 采纳后改变状态
      this.searchModel.AdoptstkVMIGUID = VMGUID
      this.onRefresh()
    }
  },
  async mounted () {
    this.onInit()
  }
}
</script>

<style lang="scss">

</style>

