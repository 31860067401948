import {
  nativeInvoke
} from '~/lib/runtime'

/**
 * 获取客户端的 SessionId
 * @returns 客户端的 SessionId
 */
export async function getSessionId () {
  const signal = 'getSession'
  const res = await nativeInvoke(signal)
  return res['SessionID']
}

/**
 * 获取客户端的 erp 授权信息
 * @returns erp授权信息
 */
export async function getErpAuthInfo (isRefresh = null) {
  const signal = 'getErpAuthInfo'
  let param = {IsRefresh: isRefresh}
  const res = await nativeInvoke(signal, param)
  return res
}

/**
 * 拨打指定电话号码
 * @param  {} phoneNumber 拨出的电话号码
 */
export async function dial (phoneNumber) {
  const signal = 'dial'
  await nativeInvoke(signal, {
    phoneNumber
  })
}

/**
 * 切换指定页面到旧版
 * @param  {} name 页面处理器名称
 */
export async function switchToLegacyPage (name) {
  const signal = 'switchToLegacyPage'
  await nativeInvoke(signal, {
    name
  })
}

/**
 * 切换到指定的页面
 * @param {} tabName 页签名称
 */
export async function switchTab (tabName, params) {
  const signal = 'switchTabPage'
  let param = _.extend(
    {},
    params,
    {
      tabName: tabName
    }
  )
  await nativeInvoke(signal, param)
}

