<template>
<div>
  <znl-dialog :visible="visible&&dialogShow"
              width="540px"
              height="200px"
              title="上传图片"
              :on-init="onInit"
              :append-to-body="true"
              :is-footer-show="false"
              @close="()=>{this.dialogShow = false, this.isRefresh = !this.isRefresh, $emit('close')}">
    <div slot="znl-dialog" class="img-upload">
      <el-row>
        <el-col class="tips-color">
          <el-row>
            1. 图片可以多选；如果要多选，按住ctrl键，再选取相应的图片
          </el-row>
        </el-col>
        <!-- <el-col class="mt20 tips-color">
          <el-row>
            2. 图片的文件名，即为库存的型号，系统会自动匹配到相应的型号上面
          </el-row>
        </el-col> -->
        <el-col class="mt20 tips-color">
          <el-row>
            2. 图片的后缀需为：<span v-for="item in accept.split(',')" :key="item">{{item.substr(1)}} </span>
          </el-row>
        </el-col>
         <el-col class="mt20 tips-color">
          <el-row>
            3. 一次性上传图片数量不能大于最大的限制量({{maxImageCount}}张)
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="mt20">
          <el-row>
            <v-upload ref="vUpload"
              :accept="accept"
              width="316px"
              :isMultiple="true"
              :folder="folder"
              select-tips="选择图片"
              :refresh="!isRefresh"
              :refresh-click="refreshClick"
              @upload-before="onUploadClick"
              @upload-completed="onUploadCompleted"
            />
          </el-row>
        </el-col>
      </el-row>
    </div>
  </znl-dialog>
</div>
</template>

<script>
import { mixin as propSyncMixin } from '@scripts/mixins/propSync'
import vUpload from '@c_modules/StkExt/Upload'

export default {
  name: 'StockImageUpload',
  mixins: [propSyncMixin],
  components: {
    vUpload
  },
  data () {
    return {
      config: {
        progressUrl: 'StockImage/Add'
      },
      folder: '',
      accept: '.jpg,.jpeg,.bmp,.png,.gif',
      dialogShow: false,
      isRefresh: false,
      fileList: [],
      maxImageCount: 50, // 每次上传最大图片数量
      refreshClick: false // 刷新上传组件的上次的单击动作
    }
  },
  props: {
    loading: Boolean,
    visible: Boolean,
    imageStkGUID: String
  },
  watch: {
    visible (val) {
      if (val) {
        this.dialogShow = true
        this.isRefresh = !this.isRefresh
      }
    }
  },
  methods: {
    async onInit () {
      let d = new Date()
      let yy = d.getFullYear().toString()
      let MM = d.getMonth() + 1
      let dd = d.getDate()
      if (MM < 10) {
        MM = '0' + MM.toString()
      }
      if (dd < 10) {
        dd = '0' + dd.toString()
      }
      let dstr = yy + MM.toString() + dd.toString()
      this.folder = 'erp-stock-img/' + this.$store.state.accountInfo.CompanyID + '/' + dstr
    },

    onCancel () {
      this.dialogShow = false
      this.$emit('close')
    },

    // 单击上传按钮的时候
    onUploadClick (fileName, fileSize, filesArry) {
      if (fileName === '' || fileName === null || _.isUndefined(fileName)) {
        return this.$message({ message: '请至少选择一张图片', type: 'warning' })
      }
      if (filesArry.length >= this.maxImageCount) {
        return this.$message({ message: '上传图片数量不能大于最大限制量!', type: 'warning' })
      }
      let isPass = true
      _.each(filesArry, file => {
        if (!this.hasValue(file.fileName)) {
          isPass = false
          this.$message({ message: '请至少选择一张图片', type: 'warning' })
          return false
        }
        let name = file.fileName.toLowerCase()
        let index = name.lastIndexOf('.')
        if (index > 0) {
          name = name.substr(index)
        }
        if (_.indexOf(this.accept.split(','), name) === -1) {
          isPass = false
          this.$message({ message: '选中的文件中有非图片文件', type: 'warning' })
          return false
        }
      })
      if (!isPass) {
        return
      }

      this.refreshClick = !this.refreshClick
    },
    // 文件上传完成之后
    onUploadCompleted (fileName, fileSize, fileUrl, filesArry) {
      var imglist = _.map(filesArry, m => {
        return {
          FileName: m.fileName,
          FileUrl: m.fileUrl,
          StkGUID: this.imageStkGUID
        }
      })
      return this.$post(this.config.progressUrl, { ImageList: imglist }, data => {
        if (data > 0) {
          this.$message({ message: '图片上传成功', type: 'success' })
          this.$emit('confirm', true)
        } else {
          this.$message({ message: '上传失败', type: 'error' })
        }
      })
    }
  },
  created () {
    this.onInit()
  }
}
</script>

<style lang="scss" scoped>
.img-upload {
  >div{
    margin-left: 8px;
  }
  .upload {
    margin-top: 10px;
  }
  .mt20 {
    //padding-top: 10px;
    // margin-top: 10px;
    margin-top: 8px;
  }
  .tips-color {
    color: #bfbfbf;
  }
}
</style>
