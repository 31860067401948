<template>
  <znl-dialog
  width="588px"
  height="330px"
  :visible="visible"
  title="设置或取消库存标记"
  v-loading="loading"
  :append-to-body="true"
  :element-loading-text="loadingText"
  @close="()=>{$emit('close')}">
    <div slot="znl-dialog" class="stock-mark-box">
      <el-row :gutter="20">
        <el-col :span="26">
          <el-radio-group v-model="MarkToType" size="small" class="form-group">
            <el-radio label="selected">对已选中的库存做处理 ({{checkCount}})</el-radio>
            <el-radio label="where">对符合查询条件的库存做处理 ({{markingCount}})</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="26" style="width:100%">
          <fieldset style="height:auto;width:auto; border:1px solid #ccc">
            <legend>自定义标记 (<span style="color:#a7a7a7">可多选</span>)</legend>
            <div class="znl-labels">

              <div class="tag-add-box" v-if="this.$store.state.accountInfo.IsMainAccount">
                <span class="iconfont icon-add_btn_ic" v-show="!showAddBox" @click="openAddMarkBox" title="点击添加新的自定义标签"></span>
                <span class="iconfont icon-arrow_left_btn" v-show="showAddBox" @click="()=>{this.showAddBox=false}" title="隐藏"></span>
                <div style="width:200px;display: inline-block;" v-show="showAddBox">
                  <span style="font-size:12px;" v-show="showAddBox">标记名称：</span>
                  <znl-input
                    ref="addMarkingName"
                    placeholder="回车确定"
                    @keyup.enter.native="AddMarking"
                    width="120"
                    layout="left"
                    :clearable="true"
                    v-model="addMarkingName"
                    v-show="showAddBox"
                    type="text">
                  </znl-input>
                </div>
              </div>

              <el-button :class="{'label-checked': MarkingCheck.indexOf(item) > -1, 'label':true, 'znl-custom-tag' : true }"
                          v-if="item"
                          v-for="item in stkMarkingList"
                          :key="item"
                          :lable="item"
                          @click="onMarkingClick(item)">
                {{item}}
                <span class="el-icon-close"
                  title="点击删除该标签"
                  v-if="$store.state.accountInfo.IsMainAccount"
                  @click.stop="deleteMark(item)"
                  ></span>
              </el-button>

            </div>
          </fieldset>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-if="this.$store.state.accountInfo.IsMainAccount" >
        <!-- <el-col :span="3" style="width:70px;">
          标记名称:
        </el-col>
        <el-col :span="8">
            <znl-input
              placeholder="以上若无勾选项,在此可添加标记名称！"
              @keyup.enter.native="AddMarking"
              width="100%"
              layout="left"
              :clearable="true"
              v-model="addMarkingName"
              type="text">
            </znl-input>
        </el-col> -->
        <el-col :span="22" style="margin-top:8px;">
          <!-- <el-button type="default-rev"
                      size=""
                      class="znl-btn-mini-auxiliary"
                      @click="AddMarking">
            <i class="iconfont icon-add_btn_ic"></i>
            <span class="ver">新增</span>
          </el-button>
          <el-button type="default-rev"
            size=""
            class="znl-btn-mini-auxiliary"
            @click="deleteMark">
            <i class="iconfont icon-delete_btn_ic"></i>
            <span class="ver">删除</span>
          </el-button> -->
          <!-- <el-button type="default-rev"
            size=""
            class="znl-btn-mini-auxiliary"
            :disabled="isShowLoading"
            @click="onClearStkMarking('extend')">
            <i class="iconfont icon-delete_btn_ic"></i>
            <span class="ver">清除推广标记</span>
          </el-button>

          <el-button type="default-rev"
            size=""
            class="znl-btn-mini-auxiliary"
            :disabled="isShowLoading"
            @click="onClearStkMarking('subscript')">
            <i class="iconfont icon-delete_btn_ic"></i>
            <span class="ver">清除订阅标记</span>
          </el-button> -->
          <znl-button  style-type="mac" :height='22' @click="onClearStkMarking('custom')">
              <i class="iconfont icon-delete_btn_ic"></i><span>清除库存的自定义标记</span>
          </znl-button>
          <!-- <el-button type="default-rev"
            size=""
            class="znl-btn-mini-auxiliary"
            :disabled="isShowLoading"
            @click="onClearStkMarking('custom')">
            <i class="iconfont icon-delete_btn_ic"></i>
            <span class="ver">清除库存的自定义标记</span>
          </el-button> -->

        </el-col>
      </el-row>
    </div>

    <div slot="footer">
      <znl-button :height="33" :width="60" style-type="main" @click="onSubmitStkMarking">保存</znl-button>
    </div>
  </znl-dialog>
</template>
<script>

export default {
  name: 'StkStockMarking',
  components: {

  },
  data () {
    return {
      isShowLoading: false,
      checkItem: '', // 选中的标签项
      stkMarkingList: [], // 库存标记
      MarkingCheck: [], // 选中的值
      config: {
        searchURL: 'StkStockMarker/Search', // 获取已有标记
        saveMarkingURL: 'StkStockMarker/Add', // 保存标记
        deleteMarkingURL: 'StkStockMarker/Delete', // 删除标记
        onSubmitStkMarkingURL: 'StkStockMarking/SubmitStkMarking', // 提交库存标记
        onClearStkMarkingURL: 'StkStockMarking/ClearStkMarking', // 清除库存标记
        onSubmitStkVMIMarkingURL: 'StockVMI/SubmitStkVMIMarking', // 供应商库存标记
        onClearStkVMIMarkingURL: 'StockVMI/ClearStkVMIMarking', // 供应商库存标记
        onSubmitStockSummaryMarkingURL: 'StockSummary/SetMarking', // 汇总库存
        onClearStockSummaryMarkingURL: 'StockSummary/ClearMarking', // 清楚汇总库存标记
        searchStockCountURL: 'StkStock/Search', // 根据条件查询库存记录数
        searchStockVMIURL: 'StockVMI/Search', // 根据条件查询供应商库存记录
        searchStockSummaryURL: 'StockSummary/GetCount' // 根据条件查询汇总库存记录数
      },
      addMarkingName: '', // 添加标记名称
      checkCount: 0, // 选中的条数
      markingCount: 0, // 符合条件的条数
      MarkToType: 'selected', // 标记的方式
      MarkToObj: {}, // 标记的对象
      subscriptionTypes: [{ key: 1, value: 'A' }, { key: 2, value: 'B' }, { key: 3, value: 'C' }],
      extendType: 0, // 推广类型
      subTypeChecked: [], // 已选择的订阅类型
      isCancelSubscript: false, // 是否取消订阅
      allMarkNames: [], // 当前公司拥有的所有标记
      loading: false,
      loadingText: '正在加载数据，请稍后...',
      showAddBox: false // 是否显示添加标记
    }
  },
  props: {
    markingType: String, // 标记类型
    stockSearchWhere: String, // 根据筛选条件查询
    selectStockList: String, // 根据选中的值查询
    VMGUID: Array, // 供应商库存传递的GUID列表
    stkGUID: Array, // 库存传递的GUID列表
    stockMarkParam: Object, // 库存传传递的需要标记的条件
    stockSummaryParam: Object, // 汇总库存传递的需要标记的条件
    isRefresh: Boolean, // 是否刷新列表
    stkVMIGUIDS: Array, // 供应商库存
    isRefreshVMI: Boolean, // 根据供应商刷新
    visible: Boolean,
    stockVMIMarkParam: Object,  // 供应商标记参数
    sumGuids: Array, // 汇总库存传递的GUID列表
    extendTypeSelected: Number, // 已勾选的推广类型
    subscriptTypeSelected: Array, // 已勾选的订阅类型
    markNamesSelected: String, // 已勾选的自定义标签
    hasExtendRight: Boolean,  // 是否有管理推广库存的权限
    hasSubscriptRight: Boolean // 是否有管理订阅库存的权限
  },
  watch: {
    isRefresh: {
      handler (value) {
        this.addMarkingName = ''
        if (!_.isEmpty(this.stkGUID)) {
          this.markingCount = this.stkGUID.length
          this.MarkToObj = { StkGUIDS: this.stkGUID }
          this.checkCount = this.stkGUID.length
        } else if (!_.isEmpty(this.stockMarkParam)) {
          this.MarkToObj = this.stockMarkParam
        }
        if (this.markingType === 'stockSummary') {
          this.markingCount = this.sumGuids.length
          this.checkCount = this.sumGuids.length
        } else if (!_.isEmpty(this.stockSummaryParam)) {
          this.MarkToObj = this.stockSummaryParam
        }
      },
      immediate: true
    },
    isRefreshVMI: {
      handler (value) {
        this.addMarkingName = ''
        if (!_.isEmpty(this.stkVMIGUIDS)) {
          this.markingCount = this.stkVMIGUIDS.length
          this.checkCount = this.stkVMIGUIDS.length
          this.MarkToObj = { VMGUIDS: this.stkVMIGUIDS }
        } else if (!_.isEmpty(this.stockVMIMarkParam)) {
          this.MarkToObj = this.stockVMIMarkParam
        }
      },
      immediate: true
    }
  },
  methods: {
    async onInit () {
      this.loading = true
      this.onSearchStockCount()
      this.extendType = this.extendTypeSelected
      this.subTypeChecked = []
      this.MarkingCheck = []

      if (this.subscriptTypeSelected && this.subscriptTypeSelected.length > 0) {
        _.each(this.subscriptTypeSelected, m => {
          if (m !== '' && !_.isUndefined(m) && m !== null) {
            this.subTypeChecked.push(m)
          }
        })
      }

      // 默认选中的自定义标签
      if (this.hasValue(this.markNamesSelected)) {
        this.MarkingCheck = this.markNamesSelected.split(',')
        this.checkItem = this.markNamesSelected
      }

      this.onLoadPage()
    },
    onLoadPage () { // 加载标记选择框
      this.loading = true
      this.$post(this.config.searchURL, { IsCustom: true }, (datas) => {
        this.allMarkNames = []
        this.stkMarkingList = []
        if (datas.length > 0) {
          this.allMarkNames = datas
          this.stkMarkingList = _.map(this.allMarkNames, m => m.MarkerName)
        }
      }).finally(() => {
        this.loading = false
      })
    },

    onSubmitStkMarking () {
      if (this.MarkToType === 'where' && this.markingCount > 2000) {
        this.$confirm('符合查询条件的库存为' + this.markingCount + '条，更新可能会花费较长时间。是否继续？', '提示', {
          confirmButtonText: '继续',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.onConfirmSubmit()
          })
      } else {
        this.onConfirmSubmit()
      }
    },

    // 提交并更新库存
    async onConfirmSubmit () {
      if (this.checkCount === 0 && this.MarkToType === 'selected') {
        this.$message({ message: '请先选中库存', type: 'warning' })
        return false
      }

      if (this.markingCount === 0 && this.MarkToType === 'where') {
        this.$message({ message: '符合查询条件的库存为0条，无法设置标记', type: 'warning' })
        return false
      }

      // 重新清理下订阅标签的顺序
      if (this.subTypeChecked.length > 0) {
        let newChecked = []
        _.each(this.subscriptionTypes, item => {
          if (_.some(this.subTypeChecked, t => t === item.key)) {
            newChecked.push(item.key)
          }
        })
        this.subTypeChecked = newChecked
      }

      this.MarkToObj = _.extend(this.MarkToObj, {
        SType: this.extendType,
        SubscriptionTypes: this.subTypeChecked,
        SubscriptionType: this.subTypeChecked.join(','),
        MarkName: this.MarkingCheck.join(',')
      })
      if (this.markingType === 'stockSummary') {
        if (this.MarkToType === 'selected') {
          this.MarkToObj.SUMGuids = this.sumGuids
          // 如果是单条操作，订阅库存的标记全部没勾选，则清除该条库存的订阅库存。如果推广的标记全部没勾选，则清除该条库存的推广标记
          if (this.sumGuids.length === 1) {
            if (this.subTypeChecked.length === 0) {
              this.MarkToObj.SubscriptionTypes = [-1]
            }
            if (this.MarkToObj.SType === 0) {
              this.MarkToObj.SType = -1
            }
          }
        } else {
          this.MarkToObj = _.extend(this.MarkToObj, this.stockSummaryParam)
        }
        if (this.isCancelSubscript) {
          this.MarkToObj.SubscriptionTypes = [-1]
        }
      }
      this.isShowLoading = true

      let url = this.config.onSubmitStockSummaryMarkingURL
      let _this = this

      switch (this.markingType) {
        case 'stock':// 我的库存
          url = this.config.onSubmitStkMarkingURL
          break
        case 'stkVMI':// 供应商库存
          url = this.config.onSubmitStkVMIMarkingURL
          break
        case 'stockSummary':// 库存汇总
          url = this.config.onSubmitStockSummaryMarkingURL
          break
      }
      this.loadingText = '正在设置标记，请稍后...'
      this.loading = true
      this.$post(url, this.MarkToObj, (data, logic) => {
        if (logic.code === 200) {
          if (data === 0) {
            this.$message({ message: '标记失败', type: 'error' })
          } else {
            this.$message({ message: '标记成功', type: 'success' })
            this.$emit('confirm', true)
          }
        } else if (_this.hasValue(logic.msg)) {
          this.$message({ message: logic.msg, type: 'error' })
        } else {
          this.$message({ message: '标记失败', type: 'error' })
        }
      }).finally(() => {
        this.loading = false
        this.isShowLoading = false
      })
    },
    onClearStkMarking (clearType) {
      let typestr = '自定义'
      if (clearType === 'extend') {
        typestr = '推广'
      } else if (clearType === 'subscript') {
        typestr = '订阅'
      }
      let GUIDS = []
      let url = ''
      switch (this.markingType) {
        case 'stock':// 我的库存
          GUIDS = this.stkGUID
          url = this.config.onClearStkMarkingURL
          this.MarkToObj.StkGUIDS = GUIDS
          break
        case 'stkVMI':// 供应商库存
          GUIDS = this.stkVMIGUIDS
          url = this.config.onClearStkVMIMarkingURL
          this.MarkToObj.VMGUIDS = GUIDS
          break
        case 'stockSummary':// 库存汇总
          GUIDS = this.sumGuids
          url = this.config.onClearStockSummaryMarkingURL
          this.MarkToObj.SUMGuids = GUIDS
          break
      }

      if (this.MarkToType === 'selected') {
        if (GUIDS.length === 0) {
          this.$message({ message: '请先选中库存！', type: 'warning' })
          return false
        }

        this.$confirm('即将清除已勾选的库存(' + GUIDS.length + '条)的所有' + typestr + '标记，是否继续？', '提示', {
          confirmButtonText: '继续',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // this.MarkToObj.SUMGuids = this.sumGuids
            this.confirmClearStkMarking(clearType, url)
          })
      } else {
        if (this.markingCount === 0) {
          this.$message({ message: '没有需要清除标记的库存！', type: 'warning' })
          return false
        }
        let msg = ''
        if (this.markingCount > 2000) {
          // msg = '符合查询条件的库存有' + this.markingCount + '条，可能会花费较长时间!'
        }
        msg += '即将清除符合查询条件的库存(' + this.markingCount + '条)的所有' + typestr + '标记，是否继续？'

        this.$confirm(msg, '提示', {
          confirmButtonText: '继续',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.confirmClearStkMarking(clearType, url)
        })
      }
    },
    async confirmClearStkMarking (clearType, url) { // 清除库存标记
      this.isShowLoading = true
      this.loading = true
      this.loadingText = '正在清除标记，请稍后...'
      let _this = this
      let param = _.extend(this.MarkToObj, { ClearType: clearType })

      this.$post(url, param, (data, logic) => {
        _this.isShowLoading = false
        _this.loading = false
        if (logic.code === 200) {
          _this.$message({ message: '清除成功', type: 'success' })
          _this.$emit('confirm', true)
        } else if (logic.msg !== '') {
          _this.$message({ message: logic.msg, type: 'error' })
        } else {
          _this.$message({ message: '清除失败', type: 'error' })
        }
      })
    },
    AddMarking () {
      if (!this.hasValue(this.addMarkingName)) {
        this.$message({ message: '请输入标记名称！', type: 'warning' })
        return false
      }
      let _this = this
      this.$post(this.config.saveMarkingURL, { MarkerName: this.addMarkingName }, (datas) => {
        if (datas > 0) {
          _this.addMarkingName = ''
          _this.showAddBox = false
          _this.$message({ message: '添加成功', type: 'success' })
          _this.onLoadPage()
        } else if (datas === -1) {
          return this.$message({ message: '请输入标记名称！', type: 'error' })
        } else if (datas === -2) {
          return this.$message({ message: '标记名称中不能包含逗号字符!', type: 'error' })
        } else if (datas === -3) {
          return this.$message({ message: '已存在同名的标记！', type: 'error' })
        } else if (datas === -4) {
          return this.$message({ message: '主账户才可以添加公司标记！', type: 'error' })
        } else {
          return this.$message({ message: '保存失败', type: 'error' })
        }
      })
    },

    // 删除标签
    deleteMark (markName) {
      if (markName === '') {
        return this.$message({ message: '请勾选一个标记!', type: 'warning' })
      } else {
        this.$confirm('确定要删除标签 ' + markName + ' 吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.onDeleteMark(markName)
        })
      }
    },
    onDeleteMark (markName) {
      this.$post(this.config.deleteMarkingURL, { MarkerName: markName }, (datas) => {
        if (datas > 0) {
          this.onLoadPage()
        }
      })
    },

    onMarkingClick (item) { // 标签button选中事件
      let index = _.findIndex(this.MarkingCheck, i => i === item)
      if (index === -1) {
        this.MarkingCheck.push(item)
      } else {
        this.MarkingCheck.splice(index, 1)// 再次选中移除
      }
    },
    onMarkingIsCheck (item) {
      return this.MarkingCheck.indexOf(item) > -1
    },
    onSearchStockCount () { // 根据条件查询符合条件的库存条数
      let URL = ''
      switch (this.markingType) {
        case 'stock':// 我的库存
          URL = this.config.searchStockCountURL
          break
        case 'stkVMI':// 供应商库存
          URL = this.config.searchStockVMIURL
          break
        case 'stockSummary':
          URL = this.config.searchStockSummaryURL
      }
      return this.$post(URL, this.MarkToObj, (data) => {
        if (this.markingType === 'stockSummary') {
          this.markingCount = data
        } else {
          this.markingCount = data.TotalCount
        }
      })
    },
    // 推广库存标记改变时
    extendClick (type) {
      if (this.extendType === type) {
        this.extendType = 0
      } else {
        this.extendType = type
      }
    },
    // 订阅库存标记改变时
    subTypeClick (subType) {
      if (subType === -1) {
        // 取消订阅
        this.isCancelSubscript = !this.isCancelSubscript
        this.subTypeChecked = []
      } else {
        let index = _.findIndex(this.subTypeChecked, i => i === subType)
        if (index === -1) {
          this.subTypeChecked.push(subType)
        } else {
          this.subTypeChecked.splice(index, 1)// 再次选中移除
        }
        this.isCancelSubscript = false
      }
    },

    // 打开添加对话框
    openAddMarkBox () {
      this.showAddBox = true
      this.$nextTick(() => {
        this.$refs.addMarkingName.handleFocus()
      })
    }
  },
  created () {
    // if (!this.hasExtendRight && !this.hasSubscriptRight) {
    //   this.height = '320px'
    // } else {
    //   this.height = '420px'
    // }
    // this.height = '320px'
    this.onInit()
  }
}
</script>

<style lang="scss">
.col-label-right {
  text-align: right;
  line-height: 30px;
}

.col-label-left {
  text-align: left;
  line-height: 30px;
}

.content-Width {
  width: 100%;
  position: relative;
  margin-top: 15px;
  .lableNum {
    position: absolute;
    top: -37px;
    left: 250px;
    font-size: 14px;
    color: #ee7700;
  }
}

.remarkFont {
  font-size: 10px;
  font-style: italic;
  color: #c0c0c0;
}

// .el-checkbox+.el-checkbox {
//   margin-right: 15px !important;
// }

.checkCls {
  vertical-align: text-bottom;
  margin-bottom: 0px;
}

.stock-mark-box {
  .znl-labels {
    min-height: 50px;
    max-height: 130px;
    height: 130px;
    overflow-y: auto;
    .label {
      margin: 5px 8px 5px 0;
      padding: 4px 5px;
      font-size: 12px;
      border: 1px solid #e8ecf5;
      background-color: #e4e8f1;
      color: #48576a;
    }
    .label-checked {
      border: 1px solid #ff972a;
      background-color: #fe9629;
      color: #ffffff;
    }
    .label:first {
      margin-left: 0;
    }
  }
}
</style>
