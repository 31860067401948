<template>
<div>
  <znl-dialog :visible="visible&&dialogShow"
              width="280px"
              height="280px"
              class="stock-extend-set-box"
              title="设置推广库存类型"
              :on-init="onInit"
              @close="()=>{this.dialogShow = false, this.isRefresh = !this.isRefresh, $emit('close')}">
    <div slot="znl-dialog" class="stock-extend-set">
      <el-row>
        <el-col>
          <el-row>
            <el-col>
              <el-radio v-model="filterType" label="1" :fill="fillColor">按已选择 (已选中 {{checkedRows.length}} 条)</el-radio>
            </el-col>
            <el-col>
              <el-radio v-model="filterType" label="2" :fill="fillColor">按搜索条件 (共 {{filterRowCount}} 条)</el-radio>
            </el-col>
          </el-row>
        </el-col>
        <el-col class="mt20">
          <el-row>
            <hr class="stk-hr">
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="mt20">
          <el-row>
            <el-col>
              <el-radio v-model="stype" :label="4" class="type4">正品企业</el-radio>
            </el-col>
            <el-col>
              <el-radio v-model="stype" :label="8" class="type4">正品物料</el-radio>
            </el-col>
            <el-col>
              <el-radio v-model="stype" :label="6" class="type6">保证有料</el-radio>
            </el-col>
            <el-col>
              <el-radio v-model="stype" :label="9" class="type9">优势推广</el-radio>
            </el-col>
            <!-- <el-radio-group v-model="stype" size="small" @change="onSTypeChange">
              <el-radio label="4" v-show="stypeShow.s4">正品企业</el-radio>
              <el-radio label="6" v-show="stypeShow.s6">保证有料</el-radio>
              <el-radio label="9" v-show="stypeShow.s9">优势推广</el-radio>
            </el-radio-group> -->
          </el-row>
        </el-col>
      </el-row>
    </div>
    <el-row slot="footer">
      <znl-button style-type="main" :height='30' :width='60'
        :disabled="loading"
        @click="onConfirm"
        >
        <span>确认设置</span>
      </znl-button>
    </el-row>
  </znl-dialog>
</div>
</template>

<script>
import { mixin as propSyncMixin } from '@scripts/mixins/propSync'

export default {
  name: 'StockExtendSet',
  mixins: [propSyncMixin],
  components: {
  },
  data () {
    return {
      config: {
        GetServerItemURL: 'PubStkStock/GetServerItem',
        SetStockExtendURL: 'StockSummary/SetStockExtend',
        getCountURL: 'StockSummary/GetCount'
      },
      dialogShow: false,
      isRefresh: false,
      stype: 4,
      stypeShow: { s8: false, s4: false, s6: false, s9: false },
      fillColor: '#F70',
      filterType: '1', // 设置条件 1: 已选择， 2: 按搜索条件
      filterRowCount: 0
    }
  },
  props: {
    height: String,
    loading: Boolean,
    dialogVisible: {
      type: Boolean,
      default: false,
      propsync: true
    },
    visible: Boolean,
    checkedRows: { // 选中的行
      type: Array,
      default: () => { return [] }
    },
    searchCondition: { // 查询条件
      type: Object,
      default: () => { return {} }
    }
  },
  watch: {
    async visible (val) {
      if (val) {
        await this.getServerItem()
        this.dialogShow = true
        this.isRefresh = !this.isRefresh
        this.getCount()
      }
    }
  },
  methods: {
    onInit: async function () {
      // this.getServerItem()
    },
    onCancel () {
      this.p_dialogVisible = false
      this.$emit('close')
    },
    onConfirm () {
      if (this.filterType === '1' && this.checkedRows.length === 0) {
        this.$message({ message: '请先选择库存', type: 'error' })
        return false
      }
      let param = {
        FilterType: this.filterType,
        SType: this.stype
      }
      if (this.filterType === '1') {
        param.SUMGuids = _.map(this.checkedRows, m => { return m.SUMGuid })
      } else {
        param = _.extend(this.searchCondition, param)
      }
      this.$post(this.config.SetStockExtendURL, param, (data, logic) => {
        if (logic.code === 200) {
          this.$message({ message: '已成功推广库存 ' + data + ' 条', type: 'success' })
        } else if (this.hasValue(logic.msg)) {
          this.$message({ message: logic.msg, type: 'error' })
        } else {
          this.$message({ message: '设置推广库存失败', type: 'error' })
        }
        this.p_dialogVisible = false
        this.$emit('confirm', true)
      })
    },
    async getServerItem () {
      let _this = this
      await this.$post(this.config.GetServerItemURL, {}, (data) => {
        if (data.length > 0) {
          _this.stypeShow.s4 = (_.some(data, item => item.ExtensionType === 4))
          _this.stypeShow.s6 = (_.some(data, item => item.ExtensionType === 6))
          _this.stypeShow.s9 = (_.some(data, item => item.ExtensionType === 9))
          _this.stypeShow.s8 = (_.some(data, item => item.ExtensionType === 8))

          // 设置默认值
          _this.stype = _this.stype
          // if (_this.stypeShow.s4) {
          //   _this.stype = '4'
          // } else if (_this.stypeShow.s6) {
          //   _this.stype = '6'
          // } else if (_this.stypeShow.s9) {
          //   _this.stype = '9'
          // }
        }
      })
    },
    async getCount () {
      this.filterRowCount = 0
      let param = {
        FilterType: '2'
      }
      param = _.extend(this.searchCondition, param)
      this.filterRowCount = await this.$post(this.config.getCountURL, param)
    }
  },
  created () {
    // this.getServerItem()
  }
}
</script>

<style lang="scss">
.stock-extend-set-box {
  .mt20 {
    margin-top: 10px;
  }
  .stock-extend-set {
    .radios span {
      font-size: 12px;
    }
    .mleft {
      margin-left: 16px;
    }
    .col2 {
      margin-left: 20px;
    }
    .ml20 {
      margin-left: 20px;
    }
    .stk-hr {
      margin-top: 0;
      margin-bottom: 0;
    }
    padding-left: 10px;
  }
}
</style>
