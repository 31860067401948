const AUTHURL = 'Security/ValidateTokenID'
const AYTHNEWURL = 'Security/GetErpToken'
const AUTHUPATEURL = 'Security/UpdateErpToken'
const ERPCOOKIENAME = 'ZNLUSERGUID'
const ErpCookieMinutes = 24 * 60
const BOMAICOOLIENAME = 'token'
const LINKALLCOOKIENANE = 'linkAllToken'
export {
  AUTHURL,
  AYTHNEWURL,
  AUTHUPATEURL,
  ERPCOOKIENAME,
  BOMAICOOLIENAME,
  LINKALLCOOKIENANE,
  ErpCookieMinutes
}
