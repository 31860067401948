<template>
<!-- 发货记录 -->
  <znl-gridmodule ref="flexGrid"
              header="出库记录"
              height="100%"
              layoutType="other"
              loading-type="animation"
              :columns="columns"
              :item-source="itemSource"
              :column-colors="columnColors"
              :summary-columns="summaryColumns"
              :is-fields-search="false"
              :is-multi-rows-check="false"
              :search-fields="searchModel"
              :page-size="pageSize"
              :page-index="pageIndex"
              :total-count="totalCount"
              :role="role"
              :users="$store.state.users"
              :on-init="onInit"
              :is-init="isInit"
              :on-search="onSearch"
              :loading="!isMini && loading"
              :on-page-changed="onBindData"
              :on-refresh="onRefresh"
              :on-save-setting="onSaveSetting"
              :on-save-config='onSaveConfig'
              :on-reset-setting="onResetSetting"
              :is-activity="isActivity"
              :pagination-position="pagePosition"
              @ondblclick="val=>$emit('ondblclick', val)"
              @switch-to-hide="val=>{$emit('switch-to-hide',val)}"
              @selection-changed="d=>{$emit('selection-changed',d)}">

  </znl-gridmodule>
</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
// import ZnlModule from '@c_common/znlGrid/znlModule'
const CONFIG = {
  PK: 'StkOutLineID',
  configURL: 'StkOutByModel/GetConfig',
  saveConfigURL: 'StkOutByModel/SaveConfig',
  resetConfigURL: 'StkOutByModel/ResetConfig',
  searchURL: 'StkOutByModel/Search'
}

export default {
  name: 'CStkOutByModel',
  mixins: [znlMethodsMixin],
  config: CONFIG,
  // components: { ZnlModule, Icon },
  data () {
    return {

      columns: [],
      itemSource: [],
      editRowIndex: -1,
      searchFields: {

      },
      columnColors: [{
        'field': 'InvQty',
        'color': '#EE7700'
      }],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      dataMapSourse: {
        InvTypeSource: [],
        UnitSource: []
      }
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    isMini: {
      type: Boolean,
      default: false
    },
    isInit: Boolean,
    isActivity: Boolean,
    pagePosition: {
      type: String,
      defaule: 'bottom'
    },
    isShow: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String
  },
  methods: {
    onInit: async function () {
      let config = await this.isSaveStorage('stkOutByModel', CONFIG.configURL)
      // let config = await this.$post(CONFIG.configURL)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      this.columns = columns
      this.defaultConfig = config
      this.pageSize = config.PageSize
      this.role = config.Role
      this.initData && this.onBindData(1)
      // this.onBindData(1)
    },
    onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stkOutByModel')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, CONFIG.default))
    },
    onResetSetting () {
      localStorage.removeItem('stkOutByModel')
      return this.$post(CONFIG.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('stkOutByModel')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, CONFIG.default))
    },
    onBindData: async function (pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.searchModel),
        Model: (this.searchModel.models === undefined ? '' : this.searchModel.models.Model),
        Brand: (this.searchModel.models === undefined ? '' : this.searchModel.models.Brand),
        Packaging: (this.searchModel.models === undefined ? '' : this.searchModel.models.Packaging),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      })
      return this.$post(CONFIG.searchURL, data, (data) => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      })
    },
    onSearch (params) {
      this.searchFields = _.extend({}, this.searchFields, params)
      return this.onBindData(1)
    }
  },
  mounted () {
    // !this.isShow && this.$refs.flexGrid.toggleTableShow(false)
  }
}
</script>
