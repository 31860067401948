import Vue from 'vue'
import Router from 'vue-router'
import clientRouter from './clientRouter' // 处理客户端出现的问题

Vue.use(Router)

// 路由的配置文件注册信息放置在这里;
// 配置名称对应到`~/routes/pages`下的文件名(不含扩展名);
// 具体作用请参见`getRoutes`方法内注释
//
// 这里模块的注册顺序和管理模式下左侧菜单条目的显示顺序一致
const registry = [
  'Home',
  'Forbidden',
  'Stk',
  'Ord',
  'Purchase',
  'Crm',
  'Fin',
  'Sts',
  // 'StkExt',
  'Spm',
  'Account',
  // 'Trading',
  // 'Common',
  'Login',
  // 保留空字符串，便于添加或注释路由
  'Register',
  'ResetPassword'
]

function getBaseUrl () {
  // 单模块显示模式
  // const erpBaseUrl = 'hidemenu/'
  // const pathname = window.location.pathname
  // if (pathname.indexOf(erpBaseUrl) === 0) {
  //   return erpBaseUrl
  // }

  // 管理模式(加载左侧菜单栏)
  return ''
}

function getRoutes () {
  // 路由统一放置到~/route/pages/目录下，
  // 使用时注册到上面定义的registry数组中，
  // 这里通过registry中的注册信息组合routes
  const routes = []
  registry.filter(m => m).forEach(
    name => routes.push(require(`~/router/pages/${name}`).default)
  )

  const routerlist =  clientRouter(routes);
  return routerlist;
  // return routes
}

export function createRouter () {
  const base = getBaseUrl()
  const routes = getRoutes()

  const config = {
    base,
    mode: '',
    routes,

    linkActiveClass: 'znl-link-active',
    linkExactActiveClass: 'znl-link-exact-active'
  }

  return new Router(config)
}
