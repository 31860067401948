<template>
  <div>
    <znl-dialog
      title="上传文件到资料库"
      :visible="Visible"
      width="500px"
      height="300px"
      class="dialog-znl"
      :close-on-click-modal="false"
      :is-footer-show="false"
      element-loading-text="正在加载中,请稍候..."
      @close="closeDialog"
    >
      <div class="content">
        <div>
          为了实现文件与商品的快速智能匹配，请选择您本次上传的产品文件命名格式
        </div>
        <div>
          文件命名格式：
          <el-radio v-model="namingFormat" label="1" @input="onChange()"
            >型号</el-radio
          >
          <el-radio v-model="namingFormat" label="2" @input="onChange()"
            >型号+品牌</el-radio
          >
        </div>
        <div>
          <span>分割字符：</span>
          <znl-input
            form-type="input"
            placeholder=""
            :clearable="false"
            size="mini"
            layout="left"
            inp-perc="100%"
            v-model="splitChar"
            :border="true"
            type="text"
            @input="onChange()"
          ></znl-input>
        </div>
        <div>文件命名示例：{{ example }}</div>
        <div>支持上传文件类型: jpg、jpeg、bmp、png、gif、ico、svg、pdf</div>
        <div class="note">
          （注：图片必须小于{{ maxImageFileSize }}MB，PDF文件必须小于{{
            maxPdfFileSize
          }}MB）
        </div>
        <vUpload
          ref="vUpload"
          width="295px"
          select-tips="选择文件"
          :accept="accept"
          :isMultiple="true"
          :folder="folder"
          :refresh.sync="isRefresh"
          :refresh-click.sync="refreshClick"
          @upload-before="onUploadClick"
          @upload-completed="onUploadCompleted"
        />
      </div>
    </znl-dialog>
  </div>
</template>

<script>
import vUpload from "@c_modules/StkExt/Upload";

const CONFIG = {
  BatchAddUrl: "FileLibrary/BatchAdd",
};

export default {
  name: "FileLibraryUpload",
  config: CONFIG,
  mixins: [],
  components: { vUpload },
  data() {
    return {
      maxFileCount: 50, //单次上次最大文件数量
      maxImageFileSize: 2, //单个图片文件最大2MB
      maxPdfFileSize: 4, //单个pdf文件最大4MB
      loading: false,
      accept: ".jpg,.jpeg,.bmp,.png,.gif,.ico,.svg,.pdf",
      folder: "",
      isRefresh: false, //上传成功后刷新
      refreshClick: false,
      namingFormat: "1", //命名格式
      splitChar: "_", //分割字符
      example: "LM358", //示例文本
    };
  },
  props: {
    //是否显示
    Visible: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  watch: {},
  methods: {
    onInit() {
      this.initFolder();
    },
    //初始化文件夹名称
    initFolder() {
      let date = new Date();
      let year = date.getFullYear().toString();
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month.toString();
      }
      let dateStr = year + month.toString();
      this.folder =
        "erp-file-library/" +
        this.$store.state.accountInfo.CompanyID +
        "/" +
        dateStr;
    },
    // 单击上传按钮的时候
    onUploadClick(fileName, fileSize, filesArry) {
      if (this.splitChar === null || this.splitChar.trim() === "") {
        this.$message({
          message: "请输入分割字符串！",
          type: "warning",
        });
        return false;
      }
      if (fileName === "" || fileName === null || _.isUndefined(fileName)) {
        this.$message({
          message: "请至少选择一个文件！",
          type: "warning",
        });
        return false;
      }
      if (filesArry.length >= this.maxFileCount) {
        return this.$message({
          message: `单次上传文件数量不能大于${this.maxFileCount}！`,
          type: "warning",
        });
      }
      let isPass = true;
      _.each(filesArry, (file) => {
        if (!this.hasValue(file.fileName)) {
          isPass = false;
          this.$message({ message: "请至少选择一个文件！", type: "warning" });
          return false;
        }
        let name = file.fileName.toLowerCase();
        let index = name.lastIndexOf(".");
        let extName = "";
        if (index > 0) {
          extName = name.substr(index);
        }
        if (_.indexOf(this.accept.split(","), extName) === -1) {
          isPass = false;
          this.$message({
            message: "选择的文件中有不支持上传的文件！",
            type: "warning",
          });
          return false;
        } else {
          //验证pdf文件大小
          if (extName === ".pdf") {
            if (file.fileSize > this.maxPdfFileSize * 1024 * 1024) {
              isPass = false;
              this.$message({
                message: `单个PDF文件不可大于${this.maxPdfFileSize}MB`,
                type: "warning",
              });
              return false;
            }
          } else {
            //验证图片大小
            if (file.fileSize > this.maxImageFileSize * 1024 * 1024) {
              isPass = false;
              this.$message({
                message: `单个图片不可大于${this.maxImageFileSize}MB`,
                type: "warning",
              });
              return false;
            }
          }
        }
      });
      if (!isPass) {
        return;
      }
      this.refreshClick = !this.refreshClick;
    },
    // 文件上传完成之后
    onUploadCompleted(fileName, fileSize, fileUrl, filesArry) {
      this.isRefresh = !this.isRefresh;

      let param = _.map(filesArry, (m) => {
        let rst = {
          FileName: m.fileName,
          FileSize: m.fileSize,
          FileUrl: m.fileUrl,
        };

        //取文件名部分，不要后缀名
        var infos = m.fileName.split(".")[0].split(this.splitChar);
        switch (this.namingFormat) {
          case "1": //型号
            rst.Model = infos[0];
            break;
          case "2": //型号+品牌
            rst.Model = infos[0];
            rst.Brand = infos.length > 1 ? infos[1] : null;
            break;
        }

        let name = m.fileName.toLowerCase();
        let index = name.lastIndexOf(".");
        if (index > 0) {
          name = name.substr(index);
        }
        if (name === ".pdf") {
          rst.FileType = 2; //pdf
        } else {
          rst.FileType = 1; //图片
        }

        return rst;
      });
      //插入数据库
      this.$post(CONFIG.BatchAddUrl, param, (data) => {
        if (data) {
          this.$message({ message: "文件上传成功", type: "success" });
          this.Visible = false;
        } else {
          this.$message({ message: "文件上传失败", type: "error" });
        }
      });
    },
    //关闭弹窗
    closeDialog() {
      this.$emit("close");
    },
    //值变更更新示例
    onChange() {
      switch (this.namingFormat) {
        case "1": //型号
          this.example = `LM358${this.splitChar}1`;
          break;
        case "2": //型号+品牌
          this.example = `LM358${this.splitChar}TI${this.splitChar}1`;
          break;
      }
    },
  },
  created() {},
  mounted() {
    this.onInit();
  },
};
</script>

<style lang="scss">
.content {
  padding: 5px 12px;
}

.note {
  font-size: 12px;
  color: gray;
}
</style>

