import {
  PubAccountSet,
  StkExtYunExt,
  StkExtStockMini,
  PubStock
} from '~/views/StkExt'
import {
  SpmPubStkStock
} from '~/views/Spm'
import {
  PubStockFriend,
  PubStockShared,
  PubMyStockShared

} from '~/views/Pub'

export default {
  path: '/StkExt',
  name: 'StkExt',
  meta: {
    title: '库存推广',
    alias: '推广的库存',
    authName: '库存推广',
    icon: 'icon-left_nav_push_ic'
  },
  children: [{
      path: 'PubStk',
      name: 'Spm/SpmPubStkStock',
      component: SpmPubStkStock,
      meta: {
        title: '推广库存分析',
        authName: '推广库存分析'
      }
    },
    {
      path: 'YunExt',
      name: 'StkExt/YunExt',
      component: StkExtYunExt,
      meta: {
        title: '云价格推广',
        authName: '云价格推广',
        display: false
      }
    },
    {
      path: 'PubAccountSet',
      name: 'StkExt/PubAccountSet',
      component: PubAccountSet,
      meta: {
        title: '推广联系方式',
        authName: '推广联系方式'
        // display: false
      }
    },
    {
      path: 'MyStockShared',
      name: 'Pub/PubMyStockShared',
      component: PubMyStockShared,
      meta: {
        title: '我的共享库存',
        authName: '我的共享库存',
        display: false
      }
    },
    {
      path: 'StockShared',
      name: 'Pub/PubStockShared',
      component: PubStockShared,
      meta: {
        title: '共享给我的库存',
        authName: '共享库存',
        display: false
      }
    },
    {
      path: 'StkExtStockMini',
      name: 'StkExt/StkExtStockMini',
      component: StkExtStockMini,
      meta: {
        title: '推广的库存(库存)',
        display: false
      }
    },
    {
      path: 'PubStockFriend',
      name: 'Pub/PubStockFriend',
      component: PubStockFriend,
      meta: {
        title: '友商库存(库存)',
        display: false
      }
    },
    {
      path: 'StockExtension',
      name: 'Pub/PubStock',
      component: PubStock,
      meta: {
        title: '库存推广'

      }
    }
  ]
}
