import {
  StkIn,
  StkOut,
  StockReserve,
  StockPurchase,
  StockAll,
  StockManage,
  TencentQDW,
  StockSum,
  TencentQDW_Inquiry,
  TencentQDW_About,
  StkFileLibrary,
  StkStockSummary,
} from '~/views/Stk'

import {
  WaitStkInList,
  WaitStkOutList,
  ExpressOrderAdd,
  ExpressOrderManage
} from '~/views/Ord'
import {
  StsModelAly,
} from '~/views/Sts'

import StockSummary from '@c_modules/StkSum/StockSummary.vue';//新汇总库存页面
import FileLibrary from '@c_modules/Stk/FileLibrary.vue';//资料库页面

export default {
  path: '/stk',
  name: 'Stk',
  component: StkOut,
  meta: {
    title: '库存管理',
    authName: '库存管理',
    icon: 'icon-left_nav_inventory_'
  },
  children: [
    // {
    //   path: 'tencent_qdw',
    //   name: 'tencent_qdw',
    //   component: TencentQDW,
    //   meta: {
    //     title: '抢单王库存',
    //     display: true
    //   }
    // },
    // {
    //   path: 'tencent_qdw_inquiry',
    //   name: 'tencent_qdw_inquiry',
    //   component: TencentQDW_Inquiry,
    //   meta: {
    //     title: '抢单王商机',
    //     display: true
    //   }
    // },
    {
      path: 'stockAll',
      name: 'Stk/StockAll',
      component: StockManage,
      meta: {
        title: '库存',
        display: true
      }
    },
    {
      path: 'StockSummary',
      name: 'StockSummary',
      component: StkStockSummary,
      meta: {
        title: '汇总库存',
        display: false
      }
    },
    {
      path: 'stockpurchase',
      name: 'Stk/StkPurchase',
      component: StockPurchase,
      meta: {
        title: '采购订货',
        authName: '采购订货',
        display: false
      }
    },
    {
      path: 'expressOrderManage',
      name: 'Ord/ExpressOrderManage',
      component: ExpressOrderManage,
      meta: {
        title: '发运单',
        authName: '发运单',
        display: false
      }
    },
    {
      path: 'expressOrderAdd',
      name: 'Ord/ExpressOrderAdd',
      component: ExpressOrderAdd,
      meta: {
        title: '编辑发运单',
        authName: '编辑发运单',
        display: false
      }
    },
    {
      path: 'WaitStkInList',
      name: 'Ord/WaitStkInList',
      component: WaitStkInList,
      meta: {
        title: '待入库清单',
        authName: '待入库清单',
        display: false

      }
    },
    {
      path: 'WaitStkOutList',
      name: 'Ord/WaitStkOutList',
      component: WaitStkOutList,
      meta: {
        title: '待发货清单',
        authName: '待发货清单',
        display: false
      }
    },
    {
      path: 'ModelAly',
      name: 'Sts/ModelAly',
      component: StsModelAly,
      meta: {
        title: '产品分析',
        authName: '产品分析',
        display: false
      }
    },
    // {
    //   path: 'tencent_qdw_about',
    //   name: 'tencent_qdw_about',
    //   component: TencentQDW_About,
    //   meta: {
    //     title: '腾讯·抢单王',
    //     display: true
    //   }
    // },
    {
      path: 'FileLibrary',
      name: 'FileLibrary',
      // component: StkFileLibrary,//包装层
      component: StkFileLibrary,
      meta: {
        title: '资料库',
        authName: '资料库',
        display: false
      }
    }
  ]
}
