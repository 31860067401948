<template>
<!-- 入库记录 -->
  <znl-gridmodule ref="flexGrid"
              header=""
              height="100%"
              :loading-type="isShowHeader ? 'animation': 'icon'"
              :columns="columns"
              :item-source="itemSource"
              :column-colors="columnColors"
              :action-btns="!isMini?actionBtns:[]"
              :btn-mouse-enter-menus="btnMouseEnterMenus"
              :is-multi-rows-check="false"
              :row-colors="rowColors"
              :summary-columns="summaryColumns"
              :is-fields-search="isFieldsSearch"
              :search-fields="searchModel"
              :page-size="pageSize"
              :page-index="pageIndex"
              :total-count="totalCount"
              :role="role"
              :users="$store.state.users"
              :on-init="onInit"
              :is-init="true"
              :is-show-rightbtns="false"
              :show-header="false"
              :loading="loading"
              :on-search="onSearch"
              :on-refresh="onRefresh"
              :on-save-setting="onSaveSetting"
              :on-save-config='onSaveConfig'
              :on-reset-setting="onResetSetting"
              :is-activity="isActivity"
              @switch-to-hide="val=>{$emit('switch-to-hide',val)}"
              @ondblclick="val=>$emit('ondblclick', val)"
              :on-page-changed="onBindData">
              <stk-out-edit
              :title="StkOutAddTitle"
              :editvisible="stkOutEditVisible"
              v-if="stkOutEditVisible"
              :edit-type="editType"
              height="500px"
              @confirm="stkOutEditVisible=false"
              @close="stkOutEditVisible=false"
              ></stk-out-edit>
              <stk-in-edit
              :title="StkInAddTitle"
              :editvisible="stkInEditVisible"
              v-if="stkInEditVisible"
              :edit-type="editType"
              height="500px"
              @confirm="stkInEditVisible=false"
              @close="stkInEditVisible=false"
              >
              </stk-in-edit>
  </znl-gridmodule>
</template>
<script>
const StkInEdit = () => import('@c_modules/Stk/StkInEdit' /* webpackChunkName: "pages/stk" */)
const StkOutEdit = () => import('@c_modules/Stk/StkOutEdit' /* webpackChunkName: "pages/stk" */)
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { openLinkAll } from '@scripts/methods/common'

const CONFIG = {
  configURL: 'StkInRecord/GetConfig',
  saveConfigURL: 'StkInRecord/SaveConfig',
  resetConfigURL: 'StkInRecord/ResetConfig',
  searchURL: 'StkInRecord/Search'
}

export default {
  name: 'StkInRecord',
  mixins: [getCommonDataMixin, openLinkAll], // 第二个方法需要添加后才能监控到外部条件改变也可以刷新页面
  config: CONFIG,
  components: {
    StkInEdit,
    StkOutEdit
  },
  data () {
    return {
      editType: 'edit',
      StkOutAddTitle: '新增发货单',
      stkOutEditVisible: false,
      StkInAddTitle: '新增入库单',
      stkInEditVisible: false,
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      searchFields: {},
      filterFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      resourceList: [],
      Size: 30,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      actionBtns: [],
      loading: false,
      btnMouseEnterMenus: {
        List: []
      },
      status: true
    }
  },
  computed: {
    pageSize: {
      get () {
        return this.Size
      },
      set (val) {
        this.Size = val
      }
    }
  },
  props: {
    isActivity: Boolean,
    initData: {
      type: Boolean,
      default: true
    },
    pagePosition: {
      type: String,
      defaule: 'bottom'
    },
    isMini: {
      type: Boolean,
      default: false
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    },
    isShow: {
      type: Boolean,
      default: true
    },
    isShowBtn: {
      type: Boolean,
      default: false
    },
    isFieldsSearch: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: 'auto'
    },
    header: {
      type: String,
      default: '入库记录'
    },
    // 是否启用分词搜索
    IsAnalyzer: {
      type: Boolean,
      default: false
    },
    isShowOperate: {
      type: Boolean,
      default: false
    },
    isShowHeader: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async onInit (config, refresh) {
      this.loading = true
      if (!config) {
        config = await this.isSaveStorage('stkInRecord', this.$options.config.configURL)
      }

      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      // 获取模块打开或者关闭的状态
      this.GetStatus(config.Status)
      // await this.selectOptionsSpecialResource()
      // 列的初始化
      this.settingColumns(columns)
      // 操作按钮初始化
      this.settingActionBtn(columns)

      this.setDateColumnFormat(columns, 'ReceiveTime')

      this.defaultConfig = config
      this.columns = columns
      this.resourceList = config.ResourceList.map(item => item.Code)

      this.role = config.Role
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize
      if (this.initData) {
        await this.onBindData()
      } else {
        this.loading = false
        this.$emit('page-loading', false)
      }
    },
    // 列的初始化
    settingColumns (columns) {
      if (!this.hasSpecial('ViewBuyPrice')) {
        let item = _.find(columns, col => col.binding === 'Price')
        if (!_.isUndefined(item)) {
          item.hasRole = false
        }
      }

      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      let hideCols = ',SupplierName,'
      let hidePrice = ',Price'
      _.each(columns, column => {
        if (this.getSpecialResourceByCode('NoSeeSupplier') && hideCols.indexOf(column.binding) > -1) {
          column.hasRole = false
        }
        if (!this.getSpecialResourceByCode('ViewBuyPrice') && hidePrice.indexOf(column.binding) > -1) {
          column.hasRole = false
        }
        // 不看替代型号，没有这个权限隐藏咧
        if (column.binding === 'ReplaceModel') {
          column.hasRole = !this.hasRes('NoSeeReplaceModel')
        }

        if (column.binding === 'Model' ||
          column.binding === 'SupplierName' ||
          column.binding === 'CustomerName' ||
          column.binding === 'BillNo') {
          _.extend(column,
            {
              className: 'znl-linkAll-column',
              renderCell: this.openLinkAll(column)
            })
        }
      })
    },
    // 操作按钮初始化
    settingActionBtn (columns) {
      // this.actionBtns = [
      //   {
      //     name: '批量出库',
      //     isShow: this.hasRes('onStkOut'),
      //     iconName: 'icon-sell-wh_btn_ic',
      //     click: () => { this.stkOutAddRows() }
      //   },
      //   {
      //     name: '批量入库',
      //     isShow: this.hasRes('onStkIn'),
      //     iconName: 'icon-buy-wh_btn_ic',
      //     click: () => { this.stkInAddRows() }
      //   }
      // ]

      // this.btnMouseEnterMenus = {
      //   List: [
      //     {
      //       name: '销售出货',
      //       isShow: this.hasRes('onStkOut'),
      //       click: row => {
      //         let rows = []
      //         rows.push(row)
      //         this.onStkOutadd(rows)
      //       }
      //     },
      //     {
      //       name: '采购入库',
      //       isShow: this.hasRes('onStkIn'),
      //       click: row => {
      //         this.onStkInadd(row)
      //       }
      //     }
      //   ],
      //   Binding: 'operate'
      // }
      this.isShowOperate && !this.onlySalesPurchaseOrder ? columns.unshift({
        name: 'stkIn',
        dataType: 5,
        binding: 'stkIn',
        width: 30,
        header: '入',
        visible: true,
        allowSorting: false,
        isFieldsSearch: false,
        isSystem: true,
        isReadOnly: true,
        isFixed: false,
        buttons: [
          {
            content: '入',
            className: 'znl-table-btn-success',
            when: true,
            click: (row) => {
              this.onStkInadd(row)
            }
          }
        ]
      }) : ''
      this.isShowOperate && !this.onlySalesPurchaseOrder ? columns.unshift({
        name: 'stkOut',
        dataType: 5,
        binding: 'stkOut',
        width: 30,
        header: '出',
        visible: true,
        allowSorting: false,
        isFieldsSearch: false,
        isSystem: true,
        isReadOnly: true,
        isFixed: false,
        buttons: [
          {
            content: '出',
            className: 'znl-table-btn-success',
            when: true,
            click: (row) => {
              let rows = []
              rows.push(row)
              this.onStkOutadd(rows)
            }
          }
        ]
      }) : ''
    },
    findColumn (columns, name) {
      const matched = columns.filter(x => x.binding === name)
      if (matched.length < 1) {
        console.log(`Can not find column: ${name}`)
        return null
      }

      return matched[0]
    },

    renderTextCell (h, content) {
      return h('span', {
        domProps: {
          innerHTML: content
        }
      })
    },

    setDateColumnFormat (columns, name) {
      const column = this.findColumn(columns, name)
      if (!column) {
        return
      }

      // 时间列数据样例: 2018-05-24 00:00:00
      // 去掉尾部'00:00:00'的时间
      //
      // 时间列是字符串，用正则检查并处理;
      // 不满足正则的数据原样输出
      const dateRe = /^\s*(\d{4}-\d{1,2}-\d{1,2}) \d{1,2}:\d{1,2}:\d{1,2}\s*$/
      column.renderCell = (h, { row: { ReceiveTime } }) => {
        const value = ReceiveTime
        let content = value
        if (value) {
          const m = value.match(dateRe)
          if (m) {
            content = m[1]
          }
        }

        return this.renderTextCell(h, content)
      }
    },

    async onBindData (pageIndex = 1) {
      this.loading = true
      let filterFields = {}
      if (this.filterFields) {
        _.each(this.filterFields, (value, key) => {
          filterFields[key.substring(0, 1).toUpperCase() + key.substring(1)] = value
        })
      }
      filterFields.IsAnalyzer = this.IsAnalyzer
      let response = await this.$post(this.$options.config.searchURL, _.extend({
        FieldWhereString: JSON.stringify(this.filterFields),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, filterFields))
      this.pageIndex = pageIndex
      this.itemSource = response.ResultList
      this.totalCount = response.TotalCount
      this.loading = false
      this.$emit('page-loading', false)
      this.checkedRows = []
      this.checkedRowsCount = 0
      this.loading = false
      this.gridShow = true
    },
    async onHeadSearch (params) {
      this.filterFields.Model = params.Model
      await this.onBindData(1)
      this.checkedRows = []
      this.checkedRowsCount = 0
    },
    async onSearch (params = {}) {
      this.filterFields = {}
      _.extend(this.filterFields, params)
      await this.onBindData(1)
      this.checkedRows = []
      this.checkedRowsCount = 0
    },
    async onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stkInRecord')
      await this.$post(this.$options.config.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.defaultConfig))
    },
    async onResetSetting () {
      localStorage.removeItem('stkInRecord')
      await this.$post(this.$options.config.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('stkInRecord')
      return this.$post(this.$options.config.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig))
    },
    async onRefresh () {
      await this.onBindData(this.pageIndex)
    },
    hasRes (code) {
      return _.some(this.resourceList, page => page === code)
    },
    hasSpecial (code) {
      return _.some(this.selectOptionsSpecialResource, page => page.Code === code)
    },
    stkOutAddRows () { // 批量销售出库
      let rows = this.onSelectRow() // 选中行的bdlineguid数组

      if (rows.length > 0) {
        this.onStkOutadd(rows)
        this.clearSelectedRows()
      }
    },
    onStkOutadd (rows) { // 销售出库传值方法
      let order = { stockLine: rows }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkOutEditVisible = true // 显示编辑页面
      })
    },
    stkInAddRows () { // 批量采购入库
      let rows = this.onSelectRow() // 选中行的bdlineguid数组
      if (rows.length > 0) {
        let order = { StkInRecord: rows }
        this.$store.commit('setParamOrder', order)
        this.$nextTick(function () {
          this.$refs.flexGrid.clearSelection()
          this.stkInEditVisible = true // 显示编辑页面
        })
      }
    },
    // 获取模块状态
    GetStatus (val) {
      if (this.isActivity) {
        this.status = val !== 0
        this.status ? this.$refs.flexGrid.toggleTableShow(true) : this.$refs.flexGrid.toggleTableShow(false)
      } else {
        this.$refs.flexGrid.toggleTableShow(true)
      }
    },
    // 保存模块状态
    saveStatus (val) {
      let status = val ? 1 : 0
      let defaultConfig = this.defaultConfig
      if (defaultConfig !== undefined && status !== this.defaultConfig.Status) {
        this.defaultConfig.Status = status
        let statusConfig = this.defaultConfig
        statusConfig.ColumnConfigs = []
        this.$post(this.$options.config.saveConfigURL, statusConfig)
      }
    },
    onStkInadd (rows) { // 入库
      let order = { StkInRecord: [rows] }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkInEditVisible = true // 显示编辑页面
      })
    },
    onSelectRow () {
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (_.isUndefined(rows[0])) {
        if (!this.CurrentItem) {
          // 选中行
          return this.$message({ message: '请至少选择一条数据进行操作', type: 'warning' })
        } else {
          this.$refs.flexGrid.clearSelection()
        }
      } else {
        // 判断选中行的客户是否一致
        var lastCustomerName = rows[0].SupplierName
        var isDiff = false
        _.each(rows, r => {
          if (lastCustomerName !== r.SupplierName) {
            isDiff = true
          }
        })
        if (isDiff) {
          return this.$message({ message: '请选择相同供应商的数据进行操作', type: 'warning' })
        }
      }
      return rows
    }
  },
  mounted () {
    this.$refs.flexGrid.toggleTableShow(false)
  },
  created () {
  }
}
</script>

<style lang="scss">
</style>

