const getSessionReq = 'getSession'
const exportStockReq = 'exportStock'
const ExportDataReq = 'exportData'
const printQuoteReq = 'printQuote'
const printTakeGoods = 'printTakeGoods'
const printStkOutReq = 'printStkOut'
const printStkInReq = 'printStkIn'
const printSOStatementReq = 'printSOStatementOrder'
const printStatementReq = 'printStatement'
const printExpressReq = 'printExpress'
const printReceiptsReq = 'printReceipts'
const printSalesOrderReq = 'printSalesOrder'
const printPurchaseOrderReq = 'printPurchaseOrder'
const addInquireReq = 'addInquire'
const addInquireListReq = 'addInquireList'
const importDataReq = 'importData'
const downloadFileReq = 'downloadFile'
const marketSearchReq = 'marketSearch'
const setLabelReq = 'setLabel'
const globalCargoReq = 'globalCargo'

const noop = () => {
}
const printPOStatementReq = 'printPOStatementOrder'
const switchTabReq = 'switchTab'
const printBase64PdfReq = 'printBase64Pdf'
const printDataSourceReq = 'printDataSource'
const expressDeliveryCloseReq = 'expressDeliveryClose'
const printBase64ImageReq = 'printBase64Image'
const printPreviewBase64ImageReq = 'printPreviewBase64Image'
const editFastPrintTemplateReq = 'editFastPrintTemplate'
const showPrintSettingsReq = 'showPrintSettings'

import ZnlDataImport from '@c_common/dataImport/DataImport'

import * as urls from '~/lib/urls'

const apiDomain = urls.getErpApiBaseUrl();
const CONFIG = {
  WebPrintURL: 'WebPrint/StkOutPrint',
  StkInPrintUrl: 'WebPrint/StkInPrint',
  PurchaseReturnPrintUrl: 'WebPrint/PurchaseReturnPrint',
  PurchaseStatementPrintUrl: 'WebPrint/PurchaseStatementPrint',
  QuotePrintUrl: 'WebPrint/QuotePrint',
  SalesReturnPrintUrl: 'WebPrint/SalesReturnPrint',
  SalesStatementPrintUrl: 'WebPrint/SalesStatementPrint',
  DownloadPrintFile: 'WebPrint/DownloadPrintFile',
  PurchaseOrderPrintUrl: "WebPrint/PurchaseOrderPrint",
  SalesOrderPrintUrl: "WebPrint/SalesOrderPrint",
};

function encode(str) {
  // Regex reg = new Regex(@"^[a-zA-Z0-9]+$");
  // return reg.IsMatch(keyword);

  const regexValue = /^[a-zA-Z0-9]+$/

  if (!regexValue.test(str)) {
    // 对字符串进行编码
    let encode = encodeURI(str);
    // 对编码的字符串转化base64

    return '==' + btoa(encode);
  }
  return str
}

export default {
  components: {
    ZnlDataImport
  },
  data() {
    return {
      zznlShowDataImport: false, //显示导入
      zznlImportType: '',
    }
  },
  methods: {
    //ai全球找货
    async globalCargo() {
      this.csRequest(globalCargoReq)
    },
    async exportStock(dataJson) {
      this.csInvoke(exportStockReq, dataJson)
    },
    async exportData(dataJson) {
      this.csInvoke(ExportDataReq, dataJson)
    },
    async printQuote(quote) {
      console.log('printQuote', quote)
      this.csRequest(printQuoteReq, quote)
    },
    // 打印拿货单
    async printTakeGoods(quote) {
      this.csInvoke(printTakeGoods, quote)
    },
    async printStkOut(stkOut, success, error) {
      this.onComPrint(printStkOutReq, stkOut, success, error)
    },
    async printStkIn(stkIn, success, error) {
      // this.csRequest(printStkInReq, stkIn, success, error)
      this.onComPrint(printStkInReq, stkIn, success, error)
    },
    async printStatement(statement, success, error) {
      // this.csRequest(printStatementReq, statement, success, error)
      this.onComPrint(printStatementReq, statement, success, error)
    },
    // 打印销售对账单
    async printSOStatement(statement, success, error) {
      // this.csRequest(printSOStatementReq, statement, success, error)
      this.onComPrint(printSOStatementReq, statement, success, error)
    },
    async printExpress(express) {
      // this.csInvoke(printExpressReq, express)
      this.onComPrint(printExpressReq, express)
    },
    async printReceipts(receipts) {
      // this.csInvoke(printReceiptsReq, receipts)
      this.onComPrint(printReceiptsReq, receipts)
    },
    async printSalesOrder(salesOrder, success, error) {
      // this.csRequest(printSalesOrderReq, salesOrder, success, error)
      this.onComPrint(printSalesOrderReq, salesOrder, success, error)
    },
    async printPurchaseOrder(purchaseOrder, success, error) {
      // this.csRequest(printPurchaseOrderReq, purchaseOrder, success, error)
      this.onComPrint(printPurchaseOrderReq, purchaseOrder, success, error)
    },
    // 跳转到市场查货
    async openMarketSearch(requests, type) {
      // 记录查询日志
      const data = requests.Requests[0]

      const { Model } = data
      // const partNoBase64 = encode(partNo)

      const params = {
        KeyWords: Model,
        FromSiteName: 'ZNL'
      }
      await this.$post('PubMiniSearchLog/AddLog', params)

      if (this.isInClient()) {
        this.csInvoke(marketSearchReq, requests)
      } else {
        const data = requests.Requests[0]

        let params = {
          qty: data.Qty,
          partNo: encode(data.Model),
          SourceType: data.SourceType, // 来源类型：1.需求明细 2.询价通知明细 3.采购通知明细
          SourceGuid: data.SourceGuid, // 需求明细GUID
          SourceId: data.SourceId // 需求明细ID，
        }

        const newParams = JSON.stringify(params)

        console.log(newParams)

        await this.$router.push({
          name: "GoodsSearch",
          query: {
            params: newParams
          }
        })

      }

    },

    async addInquire(inquire, success) {
      this.csRequest(addInquireReq, inquire, success)
    },
    async addInquireList(inquireList, success) {
      this.csRequest(addInquireListReq, inquireList, success)
    },
    async importData(importParam) {
      //客户端
      if (this.isInClient()) {
        this.csRequest(importDataReq, importParam, null, null)
      }
      //web
      else {
        //修改于 2022/1/13 by zilong
        this.zznlImportType = importParam.importType.toLowerCase()
        this.zznlShowDataImport = true
        // this.csInvoke(importDataReq, importParam)
      }
    },
    async downFile(fileParam, success) {
      if (this.isInClient()) {
        this.csRequest(downloadFileReq, fileParam, success)
      } else {
        (function () {
          // var url = 'ftp://192.168.1.10/dist/erp/fiddler.zip'
          let url = fileParam.FileUrl
          window.el = document.createElement('a')
          window.el.href = url
          window.el.target = '_blank'
          window.el.download = url.substr(url.lastIndexOf('/') + 1)
          document.body.appendChild(window.el)
          window.el.click()
          document.body.removeChild(window.el)
        })()
      }
    },
    async setLabel(setParam, success, error) {
      this.csRequest(setLabelReq, setParam, success, error)
    },
    // 执行客户端函数（回调）
    csInvoke(req, dataJson = {}) {
      if (!this.isInClient()) {
        this.$message({
          message: '操作失败，该操作需要快捷版软件协助',
          type: 'error'
        })
        return false
      }
      try {
        const promise = new Promise((resolve, reject) => {
          window.cefQuery({
            request: req,
            data: JSON.stringify(dataJson),
            onSuccess: (res) => {
              resolve(res)
            },
            onFailure: (err) => {
              reject(err)
            },
            persistent: false
          })
        })
        return promise
      } catch (e) {
        console.error('call From Browser error', e)
        return Promise.reject(e)
      }
    },
    csRequest(req, dataJson = {}, success, error) {
      // console.log("req:", req, "dataJson:", dataJson);
      console.log('请求参数：', req, dataJson, success, error);

      if (!this.isInClient()) {
        this.$message({
          message: '操作失败，该操作需要快捷版软件协助',
          type: 'error'
        })
        console.log('请求参数：', req, dataJson, success, error);
        return false
      }
      try {
        window.cefQuery({
          request: req,
          data: JSON.stringify(dataJson),
          onSuccess: success || noop,
          onFailure: error || noop,
          persistent: false
        })
      } catch (e) {
        console.log('error', e)
        return false
      }
      return true
    },

    //打印模块兼容web与erp

    onComPrint(req, dataJson, success, error) {
      if (this.isInClient()) {
        this.csRequest(req, dataJson, success, error)
      } else {
        const params = {
          TableName: dataJson.TableName,
          SubCompanyID: dataJson.SubCompanyId,
          OrderGuid: dataJson.Guid
        }
        let tableName = params.TableName.toLowerCase();
        let url = CONFIG.WebPrintURL;
        /**
         * stk_out_delivers	系统送货单模板
stk_out_sales	销售合同模板
stk_out	快速打印-默认模板
stk_in_purchase	采购合同模板
statement	对帐单模板
quotation	报价单模板
receivetemplatea	收款收据_5列
receivetemplateb	收款收据_6列
ord_purchaseorder	新采购单模板xx
ord_salesorder	新销售单模板
postatement	采购对帐单
purchasestatement	采购对帐单
taobaoorderlabel	电商标签
sales_order_receipt	销售单兼收据-电商
stkoutlabel	出货标签
stocklabel	库存标签
sales_order_receipt1	淘宝送货单
         *
        */
        if (tableName.startsWith("stk_out_delivers"))
          url = CONFIG.WebPrintURL;
        else if (tableName.startsWith("stk_out_sales"))
          url = CONFIG.WebPrintURL;
        else if (tableName.startsWith("stk_out"))
          url = CONFIG.WebPrintURL;
        else if(tableName.startsWith("stk_in_purchase"))
          url = CONFIG.StkInPrintUrl;
          else if(tableName.startsWith("stk_in"))
            url = CONFIG.StkInPrintUrl;
        else if(tableName.startsWith("statement"))
          url = CONFIG.SalesStatementPrintUrl;
        else if(tableName.startsWith("ord_purchaseorder"))
          url = CONFIG.PurchaseOrderPrintUrl;
        else if(tableName.startsWith("ord_salesorder"))
          url = CONFIG.SalesOrderPrintUrl;
        else if(tableName.startsWith("purchasestatement"))
          url = CONFIG.PurchaseStatementPrintUrl;
        else if(tableName.startsWith("postatement"))
          url = CONFIG.PurchaseStatementPrintUrl;
        else if(tableName.startsWith("stkoutlabel"))
          url = CONFIG.WebPrintURL;
        // else if(tableName.startsWith("stocklabel"))
        //   url = CONFIG.StkInPrintUrl;
        // else if(tableName.startsWith("stk_in_purchase"))
        //   url = CONFIG.StkInPrintUrl;
        // else if(tableName.startsWith("stk_in_purchase"))
        //   url = CONFIG.StkInPrintUrl;
        let p = _.extend({}, dataJson, {OrderGuid: dataJson.Guid});
        console.log('printStkOut', url, CONFIG.WebPrintURL, tableName);
        this.$post(url, p, (data, logic) => {
          if (logic.code === 200) {
            //文件下载路径
            if (data) {
              let downloadUrl = `${apiDomain}${CONFIG.DownloadPrintFile}?fileName=${data}`;
              window.open(downloadUrl) // 下载文件
            }
          } else {
            this.$message({ message: logic.msg, type: "error" });
          }
        })
      }
    },

    // 是否为客户端内嵌网页
    isInClient() {
      return !_.isUndefined(window.cefQuery)
    },
    // 打印采购对账单
    async printPOStatement(statement, success, error) {
      this.csRequest(printPOStatementReq, statement, success, error)
    },
    // 切换页签
    switchTab(tabName) {
      /**
       * /// <summary>
       /// 库存
       /// </summary>
       Stock,
       /// <summary>
       /// 市场查货
       /// </summary>
       MarketSearch,
       /// <summary>
       /// 配单
       /// </summary>
       BOM,
       /// <summary>
       /// 单据
       /// </summary>
       Order,
       /// <summary>
       /// 客户
       /// </summary>
       Customer,
       /// <summary>
       /// 供应商
       /// </summary>
       Supplier,
       /// <summary>
       /// 数据统计
       /// </summary>
       DataStatistics,
       /// <summary>
       /// 财务
       /// </summary>
       Finance,
       /// <summary>
       /// 库存推广
       /// </summary>
       StockEx,
       /// <summary>
       /// 行业分析
       /// </summary>
       IndustryAnalysis,
       /// <summary>
       /// 销售
       /// </summary>
       Sales,
       /// <summary>
       /// 采购
       /// </summary>
       Purchase,
       /// <summary>
       /// 供应链
       /// </summary>
       SupplyChain
       */
      let param = {
        TabName: tabName
      }
      this.csInvoke(switchTabReq, param)
    },

    printBase64Pdf(param, success, error) {
      this.csRequest(printBase64PdfReq, param, success, error)
    },

    // 直接打印数据源
    printDataSource(param, success, error) {
      /**
       * param说明：
       * TableName: string. 必填. 表名，关联到打印模板
       * DataSource: array. 必填. 数据源
       * DictCode: string. 字段类型配置源对应的code(将会用此code去查询PUB_DictItem)
       * TemplateName: string. 可空 模板名称
       */
      param.DataSource = JSON.stringify(param.DataSource)
      this.csRequest(printDataSourceReq, param, success, error)
    },

    // 打印电商平台的订单
    printECommerceOrder(orderLines, isShowPreview, onSuccess, error) {
      let param = {
        TableName: 'Sales_order_receipt',
        DictCode: 'Sales_order_receipt',
        DataSource: JSON.stringify(orderLines),
        ShowPreview: isShowPreview
      }
      this.csRequest(printDataSourceReq, param, onSuccess, error)
    },

    // 关闭电子面单弹窗
    closeExpressDelivery(isReload = false) {
      this.csRequest(expressDeliveryCloseReq, { IsReload: isReload })
    },

    // 打印base64图片
    printBase64Image(base64Str, printerName = null) {
      let param = { Base64String: base64Str, PrinterName: printerName }

      this.csRequest(printBase64ImageReq, param, null, null)
    },

    // 生成pdf，并打开预览窗口
    printPreviewBase64Image(base64Str) {
      let param = { Base64String: base64Str, Title: '打印预览' }
      this.csRequest(printPreviewBase64ImageReq, param, null, null)
    },

    // 编辑快速打印模板
    editFastPrintTemplate(ptid, templateName, tableName) {
      let param = {
        PTID: ptid,
        TemplateName: templateName,
        TableName: tableName
      }
      this.csRequest(editFastPrintTemplateReq, param, null, null)
    },

    // 显示打印设置窗口
    showPrintSettings(tableName) {
      if (tableName === null || tableName === undefined || tableName === "") {
        this.$message({
          message: '参数有误，TableName 不能为空',
          type: 'error'
        })
        return false;
      }
      let param = {
        TableName: tableName
      }
      this.csRequest(showPrintSettingsReq, param, null, null)
    }
  }

}
