<template>
  <znl-dialog
    class="znl-monthly-service-intro-dialog"
    title=""
    :visible.sync="dialogVisible"
    :append-to-body="true"
    v-loading="loading"
    :is-footer-show="false"
    @close="
      () => {
        $emit('close');
      }
    "
  >
    <div>
      <!-- <div class="row-tips">
      <h3>服务介绍</h3>
      <p>正能量为符合资质的客户提供代理采购服务。</p>
    </div>
    <div class="row-tips">
      <h3>服务流程</h3>
      <p>
        <img src="http://oss.bom.ai/erp/img/load_service_process.png" />
      </p>
    </div>
    <div class="row-tips">
      <h3>温馨提示</h3>
      <p>1、货物品质：请您理解，由于货物不经过正能量，因此若出现关于货物的质量、数量、内在品质、技术参数、售后维修等问题，请采购方自行与供货商协商解决。</p>
      <p>2、税率：月结服务所涉交易必须为含税（增值税专用发票13%）交易。</p>
    </div>
    <div class="row-bottom">
      <znl-button
        :height="30"
        @click="onIKnow"
        style-type="main"
      >
        <span> 我知道了 </span>
      </znl-button>
    </div> -->
      <div class="img-par">
        <img src="https://oss.bom.ai/erp/img/loan_service_intro_2.jpg" width="1000" />
        <znl-button @click="onIKnow" style-type="main" class="iknow flex-all-center">
          <span> 我知道了 </span>
        </znl-button>
      </div>
    </div>
  </znl-dialog>
</template>
<script>
export default {
  name: "znl_monthly_service_intro",
  data() {
    return {
      dialogVisible: true,
      loading: false,
    };
  },
  methods: {
    onIKnow() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss">
.znl-monthly-service-intro-dialog {
  >div{
    width: 1000px !important;
    height: 740px !important;
  }
  .znl-dialog__body{
    width: 1000px !important;
    // height: 564px !important;
    padding: 0;
  }
  .row-tips {
    margin-top: 10px;
    padding: 0 10px;
    h3 {
      font-size: 14px;
      font-weight: 400;
      color: #e70;
      padding-left: 10px;
      margin-bottom: 0;
      line-height: 18px;
      position: relative;
    }
    h3::before {
      content: "";
      height: 18px;
      width: 2px;
      background: #e70;
      display: inline-block;
      position: absolute;
      left: 0;
    }

    p {
      padding-left: 10px;
      margin-top: 6px;
    }
  }

  .row-bottom {
    text-align: center;
    margin-bottom: 10px;
  }

  .img-par {
    position: relative;
    .iknow {
      position: absolute;
      bottom: 25px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 126px;
      height: 43px !important;
      line-height: 43px !important;
      .button {
        height: 43px !important;
        line-height: 43px !important;
      }
    }
  }
}
</style>
