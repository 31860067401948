<template>
  <div id="app" :style="appStyle">
    <component :is="layout" v-if="layout"></component>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import {getDocumentRect, on, off} from "~assets/scripts/utils/dom";
import {mixin as getCommonDataMixin} from "~assets/scripts/methods/getCommonData";
import {Base64} from "js-base64";
import {isClient} from "~/lib/runtime";
import {setBomAiWebToken} from "./assets/scripts/utils/iframe";
import {parseUrlParams} from "./assets/scripts/utils/tools";
import {mapState} from "vuex";

const defaultLayout = () => import("~layouts/default" /* webpackChunkName: "layouts/default" */);
const unAuthLayout = () => import("~layouts/unauth-layout" /* webpackChunkName: "layouts/unauth-layout" */);
const whiteRouter = ['register']
const CONFIG = {
  getUserRouteURL: "UserTabLock/GetUserTabLockList"
};

export default {
  name: "app",
  head: {
    title: "首页",
    titleTemplate: "正能量ERP网页版",
    htmlAttrs: {lang: "zh"},
    meta: [
      {"http-equiv": "Content-Type", content: "text/html; charset=utf-8"},
      {"http-equiv": "expires", content: "0"},
      {"http-equiv": "pragma", content: "no-cache"},
      {"http-equiv": "cache-control", content: "no-cache"},
      {name: "viewport", content: "width=device-width, initial-scale=1"},
      {vmid: "description", name: "description", content: "正能量|ZNL"}
    ],
    link: [{rel: "icon", type: "image/x-icon", href: "/static/znlerp.ico"}],
    noscript: [{innerHTML: "This website requires JavaScript."}],
    style: [],
    script: []
  },
  provide() {
    return {
      appVm: this,
    }
  },
  mixins: [getCommonDataMixin],
  computed: {
    ...mapState(["useInfo", "webData"]),
  },
  data() {
    return {
      layout: null,
      users: [],
      appStyle: {
        // top: "3px"
      },
      navigateWatchEventConfig: {}, // 路由变化处理配置
    };
  },
  methods: {
    setLayout(layout) {
      const routerName = this.$route.name
      console.log(routerName)
      if (whiteRouter.includes(routerName)) {
        this.layout = '';
      } else {
        this.layout = layout || defaultLayout
      }

    },
    loadLayout() {
      return Promise.resolve("");
    },
    resize() {
      const domRect = getDocumentRect();
      this.$store.commit("setDomRect", {
        clientHeight: domRect.height,
        clientWidth: domRect.width
      });
    },
    async clientLogin(isRefresh = false) {
      const loading = this.$loading({
        body: true,
        fullscreen: true,
        lock: true,
        text: "正在初始化数据...",
        customClass: "znl-login-loading"
      });
      this.userLogin(isRefresh).then(
        async () => {
          try {
            await this.initBaseDataAfterLogin();
          } catch (error) {
            this.$message({
              message: error.message,
              type: 'error'
            })
            console.log(error);
          }

          let promise = this.loadLayout();
          promise.then(() => {
            this.setLayout();
            // 是否客户端
            if (!isClient()) {
              this.$post(CONFIG.getUserRouteURL, {}, data => {
                _.each(data, route => {
                  let opt = {};
                  if (route.RouteName !== "首页") {
                    let routes = this._router.options.routes;
                    _.each(routes, item => {
                      let optitem = _.find(item.children, child => {
                        if (child.meta) {
                          return child.meta.title === route.RouteName;
                        }
                      });
                      if (optitem) {
                        opt = optitem;
                      }
                    });
                    this.$store.state.tabs.push({
                      name: opt.name,
                      params: {},
                      components: [{default: opt.component}],
                      title: opt.meta.title,
                      closable: false,
                      isIframe: false
                    });
                  }
                });
              });
            }
          });
          _.delay(() => {
            loading.close();
          }, 200);
        },
        e => {
          if (process.env.NODE_ENV === "development") {
            console.error(e);
          }

          loading.close();
          this.$alert("账号登录失败,请重试...", "提示", {
            showClose: false,
            confirmButtonText: "重新登录",
            callback: action => {
              this.clientLogin(true);
            }
          });
        }
      );
    },

    async onInit() {
      if (isClient()) {
        await this.clientLogin();
        console.log('已经登录')
      } else {
        let isLogin = await this.checkLogin(false);
        if (isLogin) {
          await this.initBaseDataAfterLogin();
        }

        this.layout = defaultLayout
        if (window.location.href.indexOf('/#/login') != -1) {
          console.log("登录页面 问题纠正")
          this.layout = unAuthLayout;
        }
        this.$router.afterEach(async (to, from) => {
          console.log('开始')
          if (to.path === '/register' || to.path === '/resetPassword') {
            this.layout = ''
          } else {
            isLogin = await this.checkLogin(false);
            if (isLogin) {
              this.layout = defaultLayout
              if (!this.webData.isBomAi) {
                console.log('加载bom.ai')
                setBomAiWebToken(this.$store)
              }
              if (to.path === '/login') {
                this.layout = unAuthLayout
              }
            } else {
              console.log('未登录')
              this.layout = unAuthLayout
              if (to.path !== '/login') {
                await this.$router.push('/login')
              }
            }
          }
        })
      }
    },

    async initBaseDataAfterLogin() {
      console.log('after userLogin. init necessary data');
      await this.getAllUserURL();
      await this.getSpecialResource();
      await this.getCompanySetup();
      await this.getCurrencyCode();
    },

    /* 设置 url变化处理配置 */
    setNavigateWatchEventConfig(opts) {
      let {source, event, path} = opts;
      let {navigateWatchEventConfig} = this;
      if (!navigateWatchEventConfig[source]) {
        this.$set(this.navigateWatchEventConfig, source, {
          event, path
        })
      }

    },

    /* url处理 */
    navigateWatchEvent(url) {
      let {$route, navigateWatchEventConfig} = this;

      let params = parseUrlParams(url);
      let findItem = null;
      for (let key in navigateWatchEventConfig) {
        let item = navigateWatchEventConfig[key];
        if (item.path === this.$route.path) {
          findItem = item;
        }
      }
      if (findItem) {
        findItem.event(params);
      }
    },
  },
  created() {
    this.onInit()
  },
  mounted() {
    // 挂载登录完成 响应式宽高
    // this.clientLogin()
    this.resize();
    on(window, "resize", this.resize);
    // 监听客户端路由切换
    window.addEventListener("navigate", e => {
      this.navigateWatchEvent(e.url);
      let {url} = e;
      if (!url) {
        return;
      }
      this.$router.push(url);
      if (this.$route.path !== url) {
        // 如果请求不到地址，再请求一次。（后期查明为何第一次请求不到地址）
        this.$router.push(url);
      }
    });

    window.addEventListener("sendevent", e => {
      let {signal, content} = e;
      if (!signal || !content) {
        return;
      }
      const data = JSON.parse(Base64.decode(content));
      const newEvent = new Event(signal);
      newEvent.data = data;
      window.dispatchEvent(newEvent);
    });

  },
  destory() {
    off(window, "resize", this.resize);
  },
  watch: {
    // $route: {
    //   handler(to) {
    //     if (!isClient()) {
    //       if(to.path === '/register') {
    //         this.setLayout()
    //         return false
    //       }else if (to.path === "/login") {
    //         this.setLayout(unAuthLayout)
    //       } else {
    //         this.setLayout(defaultLayout)
    //       }
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    // }
  }
};
</script>

<style lang='scss'>
@import "app/assets/styles/variables";
@import "app/assets/styles/mixins";

#app {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 2px;

  #fw-header {
    height: 40px;
    background-color: lighten($black, 40%);
  }

  #fw-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    bottom: 0;

    #fw-left {
      width: 160px;
      // background-color: lighten($black, 40%);
      position: absolute;
      left: 0;
      // top: 10px;
      bottom: 0;
      overflow-x: hidden;
      overflow-y: auto;
      z-index: 999;

      @include transition(left 0.5s);

      &.silder-hide {
        left: -160px;
      }
    }

    #fw-right {
      position: absolute;
      left: 160px;
      right: 0;
      top: 0;
      bottom: 0;
      min-width: 1024px;
      @include transition(left 0.5s);

      &.silder-hide {
        left: 0;
      }
    }
  }
}

//add by zilong 20211118, fix 表格样式不正常
.znl-grid-component .el-table tr td {
  padding: 0 2px !important;
}
</style>

<style lang="scss">
//自定义表格配置css修复（修复布局乱，不整洁）
.el-dialog.znl-gridconfig {
  line-height: normal !important;

  .el-dialog__header {
    padding: 12px 20px 10px;

    .el-dialog__title {
      font-size: 16px;
    }

    .el-dialog__headerbtn {
      top: 20px;
    }
  }

  .el-dialog__body {
    .el-tabs.el-tabs--card.el-tabs--top {
      margin-top: 12px;
    }

    .el-tabs__item {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
    }

    .el-tabs__content {
      padding: 0px;
    }

    .el-table .el-table__cell {
      padding: 0;
    }

    .el-table .el-table__header-wrapper .el-table__header tr th {
      height: 30px;
    }

    .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
      border-bottom: 0;
    }

    .el-table td, .el-table th.is-leaf {
      border-bottom: 0;
      border-right: 0;
    }


    .el-table.customGrid.el-table--fit.el-table--fluid-height.el-table--scrollable-y.el-table--enable-row-hover.el-table--enable-row-transition {
      border-bottom: #ccc 1px solid;
    }

    .el-table__body-wrapper .is-scrolling-none {
      max-height: 318px;
    }
  }

  .el-dialog__footer {
    padding-top: 0px;

    .pull-left {
      margin-top: -5px;
    }

    .pull-left .el-input-number__decrease, .znl-gridconfig .el-dialog__footer .pull-left .el-input-number__increase {
      line-height: 28px;
    }

    .pull-left .el-input-number__increase {
      line-height: 28px;
    }

    .pull-left .el-input .el-input__inner {
      height: 30px;
    }
  }

}
</style>
