<template>
  <!-- 我的库存 -->
  <znl-gridmodule
    ref="flexGrid"
    header
    :height="height"
    :loading="loading"
    :gridtype="isAdopt?'action':'base'"
    :columns="columns"
    :item-source="itemSource"
    :column-colors="columnColors"
    :row-colors="rowColors"
    :summary-columns="summaryColumns"
    :is-fields-search="isFieldsSearch"
    :show-save-row-right-menu="false"
    :show-delete-row-right-menu="false"
    :is-multi-rows-check="false"
    :show-save-btn="false"
    :search-fields="searchModel"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :on-init="onInit"
    :is-init="true"
    :is-show-rightbtns="false"
    :role="role"
    :show-header="isAdopt"
    :users="$store.state.users"
    :on-search="onSearch"
    :on-refresh="onRefresh"
    :on-save-row="onSaveRows"
    :on-save-rows="onSaveRows"
    :on-save-setting="onSaveSetting"
    :on-save-config="onSaveConfig"
    :on-reset-setting="onResetSetting"
    :on-page-changed="onBindData"
    :pagination-position="pagePosition"
    checkboxBindingKey="StkGUID"
    @switch-to-hide="val=>{$emit('switch-to-hide',val)}"
    @ondblclick="val=>$emit('ondblclick', val)"
  >
    <div slot="heading-slot" v-if="isAdopt" class="v-table-toolbar">
      <znl-button
        style-type="main"
        class="btn-purchase-hide"
        :height="22"
        tip="请输入采纳量采纳"
        @click="OnAdoptPrice()"
      >
        <span>按采纳量采纳</span>
      </znl-button>
    </div>
  </znl-gridmodule>
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { openLinkAll } from '@scripts/methods/common'
import { renderRequiredColumn } from '~assets/scripts/methods/common'

const CONFIG = {
  configURL: 'StkStock/GetConfig',
  saveConfigURL: 'StkStock/SaveConfig',
  resetConfigURL: 'StkStock/ResetConfig',
  searchURL: 'StkStockMini/Search',
  saveURL: 'InquireAdopt/SaveByAdoptQty', // 按采纳量采纳
  saveSingleURL: 'InquireAdopt/SaveByAdoptInfo'  // 按单条询价采纳
}

export default {
  name: 'StkStockMini',
  mixins: [znlMethodsMixin, getCommonDataMixin, openLinkAll, renderRequiredColumn],
  config: CONFIG,
  components: {},
  data () {
    return {
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      Size: 10,
      pageIndex: 1,
      loadingText: '数据加载中,请稍后...',
      loading: false,
      totalCount: 0,
      role: '1',
      Status: true,
      // 需采纳量
      needAdoptQty: 0,
      IsSingle: false
    }
  },
  watch: {
    itemSource () {
      // this.$emit('pageloading', false)
    }
  },
  computed: {
    pageSize: {
      get () {
        return this.Size
      },
      set (val) {
        this.Size = val
      }
    }
  },
  props: {
    isActivity: Boolean,
    initData: {
      type: Boolean,
      default: true
    },
    pagePosition: {
      type: String,
      defaule: 'bottom'
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isFieldsSearch: {
      type: Boolean,
      default: true
    },
    parentPageSize: {
      type: Number,
      default: 0
    },
    height: {
      type: String,
      default: 'auto'
    },
    // 是否启用分词搜索
    IsAnalyzer: {
      type: Boolean,
      default: false
    },
    // 是否采纳库存量
    isAdopt: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async onInit () {
      let config = await this.isSaveStorage('stkStock_Mini', CONFIG.configURL)
      // 获取模块打开或者关闭的状态
      this.GetStatus(config.Status)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      if (this.isAdopt) {
        columns = this.renderRequiredColumn(columns)
      }

      _.each(columns, (item, index) => {
        if (item.binding === 'Model' ||
          item.binding === 'SupplierName' ||
          item.binding === 'CustomerName' ||
          item.binding === 'BillNo') {
          _.extend(item,
            {
              className: 'znl-linkAll-column',
              renderCell: this.openLinkAll(item)
            })
        }
      })
      // 添加采纳列
      this.isAdopt && columns.unshift({
        name: 'adopt',
        dataType: 5,
        binding: 'adopt',
        width: 36,
        header: '采纳',
        visible: true,
        allowSorting: false,
        isReadOnly: false,
        editable: false,
        isFieldSearch: true,
        isSystem: true,
        buttons: [
          {
            className: 'znl-table-btn',
            content: d => {
              return '<a>采纳</a>'
            },
            click: (row) => {
              let rows = []
              if (row.InvQty > this.needAdoptQty) {
                row.AdoptQty = this.needAdoptQty
              } else {
                row.AdoptQty = row.InvQty
              }
              this.IsSingle = true
              row.IsSingle = true
              rows.push(row)
              this.onSaveRows(rows)
            }
          }
        ]
      })
      // 特殊权限， 判断是否可以查看销售
      if (!this.hasViewSalesPrice()) {
        let salesPrice = _.find(columns, m => m.binding === 'SalesPrice')
        if (!_.isUndefined(salesPrice)) {
          salesPrice.hasRole = false
        }
        let followPrice = _.find(columns, m => m.binding === 'FollowPrice')
        if (!_.isUndefined(followPrice)) {
          followPrice.hasRole = false
        }
      }

      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      let hideCols = ',SupplierName,'
      _.each(columns, column => {
        if (
          this.getSpecialResourceByCode('NoSeeSupplier') &&
          hideCols.indexOf(column.binding) > 0
        ) {
          column.hasRole = false
        }
        // 看采购价 // 特殊权限， 判断是否可以查看采购价
        let hideViewPrice = 'TaxBuyPrice,BuyPrice,TotalCostAmount'
        if (
          !this.getSpecialResourceByCode('ViewBuyPrice') &&
          hideViewPrice.indexOf(column.binding) > -1
        ) {
          column.hasRole = false
        }
      })

      this.columns = columns
      _.each(columns, (column, i) => {
        column.isReadOnly = true
      })
      let AdoptQtyColumns = _.find(columns, column => column.name === 'AdoptQty')
      if (this.isAdopt) {
        _.extend(AdoptQtyColumns, { isReadOnly: false, visible: true, hasRole: true })
      } else {
        _.extend(AdoptQtyColumns, { isReadOnly: true, visible: false, hasRole: false })
      }
      this.defaultConfig = config
      if (this.parentPageSize > 0) {
        config.PageSize = this.parentPageSize
      }
      this.pageSize = config.PageSize
      this.role = config.Role
      this.initData && this.onBindData()
    },
    async onBindData (pageIndex = 1) {
      // 需采纳量
      this.needAdoptQty = this.searchFields.NeedAdoptQty
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          Model:
            this.searchModel.models === undefined
              ? ''
              : this.searchModel.models.Model,
          Brand:
            this.searchModel.models === undefined
              ? ''
              : this.searchModel.models.Brand,
          Packaging:
            this.searchModel.models === undefined
              ? ''
              : this.searchModel.models.Packaging,
          AdoptstkGUID: this.searchModel.AdoptstkGUID,
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          IsZero: this.searchModel.IsZero, // 零库存
          BDLineGUID: this.isAdopt === true ? this.searchModel.BDLineGUID : null,
          IsAnalyzer: this.IsAnalyzer,
          ASGUID: this.searchModel.ASGUID
        }
      )
      return this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex
        // 给AdoptQty赋初始值 避免采纳时获取不到采纳数量 。
        _.each(data.ResultList, item => {
          if (item.AdoptQty === undefined) {
            item.AdoptQty = null
          }
        })
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      })
    },

    hasViewBuyPrice () {
      // 特殊权限，查看采购价
      let isnotSee = _.some(
        this.selectOptionsSpecialResource,
        item => item.Code === 'ViewBuyPrice'
      )
      return isnotSee
    },
    hasViewSalesPrice () {
      // 特殊权限，查看销售价
      let isnotSee = _.some(
        this.selectOptionsSpecialResource,
        item => item.Code === 'ViewSalesPrice'
      )
      return isnotSee
    },
    onSearch (params) {
      this.searchFields = _.extend({}, this.searchFields, params)
      return this.onBindData(1)
    },
    async onSaveRows (rows) {
      // console.log(123, rows)
      let isDiff = false
      let msg = ''
      let diffMode = ''

      _.each(rows, item => {
        if (item.AdoptQty < 0) {
          msg = '采纳数量不能小于0'
          return
        } else {
          msg = ''
        }
        item.BDLineGUID = this.searchModel.BDLineGUID
        item.ASGUID = this.searchModel.ASGUID
        if (this.hasValue(item.BuyPrice) && item.BuyPrice !== 0) {
          item.Price = item.BuyPrice
        }
        if (item.Model !== this.searchModel.Model) {
          diffMode += item.Model + ','
          isDiff = true
        }
      })
      // diffMode = diffMode.Substring(0, diffMode.Length - 1)
      if (this.hasValue(msg)) {
        return this.$message({ message: msg, type: 'error' })
      }
      if (isDiff) {
        let text = '采纳型号' + diffMode + '与需求型号' + this.searchModel.Model + '不一致，是否继续采纳?'
        await this.$confirm(text, '采纳提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          return this.onSaveAdopt(rows)
        })
      } else {
        return await this.onSaveAdopt(rows)
      }
    },
    async  onSaveAdopt (rows) {
      if (!this.IsSingle) {
        await this.onSaveByAdoptQty(rows)
      } else {
        await this.onSaveBySingle(rows[0])
      }
    },
    async onSaveByAdoptQty (rows) {
      if (rows.length > 0) {
        let array = []
        _.each(rows, item => {
          let info = {
            BDLineGUID: item.BDLineGUID,
            Qty: item.AdoptQty,
            Price: item.BuyPrice,
            IAGUID: item.IAGUID,
            StkGUID: item.StkGUID,
            Brand: item.Brand,
            Packaging: item.Packaging,
            MPQ: item.MPQ,
            MakeYear: item.MakeYear,
            Quality: item.Quality,
            DeliveryDate: item.DeliveryDate,
            ASGUID: item.ASGUID
          }
          array.push(info)
        })
        this.loading = true
        return this.$post(CONFIG.saveURL, array, (datas, logic) => {
          if (logic.code === 200) {
            this.loading = false
            this.$emit('save-success', datas)
            this.$refs.flexGrid.clearSelection()
            this.onBindData(this.pageIndex)
            this.$message({ message: '保存成功', type: 'success' })
          } else {
            this.loading = false
            this.$message({ message: logic.msg, type: 'error' })
          }
        }).catch(e => {
          this.loading = false
        })
      }
    },
    // 按单条询价
    async onSaveBySingle (row) {
      let info = {
        BDLineGUID: row.BDLineGUID,
        Qty: row.AdoptQty,
        Price: row.BuyPrice,
        StkGUID: row.StkGUID,
        Brand: row.Brand,
        Packaging: row.Packaging,
        MPQ: row.MPQ,
        MakeYear: row.MakeYear,
        Quality: row.Quality,
        DeliveryDate: row.DeliveryDate,
        ASGUID: row.ASGUID,
        IsSingle: true
      }
      this.loading = true
      return this.$post(CONFIG.saveSingleURL, info, (datas, logic) => {
        if (logic.code === 200) {
          this.loading = false
          this.$emit('save-success', datas)
          this.onBindData(this.pageIndex)
          this.$message({ message: '保存成功', type: 'success' })
        } else {
          this.loading = false
          this.$message({ message: logic.msg, type: 'error' })
        }
      }).catch(e => {
        this.loading = false
      })
    },
    async onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stkStock_Mini')
      await this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, this.defaultConfig)
      )
    },
    async onResetSetting () {
      localStorage.removeItem('stkStock_Mini')
      await this.$post(this.$options.config.resetConfigURL)
    },
    onSaveConfig (column) {
      localStorage.removeItem('stkStock_Mini')
      return this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig)
      )
    },
    // 获取模块状态
    GetStatus (val) {
      if (this.isActivity) {
        this.Status = val !== 0
        this.Status ? this.$refs.flexGrid.toggleTableShow(true) : this.$refs.flexGrid.toggleTableShow(false)
      } else {
        this.$refs.flexGrid.toggleTableShow(true)
      }
    },
    // 保存模块状态
    saveStatus (val) {
      let Status = val ? 1 : 0
      let defaultConfig = this.defaultConfig
      if (defaultConfig !== undefined && Status !== this.defaultConfig.Status) {
        this.defaultConfig.Status = Status
        let statusConfig = this.defaultConfig
        statusConfig.ColumnConfigs = []
        this.$post(this.$options.config.saveConfigURL, statusConfig)
      }
    },
    async  OnAdoptPrice () {
      let rows = this.$refs.flexGrid.getChangedData()
      this.IsSingle = false
      this.onSaveRows(rows)
    }
  },
  mounted () {
    this.$refs.flexGrid.toggleTableShow(false)
  }
}
</script>

<style lang="scss">
</style>
