import {
  StsQuoted,
  StsSaleOut,
  StsInquire,
  StsPurchaseIn,
  StsUnReceive,
  StsUnPayment,
  StsCustFollows,
  StsHotQuoteModels,
  // StsHotSearchModels,
  // StsOutDialHistory,
  StsHotSalesModel,
  HotSalesCustomer,
  HotInBillSupplier,
  StkStockStatisticSearchView,
  StockWarningStatisticSearchView,
  StsDataStatistics,
} from '~/views/Sts'

import {
  DataTools
} from '~/views/Industry'

export default {
  path: '/Sts',
  name: 'Sts',
  component: StsQuoted,
  meta: {
    title: '数据统计',
    alias: '报价统计',
    authName: '数据统计',
    icon: 'icon-left_nav_date_ic'
  },
  children: [
    // {
    //   path: 'quoted',
    //   name: 'Sts/Quoted',
    //   component: StsQuoted,
    //   meta: {
    //     title: '报价统计',
    //     authName: '报价统计',
    //     display: false
    //   }
    // },
    // {
    //   path: 'saleout',
    //   name: 'Sts/SaleOut',
    //   component: StsSaleOut,
    //   meta: {
    //     title: '销售出库统计',
    //     authName: '销售出库统计'
    //   }
    // },
    // {
    //   path: 'inquire',
    //   name: 'Sts/Inquire',
    //   component: StsInquire,
    //   meta: {
    //     title: '询价统计',
    //     authName: '询价统计'
    //   }
    // },
    // {
    //   path: 'purchasein',
    //   name: 'Sts/PurchaseIn',
    //   component: StsPurchaseIn,
    //   meta: {
    //     title: '采购入库统计',
    //     authName: '采购入库统计'
    //   }
    // },
    // {
    //   path: 'unreceive',
    //   name: 'Sts/UnReceive',
    //   component: StsUnReceive,
    //   meta: {
    //     title: '未收款统计',
    //     authName: '未收款统计'
    //   }
    // },
    // {
    //   path: 'unpayment',
    //   name: 'Sts/UnPayment',
    //   component: StsUnPayment,
    //   meta: {
    //     title: '未付款统计',
    //     authName: '未付款统计'
    //   }
    // },
    // {
    //   path: 'custfollows',
    //   name: 'Sts/CustFollows',
    //   component: StsCustFollows,
    //   meta: {
    //     title: '跟进记录统计',
    //     authName: '跟进记录统计'
    //   }
    // },
    // {
    //   path: 'hotquotemodels',
    //   name: 'Sts/HotQuoteModels',
    //   component: StsHotQuoteModels,
    //   meta: {
    //     title: '型号报价统计',
    //     authName: '型号报价统计'
    //   }
    // },
    // {
    //   path: 'hotsearchmodels',
    //   name: 'Sts/HotSearchModels',
    //   component: StsHotSearchModels,
    //   meta: {
    //     title: '热搜型号统计',
    //     authName: '热搜型号统计'
    //   }
    // },
    // {
    //   path: 'outDialHistory',
    //   name: 'Sts/OutDialHistory',
    //   component: StsOutDialHistory,
    //   meta: {
    //     title: '拨号统计',
    //     authName: '拨号统计'
    //   }
    // },
    // {
    //   path: 'HotSalesModel',
    //   name: 'Sts/HotSalesModel',
    //   component: StsHotSalesModel,
    //   meta: {
    //     title: '热销型号统计',
    //     authName: '热销型号统计'
    //   }
    // },
    // {
    //   path: 'HotSalesCustomer',
    //   name: 'Sts/HotSalesCustomer',
    //   component: HotSalesCustomer,
    //   meta: {
    //     title: '客户龙虎榜',
    //     authName: '客户龙虎榜'
    //   }
    // },
    // {
    //   path: 'HotInBillSupplier',
    //   name: 'Sts/HotInBillSupplier',
    //   component: HotInBillSupplier,
    //   meta: {
    //     title: '供应商龙虎榜',
    //     authName: '供应商龙虎榜'
    //   }
    // },
    // {
    //   path: 'StkStockStatisticSearchView',
    //   name: 'Sts/StkStockStatisticSearchView',
    //   component: StkStockStatisticSearchView,
    //   meta: {
    //     title: '库存统计',
    //     display: false
    //   }
    // },
    // {
    //   path: 'StockWarningStatisticSearchView',
    //   name: 'Sts/StockWarningStatisticSearchView',
    //   component: StockWarningStatisticSearchView,
    //   meta: {
    //     title: '库存预警统计',
    //     display: false
    //   }
    // },
    {
      path: 'StsDataStatistics',
      name: 'Sts/StsDataStatistics',
      component: StsDataStatistics,
      meta: {
        title: '数据统计'
      }
    },
    // {
    //   path: 'Industry',
    //   name: 'Sts/Industry',
    //   component: DataTools,
    //   meta: {
    //     title: '行业数据'
    //   }
    // }
  ]
}
