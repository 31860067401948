<template>
  <!-- 提示弹窗 -->
  <div>
    <znl-dialog
      title="提示"
      :visible="visible"
      subhead
      width="420px"
      class="dialog-confirm-box"
      :close-on-click-modal="false"
      :is-footer-show="true"
      @close="onClose"
    >
      <div>
        <div class="el-message-box__content">
          <div class="el-message-box__status el-icon-warning"></div>
          <div class="el-message-box__message">
            <p>
              {{message}}
            </p>
          </div>
        </div>
      </div>
      <div slot="footer" class="confirm-footer footer">
        <znl-button
          style-type="main"
          @click="onButton1Click()"
          :height="30"
        >
          <span>{{button1Text}}</span>
        </znl-button>

        <znl-button
          style-type="mac"
          @click="onButton2Click()"
          :height="30"
        >
          <span>{{button2Text}}</span>
        </znl-button>

        <znl-button
          style-type="mac"
          @click="onButton3Click()"
          :height="30"
        >
          <span>{{button3Text}}</span>
        </znl-button>
      </div>
    </znl-dialog>
  </div>
</template>
<script>

const CONFIG = {
  saveUrl: "EDAddress/Save"
};

export default {
  name: "AddressEditDialog",
  mixins: [],
  components: {},

  data() {
    return {}
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    addrId: {
      type: Number,
      default: 0
    },
    button1Text: {
      type: String,
      default: '是'
    },
    button2Text: {
      type: String,
      default: '否'
    },
    button3Text: {
      type: String,
      default: '取消'
    },
    message: {
      type: String,
      default: ''
    }
  },

  methods: {
    onInit() {
    },
    onButton1Click () {
      this.$emit('button1-click')
      this.onClose()
    },
    onButton2Click () {
      this.$emit('button2-click')
      this.onClose()
    },
    onButton3Click () {
      this.$emit('button3-click')
      this.onClose()
    },
    onClose() {
      this.$emit("close");
    }
  },
  mounted() {
    this.onInit();
  }
};
</script>
<style lang="scss">
.dialog-confirm-box {
  .confirm-footer{
    position: absolute;
    right: 5px;
    bottom: 8px;
    >.znl-button{
      margin-left: 5px;
      padding: 0 8px;
    }
  }
}
</style>
