<template>
  <!-- loading-type="animation" -->
  <!-- 设置导出列 -->
  <znl-dialog
    :visible="visible"
    v-loading="loading"
    height="500px"
    width="300px"
    element-loading-text="正在保存中..."
    class="set-exportcolumn-box"
    :title="title"
    @close="()=>{$emit('close')}"
    @confirm="onSave"
  >
    <el-row type="flex" slot="znl-dialog">
      <el-col>
        <el-row class="form_Row_W100">
          <div class="o-price">
            <table
              class="table-style"
              style="width:100%"
              border="0"
              cellspacing="0"
              cellpadding="0"
            >
              <tr class="header-bgcolor header">
                <td style="width:38px;text-align: center;"></td>
                <td style="width:160px;text-align: left;text-indent: 5px;">列名</td>
                <td style="text-align: center;">导出</td>
              </tr>
            </table>

            <div class="content-table">
              <table
                class="table-style"
                style="width:100%;"
                border="0"
                cellspacing="0"
                cellpadding="0"
              >
                <tr v-for="(item, index) in dataSource" :key="index" class="item">
                  <td style="width:20px;text-align: center;">{{ index +1}}</td>
                  <td style="text-indent: 5px;width:100px;">{{item.header || item.SCTitle}}</td>
                  <td style="text-align: center; width:40px;">
                    <el-checkbox v-model="item.IsExprot" size="small"></el-checkbox>
                  </td>
                </tr>
              </table>
            </div>

          </div>
        </el-row>
      </el-col>
    </el-row>

    <el-row align="middle" type="flex" justify="end" slot="footer" class="dialog-footer">
      <el-col
        style="display: flex;justify-content: flex-end;align-items: center; margin-top: 10px;"
      >
        <znl-button :height="30" :width="60" @click="onSave()" style-type="main">保 存</znl-button>
      </el-col>
    </el-row>
  </znl-dialog>
</template>
<script>

export default {
  name: 'setExportColumn',
  components: {
  },
  data() {
    return {
      dialogShow: false,
      showDelBtn: false,
      standardPriceIndex: 0,
      radio: false,
      dataSource: [],
      iscontinueSave: false,
      loading: false
    }
  },
  props: {
    visible: Boolean,
    saveConfigURL: String,
    columns: {
      type: Array,
      default: () => {
        return []
      }
    },
    addColumns: {
      type: Array,
      default: () => {
        return []
      }
    },
    addConfigColumns: {
      type: Object,
      default: () => {
        return {}
      }
    },
    title: {
      type: String,
      default: '设置导出列'
    }
  },
  watch: {
    isRefresh: {
      handler(value) {
        this.onBindData()
      },
      immediate: true
    },
    addColumns(newValue, oldValue) {
      this.itemSource = []
    },
    visible(val) {
      if (val) {
        this.onBindData()
        this.dialogShow = true
      }
    }
  },
  methods: {
    onBindData() {
      let columns = _.filter(this.addConfigColumns.ColumnConfigs, function (item, index) {
        return !item.IsSystem && !_.isUndefined(item.BindField)
      })
      let copy = JSON.parse(JSON.stringify(columns))
      this.dataSource = copy
    },
    onCancel() {
      this.dialogShow = false
      this.$emit('confirm', true)
    },
    onSave() {
      this.loading = true
      _.each(this.addConfigColumns.ColumnConfigs, col => {
        let col2 = _.find(this.dataSource, ds => {return ds.BindField === col.BindField})
        if (col2) {
          col['IsExprot'] = col2.IsExprot
        }
      })

      this.$post(this.saveConfigURL, this.addConfigColumns, (data, logic) => {
        this.loading = false
        if (logic.code === 200) {
          this.$message({ message: '操作成功', type: 'success' })
          this.$emit('confirm', true)
        } else {
          this.$message({ message: logic.msg, type: 'error' })
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  created() {
  }
}
</script>

<style lang="scss">
@import "app/assets/styles/_form";

.set-exportcolumn-box {
  .znl-dialog__body {
    // overflow-y: scroll;
    // padding: 30px 0 5px 0;
  }
  .content-table{
    overflow-y: auto;
    height: 370px;
  }
  .el-dialog__body {
    padding: 0 !important;
    margin: 0 auto;
  }

  .header{
    font-weight: bolder;
  }
  .header-bgcolor {
    background: #f5f5f5;
    height: 30px;
    line-height: 30px;
    // position: fixed;
    width: 97%;
    z-index: 999;
    top: 37px;
    td {
      font-size: 12px;
    }
  }
  .table-style .item td {
    font-size: 12px;
    border: none;
  }

  .table-style .item:nth-child(even) > td {
    background-color: #f5f5f5;
  }
  .table-style .item:nth-child(odd) > td {
    background-color: #fff;
  }
  .el-dialog__footer {
    padding: 8px 10px;
    background: #f5f5f5;
  }
}
</style>
