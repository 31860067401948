<template>
  <div class="file-library-box" :style="{ height: height }">
    <znl-table
      ref="table"
      gridtype="action"
      :columns="columns"
      :item-source="itemSource"
      v-loading="loading"
      box-class="file-library-box"
      element-loading-text="数据加载中,请稍后..."
      :on-page-changed="onBindData"
      :page-size="searchCondition.PageSize"
      :page-index="searchCondition.PageIndex"
      :total-count="searchCondition.TotalCount"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('FileLibrary')"
      checkbox-binding-key="Id"
      :showCheck="true"
      @drag-done="onDragDone"
      @select-change="onSelectChange"
      @on-show-trigger="onHideShowColTrigger"
      @drag-col-position="
        (val) => {
          onSaveConfig(val, true);
        }
      "
      @on-config-save="
        (val) => {
          onSaveConfig(val, false);
        }
      "
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          form-type="input"
          placeholder="型号"
          :clearable="true"
          @keyup.enter.native="onBindData()"
          width="120px"
          size="mini"
          layout="left"
          inp-perc="100%"
          :border="true"
          v-model="searchCondition.Model"
          type="text"
        ></znl-input>

        <znl-input
          form-type="input"
          placeholder="品牌"
          :clearable="true"
          @keyup.enter.native="onBindData()"
          width="120px"
          size="mini"
          layout="left"
          inp-perc="100%"
          v-model="searchCondition.Brand"
          :border="true"
          type="text"
        ></znl-input>
        <znl-input
          form-type="input"
          placeholder="备注"
          :clearable="true"
          @keyup.enter.native="onBindData()"
          width="120px"
          size="mini"
          layout="left"
          inp-perc="100%"
          v-model="searchCondition.Remark"
          :border="true"
          type="text"
        ></znl-input>

        <znl-button
          type="multiple"
          style-type="mac"
          class="box-left-btn search-btn"
        >
          <znl-button-menu @click="onBindData()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
        </znl-button>

        <znl-button style-type="mac" @click="onDelete()">
          <span>删除</span>
        </znl-button>
        <znl-button
          style-type="mac"
          @click="
            () => {
              this.fileLibraryUploadVisible = true;
            }
          "
        >
          <span>上传</span>
        </znl-button>
        <znl-button
          class="save-self-btn"
          style-type="main"
          @click="onBatchSave()"
        >
          <i class="iconfont icon-save_btn_ic"></i>
          <span>保存修改</span>
        </znl-button>
      </div>
    </znl-table>

    <el-dialog title="图片预览" :visible.sync="imagePreviewVisible" width="70%" v-dialogDrag>
      <div class="file-preview-btns">
        <znl-button style-type="mac"
          tip=""
          class="file-download-btn"
          @click="viewOrigin(imageSrc)">
          <span>查看原图</span>
        </znl-button>
      </div>
      <div style="text-align: center;">
        <img id="img_preview_file_library" :src="imageSrc" class="file-library-img" :style="{maxHeight: maxHeight, maxWidth:maxWidth}" />
      </div>
    </el-dialog>

    <FileLibraryUpload
      :visible="fileLibraryUploadVisible"
      @close="fileLibraryUploadClose"
    ></FileLibraryUpload>
  </div>
</template>

<script>
import FileLibraryUpload from "@c_modules/Stk/FileLibraryUpload";
import { erpTableSetHandler } from "@scripts/methods/common";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

const CONFIG = {
  ConfigURL: "FileLibrary/GetConfig",
  SearchURL: "FileLibrary/Search",
  BatchUpdateURL: "FileLibrary/BatchUpdate",
  DeleteURL: "FileLibrary/Delete",
};
export default {
  name: "fileLibrary",
  config: CONFIG,
  components: { FileLibraryUpload },
  mixins: [erpTableSetHandler, getCommonDataMixin],
  data() {
    return {
      columns: [],
      itemSource: [],
      loading: false,
      searchCondition: {
        Model: "",
        Brand: "",
        PageIndex: 1,
        PageSize: 30,
        TotalCount: 0,
      },
      fileLibraryUploadVisible: false,
      selectedRows: [], //选中行
      imagePreviewVisible: false, //图片预览
      imageSrc: "", //图片路径
    };
  },
  created() {},
  mounted() {
    this.$refs.table.init();
    this.onInit();
  },
  props: {
    height: {
      type: String,
      default: () => {
        return "100%";
      },
      afterGridInit: {
        // 表格初始化完成后调用
        type: Function,
      },
    },
  },
  computed: {
    boxClass() {
      if (this.height === "100%") {
        return "module-wrapper";
      }
      return "file-library-box";
    },
    maxHeight() {
      return (document.body.clientHeight - 50) * 0.65 + "px";
    },
    maxWidth() {
      return (document.body.clientWidth - 50) * 0.65 + "px"; // 弹窗的宽度为75%， 内容设置为65%
    }
  },
  methods: {
    onInit: async function (config) {
      this.loading = true;
      if (!config) {
        config = await this.isSaveStorage("FileLibrary", CONFIG.ConfigURL);
      }
      let columns = config.ColumnConfigs;

      let _this = this;
      let tempConfig = [];
      tempConfig.push({
        width: 50,
        type: "button",
        title: "操作",
        btnTxt: "预览",
        visible: true,
        isDeal: false,
        click: (row) => {
          //图片
          if (row.FileType === 1) {
            this.imagePreviewVisible = true;
            this.imageSrc = row.FileUrl;
          }
          //pdf
          else {
            window.open(row.FileUrl);
          }
        },
      },
      {
        width: 50,
        type: "panel",
        title: "文件",
        visible: true,
        isDeal: false,
        classNames: [],
        trigger(rowData, rowIndex, field) {
          let ext = _this.getExt(rowData.FileUrl)
          if (ext === ".pdf")
            return `<img src="/static/imgs/pdf.png" class="file-library-img-cell" />`;
          return `<img src="${rowData.FileUrl}" class="file-library-img-cell" />`;
        },
        click(row) {
          //图片
          if (row.FileType === 1) {
            this.imagePreviewVisible = true;
            this.imageSrc = row.FileUrl;
          }
        },
        render(rowData, rowIndex, field) {
          let ext = _this.getExt(rowData.FileUrl)
          if (ext === ".jpg" || ext === ".jpeg" || ext === ".bmp" || ext === ".png" || ext === ".gif" || ext === ".ico")
            return `<img src="${rowData.FileUrl}" class="file-library-img-preview" />`;
          return "";
        }
      }
      );
      columns = tempConfig.concat(columns);

      this.columns = columns;
      this.searchCondition.PageSize = config.PageSize;
      this.$refs.table.init();
      this.afterGridInit && this.afterGridInit();
      await this.onBindData();
      this.loading = false;
      this.$emit("page-loading", false);
    },
    async onBindData(pageIndex = 1) {
      this.loading = true;
      this.searchCondition.pageIndex = pageIndex;
      let { totalCount, itemSource } = await this.$post(
        CONFIG.SearchURL,
        this.searchCondition,
        (data) => {
          this.$emit("page-loading", false);
          this.$emit("set-total-count", data.TotalCount);
          return {
            pageIndex,
            totalCount: data.TotalCount,
            itemSource: data.ResultList,
          };
        }
      );
      this.itemSource = itemSource;
      this.searchCondition.PageIndex = pageIndex;
      this.searchCondition.TotalCount = totalCount;
      setTimeout(() => {
        this.loading = false;
      }, 800);
    },
    getExt(fileUrl) {
      if (fileUrl === undefined || fileUrl === null || fileUrl.length == 0)
        return "";
      let url = fileUrl.toLowerCase();
      let index = url.lastIndexOf(".");
      if (index > 0)
        return url.substr(index);
      return "";
    },
    fileLibraryUploadClose() {
      this.fileLibraryUploadVisible = false;
      this.onBindData();
    },
    onSelectChange(rows) {
      this.selectedRows = rows;
    },
    onDelete(rows) {
      if (this.selectedRows === null || this.selectedRows.length === 0) {
        this.$message.warning("请选择要删除的数据！");
        return;
      }
      let _this = this;

      this.$confirm("确定要删除吗？", "提示").then(async () => {
        var ids = _.map(_this.selectedRows, (item) => item.Id);

        await _this.$post(CONFIG.DeleteURL, { Ids: ids }, (data) => {
          _this.onBindData();
        });
      });
    },
    // 批量保存
    onBatchSave() {
      let rows = this.$refs.table.getChangedData();
      if (rows === undefined || rows === null || rows.length <= 0) {
        return this.$message({ message: "数据没有发生变化", type: "warning" });
      } else {
        this.onSaveRows(rows);
      }
    },
    onSaveRows(rows) {
      if (rows.length > 0) {
        return this.$post(CONFIG.BatchUpdateURL, rows, (data) => {
          if (data >= 0) {
            this.$refs.table.cleanEdit();
            this.$message({ message: "保存成功", type: "success" });
          } else {
            this.$message({ message: "保存失败", type: "error" });
          }
        });
      }
    },
    viewOrigin (url) {
      window.open(url);
    },

    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("FileLibrary", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("FileLibrary", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("FileLibrary", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("FileLibrary");
    },
  },
};
</script>

<style lang="scss">
.file-library-box{
  .file-library-img {
    max-width: 90%;
    max-height: 90%;
    object-fit: cover;
  }
  .file-preview-btns {
    position: absolute;
    top: 55px;
  }
  .file-library-img-cell {
    height: 26px;
  }
  .file-library-img-preview {
    width: auto;
    height: auto;
    max-width: 500px;
    max-height: 500px;
  }
}
</style>
