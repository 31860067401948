import { SalesAndInvoice } from '~/views/Ord'
import { BomManage } from '~/views/Bom'
import { Taobao, TaobaoItem } from '~/views/Taobao'
import { EExpressOrderForWin } from '~/views/Logistics'

export default {
  path: '/Sales', // 路由前缀
  name: 'Sales',
  component: SalesAndInvoice,
  meta: {
    title: '销售',
    alias: '销售',
    authName: '销售管理',
    icon: 'icon-left_nav_sales_ic'
  },
  children: [{
    path: 'BomManage',
    name: 'Sales/BomManage',
    component: BomManage,
    meta: {
      title: '配单',
      authName: 'BOM管理'
    }
  },
  {
    path: 'OrderManage',
    name: 'Sales/OrderManage',
    component: SalesAndInvoice,
    meta: {
      title: '销售管理',
      authName: '销售单'
    }
  },
  {
    path: 'Taobao',
    name: 'Sales/Taobao',
    component: Taobao,
    meta: {
      title: '淘宝订单',
      display: false
    }
  },
  {
    path: 'TaobaoItem',
    name: 'Sales/TaobaoItem',
    component: TaobaoItem,
    meta: {
      title: '淘宝宝贝',
      display: false
    }
  },
  {
    path: 'EExpressOrderForWin',
    name: 'Logistics/EExpressOrderForWin',
    component: EExpressOrderForWin,
    meta: {
      title: '电子面单',
      display: false
    }
  }
  ]
}
