import { ResetPasswordView } from '~/views/ResetPassword/index'

export default {
  path: '/resetPassword',
  name: 'resetPassword',
  component: ResetPasswordView,
  meta: {
    title: '修改密码',
    closable: false,
    display: false,
    anonymous: true
  }
}
