
/* 将url 中的 参数处理成 对象 */
const parseUrlParams = (url) => {
  var params = {};
  var queryString = url.split('?')[1];

  if (!queryString) {
    return params;
  }

  var paramPairs = queryString.split('&');

  for (var i = 0; i < paramPairs.length; i++) {
    var pair = paramPairs[i].split('=');
    var paramName = decodeURIComponent(pair[0]);
    var paramValue = decodeURIComponent(pair[1]);

    // 处理数组参数
    if (paramName.endsWith('[]')) {
      paramName = paramName.slice(0, -2);
      if (!params[paramName]) {
        params[paramName] = [];
      }
      params[paramName].push(paramValue);
    } else {
      params[paramName] = paramValue;
    }
  }

  return params;
}


export {
  parseUrlParams
}
