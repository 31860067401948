import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import {
  ERPCOOKIENAME,
  BOMAICOOLIENAME,
  LINKALLCOOKIENANE
} from '@scripts/config'
import { Home } from '~/views/index'
Vue.use(Vuex)

export function createStore() {
  return new Vuex.Store({
    state: {
      loading: false,
      pageLoading: true,
      siderbarHide: false,
      visibled: false,
      divHeight: 0,
      gridModule: 0,
      activeTab: 'Home',
      tabs: [
        {
          name: 'Home',
          params: {},
          query: {},
          components: [{default: Home}],
          title: '首页',
          closable: false,
          isIframe: false
        }
      ],
      linkAllInfo: {
        topicName: ''
      },
      accountInfo: {
        isMainAccount: false,
        companyID: null,
        pageRoles: [],
        moduleRoles: [],
        elementRoles: [],
        pageAuthorUrl: 'UserResource/GetPageModuls',
      },
      domRect: {
        clientWidth: 0,
        clientHeight: 0,
        headerHeight: 0,
        siderWidth: 160,
        tabHeader: 40,
        contentHeight: 0,
        contentWidth: 0,
        contentHeightWithoutTab: 0
      },
      paramOrder: {}, // 声明paramOrder对象
      users: [],
      isLoginBomaiSuccess: false,
      commonDatas: {
        SundryFeesType: [], //杂费费用类型
        CurrencyCodeOptions: [], // 币种
        TaxRateOptions: [], // 税点
        AllUserURL: [], // 发货 入库 采购 销售 人员列表
        PaymentTypeOptions: [], // 收款/结算方式
        QtyUnit: [], // 单位
        Quality: [], // 品质
        TransportCompany: [], // 快递公司
        WarehouseOptions: [], // 仓库列表
        StockSearchField: [], // 销售记录参数
        StorageType: [], // 库存类型
        BankAccount: [], // 银行账号
        BankAccountName: [], //
        BankAccountNo: [], // 银行账号
        SpecialResource: [], // 特殊权限
        CompanyParamSetup: [], // 公司参数配置信息
        UserSubCompany: [], // 子公司列表
        SalesCompanyInfo: [], // 销售公司信息
        DemandSources: [], // 需求来源
        CustomerHeat: [] // 客户热度
      },
      requestStates: {
        isGetSundryFeesType: true,
        isGetCurrencyCodeEnd: true,
        isGetReceiptTypeEnd: true,
        isGetStorageTypeEnd: true,
        isGetTaxRateEnd: true,
        isGetQtyUnitEnd: true,
        isGetQualityEnd: true,
        isGetWarehouseEnd: true,
        isGetTransportCompanyEnd: true,
        isGetBankAccountEnd: true,
        isGetBankAccountNameEnd: true,
        isGetBankAccountNoEnd: true,
        isGetSpecialResource: true,
        isGetSearchFieldEnd: true,
        isGetCompanyParamSetup: true,
        fetchingAppUsers: true,
        isGetUserSubCompany: true,
        isGetSalesCompanyInfo: true,
        isDemandSources: true,
        isGetCustomerHeat: true
      },
      shoppingData: [],
      [ERPCOOKIENAME]: '',
      [BOMAICOOLIENAME]: '',
      [LINKALLCOOKIENANE]: '',

      // 储存用户资料
      useInfo: {},

      // 设置嵌套网站token
      webData: {
        isBomAi: false
      }
    },
    mutations
  })
}
