import Vue from 'vue'
Vue.component('my-item-zh', {
  functional: true,
  render: function (h, ctx) {
    var item = ctx.props.item
    return h('li', ctx.data, [
      h('znl-gridmodule', {
        props: {
          onInit: async function () { },
          itemSource: [item],
          columns: [{
            visible: true,
            name: 'CompanyName',
            dataType: 2,
            binding: 'CompanyName',
            width: 200,
            header: '公司名称'
          }, {
            visible: true,
            name: 'FollowUserName',
            dataType: 2,
            binding: 'FollowUserName',
            width: 60,
            header: '跟进人'
          }, {
            visible: true,
            name: 'Address',
            dataType: 2,
            binding: 'Address',
            width: 150,
            header: '地址'
          }, {
            visible: true,
            name: 'ContactName',
            dataType: 2,
            binding: 'ContactName',
            width: 60,
            header: '联系人'
          }, {
            visible: true,
            name: 'Telephone',
            dataType: 2,
            binding: 'Telephone',
            width: 100,
            header: '联系人电话'
          }, {
            visible: true,
            name: 'CompanyTypeStr',
            dataType: 2,
            binding: 'CompanyTypeStr',
            width: 80,
            header: '公司类型'
          }],
          'is-fields-search': false,
          height: '110px'
        },
        attrs: {
          class: 'znl-autocomplete'
        }
      })
    ])
  },
  props: {
    item: { type: Object, required: true }
  }
})
