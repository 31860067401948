<template>
  <znl-dialog
    :title="title"
    :visible="editvisible"
    height="640px"
    width="1000px"
    class="znl-editInvoices2"
    :close-on-click-modal="false"
    :loading.sync="loading"
    :loading-text="elementLoadingText"
    :footer-height="50"
    :header-height="40"
    @close="val=>{$emit('close', val)}"
  >
    <znl-layout-topbottom
      v-show="showPage"
      :top-height="showAll?'130px':'40px'"
      class="grid-data-salereturnedit-box m-border-box"
    >
      <div slot="znlTop" class="znlTop bom-add-edit">
        <el-row type="flex" class="bom-add-title">
          <el-col :span="22" :offset="1">
            <div class="Edit-box">
              <el-row
                class="form_Row_W100 formContainer m-borderbg-box"
                type="flex"
                justify="flex-end"
              >
                <el-col :span="12">
                  <el-row class="form_Row_W100" type="flex">
                    <el-col>
                      <znl-input
                        ref="znlAutocomplete"
                        title-width="80px"
                        form-type="input"
                        placeholder="请输入客户简称或拼音字母,并按回车键查询"
                        size="mini"
                        layout="left"
                        :is-must-fill="true"
                        :border="true"
                        title="*客户名称："
                        width="98%"
                        :clearable="true"
                        v-model="BomMaster.CustomerName"
                        @keyup.enter.native="SearchCompany"
                        type="text"
                      >
                        <i slot="suffix" class="iconfont icon-edit_btn_ic" @click="SearchCompany"></i>
                      </znl-input>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="12" id="BOM-change-Bottom" style="padding-bottom:1px">
                  <el-row class="form_Row_W100">
                    <el-col :span="12">
                      <znl-input
                        title-width="75px"
                        form-type="input"
                        placeholder
                        size="mini"
                        layout="left"
                        :is-must-fill="false"
                        title="BOM名称："
                        width="100%"
                        :clearable="true"
                        :border="true"
                        v-model="BomMaster.BomName"
                        type="text"
                      ></znl-input>
                    </el-col>
                    <el-col :span="12">
                      <znl-input
                        title-width="70px"
                        form-type="input"
                        placeholder
                        size="mini"
                        layout="left"
                        :is-must-fill="false"
                        title="板子套数："
                        width="96%"
                        :height="22"
                        :border="true"
                        pattern="^[0-9]*$"
                        pattern-tip="请输入正整数"
                        @pattern-authend="val=>reg_copyNumber_status=val"
                        ref="CopyNumber"
                        :clearable="true"
                        v-model="BomMaster.CopyNumber"
                        type="text"
                      ></znl-input>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="2" class="show_more_btn">
            <span :title="showAll ? '收起': '展开'" @click="showAll=!showAll,  doLayout()">
              <i
                class="iconfont"
                style="font-size: 12px; padding-left:7px"
                :class="showAll ? 'icon-table_pack-up_btn_n1': 'icon-table_unfold_n1'"
              ></i>
              更多项
            </span>
          </el-col>
        </el-row>

        <el-row type="flex" class="bom-add-more">
          <el-col :span="22" :offset="1">
            <div class="Edit-more">
              <el-row
                class="form_Row_W100 formContainer m-borderbg-box"
                type="flex"
                v-if="showAll"
                justify="space-around"
              >
                <el-col :span="12" class="min-boderleftnone-box" style="padding-bottom:2px;">
                  <el-row class="form_Row_W100" type="flex">
                    <el-col>
                      <znl-input
                        ref="Address"
                        title-width="80px"
                        form-type="input"
                        placeholder
                        size="mini"
                        layout="left"
                        :is-must-fill="false"
                        title="地 址："
                        width="98%"
                        :border="true"
                        :clearable="true"
                        v-model="BomMaster.Address"
                        type="text"
                      ></znl-input>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="12" class="min-boder-box">
                  <el-row class="form_Row_W100">
                    <el-col :span="12">
                      <znl-input
                        title-width="75px"
                        ref="CurrencyCode"
                        layout="left"
                        form-type="select"
                        :disabled="false"
                        width="72%"
                        placeholder="请选择"
                        popper-class="popperClass"
                        title="*币 种："
                        :is-must-fill="true"
                        :border="true"
                        :clearable="true"
                        @change="CurrencyCodeChange"
                        :select-options="selectOptionsCurrencyCode"
                        v-model="BomMaster.CurrencyCode"
                      ></znl-input>
                      <znl-input
                        form-type="input"
                        :disabled="BomMaster.ExchangeRate==1?true:false"
                        placeholder
                        size="mini"
                        layout="left"
                        :is-must-fill="false"
                        title
                        width="26%"
                        :border="true"
                        :clearable="true"
                        v-model="BomMaster.ExchangeRate"
                        type="text"
                      ></znl-input>
                    </el-col>
                    <el-col :span="12">
                      <znl-input
                        title-width="70px"
                        form-type="input"
                        placeholder
                        size="mini"
                        layout="left"
                        :is-must-fill="false"
                        title="PO 号："
                        :border="true"
                        width="96%"
                        :clearable="true"
                        v-model="BomMaster.CustomerOrderNo"
                        type="text"
                      ></znl-input>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>

              <el-row
                class="form_Row_W100 formContainer m-borderbg-box"
                type="flex"
                v-if="showAll"
                justify="space-around"
              >
                <el-col
                  :span="12"
                  class="min-borderleftbottommnone-box"
                  style="padding:1px 0 1px 0;"
                >
                  <el-row class="form_Row_W100">
                    <el-col :span="12">
                      <znl-input
                        ref="Contact"
                        title-width="80px"
                        form-type="input"
                        placeholder
                        size="mini"
                        layout="left"
                        :is-must-fill="false"
                        title="联系人："
                        :border="true"
                        width="100%"
                        :clearable="true"
                        v-model="BomMaster.Contact"
                        type="text"
                      ></znl-input>
                    </el-col>
                    <el-col :span="12">
                      <znl-input
                        ref="Telephonenumb"
                        title-width="80px"
                        form-type="input"
                        placeholder
                        size="mini"
                        layout="left"
                        :is-must-fill="false"
                        title="电 话："
                        :border="true"
                        width="96%"
                        :clearable="true"
                        v-model="BomMaster.Telephone"
                        type="text"
                      ></znl-input>
                    </el-col>
                    <el-col :span="12">
                      <znl-input
                        ref="Q Q"
                        title-width="80px"
                        form-type="input"
                        placeholder
                        size="mini"
                        layout="left"
                        :is-must-fill="false"
                        title="QQ："
                        :border="true"
                        width="100%"
                        :clearable="true"
                        v-model="BomMaster.QQ"
                        type="text"
                      ></znl-input>
                    </el-col>
                    <el-col :span="12">
                      <znl-input
                        ref="BomRemark"
                        title-width="80px"
                        form-type="input"
                        placeholder
                        size="mini"
                        layout="left"
                        :is-must-fill="false"
                        :border="true"
                        title="备 注："
                        width="96%"
                        :clearable="true"
                        v-model="BomMaster.Remark"
                        type="text"
                      ></znl-input>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="12" class="min-boderbottomnone-box" style="padding-top:1px;">
                  <el-row class="form_Row_W100">
                    <el-col :span="12">
                      <znl-input
                        title-width="75px"
                        layout="left"
                        :disabled="false"
                        :border="true"
                        form-type="select"
                        width="100%"
                        placeholder="请选择"
                        popper-class="popperClass"
                        title="*税 点："
                        :is-must-fill="true"
                        :clearable="true"
                        :select-options="selectOptionsTaxRate"
                        @change="CalcTaxRate"
                        v-model="BomMaster.TaxRate"
                      ></znl-input>
                    </el-col>
                    <el-col :span="12">
                      <znl-input
                        title-width="70px"
                        layout="left"
                        :disabled="false"
                        :border="true"
                        form-type="select"
                        width="96%"
                        placeholder="请选择"
                        popper-class="popperClass"
                        title="需求来源："
                        :is-must-fill="false"
                        :clearable="true"
                        :select-options="selsectOptionsDemandSources"
                        v-model="BomMaster.RequestSource"
                      ></znl-input>
                    </el-col>
                  </el-row>
                  <el-row class="form_Row_W100" type="flex" v-if="showAll">
                    <el-col
                      :span="6"
                      class="flex-center"
                      style="font-size: 12px;padding-left:15px;"
                    >是否加急：
                      <el-checkbox size="small" v-model="BomMaster.IsUrgent" style="color:#EE7700"></el-checkbox>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="2" class="show_more_btn"></el-col>
        </el-row>
      </div>

      <div slot="znlBottom" class="znlBottom">
        <el-row type="flex" class="bom_row">
          <el-col :span="3" class="BomMaster_header">
            <span>方式一
              <br>上传BOM文件
            </span>
          </el-col>
          <el-col :span="21">
            <el-row type="flex" :span="24">
              <v-upload
                ref="vUpload"
                accept=".xls, .xlsx, .csv"
                :refresh="!isRefreshUpload"
                :refresh-click="refreshClick"
                :has-click-upload="false"
                :disabled="editType!=='addNew'"
                width="762px"
                folder="erp-bom-file"
                @upload-before="onUploadClick"
                @upload-completed="onUploadCompleted"
              ></v-upload>
            </el-row>
          </el-col>
        </el-row>

        <el-row type="flex" class="bom_row">
          <el-col :span="3" class="BomMaster_header" style="padding-top: 10px;">
            <span>方式二
              <br>粘贴剪切板内容
            </span>
          </el-col>
          <el-col :span="21">
            <el-row type="flex" style="font-size: 12px;">
              <el-col :span="22">
                <span class="fontColor">*需求来自于Excel内容、邮件、QQ等，先把需求数据复制到剪切板，在下面框内按Ctrl+V键粘贴内容，再进行修改整理</span>
              </el-col>
              <el-col :span="2" class="show_more_btn">
                <span @click="toogleShow = !toogleShow,  doLayout()">
                  <i
                    class="iconfont"
                    style="font-size: 12px"
                    :class="toogleShow ? 'icon-table_pack-up_btn_n1': 'icon-table_unfold_n1'"
                  ></i>
                  {{this.toogleShow? '收起': '展开'}}
                </span>
              </el-col>
            </el-row>
            <el-row>
              <!-- 导入数据组件 -->
              <pasteDataAcquisition
                ref="pasteDataAcquisition"
                :columns="columns"
                v-show="toogleShow"
                :show-columns="showcolumnsheader"
                class="bom_pasteDataAcquisition"
                @set-columns-list="val=>{showcolumnsheader.push(val)}"
                :rows="4"
                @add-excel-data="addExcelData"
                @set-column-order="(colsOrder) => {headerColumnsOrder = colsOrder}"
              ></pasteDataAcquisition>
            </el-row>
          </el-col>
        </el-row>

        <el-row type="flex" class="bom_row">
          <el-col :span="3" class="BomMaster_header" style="padding-top: 20px;">
            <span>方式三
              <br>直接录入右侧表格
            </span>
          </el-col>
          <el-col :span="21">
            <div :style="{ height: heightCom }">
              <znl-gridmodule
                ref="flexGrid"
                height="100%"
                gridtype="action"
                :has-znl-actions="false"
                :has-znl-btns="false"
                layout-type="other"
                class="m-btn-box"
                :columns="columns"
                :on-delete-checked-rows="onDeletedRows"
                :hasDefaultBeforeNewRow="true"
                :edit-row-index="editRowIndex"
                :item-source="itemSource"
                :column-colors="columnColors"
                :from-btns="actionBtns"
                :is-show-frombtns="true"
                :on-init="onInit"
                :is-init="true"
                :grid-show="gridShow"
                :on-refresh="onRefresh"
                :on-search="onSearch"
                :is-fields-search="false"
                :is-multi-rows-check="true"
                :row-colors="rowColors"
                :show-save-row-right-menu="false"
                :show-delete-row-right-menu="false"
                :summary-columns="summaryColumns"
                :search-fields="searchModel"
                :page-size="pageSize"
                :page-index="pageIndex"
                :is-multi-rows-check-fixed="false"
                :total-count="totalCount"
                :role="role"
                @remove-excel-data="removeitemSource"
                @edit:cell-ended="onCellEdited"
              >
                <div slot="from-module"></div>
              </znl-gridmodule>
            </div>
          </el-col>
        </el-row>
      </div>
    </znl-layout-topbottom>

    <div slot="footer" style="padding: 0 10px;">
      <div
        class="fontColor"
        style="font-size: 12px; position: absolute;left: 135px;"
      >产品类型根据型号自动生成，可做手工调整，如果设置产品类型对应的采购，在需求通知查价或报价时，自动分配相应的采购</div>
      <znl-button style-type="mac" @click="onSave(false)" :height="24">
        <i class="iconfont icon-save_btn_ic"></i>
        <span>保存</span>
      </znl-button>
      <znl-button style-type="main" @click="onSave(true)" :height="24">
        <i class="iconfont icon-save_btn_ic"></i>
        <span>保存并关闭</span>
      </znl-button>
      <d-company-contact-select
        :visible.sync="companyVisible"
        v-if="companyVisible"
        companyType="1,3"
        title="选择客户"
        :CompanyName="BomMaster.CustomerName"
        :searchModel="SelectSearchModel"
        @close="()=>{companyVisible=false}"
        @update:visible="val=>{companyVisible=val}"
        @confirm="companyChecked"
      ></d-company-contact-select>
    </div>
  </znl-dialog>
</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import pasteDataAcquisition from '@c_common/znlGrid/commonComponents/pasteDataAcquisition'
import dCompanyContactSelect from '@c_modules/Crm/DCompanyContactSelect'
import { mixin as moveToMixin } from '~assets/scripts/directives/moveTo'
import { renderRequiredColumn } from '~assets/scripts/methods/common'

import vUpload from '@c_modules/StkExt/Upload'
const CONFIG = {
  default: null,
  configURL: 'BomDetailLine/GetConfig',
  saveUrl: 'BomMaster/Save',
  searchURL: 'BomMasterDetail/Search',
  getExcelData: 'BomMaster/GetExcelData',
  getInfo: 'BomMaster/GetInfo',
  getProduceList: 'BomMaster/GetProduceList'
}
export default {
  name: 'BomMasterEdit',
  mixins: [znlMethodsMixin, getCommonDataMixin, moveToMixin, renderRequiredColumn],
  components: {
    vUpload,
    pasteDataAcquisition,
    dCompanyContactSelect
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      showcolumnsheader: ['Model', 'Brand', 'Qty'],
      reg_copyNumber_status: true,
      showAll: false,
      actionBtns: [
        {
          name: '新增一行',
          iconName: 'icon-add_btn_ic',
          click: () => {
            this.$refs.flexGrid.addRow(true)
          }
        },
        {
          name: '删除选择行',
          iconName: 'icon-delete_btn_ic',
          click: () => {
            // this.$refs.flexGrid.deleteCheckedRows()
            this.onDelRow()
          }
        }, {
          name: '清除所有行',
          iconName: 'icon-delete_btn_ic',
          click: () => {
            this.itemSource = []
          }
        }
        // {
        //   name: '导入库存',
        //   iconName: 'icon-add_btn_ic',
        //   click: () => {
        //     this.improtVisivle = !this.improtVisivle
        //     // console.log(this.improtVisivle)
        //     // this.toogleShow = !this.toogleShow
        //     // console.log(this.toogleShow)
        //   }
        // }
      ],
      BomMaster: {
        // 客户需求单对象
        BOMGUID: '',
        BillNo: '',
        QQ: '', // QQ
        Email: '', // Email
        CustomerName: '', // 客户名称
        CustomerGUID: '', // 客户ID
        Contact: '', // 联系人
        Address: '', // 地址
        Telephone: '', // 联系电话
        RequestSource: '', // 需求来源
        TaxRate: 0, // 税点
        CurrencyCode: 'RMB', // 币种
        ExchangeRate: '1', // 汇率
        ReceiptType: '', // 收款方式
        IsAdd: true, // 是否新增
        IsSaveCustomerInfo: false,
        DetailList: [], // 保存需求单详情明细行
        BomName: '<未命名>',
        BomUrl: null,
        CopyNumber: 1,
        FolderId: 0 // 文件夹id
      },
      columnColors: [],
      gridShow: true,
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      editRowIndex: -1,
      companyVisible: false,
      isRefreshUpload: false,
      refreshClick: false, // 刷新上传组件的上次的单击动作
      improtVisivle: false,
      toogleShow: true,
      loading: true,
      showPage: false,
      elementLoadingText: '正在保存数据,请稍后...',
      CompanyName: '',
      SelectSearchModel: {
        CompanyName: ''
      },
      headerColumnsOrder: []
    }
  },
  props: {
    isShowImportModule: {
      type: Boolean,
      defaule: true
    },
    editType: {
      type: String,
      default: 'addNew'
    },
    title: {
      type: String,
      default: () => {
        return '编辑客户需求'
      }
    },
    editvisible: {
      type: Boolean,
      defaule: false
    },
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: {
      type: String,
      default: () => {
        return '100%'
      }
    },
    folderId: {
      type: Number,
      default: () => {
        return 0
      }
    },
    adddata: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    adddata(newValue, oldValue) {
      this.itemSource = []
      if (newValue !== undefined) {
        for (let i = 0; i < newValue.length; i++) {
          this.addItemSourceDatas(newValue[i])
        }
      }
    },
    showAll() {
      let changebottom = document.getElementById('BOM-change-Bottom')
      if (this.showAll === true) {
        changebottom.style.borderLeft = '4px solid #e6e6e6'
        changebottom.style.paddingBottom = '0px'
      } else if (this.showAll === false) {
        changebottom.style.borderLeft = '4px solid #f5f5f5'
        changebottom.style.paddingBottom = '1px'
      }
    },
    isShowImportModule(val) {
      this.toogleShow = val
    }
  },
  methods: {
    addItemSourceDatas(data) {
      this.itemSource.push({
        Model: data.Model,
        Brand: data.Brand,
        Qty: data.InvQty,
        BuyPrice: data.BuyPrice,
        Packaging: data.Packaging,
        Unit: data.Unit,
        MakeYear: data.MakeYear,
        Quality: data.Quality,
        NTQuotePrice: data.SalesPrice,
        QuotePrice: data.SalesPrice,
        MPQ: data.MPQ
      })
    },
    removeitemSource() {
      this.itemSource = []
    },
    // 重置页面 清除数据
    onResetBomMaster() {
      this.BomMaster.BOMGUID = ''
      this.BomMaster.BillNo = ''
      this.BomMaster.QQ = '' // QQ
      this.BomMaster.Email = '' // Email
      this.BomMaster.CustomerName = '' // 客户名称
      this.BomMaster.CustomerGUID = '' // 客户ID
      this.BomMaster.Contact = '' // 联系人
      this.BomMaster.Address = '' // 地址
      this.BomMaster.Telephone = '' // 联系电话
      this.BomMaster.RequestSource = '' // 需求来源
      this.BomMaster.TaxRate = 0 // 税点
      this.BomMaster.CurrencyCode = 'RMB' // 币种
      this.BomMaster.ExchangeRate = '1' // 汇率
      this.BomMaster.ReceiptType = '' // 收款方式
      this.BomMaster.IsAdd = true // 是否新增
      this.BomMaster.IsSaveCustomerInfo = false
      this.BomMaster.BomUrl = null
      this.BomMaster.BomName = null
      this.BomMaster.Qty = null
      this.BomMaster.IsUrgent = null
      this.BomMaster.CustomerOrderNo = null
      this.BomMaster.DetailList = [] // 保存需求单详情明细行
      this.BomMaster.Remark = null
      this.itemSource = []
      this.isRefreshUpload = !this.isRefreshUpload
      this.BomMaster.BFileName = null
      this.$nextTick(() => {
        if (this.editType === 'addNew') {
          this.addNullItems(5)
        }
      })
    },
    doLayout() {
      this.$nextTick(() => {
        this.$refs.flexGrid.doLayout()
      })
    },
    // : async function
    onInit: async function (config) {
      this.elementLoadingText = '数据加载中,请稍后...'
      this.loading = true
      if (!config) {
        config = await this.isSaveStorage('bomDetailLine', CONFIG.configURL)
      }
      let columnConfigs = _.filter(config.ColumnConfigs, item => item.IsReadonly === false)
      let columns = this.flexGridColumnsHandler(columnConfigs)

      this.showPage = true

      let taxRate = this.getCompanySetupValue('DefaultTaxRate');
      if (this.hasValue(taxRate) && taxRate > 0) {
        this.BomMaster.TaxRate = taxRate
      }
      let currencyCode = this.getCompanySetupValue('DefaultSalesCurrencyCode');
      if (this.hasValue(currencyCode)) {
        this.BomMaster.CurrencyCode = currencyCode
      }

      // 品质
      // let qualityDropDown = this.selectOptionsQuality
      // qualityDropDown && _.extend(
      //     _.find(columns, function (column) {
      //       return column.binding === 'Quality'
      //     }),
      //     { dataMap: qualityDropDown, showDropDown: true }
      //   )
      // 单位
      let qtyUnitDropDown = await this.selectOptionsQtyUnit
      qtyUnitDropDown && _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Unit'
        }),
        { dataMap: qtyUnitDropDown, showDropDown: true }
      )
      columns = this.renderRequiredColumn(columns)
      this.columns = columns
      if (this.$store.state.paramOrder && this.$store.state.paramOrder.BOMGUID) {
        let guid = this.$store.state.paramOrder.BOMGUID
        this.LoadEditData(guid)
      }
      this.loading = false
    },

    companyChecked(item) {
      // console.log(item, 123)
      this.BomMaster.CustomerName = item.CompanyName // 客户名称
      this.BomMaster.CustomerGUID = item.CCompanyID // 客户ID

      if (this.hasValue(item.Address)) {
        this.BomMaster.Address = item.Address
      }
      if (this.hasValue(item.ContactName)) {
        this.BomMaster.Contact = item.ContactName
      }
      if (this.hasValue(item.Telephone)) {
        this.BomMaster.Telephone = item.Telephone
      }
      if (this.hasValue(item.TaxRate)) {
        this.BomMaster.TaxRate = item.TaxRate
        var taxRateObj = new Object();
        taxRateObj.key = this.BomMaster.TaxRate
        this.CalcTaxRate(taxRateObj)
      }
      if (this.hasValue(item.CurrencyCode)) {
        this.BomMaster.CurrencyCode = item.CurrencyCode
      }
      this.CurrencyCodeChange()
    },
    SearchCompany() {
      this.InTypes = '1,3' // 客户和供应商客户
      this.companyVisible = true
      this.CompanyName = this.BomMaster.CustomerName
      this.SelectSearchModel.CompanyName = this.BomMaster.CustomerName
    },
    onSave(state) {
      if (!this.reg_copyNumber_status) {
        this.$message({ message: '板子套数输入类型有错', type: 'wraning' })
        this.$refs.CopyNumber.handleFocus()
        return
      }
      if (this.checkData() && this.checkLength()) {
        this.saveBom(state)
      }
    },
    saveBom(state) {
      this.elementLoadingText = '正在保存数据,请稍后...'
      this.loading = true
      this.BomMaster.FolderId = this.folderId
      this.BomMaster.HeaderColumnsOrder = this.headerColumnsOrder
      return this.$post(CONFIG.saveUrl, this.BomMaster, (data, logic) => {
        _.delay(() => {
          this.loading = false
        }, 200)
        if (logic.code === 200) {
          this.$message({ message: '保存成功', type: 'success' })
          this.BomMaster.HeaderColumnsOrder = null
          this.$emit('save-success')
          if (state) {
            if (this.BomMaster.BOMGUID !== '') {  // 新增需求值为空，修改需求取guid
              this.$emit('select-bom', this.BomMaster.BOMGUID)
            }
            this.$emit('close')
          } else {
            // 连续录单清空以前数据
            this.onResetBomMaster()
          }
        } else {
          this.$message({ message: logic.msg, type: 'error' })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    checkData() {
      let isPass = true
      let rows = []
      if (!this.hasValue(this.BomMaster.CustomerName)) {
        this.$message({ message: '请填写客户名称', type: 'warning' })
        isPass = false
      }
      if (!this.hasValue(this.BomMaster.CurrencyCode)) {
        this.$message({ message: '请填写币种', type: 'warning' })
        isPass = false
      }
      if (!this.hasValue(this.BomMaster.TaxRate)) {
        this.$message({ message: '请填写税点', type: 'warning' })
        isPass = false
      }
      if (this.itemSource.length > 0) {
        let messageStr = ''
        _.each(this.itemSource, (item, index) => {
          if (messageStr.length > 0) {
            return true
          }
          // 判断是否为空行
          if (!this.isEmptyRow(item)) {
            if (!this.hasValue(item['Model'])) {
              messageStr = '需求明细第' + (index + 1) + '行 型号不能为空'
            } else if (item['Model'].length > 100) {
              isPass = false
              messageStr = '单据明细第' + (index + 1) + '行 型号长度不能超过100个字符'
            } else if (!this.hasValue(item['Qty'])) {
              messageStr = '需求明细第' + (index + 1) + '行 需求量不能为空'
            } else if (!isFinite(item['Qty'])) {
              messageStr = '需求内容第' + (index + 1) + '行 需求量必须为数字类型'
            } else if (item['Qty'] <= 0) {
              messageStr = '需求明细第' + (index + 1) + '行 需求量需大于0'
            } else if (this.hasValue(item['Brand']) && item['Brand'].length > 50) {
              isPass = false
              messageStr = '单据明细第' + (index + 1) + '行 品牌长度不能超过50个字符'
            } else if (this.hasValue(item['Packaging']) && item['Packaging'].length > 50) {
              isPass = false
              messageStr = '单据明细第' + (index + 1) + '行 封装长度不能超过50个字符'
            } else if (this.hasValue(item['MakeYear']) && item['MakeYear'].length > 50) {
              isPass = false
              messageStr = '单据明细第' + (index + 1) + '行 年份长度不能超过50个字符'
            } else if (this.hasValue(item['DeliveryDate']) && item['DeliveryDate'].length > 50) {
              isPass = false
              messageStr = '单据明细第' + (index + 1) + '行 货期长度不能超过50个字符'
            } else if (this.hasValue(item['ProductCategory']) && item['ProductCategory'].length > 50) {
              isPass = false
              messageStr = '单据明细第' + (index + 1) + '行 产品类型不能超过50个字符'
            } else if (this.hasValue(item['CustomerPartNo']) && item['CustomerPartNo'].length > 50) {
              isPass = false
              messageStr = '单据明细第' + (index + 1) + '行 客户料号不能超过50个字符'
            } else if (this.hasValue(item['BuyerRemark']) && item['BuyerRemark'].length > 200) {
              isPass = false
              messageStr = '单据明细第' + (index + 1) + '行 采购报价备注长度不能超过200个字符'
            } else if (this.hasValue(item['QuoteRemark']) && item['QuoteRemark'].length > 200) {
              isPass = false
              messageStr = '单据明细第' + (index + 1) + '行 销售报价备注长度不能超过200个字符'
            } else if (this.hasValue(item['Remark']) && item['Remark'].length > 200) {
              isPass = false
              messageStr = '单据明细第' + (index + 1) + '行 客户备注长度不能超过200个字符'
            } else if (this.hasValue(item['QuotePrice']) && !isFinite(item['QuotePrice'])) {
              isPass = false
              messageStr = '单据明细第' + (index + 1) + '行 给客户报价(含税)必须为数字类型'
            } else if (this.hasValue(item['NTQuotePrice']) && !isFinite(item['NTQuotePrice'])) {
              isPass = false
              messageStr = '单据明细第' + (index + 1) + '行 给客户报价(未税)必须为数字类型'
            } else if (this.hasValue(item['BuyPrice']) && !isFinite(item['BuyPrice'])) {
              isPass = false
              messageStr = '单据明细第' + (index + 1) + '行 供应商报价必须为数字类型'
            } else {
              rows.push(item)
            }
          }
        })
        if (messageStr.length > 0) {
          isPass = false
          this.$message({ message: messageStr, type: 'warning' })
        }
      }
      if (isPass && rows.length === 0) {
        this.$message({ message: '请添加BOM明细', type: 'warning' })
        isPass = false
      }

      this.BomMaster.DetailList = rows
      return isPass
    },
    checkLength() {
      let ret = true
      if (this.hasValue(this.BomMaster.CustomerName) && this.BomMaster.CustomerName.length > 100) {
        ret = false
        this.$refs.znlAutocomplete.handleFocus()
        this.$message({ message: '客户名称长度不能超过100个字符', type: 'warning' })
      } else if (this.hasValue(this.BomMaster.Address) && this.BomMaster.Address.length > 200) {
        ret = false
        this.$refs.Address.handleFocus()
        this.$message({ message: '地址长度不能超过200个字符', type: 'warning' })
      } else if (this.hasValue(this.BomMaster.Contact) && this.BomMaster.Contact.length > 50) {
        ret = false
        this.$refs.Contact.handleFocus()
        this.$message({ message: '联系人长度不能超过50个字符', type: 'warning' })
      } else if (this.hasValue(this.BomMaster.Telephone) && this.BomMaster.Telephone.length > 50) {
        ret = false
        this.$refs.Telephonenumb.handleFocus()
        this.$message({ message: '电话长度不能超过50个字符', type: 'warning' })
      } else if (this.hasValue(this.BomMaster.Remark) && this.BomMaster.Remark.length > 1000) {
        ret = false
        this.$refs.BomRemark.handleFocus()
        this.$message({ message: '备注不能超过1000个字符', type: 'warning' })
      }
      return ret
    },
    isEmptyRow(row) {
      let hasval = false
      let skipKeys = ['isEdit', '_PKIndex', '_PKRowindex']
      for (var i in row) {
        if (_.some(skipKeys, m => m === i)) { continue }
        if (!_.isUndefined(row[i]) && row[i] !== null && row[i] !== '') {
          hasval = true
          break
        }
      }
      return !hasval
    },
    onDelRow() {
      _.each(this.$refs.flexGrid.getSelectedRows(), data => {
        _.each(this.itemSource, (item, i) => {
          if (_.isEqual(item, data)) {
            this.itemSource.splice(i, 1)
            return
          }
        })
      })
    },
    CurrencyCodeChange(val) {     // 币种改变选项事件
      let itemVal = val ? val.key : this.BomMaster.CurrencyCode // 选中的值
      if (!this.hasValue(itemVal)) { itemVal = 'RMB' }
      if (itemVal === 'RMB') {
        this.IsExchangeRateRMB = true
      } else {
        this.IsExchangeRateRMB = false
      }
      var info = _.find(this.selectOptionsCurrencyCode, function (item) { return item.key === itemVal })
      this.BomMaster.ExchangeRate = info.ExchangeRate
    },
    // onRefresh () {
    //   return this.onBindData(this.pageIndex)
    // },
    // onSearch (params) {
    //   this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
    //   return this.onBindData(1)
    // },
    isNullItemSource() {
      return (this.itemSource.length === 0 || (this.itemSource.length < 2 && (this.itemSource[0].Model === '' || this.itemSource[0].Model === undefined) &&
        (this.itemSource[0].Qty === null || this.itemSource[0].Qty === undefined)))
    },
    LoadEditData(guid) {
      // this.editType = 'edit'
      this.$post(CONFIG.getInfo, { BOMGUID: guid }, bomMaster => {
        _.delay(() => {
          this.loading = false
        }, 200)
        bomMaster.CustomerName = bomMaster.CustomerName || ''
        this.$store.commit('setParamOrder', {})
        this.BomMaster = bomMaster
        this.itemSource = bomMaster.DetailList
      }, () => {
        this.loading = false
      })
    },
    onDeletedRows() {
      _.each(this.$refs.flexGrid.getSelectedRows(), data => {
        _.each(this.itemSource, (item, i) => {
          if (
            _.isEqual(
              _.pick(item, value => {
                return !_.isUndefined(value)
              }),
              _.pick(data, value => {
                return !_.isUndefined(value)
              })
            )
          ) {
            this.itemSource.splice(i, 1)
            return
          }
        })
      })
    },
    addExcelData(data) {
      let index = 0
      if (this.itemSource && this.itemSource.length > 0) {
        index = this.itemSource.length - 1
        let info = this.itemSource[index]
        if (!this.hasValue(info.Model) && !this.hasValue(info.Qty)) {
          this.itemSource.pop()
          index--
        }
      }
      _.each(data, item => {
        this.getValue(item)
      })
      _.each(data, item => {
        index++
        item.ID = index
        this.itemSource.push(item)
      })
      if (data.length !== 0) {
        this.toogleShow = false
      }
      let models = []
      let items = []
      _.each(this.itemSource, (item, index) => {
        if (item.Model !== undefined) {
          item.Model = item.Model.toUpperCase()
          if (!_.find(models, val => { return val.Model === item.Model })) {
            models.push(item.Model)
          }
        }
        if (this.hasValue(item.Model) || this.hasValue(item.Qty) || this.hasValue(item.Brand)) {
          items.push(item)
        }
      })

      this.itemSource = items
      if (models.length > 0) {
        // this.elementLoadingText = '正在匹配产品类型,请稍后...'
        // this.loading = true
        this.$post(CONFIG.getProduceList, models, (data, logic) => {
          _.delay(() => {
            this.loading = false
          }, 200)
          if (logic.code !== 200) {
            this.$message({ message: logic.msg, type: 'error' })
          } else {
            if (data !== undefined && data.length > 0) {
              _.each(this.itemSource, item => {
                _.each(data, val => {
                  if (!_.isUndefined(item.Model) && _.isNull(item.Model) && !_.isUndefined(val.Model) && !_.isNull(val.Model)) {
                    if (item.Model.toUpperCase() === val.Model.toUpperCase() && !_.isUndefined(val.Category) && !_.isNull(val.Category)) {
                      this.$set(item, 'ProductCategory', val.Category)
                    }
                  }
                })
              })
            }
          }
        })
      }
    },
    getValue(item) {
      // 计算未税价和含税价和金额
      if (this.hasValue(item.NTQuotePrice)) {
        // 计算含税
        let _price = this.CalcPriceInTax(item.NTQuotePrice)
        item.QuotePrice = _price === 0 ? null : _price
      } else if (this.hasValue(item.QuotePrice)) {
        // 含税算未税
        item.NTQuotePrice = this.CalcPrice(item.QuotePrice)
      }
    },
    hasValue(val) {
      if (val === null || val === undefined || val === '' || typeof (val) === 'string' && val.trim() === '') {
        return false
      }
      return true
    },
    BmdSave() {
      // 保存
      if (this.checkData() && this.checkLength()) {
        this.save()
      }
    },

    // 单击上传按钮的时候
    onUploadClick(fileName, fileSize) {
      if (fileName === '' || fileName === null || _.isUndefined(fileName)) {
        return this.$message({
          message: '请选择一个 CSV/XLS/XLSX 格式的文件',
          type: 'error'
        })
      }
      if (
        fileName.lastIndexOf('.csv') < 0 &&
        fileName.lastIndexOf('.xls') < 0 &&
        fileName.lastIndexOf('.xlsx') < 0
      ) {
        return this.$message({
          message: '仅限 CSV/XLS/XLSX 格式的文件',
          type: 'error'
        })
      }

      this.refreshClick = !this.refreshClick
    },
    // 文件上传完成之后
    onUploadCompleted(fileName, fileSize, fileUrl) {
      // 读取excel文件内容
      return this.$post(CONFIG.getExcelData, { FileUrl: fileUrl }, (data, logic) => {
        if (logic.code !== 200) {
          this.$message({ message: '读取文件数据失败', type: 'error' })
        } else {
          this.BomMaster.BFileName = fileName
          this.BomMaster.BomUrl = fileUrl
          if (this.BomMaster.BomName === '' || this.BomMaster.BomName === '<未命名>' ||
            this.BomMaster.BomName === null) {
            this.BomMaster.BomName = fileName.substr(0, fileName.lastIndexOf('.'))
          }
          if (data !== '' && data !== null && data !== undefined) {
            this.$refs.pasteDataAcquisition.setTextareaData(data)
          }
        }
      })
    },
    addNullItems(num) {
      // console.log(num)
      for (let i = 0; i < num; i++) {
        // console.log(i)
        this.$refs.flexGrid.addRow(true)
      }
    },
    // 单元格结束触发事件
    onCellEdited(aftervalue, beforevalue, colname, gridobj, rowindex, colindex) {
      // 1.修改后值 2.修改前值 3.列名 4.表格对象 5.行索引 6.列索引
      // 修改的行
      // if (colname === 'Model' && !_.isUndefined(aftervalue) && !_.isNull(aftervalue)) {
      //   gridobj.row['Model'] = aftervalue.toUpperCase()
      // }
      // 给客户报价(含税)
      if (colname === 'QuotePrice' && aftervalue !== beforevalue) {
        let priceNotTax = this.CalcPrice(aftervalue)
        gridobj.row['QuotePrice'] = this.toFloat(aftervalue, 6)
        gridobj.row['NTQuotePrice'] = this.toFloat(priceNotTax, 6)
      }
      // 给客户报价(未税)
      if (colname === 'NTQuotePrice' && aftervalue !== beforevalue) {
        let priceInTax = this.CalcPriceInTax(aftervalue)
        gridobj.row['NTQuotePrice'] = this.toFloat(aftervalue, 6)
        gridobj.row['QuotePrice'] = this.toFloat(priceInTax, 6)
      }
    },
    // 计算含税价
    CalcPriceInTax(price) {
      return this.toFloat(price * (1 + this.BomMaster.TaxRate / 100), 6)
    },
    // 计算未税
    CalcPrice(priceintax) {
      return this.toFloat(priceintax / (1 + this.BomMaster.TaxRate / 100), 6)
    },
    CalcTaxRate(val) {
      val ? (this.BomMaster.TaxRate = val.key) : (this.BomMaster.TaxRate = null)
      if (this.itemSource.length > 0) {
        const source = this.itemSource
        for (let i = 0; i < source.length; i++) {
          // 计算未税价和含税价和金额
          if (this.hasValue(source[i].NTQuotePrice)) {
            // 计算含税
            let _priceIntax = this.CalcPriceInTax(source[i].NTQuotePrice)
            source[i].QuotePrice = _priceIntax
          } else if (this.hasValue(source[i].QuotePrice)) {
            // 含税算未税
            let _price = this.CalcPrice(source[i].QuotePrice)
            source[i].NTQuotePrice = _price
          }
        }
        this.itemSource = JSON.parse(JSON.stringify(source))
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.editType === 'addNew') {
        this.addNullItems(5)
      } else if (this.editType === 'edit') {
        this.toogleShow = false
      }
    })
  },
  created() { },
  computed: {
    scollWidth() {
      return this.customizes.length * 80 + 'px'
    },
    heightCom() {
      if (this.toogleShow && !this.showAll) {
        return '186px'
      } else if (!this.toogleShow && !this.showAll) {
        return '420px'
      } else if (this.toogleShow && this.showAll) {
        return '175px'
      } else if (!this.toogleShow && this.showAll) {
        return '340px'
      }
    }
  }
}
</script>

<style lang="scss" >
@import "app/assets/styles/_globalStyle.scss";
.znl-editInvoices2 {
  .znl-layout-topbottom {
    overflow: hidden;
  }
  .flex-center {
    display: flex;
    align-items: center;
  }
  .el-table__body-wrapper {
    overflow-y: auto;
  }
  .znl-layout-leftright .znlRight {
    margin-top: 5px;
  }
  .show_more_btn {
    display: flex;
    align-items: center;
    font-size: 12px !important;
    padding-left: 8px;
    span {
      display: inline-block;
      border-radius: 3px;
      height: 12px;
      line-height: 12px;
      cursor: pointer;
    }
  }
  .from_bottommargin {
    // margin-bottom: 5px;
    padding-left: 5px;
    // line-height: 15px;
  }
  .znl-dialog.el-dialog .el-dialog__body {
    // line-height: 30px;
    padding: 0px 10px !important;
  }
  .BomMaster_header {
    color: #999;
    font-size: 12px;
    height: 35px;
    line-height: 14px;
    text-align: center;
    padding-right: 5px;
  }
  .bom_row {
    margin-top: 3px;
  }

  .znl-table-body-wrapper {
    overflow: hidden;
    .action-flex-grid {
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      .base-flex-grid {
        flex: 1;
      }
    }
  }
  .m-btn-box .znl-frombtns {
    height: 30px !important;
  }

  .el-dialog__footer {
    height: 46px;
  }
}

.fontColor {
  color: $fontExplainColor;
  text-align: left;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.el-table--border::after,
.el-table--group::after {
  opacity: 0;
}
.el-table::before {
  opacity: 0;
}
.bom-add-edit {
  .znl-input-title {
    text-align: right;
  }
  .bom-add-title {
    .Edit-box {
      border: 1px solid #d8d8d8;
      border-bottom: 0px;
      //  margin-left:34px ;
      width: 100%;
      .el-row {
        // 编辑框的el-row
        padding: 0;
      }
      .m-border-box {
        padding: 2px 0;
      }
      #BOM-change-Bottom {
        border-left: 4px solid #f5f5f5;
      }
    }
  }
  .bom-add-more {
    .Edit-more {
      border: 1px solid #d8d8d8;
      border-top: 0px;
      // margin-left:34px ;
      width: 100%;
      .el-row {
        // 编辑框的el-row
        padding: 0;
      }
      .m-border-box {
        padding: 2px 0;
      }
    }
  }
}


//add by zilong 20211118, fix 样式不正常
.znl-editInvoices2{
  .bom_pasteDataAcquisition{
    textarea{
      padding: 3px;
      font-size: 12px;
    }
  }
  .znl-dialog__body {
    line-height: 24px!important;
    padding: 2px 8px;
  }
}
</style>

