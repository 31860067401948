class AuthHandler {
  constructor (app) {
    this.app = app
  }

  get roles () {
    return this.app.$store.state.pageRoles
  }

  authorize (to, from) {
    console.log(to, from, 'authorization')
    if (this.isInRole(to.params.authName)) {
      const tab = {
        name: to.name,
        params: to.params,
        query: to.query,
        components: to.matched.map(t => t.components),
        title: to.meta.alias || to.meta.title,
        closable: to.meta.closable,
        isIframe: to.meta.isIframe
      }
      this.app.$store.commit('addTab', tab)
    } else {
      // load a empty page
      this.app.openTab(to.name, {})
      this.app.$notify.error({message: '您没有权限访问此页面', title: '提示'})
    }
  }

  isInRole (authName) {
    return _.isUndefined(authName) || _.includes(this.roles, authName)
  }
}

export function authorize (app, router) {
  const handler = new AuthHandler(app)
  router.afterEach(_.bind(handler.authorize, handler))
}
