<template>

  <znl-gridmodule ref="flexGrid"
              :header="header"
              height="100%"
              layoutType="other"
              loading-type="animation"
              :columns="columns"
              :item-source="itemSource"
              :action-btns="!isMini?actionBtns:[]"
              :column-colors="columnColors"
              :row-colors="rowColors"
              :is-multi-rows-check="isMini?false:true"
             :btn-mouse-enter-menus="btnMouseEnterMenus"
              :summary-columns="summaryColumns"
              :search-fields="searchModel"
              :is-fields-search="isFieldsSearch"
              :page-size="pageSize"
              :page-index="pageIndex"
              :total-count="totalCount"
              :role="role"
              :on-init="onInit"
              :is-init="isInit"
              :loading="!isMini && loading"
              :users="$store.state.users"
              :on-search="onSearch"
              :on-refresh="onRefresh"
              :on-save-setting="onSaveSetting"
              :on-save-config='onSaveConfig'
              :on-reset-setting="onResetSetting"
              :on-page-changed="onBindData"
              :is-activity="isActivity"
              :pagination-position="pagePosition"
              @switch-to-hide="val=>{$emit('switch-to-hide',val)}"
              @ondblclick="val=>$emit('ondblclick', val)">
           <div>
            <stk-out-edit
            :title="StkOutAddTitle"
            :editvisible="stkOutEditVisible"
            v-if="stkOutEditVisible"
            :edit-type="editType"
            height="500px"
            @confirm="stkOutEditVisible=false"
            @close="stkOutEditVisible=false"
            ></stk-out-edit>
            <stk-in-edit
            :title="StkInAddTitle"
            :editvisible="stkInEditVisible"
            v-if="stkInEditVisible"
            :edit-type="editType"
            height="500px"
            @confirm="stkInEditVisible=false"
            @close="stkInEditVisible=false"
            >
            </stk-in-edit>
           </div>
   </znl-gridmodule>

</template>
<script>
import {mixin as znlMethodsMixin, watchMixin as znlMethodswatch} from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
const StkInEdit = () => import('@c_modules/Stk/StkInEdit' /* webpackChunkName: "pages/stk" */)
const StkOutEdit = () => import('@c_modules/Stk/StkOutEdit' /* webpackChunkName: "pages/stk" */)

const CONFIG = {
  configURL: 'StkSalesRecord/GetConfig',
  saveConfigURL: 'StkSalesRecord/SaveConfig',
  resetConfigURL: 'StkSalesRecord/ResetConfig',
  searchURL: 'StkSalesRecord/Search'
}

export default {
  name: 'StkSalesRecord',
  mixins: [znlMethodsMixin, znlMethodswatch, getCommonDataMixin], // 第二个方法需要添加后才能监控到外部条件改变也可以刷新页面
  config: CONFIG,
  components: {
    StkOutEdit,
    StkInEdit
  },
  data () {
    return {
      editType: 'edit',
      StkOutAddTitle: '新增发货单',
      stkOutEditVisible: false,
      StkInAddTitle: '新增入库单',
      stkInEditVisible: false,
      actionBtns: [],
      columns: [],
      resourceList: [],
      itemSource: [],
      editRowIndex: -1,
      searchFields: {},
      filterFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      Size: 5,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      OrdPurchaseOrderAddVisible: false,
      IsRefreshByInquire: false,
      InquireModel: '',
      loading: true,
      btnMouseEnterMenus: {
        List: []
      }
    }
  },
  computed: {
    pageSize: {
      get () {
        return this.Size
      },
      set (val) {
        this.Size = val
      }
    }
  },
  props: {
    initData: {
      tayp: Boolean,
      default: true
    },
    isInit: Boolean,
    isActivity: Boolean,
    pagePosition: {
      type: String,
      defaule: 'bottom'
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    },
    isMini: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: () => {
        return ''
      }
    },
    isShow: {
      type: Boolean,
      default: true
    },
    isFieldsSearch: {
      type: Boolean,
      default: false
    },
    height: String,
    isVisibleAdopt: {// 是否显示采购列
      type: Boolean,
      default: false
    },
    isVisibleOrdpurchase: {
      type: Boolean,
      dafault: true
    }
  },
  methods: {
    onInit: async function () {
      // let config = await this.$post(CONFIG.configURL)
      let config = await this.isSaveStorage('stkSalesRecord', CONFIG.configURL)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      // await this.selectOptionsSpecialResource()
      // if (!this.hasSpecial('ViewSalesPrice')) {
      //   let PriceInTax = _.find(columns, m => m.binding === 'PriceInTax')
      //   if (!_.isUndefined(PriceInTax)) {
      //     PriceInTax.visible = false
      //   }
      //   let Price = _.find(columns, m => m.binding === 'Price')
      //   if (!_.isUndefined(Price)) {
      //     Price.visible = false
      //   }
      //   let Amount = _.find(columns, m => m.binding === 'Amount')
      //   if (!_.isUndefined(Amount)) {
      //     Amount.visible = false
      //   }
      // }

      this.resourceList = config.ResourceList
      this.defaultConfig = config
      this.columns = columns
      this.actionBtns = [
        {
          name: '批量出库',
          isShow: this.hasRes('onStkOut'),
          iconName: 'icon-sell-wh_btn_ic',
          click: () => { this.stkOutAddRows() }
        },
        {
          name: '批量入库',
          isShow: this.hasRes('onStkIn'),
          iconName: 'icon-buy-wh_btn_ic',
          click: () => { this.stkInAddRows() }
        }
      ]
      this.isMini ? ''
      : columns.unshift({// 操作列
        name: 'operate',
        dataType: 5,
        binding: 'operate',
        width: 36,
        header: '操作',
        visible: true,
        isReadOnly: true,
        isFieldsSearch: false,
        isSystem: true,
        align: 'left',
        className: 'znlerp-operate znl-text-center',
        buttons: [
          {
            content: '<i class="iconfont icon-left_nav_show_btn"></i>',
            className: 'znl-table-btn',
            click: () => {}
          }
        ]
      })
      this.btnMouseEnterMenus = {
        List: [
          {
            name: '销售出货',
            isShow: this.hasRes('onStkOut'),
            click: row => {
              let rows = []
              rows.push(row)
              this.onStkOutadd(rows)
            }
          },
          {
            name: '采购入库',
            isShow: this.hasRes('onStkIn'),
            click: row => {
              this.onStkInadd(row)
            }
          }
        ],
        Binding: 'operate'
      }
      this.role = config.Role
      this.pageSize = config.PageSize
      // console.log('init', this.initData)
      this.initData && this.onBindData()
    },
    onAdoptEnd (stkGUID) { // 采纳结束后前台显示值改变
      let filter = {
        AdoptstkInquireGUID: stkGUID
      }
      this.filterFields = filter // 更新外部条件
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('stkSalesRecord')
      return this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig)
      )
    },
    onOrdpurchase (inquire) { // 生成采购单
      this.InquireModel = inquire
      this.OrdPurchaseOrderAddVisible = true
      this.IsRefreshByInquire = !this.IsRefreshByInquire
    },
    onBindData (pageIndex) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.searchModel), // fieldsFilter
        AdoptstkVMIGUID: this.searchModel.AdoptstkVMIGUID,
        PageIndex: pageIndex,
        PageSize: this.pageSize
      })
      return this.$post(CONFIG.searchURL, data, (data) => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
        this.loading = false
      })
    },
    hasRes (code) {
      return _.some(this.resourceList, page => page.Code === code)
    },
    stkOutAddRows () { // 批量销售出库
      let rows = this.onSelectRow() // 选中行的bdlineguid数组

      if (rows.length > 0) {
        this.onStkOutadd(rows)
        this.clearSelectedRows()
      }
    },
    onStkOutadd (rows) { // 销售出库传值方法
      let order = { stockLine: rows }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkOutEditVisible = true // 显示编辑页面
      })
    },
    onStkInadd (rows) { // 入库
      let order = {StkInRecord: [rows]}
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkInEditVisible = true // 显示编辑页面
      })
    },
    stkInAddRows () { // 批量采购入库
      let rows = this.onSelectRow() // 选中行的bdlineguid数组
      if (rows.length > 0) {
        let order = { StkInRecord: rows }
        this.$store.commit('setParamOrder', order)
        this.$nextTick(function () {
          this.$refs.flexGrid.clearSelection()
          this.stkInEditVisible = true // 显示编辑页面
        })
      }
    },
    clearSelectedRows () {
      this.$refs.flexGrid.clearSelection()
    },
    onSelectRow () {
      // let guid = [] // 选中行的bdlineguid数组
      let rows = this.$refs.flexGrid.getSelectedRows()
      // console.log(123123123, rows)
      if (_.isUndefined(rows[0])) {
        if (!this.CurrentItem) {
          // 选中行
          return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
        } else {
          this.$refs.flexGrid.clearSelection()
          // guid.push(this.CurrentItem.BDLineGUID)
        }
      } else {
        // 判断选中行的客户是否一致
        var lastCustomerName = rows[0].SupplierName
        var isDiff = false
        _.each(rows, r => {
          if (lastCustomerName !== r.SupplierName) {
            isDiff = true
          }
        })
        if (isDiff) {
          return this.$message({ message: '请选择相同供应商的数据进行操作', type: 'error' })
        }
        // guid = _.map(rows, item => {
        //   return item.BDLineGUID
        // })
      }
      return rows
    },
    hasSpecial (code) {
      return _.some(this.selectOptionsSpecialResource, page => page.Code === code)
    },
    async onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stkSalesRecord')
      await this.$post(this.$options.config.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.defaultConfig))
    },
    async onResetSetting () {
      localStorage.removeItem('stkSalesRecord')
      await this.$post(this.$options.config.resetConfigURL)
    }
  },
  mounted () {
    !this.isShow && this.$refs.flexGrid.toggleTableShow(false)
  }
}
</script>

<style lang="scss">

</style>
