
const isProduction = process.env.NODE_ENV === "production";
const defaultBomAiSrc = isProduction ? 'https://www.bom.ai' : 'https://wxapp.bom.ai'
export function setBomAiWebToken(store) {

  console.log(store.state.useInfo)
  const {AccessToken} = store.state.useInfo
  // 创建iframe
  const iframeDom = document.createElement('iframe')
  iframeDom.id = 'iframeDom_id'

  var href = window.location.href;
  let src = defaultBomAiSrc;
  if(href.indexOf('http://test.znlbd.com') > -1)
  {
    src = "http://test.bom.ai"
  }

  iframeDom.src = `${src}`
  iframeDom.style.display = 'none'
  document.body.appendChild(iframeDom)


  // 建立通讯
  const iframe = document.getElementById('iframeDom_id')
  iframe.onload = function () {
    iframe.contentWindow.postMessage(JSON.stringify({
      source: 'BomBuySearch',
      token: AccessToken
    }), '*')
  }

  window.addEventListener('message', (v) => {
    if(v.data && typeof v.data == 'string') {
      let data = JSON.parse(v.data)
      console.log(data, 'data')
      if (data.source === "BomAi") {
        console.log('token已写入')
        console.log('setIsBomAi', 'token已写入')
        destroyIframe('iframeDom_id')
        store.commit('setIsBomAi', true)
      }
    }
  });
}

function destroyIframe(iframeID){
  let iframe = document.getElementById(iframeID);

  //把iframe指向空白页面，这样可以释放大部分内存。
  iframe.src = 'about:blank';

  try{
    iframe.contentWindow.document.write('');
    iframe.contentWindow.document.close();
  }catch(e){}

  //把iframe从页面移除
  iframe.parentNode.removeChild(iframe);

}

