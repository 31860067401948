import {
  PrintBroker
} from '~/views/Common'

export default {
  path: '/common',
  name: 'Common',
  meta: {
    title: 'Common',
    icon: 'icon-left_nav_crm_ic'
  },
  children: [{
    path: 'PrintBroker',
    name: 'PrintBroker',
    component: PrintBroker,
    meta: {
        title: 'PrintBroker'
      }
    }
  ]
}
