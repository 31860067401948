const pageA5 = {
  width: "24cm",
  height: "14cm",
  paddingLeft: "0.5cm",
  paddingTop: "0.5cm",
  rowHeight: 26,
  rowWidth: "100%",
  lineHeight: 26,
  fontSize: 16,
  fontFamily: "SimSun"
};
const pageA4 = {
  width: "21cm",
  height: "29.7cm",
  paddingLeft: "0.5cm",
  paddingTop: "0.5cm",
  rowHeight: 26,
  rowWidth: "100%",
  lineHeight: 26,
  fontSize: 16,
  fontFamily: "SimSun"
};
const table = {
  rowHeight: 26,
  headerHeight: 26
};
const xsshd = {
  page: pageA5,
  name: "销售送货单",
  row: [
    {
      content: [{ text: "深圳市正能量网络技术有限公司" }],
      fontSize: 24,
      fontFamily: "SimSun",
      textAlign: "center",
      fontWeight: "bolder",
      marginBottom: 20
    },
    {
      content: [
        {
          text: "联系电话:",
          field: "Mobile"
        },
        {
          text: "客户名称:",
          field: "SalCompany"
        }
      ]
    },
    {
      content: [
        {
          text: "手机/微信:",
          field: "Mobile"
        },
        {
          text: "订单编号:",
          field: "BillNo"
        }
      ]
    },
    {
      content: [
        {
          text: "地址:",
          field: "Address"
        },
        {
          text: "电话:",
          field: "Mobile"
        }
      ]
    },
    {
      fontSize: 20,
      fontWeight: "bold",
      textAlign: "center",
      marginTop: 10,
      marginBottom: 5,
      fontFamily: "SimSun",
      content: [
        {
          text: "销售送货单"
        }
      ]
    },
    {
      type: "table",
      colConfig: [
        {
          headerName: "名称及规格",
          field: "Model"
        },
        {
          headerName: "批号",
          field: "Brand"
        },
        {
          headerName: "数量",
          field: "Qty"
        },
        {
          headerName: "单价",
          field: "BuyPrice"
        },
        {
          headerName: "金额",
          field: "PurchaseAmount"
        },
        {
          headerName: "备注",
          field: "remark"
        }
      ],
      footer: [
        {
          text: "合计人民币(大写): ",
          type: "capital",
          colspan: 3
        },
        {
          text: "小写",
          type: "count",
          colspan: 6
        }
      ]
    },
    {
      type: "textarea",
      marginTop: 20,
      paddingBottom: 20,
      lineHeight: 26,
      rowHeight: 120,
      fontSize: 16,
      align: "left",
      content: `备注：
(1) 请在收到货物后如有疑问，请在当他收货验货提出， 否则视为验收合格。
(2) 产品包装如果有拆过以及损坏或上机安装后拆回货物一律不予退换
(3) 请检查产品后再上记，如有发现品质问题，请及时与公司有关负责人联系。`
    },
    {
      content: [
        {
          text: "销售单位及经手人:",
          field: "ReceiptType"
        },
        {
          text: "收货单位及经手人:",
          field: "UpdateByName"
        }
      ]
    }
  ],
  table
};
const bjd = {
  page: pageA5,
  name: "报价单",
  row: [
    {
      textAlign: "center",
      content: [
        {
          field: "SubCompanyName"
        }
      ],
      fontSize: 24,
      marginBottom: 20
    },
    {
      type: "image",
      isFloat: true,
      width: 180,
      height: 34,
      top: 0,
      src: "https://static.bom.ai/files/owner/logo.png"
    },
    {
      content: [
        {
          text: "地址:",
          field: "Address"
        },
        {
          text: "联系人:",
          field: "Contact"
        }
      ]
    },
    {
      content: [
        {
          text: "电话:",
          field: "Mobile"
        },
        {
          text: "传真:",
          field: "Mobile"
        }
      ]
    },
    {
      fontWeight: "bold",
      marginTop: 20,
      content: [
        {
          text: "特为下面客户报价"
        }
      ],
      bold: true
    },
    {
      content: [
        {
          text: "公司名:",
          field: "SalCompany"
        }
      ]
    },
    {
      content: [
        {
          text: "公司地址:",
          field: "Address"
        }
      ]
    },
    {
      content: [
        {
          text: "联系人:",
          field: "Contact"
        },
        {
          text: "电话:",
          field: "Mobile"
        },
        {
          text: "传值:",
          field: "Mobile"
        }
      ]
    },
    {
      fontSize: 20,
      textAlign: "center",
      content: [{ text: "报价单" }]
    },
    {
      type: "table",
      countField: "PriceInTax",
      qtyField: "Qty",
      colConfig: [
        {
          headerName: "型号",
          field: "Model"
        },
        {
          headerName: "品牌",
          field: "Brand"
        },
        {
          headerName: "年份",
          field: "MakeYear"
        },
        {
          headerName: "封装",
          field: "Packaging"
        },
        {
          headerName: "数量",
          field: "Qty"
        },
        {
          headerName: "单价",
          field: "PriceInTax"
        },
        {
          headerName: "备注",
          field: "Remark"
        }
      ],
      footer: [
        {
          text: "合计人民币(大写)",
          type: "count",
          colspan: 4
        },
        {
          text: "税率",
          field: "TaxRate",
          colspan: 3
        }
      ]
    },
    {
      type: "textarea",
      field: "SalesPriceClause_CN",
      marginTop: 10
    }
  ],
  table
};
const cgdzd = {
  page: pageA5,
  name: "采购对账单",
  row: [
    {
      textAlign: "center",
      content: [
        {
          field: "SubCompanyName"
        }
      ],
      fontSize: 24,
      marginTop: 10,
      marginBottom: 20
    },
    {
      content: [
        {
          text: "电话",
          field: "Telephone"
        },
        {
          text: "传真",
          field: "Fax"
        }
      ]
    },
    {
      textAlign: "center",
      content: [
        {
          text: "月结对账单"
        }
      ]
    },
    {
      fontWeight: "bold",
      content: [{ text: "供应商基本信息" }]
    }
  ],
  table
};
const cght = {
  page: pageA4,
  name: "采购合同",
  row: [
    {
      textAlign: "center",
      content: [
        {
          field: "SubCompanyName"
        }
      ],
      fontSize: 24,
      marginBottom: 20
    },
    {
      type: "image",
      isFloat: true,
      width: 180,
      height: 34,
      top: 0,
      src: "https://static.bom.ai/files/owner/logo.png"
    },
    {
      textAlign: "center",
      content: [
        {
          text: "采购合同"
        }
      ],
      fontSize: 20
    },
    {
      content: [
        {
          text: "供方:",
          field: "SupplierName"
        },
        {
          text: "合同编号:",
          field: "BillNo"
        }
      ]
    },
    {
      content: [
        {
          text: "需方:",
          field: "SubCompanyName"
        },
        {
          text: "签订日期:",
          field: "ReceiveTimeStr"
        }
      ]
    },
    {
      marginTop: 5,
      marginBottom: 5,
      content: [
        {
          text:
            "经供需双方友好协商，依平等互利、诚信的原则，特订立本购销合同，由供需双方共同遵照执行："
        }
      ]
    },
    {
      content: [
        {
          text: "一、名称、规格、材质、产地、数量、单价、金额、等。",
          width: 500
        },
        {
          text: "币种:",
          field: "CurrencyCode"
        },
        {
          text: "税率:",
          field: "TaxRate"
        }
      ]
    },
    {
      type: "table",
      countField: "Price",
      colConfig: [
        {
          headerName: "序号",
          field: "RowID"
        },
        {
          headerName: "产品名称",
          field: "Model"
        },
        {
          headerName: "生产厂家",
          field: "Brand"
        },
        {
          headerName: "货期",
          field: ""
        },
        {
          headerName: "批号",
          field: "MakeYear"
        },
        {
          headerName: "数量",
          field: "Qty"
        },
        {
          headerName: "单位",
          field: "Unit"
        },
        {
          headerName: "单价",
          field: "Price"
        },
        {
          headerName: "金额",
          field: "Amount"
        },
        {
          headerName: "备注",
          field: "Remark"
        }
      ],
      footer: [
        {
          text: "合计",
          colspan: 2
        },
        {
          type: "count",
          colspan: 10
        }
      ]
    },
    {
      content: [
        {
          text: "二、付款方式",
          field: "ReceiptType"
        }
      ]
    },
    {
      marginBottom: 10,
      content: [
        {
          text: "供方（盖章）"
        },
        {
          text: "需方（盖章）"
        }
      ]
    },
    {
      content: [
        {
          text: "代表人",
          field: "Contact"
        },
        {
          text: "代表人",
          field: "Company_Contact"
        }
      ]
    },
    {
      content: [
        {
          text: "地址",
          field: "Address"
        },
        {
          text: "地址",
          field: "SubCompany_Address"
        }
      ]
    },
    {
      content: [
        {
          text: "电话"
        },
        {
          text: "电话"
        }
      ]
    },
    {
      content: [
        {
          text: "传真"
        },
        {
          text: "传真"
        }
      ]
    }
  ],
  table
};
const dzd = {
  page: pageA5,
  name: "对账单",
  row: [
    {
      textAlign: "center",
      fontSize: 24,
      marginTop: 10,
      marginBottom: 20,
      content: [
        {
          field: "SubCompanyName"
        }
      ]
    },
    {
      textAlign: "right",
      content: [
        {
          text: "电话:",
          field: "Telephone"
        },
        {
          text: "传真:",
          field: "Fax"
        }
      ]
    },
    {
      textAlign: "center",
      content: [
        {
          text: "月结对账单"
        }
      ]
    },
    {
      fontWeight: "bold",
      content: [
        {
          text: "客户基本信息"
        }
      ]
    },
    {
      content: [
        {
          text: "公司编号",
          field: "CompanyCode"
        },
        {
          text: "对账期间",
          field: "ReconcileDate"
        }
      ]
    },
    {
      content: [
        {
          text: "公司名称",
          field: "CompanyName"
        },
        {
          text: "票据种类"
        }
      ]
    },
    {
      content: [
        {
          text: "财务联系人",
          field: "Contact"
        },
        {
          text: "税率",
          filed: "TaxRate"
        }
      ]
    },
    {
      content: [
        { text: "电话", field: "Telephone" },
        {
          text: "结算方式",
          field: "ReceiptType"
        }
      ]
    },
    {
      content: [
        {
          text: "传真",
          field: "Fax"
        },
        {
          text: "E-mail:(对账用)",
          field: "Email"
        }
      ]
    },
    {
      fontWeight: "bold",
      content: [
        {
          text: "业务信息"
        }
      ]
    },
    {
      type: "table",
      countField: "Amount",
      colConfig: [
        {
          headerName: "日期",
          field: "ShipTimeStr"
        },
        {
          headerName: "送货单号",
          field: "BillNo"
        },
        {
          headerName: "物料名称及型号",
          field: "SalesModel"
        },
        {
          headerName: "数量",
          field: "Qty"
        },
        {
          headerName: "单价",
          field: "PriceInTax"
        },
        {
          headerName: "金额",
          field: "Amount"
        },
        {
          headerName: "客户料号",
          field: "CustomerPartNo"
        },
        { headerName: "客户订单号", field: "CustomerOrderNo" }
      ],
      footer: [
        {
          text: "本期应收款合计",
          field: "AllUpSalesAmount",
          colspan: 5
        },
        {
          type: "count",
          colspan: 4
        }
      ]
    },
    {
      content: [
        {
          text: "上月结存金额"
        },
        {
          text: "本月已收金额",
          field: "AllReceAmount"
        }
      ]
    },
    {
      content: [
        {
          text: "本月应收款金额",
          field: "AllSalesAmount"
        },
        {
          text: "本月累计数",
          field: "AllUnReceAmount"
        }
      ]
    },
    {
      content: [
        {
          text:
            "注：以上业务信息请根据我司提供的收货/退货单据内容填写，“单据编号”是指我司的收货单/退货单或其他有效单据的编号。"
        }
      ]
    },
    {
      content: [
        {
          text: "此对账单原件为我司安排收款的唯一有效凭证。"
        }
      ]
    },
    {
      content: [
        {
          field: "CompanyName"
        },
        {
          text: "客户"
        }
      ]
    },
    {
      content: [
        {
          text: "（盖章）"
        },
        {
          text: "（盖章）"
        }
      ]
    },
    {
      content: [
        {
          text: "日期:__________________"
        },
        {
          text: "日期:__________________"
        }
      ]
    }
  ],
  table
};
const sksj5 = {
  page: pageA5,
  name: "收款收据5",
  row: [
    {
      fontSize: 24,
      textAlign: "center",
      marginTop: 10,
      fontWeight: "bold",
      marginBottom: 20,
      content: [
        {
          field: "SubCompanyName"
        }
      ]
    },
    {
      content: [
        {
          text: "Tel",
          field: "Telephone"
        },
        {
          text: "Fax",
          field: "Fax"
        }
      ]
    },
    {
      content: [
        {
          text: "Address",
          field: "Address1"
        }
      ]
    },
    {
      fontSize: 24,
      textAlign: "center",
      content: [
        {
          text: "收  款  收  据"
        }
      ]
    },
    {
      content: [
        {
          text: "客户名称",
          field: "CustomerName"
        },
        {
          text: "收款日期",
          field: "ShipTimeStr"
        }
      ]
    },
    {
      type: "table",
      countField: "Amount",
      colConfig: [
        {
          headerName: "序号",
          field: "RowId"
        },
        {
          headerName: "规格及封装",
          field: "Model"
        },
        {
          headerName: "数量",
          field: "Qty"
        },
        {
          headerName: "单价",
          field: "Price"
        },
        {
          headerName: "金额",
          field: "Amount"
        }
      ],
      footer: [
        {
          text: "总计(金额:￥)",
          colspan: 2
        },
        {
          type: "count",
          colspan: 4
        }
      ]
    }
  ],
  table
};
const sksj6 = {
  page: pageA5,
  name: "收款收据6",
  row: [
    {
      fontSize: 24,
      textAlign: "center",
      marginTop: 10,
      fontWeight: "bold",
      marginBottom: 20,
      content: [
        {
          field: "SubCompanyName"
        }
      ]
    },
    {
      type: "image",
      isFloat: true,
      width: 180,
      height: 34,
      top: 30,
      src: "https://static.bom.ai/files/owner/logo.png"
    },
    {
      content: [
        {
          text: "Tel",
          field: "Telephone"
        },
        {
          text: "Fax",
          field: "Fax"
        }
      ]
    },
    {
      content: [
        {
          text: "Address",
          field: "Address1"
        }
      ]
    },
    {
      textAlign: "center",
      fontSize: 24,
      content: [{ text: "收款收据" }]
    },
    {
      type: "table",
      countField: "Amount",
      colConfig: [
        {
          headerName: "名称及规格",
          field: "Model"
        },
        {
          headerName: "封装",
          field: "Packaging"
        },
        {
          headerName: "数量",
          field: "Qty"
        },
        {
          headerName: "单价(人民币)",
          field: "Price"
        },
        {
          headerName: "金额(人民币)",
          field: "Amount"
        },
        {
          headerName: "备注",
          field: "Remark"
        }
      ],
      footer: [
        {
          text: "合计人民币(大写)",
          type: "capital",
          colspan: 3
        },
        {
          text: "小写",
          type: "count",
          colspan: 6
        }
      ]
    },
    {
      fontSize: 13,
      rowHeight: 20,
      content: [
        {
          whiteSpace: "normal",
          text:
            "注：客户在收货后，应在7天内对产品进行质量检测，如果有问题，请在15天内，以书面形式反馈给本公司，否则视为检验合格，产品上机后一律不予退换，若未按上述执行的，其一切后果由收货方负责，与公司无关。"
        }
      ]
    },
    {
      content: [
        {
          text: "收款单位及经手人（盖章）:___________."
        },
        {
          text: "付款单位及经手人（盖章）:___________."
        }
      ]
    }
  ],
  table
};
const shd = {
  page: pageA5,
  name: "送货单",
  row: [
    {
      fontSize: 24,
      textAlign: "center",
      marginTop: 10,
      fontWeight: "bold",
      marginBottom: 20,
      content: [
        {
          field: "SubCompanyName"
        }
      ]
    },
    {
      type: "image",
      isFloat: true,
      width: 180,
      height: 34,
      top: 30,
      src: "https://static.bom.ai/files/owner/logo.png"
    },
    {
      content: [
        {
          text: "公司地址",
          field: "Address1"
        },
        {
          text: "客户",
          field: "CustomerName"
        }
      ]
    },
    {
      content: [
        {
          text: "门市地址",
          field: "Address2"
        },
        {
          text: "姓名",
          field: "Contact"
        }
      ]
    },
    {
      content: [
        { text: "电话", field: "Telephone" },
        { text: "电话", field: "Telephone" }
      ]
    },
    {
      content: [
        { text: "传真", field: "Fax" },
        { text: "地址", field: "Address" }
      ]
    },
    {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: 20,
      content: [
        {
          text: "送货清单"
        }
      ]
    },
    {
      type: "table",
      countField: "Amount",
      colConfig: [
        {
          headerName: "名称及规格",
          field: "SalesModel"
        },
        {
          headerName: "品牌",
          field: "Brand"
        },
        {
          headerName: "批号",
          field: "MakeYear"
        },
        {
          headerName: "封装",
          field: "Packaging"
        },
        {
          headerName: "数量",
          field: "Qty"
        },
        {
          headerName: "单价",
          field: "PriceInTax"
        },
        {
          headerName: "金额",
          field: "Amount"
        },
        {
          headerName: "备注",
          field: "Remark"
        }
      ],
      footer: [
        {
          text: "合计人民币(大写)",
          type: "capital",
          colspan: 3
        },
        {
          text: "小写",
          type: "count",
          colspan: 5
        }
      ]
    },
    {
      type: "textarea",
      field: "SellDeliveryClause"
    },
    {
      content: [
        {
          text: "销售单位及经手人"
        },
        {
          text: "收货单位及经手人"
        }
      ]
    },
    {
      content: [
        {
          text: "Issued By(签章)",
          field: "Shipper"
        },
        {
          text: "Received By(签章)"
        }
      ]
    }
  ],
  table
};
const xsht = {
  page: pageA5,
  name: "销售合同",
  row: [
    {
      fontSize: 24,
      textAlign: "center",
      fontWeight: "bold",
      marginBottom: 20,
      content: [
        {
          field: "SubCompanyName"
        }
      ]
    },
    {
      type: "image",
      isFloat: true,
      width: "180",
      top: 0,
      height: 34,
      src: "https://static.bom.ai/files/owner/logo.png"
    },
    {
      width: "800",
      content: [
        {
          text: "供方",
          field: "SubCompanyName"
        },
        {
          text: "合同编号",
          field: "BillNo"
        }
      ]
    },
    {
      content: [
        {
          text: "需方",
          field: "CustomerName"
        },
        {
          text: "签订日期",
          field: "ShipTimeStr"
        }
      ]
    },
    {
      content: [
        {
          text:
            "经供需双方友好协商，依平等互利、诚信的原则，特订立本购销合同，由供需双方共同遵照执行："
        }
      ]
    },
    {
      content: [
        { text: "一、名称、规格、材质、产地、数量、单价、金额、等。" },
        {
          text: "币种",
          field: "CurrencyCode"
        },
        {
          text: "税率",
          field: "TaxRate"
        }
      ]
    },
    {
      type: "table",
      countField: "Amount",
      colConfig: [
        {
          headerName: "产品名称",
          field: "SalesModel"
        },
        {
          headerName: "生产厂家",
          field: "Brand"
        },
        {
          headerName: "货期"
        },
        {
          headerName: "批号",
          field: "MakeYear"
        },
        {
          headerName: "数量",
          field: "Qty"
        },
        {
          headerName: "单位",
          field: "Unit"
        },
        {
          headerName: "单价",
          field: "Amount"
        },
        {
          headerName: "备注",
          field: "Remark"
        }
      ],
      footer: [
        {
          text: "合计人民币(大写)",
          type: "capital",
          colspan: 3
        },
        {
          text: "小写",
          type: "count",
          colspan: 6
        }
      ]
    },
    {
      content: [
        {
          text: "二、付款方式",
          field: "ReceiptType"
        }
      ]
    },
    { type: "textarea", field: "SellContractClause_CN" },
    {
      content: [
        {
          text: "供方（盖章）"
        },
        {
          text: "需方（盖章）"
        }
      ]
    },
    {
      content: [
        {
          text: "代表人",
          field: "Contact"
        },
        {
          text: "代表人",
          field: "Contact"
        }
      ]
    },
    {
      content: [
        {
          text: "地址",
          field: "Address1"
        },
        {
          text: "地址",
          field: "Address"
        }
      ]
    },
    {
      content: [
        {
          text: "电话",
          field: "Telephone"
        },
        {
          text: "电话",
          field: "Telephone"
        }
      ]
    },
    {
      content: [
        {
          text: "传真",
          field: "Fax"
        },
        {
          text: "传真",
          field: "Fax"
        }
      ]
    }
  ],
  table
};
const xcght = {
  page: pageA5,
  name: "采购合同",
  row: [
    {
      fontSize: 24,
      textAlign: "center",
      marginTop: 10,
      fontWeight: "bold",
      marginBottom: 20,
      content: [
        {
          field: "SubCompanyName"
        }
      ]
    },
    {
      type: "image",
      isFloat: true,
      width: "180",
      height: "34",
      top: 0,
      src: "https://static.bom.ai/files/owner/logo.png"
    },
    {
      textAlign: "center",
      fontSize: 20,
      marginTop: "10",
      content: [
        {
          text: "采购合同"
        }
      ]
    },
    {
      content: [
        {
          text: "供方:",
          field: "SupplierName"
        },
        {
          text: "合同编号:",
          field: "BillNo"
        }
      ]
    },
    {
      content: [
        {
          text: "需方:",
          field: "SubCompanyName"
        },
        {
          text: "签订日期:",
          field: "PODate"
        }
      ]
    },
    {
      content: [
        {
          text:
            "经供需双方友好协商，依平等互利、诚信的原则，特订立本购销合同，由供需双方共同遵照执行:"
        }
      ]
    },
    {
      content: [
        {
          text: "一、采购内容:"
        },
        {
          text: "币种:",
          field: "CurrencyCode"
        },
        {
          text: "税率:",
          field: "TaxRate"
        }
      ]
    },
    {
      type: "table",
      countField: "Amount",
      colConfig: [
        {
          headerName: "料号",
          field: "CustomerPartNo"
        },
        {
          headerName: "产品名称",
          field: "ProductCategory"
        },
        {
          headerName: "规格型号",
          field: "Model"
        },
        {
          headerName: "单位",
          field: "Unit"
        },
        { headerName: "数量", field: "Qty" },
        {
          headerName: "单价",
          field: "Price"
        },
        {
          headerName: "金额",
          field: "Amount"
        },
        {
          headerName: "备注",
          field: "Remark"
        }
      ],
      footer: [
        {
          text: "合计人民币(大写)",
          type: "capital",
          colspan: 3
        },
        {
          text: "小写",
          type: "count",
          colspan: 6
        }
      ]
    },
    {
      content: [
        {
          text: "二、交货日期:",
          field: "NeedDeliveryDate"
        }
      ]
    },
    {
      content: [{ text: "三、验收方法：按生产工厂技术标准验收" }]
    },
    {
      content: [{ text: "四、付款方式：" }, { field: "PaymentType" }]
    },
    {
      content: [
        { text: "四、价格说明：" },
        { field: "TaxRate" },
        { text: "个点增值税专用发票" }
      ]
    },
    {
      content: [
        {
          whiteSpace: "normal",
          text:
            "六、质量保证：供方须确保产品为全新原装合格产品，如发现产品为残次品或翻新品等不合格产品，供方须无条件进行退换货，并承担相应责任。"
        }
      ]
    },
    {
      content: [
        {
          text:
            "七、违约责任：《采购合同》一旦签订，供需双方按照约定执行，如有违约，按相关规定处理。"
        }
      ]
    },
    {
      content: [
        {
          text:
            "八、文本效应：本《合同》传真件等同原件，本《合同》有签字即生效。"
        }
      ]
    },
    {
      content: [
        {
          text:
            "九、其他说明：本合同中未能约束的情况，双方协商解决或根据《合同法》有关规定。"
        }
      ]
    },
    {
      marginBottom: 10,
      content: [
        {
          text: "供方（盖章）"
        },
        {
          text: "需方（盖章）"
        }
      ]
    },
    {
      content: [
        {
          text: "代表人"
        },
        {
          text: "代表人"
        }
      ]
    },
    {
      content: [
        {
          text: "地址"
        },
        {
          text: "地址"
        }
      ]
    },
    {
      content: [
        {
          text: "电话"
        },
        {
          text: "电话"
        }
      ]
    },
    {
      content: [
        {
          text: "传真"
        },
        {
          text: "传真"
        }
      ]
    }
  ],
  table
};
export default { xsshd, bjd, cgdzd, cght, dzd, sksj5, sksj6, shd, xsht, xcght };
