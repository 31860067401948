import 'signalr'
import QrCode from 'qrcode'

import {
  getSessionId
} from '~/lib/auth'
import {
  ERPCOOKIENAME
} from '@scripts/config'
import {
  getCookie
} from '~assets/scripts/utils/dom'

const CONFIG = {
  WeChatHost: 'https://wx.bom.ai/',
  QrLoginUrl: 'oauth/authorize',
  SignalMessageUrl: "messageConnection",
  AutoRegUrl: "api/v1/user/client/autoreg",
  RedirectUrl: 'https://www.bom.ai/wxbind'
}

export default {
  name: 'signalrMixins',
  mixins: [],
  data() {
    return {
      clientType: 0,
      timecode: 0,
      connection: {},
      isConnection: true,
      webbrowserType: 0
    }
  },
  methods: {
    async createImage() {
      // $("#bomID_ERPcodeimg").attr('src', 'https://static.bom.ai/files/owner/logo.png')
      var connection = $.connection(CONFIG.WeChatHost + CONFIG.SignalMessageUrl)
      var connected = false
      connection.logging = true
      let _this = this

      // 开启连接
      // $("#bomID_ERPcodeimg").text("加载中...");
      console.log('加载中...')
      connection.start({
          jsonp: true
        })
        .done(function () {
          connected = true;
          _this.timecode = 0
        })
        .fail(function () {
          // $("#bomID_ERPcodeimg").text("二维码已失效");
          console.log('二维码已失效')
          if (_this.timecode != 1) {
            setTimeout(CreateImage, 500)
            _this.timecode++
          }
        })

      // 接收服务器推送
      connection.received(function (message) {
        if (message.indexOf("connectionid") >= 0) {
          _this.createQRCode(message)
        } else if (message.indexOf("openid") >= 0) {
          var openid = message.replace("openid=", "")
          console.log('start AutoRegUserInfo')
          _this.AutoRegUserInfo(openid)
        }
      })
    },
    //请求图片
    async createQRCode(message) {
      var token = message.replace("connectionid=", "")
      this.CreateQrImage(token)
    },
    //创建二维码图片
    async CreateQrImage(Token) {
      var $img = $(document.getElementById("bomID_ERPcodeimg"))
      var urlstr = CONFIG.WeChatHost + CONFIG.QrLoginUrl + "?Token=" + Token + "&RedirectUrl=" + CONFIG.RedirectUrl
      let imgsrc = await QrCode.toDataURL(urlstr)
      $img.attr('src', imgsrc)
    },

    //自动注册（客户端）
    async AutoRegUserInfo(openid) {
      let _this = this
      const sessionId = await getSessionId()
      let data = await this.$post("Security/RegBomAiUserInfo", {
        OpenId: openid,
        ErpSessionID: sessionId
      });
      if (this.hasValue(data) && this.hasValue(data.BomAiToken)) {
        // 扫码注册成功后,用erp的tokenID登陆bomai
        let erpToken = getCookie(ERPCOOKIENAME, this.$store)
        await this.LogBomAi(erpToken)

        _this.$message({
          message: '您的微信已成功绑定当前ERP账号',
          type: 'success'
        })
        _this.scanSuccess()
      } else {
        _this.$message({
          message: '绑定失败，请尝试关闭对话框，然后重试',
          type: 'error'
        })
      }
    },
  }
}
