<template>
  <div>
    <znl-dialog
      :title="title"
      :visible="editvisible"
      subhead="(橙色底为必填项)"
      height="640px"
      width="1000px"
      class="znl-editInvoices"
      :close-on-click-modal="false"
      :is-footer-show="false"
      v-loading="dialogLoading  || clientSelectLoading"
      element-loading-text="数据加载中,请稍后..."
      @close="val=>{$emit('close', val), onResetStkout()}"
    >
      <znl-layout-topbottom
        top-height="60%"
        slot="znl-dialog"
        v-show="!dialogLoading && !clientSelectLoading"
      >
        <div slot="znlTop" class="znlTop">
          <div slot="from-module">
            <el-row class="formContainer" type="flex" justify="space-around">
              <el-col>
                <el-row>
                  <el-col :span="6">
                    <znl-input
                      ref="ShipTime"
                      title-width="60px"
                      layout="left"
                      form-type="input"
                      :readonly="false"
                      :disabled="false"
                      :clearable="true"
                      v-model="StkOut.ShipTime"
                      width="100%"
                      title="料号："
                    ></znl-input>
                  </el-col>
                  <el-col :span="6">xx</el-col>
                  <el-col :span="6">xx</el-col>
                  <el-col :span="6">
                    <div>
                      <img
                        src="http://192.168.1.10:8008/images/%E4%BA%A7%E5%93%81%E4%BF%A1%E6%81%AF/u12636.png"
                      >
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <znl-input
                      ref="ShipTime"
                      title-width="60px"
                      layout="left"
                      form-type="input"
                      :readonly="false"
                      :disabled="false"
                      :clearable="true"
                      v-model="StkOut.ShipTime"
                      width="100%"
                      title="料号："
                    ></znl-input>
                  </el-col>
                  <el-col :span="8">
                    <znl-input
                      ref="ShipTime"
                      title-width="60px"
                      layout="left"
                      form-type="input"
                      :readonly="false"
                      :disabled="false"
                      :clearable="true"
                      v-model="StkOut.ShipTime"
                      width="100%"
                      title="料号："
                    ></znl-input>
                  </el-col>
                  <el-col :span="8">
                    <znl-input
                      ref="ShipTime"
                      title-width="60px"
                      layout="left"
                      form-type="input"
                      :readonly="false"
                      :disabled="false"
                      :clearable="true"
                      v-model="StkOut.ShipTime"
                      width="100%"
                      title="料号："
                    ></znl-input>
                  </el-col>

                  <el-col :span="8"></el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </znl-layout-topbottom>
    </znl-dialog>
    <znl-dialog
      title="从Excel粘贴"
      :visible="isBulkAddShow"
      height="325px"
      width="900px"
      class="pasteDataAcquisitionLog"
      :close-on-click-modal="false"
      :is-footer-show="false"
      @close="isBulkAddShow=false"
    >
      <pasteDataAcquisition slot="znl-dialog" :columns="columns" @add-excel-data="addExcelData"></pasteDataAcquisition>
    </znl-dialog>
    <d-company-contact-select
      :visible="dialogCompanySelectVisible"
      companyType="1,3"
      title="选择客户"
      @close="()=>{dialogCompanySelectVisible=false}"
      @update:visible="val=>{dialogCompanySelectVisible=val}"
      @confirm="companyChecked"
    ></d-company-contact-select>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { mixin as moveToMixin } from '~assets/scripts/directives/moveTo'
import dCompanyContactSelect from '@c_modules/Crm/DCompanyContactSelect'
import autocomplete from '@c_common/znlGrid/autocomplete'
import pasteDataAcquisition from '@c_common/znlGrid/commonComponents/pasteDataAcquisition'
import ClientSelect from '@c_modules/Stk/ClientSelect'

const CONFIG = {
  PK: 'StkOutLineID',
  configURL: 'StkOutLine/GetConfig',
  configJsonURL: 'StkOut/GetConfig',
  StkOutSaveURL: 'StkOutAdd/Add',
  StkOutSearchById: 'StkOutAdd/Search',
  StkOutSearchByLineguid: 'StkOutAdd/GetInlineByStkOutGUIDS',
  SearchByBOMGUIDS: 'SalesOrderAdd/SearchByBOMGUIDS',
  SearchByReserveGUIDSPL: 'StkOutAdd/SearchByReserveGUIDSPL', // 通过备货GUID批量查询库存
  GetStkVMIByGUIDS: 'StockVMI/GetStkVMIByGUIDS', // 供应商库存供应商库存
  getPrintTemplatesURL: 'PrintTemplate/Search',
  GetPrice: 'StkOutAdd/GetPrice' // 获取上次的价格
}
const defaultStkOut = {
  BillNo: '', // 销售单号
  ShipTime: new Date().toLocaleDateString(), // 出货日期
  Shipper: '', // 销售员
  ShipUserID: '',
  Amount: null, // 应收金额
  CustomerGUID: '', // 客户guid
  CustomerName: '', // 客户名称
  ReceiptType: '', // 收款方式
  ReceivedAmount: null, // 实收金额
  CustomerBankNo: '', // 收款帐户
  Address: '', // 收货地址
  ExpectReceiptDate: null, // 预计收款日期
  GrossProfit: null, // 利润总额
  DiscountAmount: null, // 折扣金额
  Contact: '', // 联系人
  Telephone: '', // 联系电话
  CustomerOrderNo: '', // 客户订单号
  TaxRate: null, // 税点
  OtherFeeAmount: null, // 其它费用
  TransportCompany: '', // 快递公司
  ShippingNo: '', // 快递单号
  CurrencyCode: '', // 币种
  ExchangeRate: '1', // 汇率
  Remark: '', // 销售备注
  IsAllowOut: true,
  LineList: [], // 出库单详情
  specialResource: [],
  IsAdd: true, // 是否新增
  PDID: '',
  CBGUID: ''// 收款账号,

}
export default {
  name: 'StkOutEdit',
  config: CONFIG,
  mixins: [znlMethodsMixin, moveToMixin, getCommonDataMixin],
  components: {
    // VCompanySelect,
    dCompanyContactSelect,
    autocomplete,
    pasteDataAcquisition,
    ClientSelect
    // znlDivThree
  },
  data() {
    return {
      // customizeColumns: [],
      hasOperationColumn: {
        StockQuery: false,
        StockVMIMini: false,
        PastInquire: false
      },
      clientSelectLoading: false,
      dialogLoading: true,
      IsContinuousRecord: false,
      isBulkAddShow: false,
      autocompleteShow: false,
      currentUserID: this.$store.state.accountInfo.UserID, // 获取当前登录人名称
      dialogCompanySelectVisible: false,
      InTypes: '', // 1:客户，2：供应商，3:两者都是的公司
      StkOut: {
        BillNo: '', // 销售单号
        ShipTime: new Date().toLocaleDateString(), // 出货日期
        ShipUserID: this.$store.state.accountInfo.UserID, // 销售员ID
        Shipper: this.$store.state.accountInfo.UserName, // 销售员
        Amount: null, // 应收金额
        CustomerGUID: '', // 客户guid
        CustomerName: '', // 客户名称
        ReceiptType: '', // 收款方式
        ReceivedAmount: null, // 实收金额
        CustomerBankNo: '', // 收款帐户
        Address: '', // 收货地址
        ExpectReceiptDate: null, // 预计收款日期
        GrossProfit: null, // 利润总额
        DiscountAmount: null, // 折扣金额
        Contact: '', // 联系人
        Telephone: '', // 联系电话
        Mobile: '', // 手机号码
        CustomerOrderNo: '', // 客户订单号
        TaxRate: null, // 税点
        OtherFeeAmount: null, // 其它费用
        TransportCompany: '', // 快递公司
        ShippingNo: '', // 快递单号
        CurrencyCode: '', // 币种
        ExchangeRate: '1', // 汇率
        Remark: '', // 销售备注
        LineList: [], // 出库单详情
        IsAdd: true, // 是否新增
        IsAllowOut: true, // 是否允许出库
        PDID: '',

        CBGUID: '',    // 收款账号,
        stkOutGUID: '', // 发货单ID

        IsTakeOutPrint: false, // 是否带出上次价格
        ExistSalesOrder: false // 是否先做销售单
      },
      IsStkOut: false, // 是否允许负库存出库
      IsCustomerName: false, // 公司名称是否必填
      TransportCompanyOptions: [], // 快递公司
      resourceList: [],
      paramTax: '',
      paramQtyType: '',
      paramQtyUnit: '',
      ParamJosn: [],
      ParamJosnLine: [],
      disabledInput: true, // 禁用采购单
      IsManual: false, // 手动
      isAllow: true, // 是否允许选择手动框
      isPopup: false,
      columns: [],
      ondblclic: [],
      itemSource: [],
      itemQty: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      users: [],
      usableUser: [],
      role: '1',
      showAddRowButton: true,
      showAddRowRightMenu: true,
      showDeleteRowButton: true,
      isVisibled: false,
      fromSalesOrderOpen: false,
      showClickBtn: true,
      salesOrderLineListOrigin: [],
      loading: false,
      refreshChecklist: false, // 是否刷新库存选择页面
      checkQty: false,
      isReadonly: false,   // 控制输入框是否只读
      fromBtns: [
      ],
      IsOutQty: true,
      PrintTemplate: '', // 打印模板
      PrintTemplates: [],
      IsExchangeRateRMB: true,
      fromSaveBtns: [
        {
          name: '保存并出库',
          click: () => {
            this.stkoutSaveAndOut()
          },
          class: 'znl-btn znl-btn-minor'
        },
        {
          name: '保存',
          class: 'znl-btn znl-btn-main',
          click: () => {
            this.stkoutSave()
          }
        }
      ],
      startTime: 0,
      endTime: 0,
      restaurants: [],
      autocompleteSearchData: '',
      IsPrint: true, // 保存后继续打印
      // visibled: this.$store.state.visibled,
      IsstkOutGUIDLine: false // 是否是销售退货
    }
  },
  props: {
    editType: String,
    title: String,
    editvisible: {
      type: Boolean,
      defaule: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: {
      type: String,
      default: () => {
        return '99%'
      }
    },
    stockLine: Array, // 库存对象
    stkOutGUIDLine: Array, // 出库明细
    stkOutGUID: String,
    showOutAddBystock: Boolean, // 从库存中传入值判断是否刷新页面
    showOutAddBystkout: Boolean, // 从出库中出入值判断是否刷新页面
    showOutAddBystkoutLine: Boolean, // 从出库明细中出入值判断是否刷新页面
    // SOModel: Object,
    refershReserve: Boolean, // 从库存备货页面进行出库
    ReserveGuid: String, // 我的备货进行出库

    isRefreshVMI: Boolean, // 供应商库存
    VMGUIDS: Array, // 供应商库存
    BDLineGuids: Array, // 库存对象GUIDS
    autoAddNewRow: { // 是否在明细自动增加一行
      type: Boolean,
      default: () => {
        return true
      }
    }

  },
  watch: {
    IsManual(value) {
    },
    showOutAddBystock: {
      handler(value) {
        if (!_.isEmpty(this.stockLine)) {
          this.isPopup = true
          this.LoadListByStock(this.stockLine, false)
        }
      },
      immediate: true
    },
    showOutAddBystkout: {
      handler(value) {
        if (!_.isEmpty(this.stkOutGUID)) {
          this.isPopup = true
          this.LoadEditData(this.stkOutGUID)
        }
      },
      immediate: true
    },
    showOutAddBystkoutLine: {
      handler(value) {
        if (!_.isEmpty(this.stkOutGUIDLine)) {
          this.isPopup = true
          this.LoadLineAddData(this.stkOutGUIDLine, false)
        }
      },
      immediate: true
    },
    refershReserve: {
      handler(value) {
        if (!_.isEmpty(this.ReserveGuid)) {
          this.isPopup = true
          this.LoadReserveLine(this.ReserveGuid)
        }
      },
      immediate: true
    },
    isRefreshVMI: {
      handler(value) {
        if (!_.isEmpty(this.VMGUIDS)) {
          this.LoadStkVMIGUID(this.VMGUIDS) // 加载供应商库存
        }
      },
      immediate: true
    },
    itemSource() {
      this.$nextTick(() => {
        this.newRowsStyle()
      })
    }
  },
  methods: {
    // 初始化
    onInit: async function () {
      let config = await this.isSaveStorage('stkOutEdit', CONFIG.configURL)
      // let config = await this.$post(CONFIG.configURL)
      let configs = await this.$post(CONFIG.configJsonURL)
      this.resourceList = config.ResourceList.map(item => item.Code)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      // 获取参数配置
      this.ParamJosn = configs.ParamJosn
      // 获取订单详情参数配置
      this.ParamJosnLine = config.ParamJosn
      this.paramConfiguration()
      let isvisibled = this.$store.state.visibled
      if (isvisibled) {
        this.isVisibled = isvisibled
      }
      // 下拉列表
      // 采购员
      let UserAllowUsers = this.selectOptionsAllUserURL
      // console.log('采购员', UserAllowUsers)
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'BuyerName'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )

      // 单位
      let QtyUnitDropDown = this.selectOptionsQtyUnit
      // console.log('单位', QtyUnitDropDown)
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Unit'
        }),
        { dataMap: QtyUnitDropDown, showDropDown: true }
      )
      if (this.hasSpecialRes('NoSeeProfit') === true) {
        let GrossProfitColumn = _.find(columns, column => column.name === 'GrossProfit') // 利润
        let ProfitMarginsColumn = _.find(columns, column => column.name === 'ProfitMargins') // 利润率
        GrossProfitColumn && _.extend(GrossProfitColumn, { isReadOnly: true, visible: false })
        ProfitMarginsColumn && _.extend(ProfitMarginsColumn, { isReadOnly: true, visible: false })
      }

      if (this.$store.state.paramOrder && this.$store.state.paramOrder.BDLineGuids) {
        // let info = this.$store.state.paramOrder.BDLineGuids
        // // this.LoadListByMtlGuids(info, false)
        this.$store.commit('setParamOrder', {})
      }
      // 按整张需求生成发货单
      if (this.$store.state.paramOrder && this.$store.state.paramOrder.BOMGUIDS) {
        let info = this.$store.state.paramOrder.BOMGUIDS
        this.LoadListByBOMGUIDS(info, false)
        this.$store.commit('setParamOrder', {})
      }
      // 库存类型
      let stkTypeColumn = _.find(columns, column => column.name === 'InvType')
      stkTypeColumn &&
        _.extend(stkTypeColumn, {
          dataMap: this.selectStorageType,
          showDropDown: true
        })

      // 品质
      let QualityDropDown = await this.selectOptionsQuality
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Quality'
        }),
        { dataMap: QualityDropDown, showDropDown: true }
      )

      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.fromSalesOrderOpen = false
      if (this.$store.state.paramOrder) {
        if (this.$store.state.paramOrder.stkOutGUID) {
          let stkoutguid = this.$store.state.paramOrder.stkOutGUID
          this.$store.commit('setParamOrder', {})
          this.LoadEditData(stkoutguid)
        }
        if (this.$store.state.paramOrder.stkOutGUIDLine) {
          this.IsstkOutGUIDLine = true
          let info = this.$store.state.paramOrder.stkOutGUIDLine
          let isreturn = false
          if (this.$store.state.paramOrder.IsReturn) {
            isreturn = this.$store.state.paramOrder.IsReturn
          }
          this.$store.commit('setParamOrder', {})
          this.LoadLineAddData(info, isreturn)
        }
        if (this.$store.state.paramOrder.stockLine) {
          let info = this.$store.state.paramOrder.stockLine
          let IsOutQty = this.$store.state.paramOrder.IsOutQty
          this.IsOutQty = IsOutQty
          this.$store.commit('setParamOrder', {})
          this.LoadListByStock(info, false)
        }
        if (this.$store.state.paramOrder.VMGUIDS) {
          let info = this.$store.state.paramOrder.VMGUIDS
          this.$store.commit('setParamOrder', {})
          this.LoadStkVMIGUID(info, false)
        }
        if (this.$store.state.paramOrder.stkSalesRecord) {
          let info = this.$store.state.paramOrder.stkSalesRecord
          this.$store.commit('setParamOrder', {})
          // console.log(info)
          this.LoadReserveLinePL(info)
        }
        // 销售备货批量出库
        if (this.$store.state.paramOrder.stockLinePL) {
          let info = this.$store.state.paramOrder.stockLinePL
          this.$store.commit('setParamOrder', {})
          this.LoadReserveLinePL(info)
        }
        if (this.$store.state.paramOrder.RFQ_InquireModel) {
          let info = this.$store.state.paramOrder.RFQ_InquireModel
          this.$store.commit('setParamOrder', {})
          this.InquireModel(info)
        }

        if (this.$store.state.paramOrder.InquireModel) {
          let info = this.$store.state.paramOrder.InquireModel
          this.$store.commit('setParamOrder', {})
          this.InquireModel(info)
        }

        if (this.$store.state.paramOrder.setParamShared) {
          let info = this.$store.state.paramOrder.setParamShared
          this.$store.commit('setParamOrder', {})
          this.InquireModel(info)
        }

        if (this.$store.state.paramOrder.SOModels) {
          let info = this.$store.state.paramOrder.SOModels
          this.$store.commit('setParamOrder', {})
          this.isPopup = false
          this.LoadSaleData(info)
          this.showAddRowButton = false
          this.showAddRowRightMenu = false
          this.showDeleteRowButton = false
          this.fromSalesOrderOpen = true
          this.isAllow = false // 编辑禁用选择
        }
        if (this.$store.state.paramOrder.stockReserveLine) {
          let info = this.$store.state.paramOrder.stockReserveLine
          this.$store.commit('setParamOrder', {})
          this.LoadReserveLinePL(info)
        }

        if (this.$store.state.paramOrder.stkInLines) {
          let stkInLines = this.$store.state.paramOrder.stkInLines
          this.$store.commit('setParamOrder', {})
          this.$nextTick(function () {
            _.each(stkInLines, items => {
              items.BuyPrice = items.Price
              items.Price = items.SalesPrice
              items.PriceInTax = items.SalesPrice
              let amount = this.toFloat(this.ConvertNumber(items.PriceInTax) * this.ConvertNumber(items.Qty), 2)
              items.Amount = amount
              items.Status = 1
            })
            this.StkOut.CustomerName = stkInLines[0].SupplierName
            this.itemSource = stkInLines
            this.$nextTick(function () {
              this.onBindData()
              this.CalcPay(true)
              this.CalcStkOutAmoutAndGrossProfit(true)
            })
          })
        }
      }

      this.fromBtns = [
        {
          name: '新增一行',
          iconName: 'icon-add_btn_ic',
          isShow: this.isAllow,
          click: () => {
            this.$refs.flexGrid.addRow(true)
          }
        },
        {
          name: '删除选择行',
          iconName: 'icon-delete_btn_ic',
          click: () => {
            this.$refs.flexGrid.deleteCheckedRows()
          }
        },
        {
          name: '导入数据',
          iconName: 'icon-add_btn_ic',
          isShow: true,
          click: () => {
            this.isBulkAddShow = !this.isBulkAddShow
          }
        }
      ]
      this.$nextTick(() => {
        this.dialogLoading = false
      })
    },
    // 多页签 双击事件回调
    async ondblclick(row, typeName) {
      // console.log(row, typeName, this.itemSource, this.columns)
      if (_.isUndefined(row)) {
        return
      }
      let rows = [row]

      if (typeName === 'StockQuery') {   // 我的库存
        this.LoadListByStock(rows)
      } else if (typeName === 'StockVMIMini') { // 供应商库存
        this.LoadStkVMI(rows)
      } else if (typeName === 'StkOutByModel') { // 发货记录
        this.loadStkOutLine(rows)
      } else if (typeName === 'HisOfferPriceByModel') { // 报价记录
        this.loadQuotedPriceLine(rows)
      }
    },

    onBeforeAddRow: async function () {
      let nullPK = {}
      nullPK[CONFIG.PK] = null
      if (!_.isUndefined(CONFIG.defaultValURL)) {
      }
      const detailModel = {
        TotalAmount: null,
        AmountNoTax: null,
        PrintAmount: null,
        StatusName: '',
        IsDelete: false,
        RowGuid: 0,
        StkOutLineID: 0,
        StkOutGUID: null,
        StkOutLineGUID: null,
        ProductCategory: '',
        Model: '',
        SpModel: '',
        Qty: null,
        Price: null,
        PriceInTax: null,
        PBillNo: '',
        BuyPrice: null,
        BuyerName: '',
        Amount: null,
        GrossProfit: null,
        StorageName: '',
        SalesModel: '',
        SalesQty: null,
        CustomerOrderNo: '',
        Unit: this.paramQtyUnit,
        InvType: this.paramQtyType
      }
      return _.extend({}, detailModel, nullPK)
    },
    setClientSelectLoading(val) {
      this.clientSelectLoading = val
    },
    // 打印模板
    onPrint() {
      var tableName = this.PrintTemplate// 模板ID
      var OutGuid = this.StkOut.stkOutGUID// 销售单ID
      if (tableName === '--请选择打印模板--' || tableName === '') {
        this.$message({ message: '请选择打印模板', type: 'error' })
        return false
      } else if (OutGuid === '' || OutGuid === null || OutGuid === undefined) {
        this.$message({
          message: '请先保存后才能打印',
          type: 'error'        })
        return false
      } else {
        this.printStkOut({ TableName: tableName, Guid: OutGuid }, function () {
        })
        return true
      }
    },
    addExcelData(data) {

    },
    getValue(item) {
      // 如果单位为空 则默认参数配置的默认单位
      if (!this.hasValue(item.Unit)) {
        item.Unit = this.paramQtyUnit
      }
      // 如果库存类型为空则默认参数配置的默认库存类型
      if (!this.hasValue(item.InvType)) {
        item.InvType = this.paramQtyType
      }
      // 计算未税价和含税价和金额
      if (this.hasValue(item.Price)) {
        // 计算含税
        let _price = this.CalcPriceInTax(item.Price)
        item.PriceInTax = _price === 0 ? null : _price
      } else if (!this.hasValue(item.Price) && this.hasValue(item.PriceInTax)) {
        // 含税算未税
        item.Price = this.CalcPrice(item.PriceInTax)
      }
      item.GrossProfit = this.CalcGrossProfit(
        item.Price,
        item.BuyPrice,
        item.Qty,
        item.ShareFee
      )
      // console.log(item.GrossProfit, ' item.GrossProfit')
      item.Amount = this.toFloat(this.CalcAmout(item.Qty, item.Price), 2)
      this.CalcPay(false)
      this.CalcStkOutAmoutAndGrossProfit(false)
    },
    // 获取参数配置
    paramConfiguration() {
      // 单据保存后立即打印 0是 1否
      let paramIsAutoPrint = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === 'IsAutoPrint') {
          return paramjosn.ParamValue
        }
      })
      if (paramIsAutoPrint.ParamValue === '0') { this.IsPrint = true } else { this.IsPrint = false }
      // 连续录单模式
      let paramIsContinuous = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === 'IsContinuousRecord') {
          return paramjosn.ParamValue
        }
      })
      if (paramIsContinuous.ParamValue === '1') { this.IsContinuousRecord = true } else { this.IsContinuousRecord = false }

      // 默认打印模板
      let paramPrintTemplate = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === 'PrintTemplate') {
          return paramjosn.ParamValue
        }
      })
      this.PrintTemplate = paramPrintTemplate.ParamValue
      // 默认单位
      let paramQtyUnit = _.find(this.ParamJosnLine, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === 'QtyUnit') {
          return paramjosn.ParamValue
        }
      })
      this.paramQtyUnit = paramQtyUnit.ParamValue
      // console.log(this.paramQtyUnit, '  this.paramQtyUnit')
      // 默认库存类型
      let paramQtyType = _.find(this.ParamJosnLine, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === 'QtyType') {
          return paramjosn.ParamValue
        }
      })
      this.paramQtyType = paramQtyType.ParamValue
      // 默认币种
      let paramCurrencyCode = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === 'CurrencyCode') {
          return paramjosn.ParamValue
        }
      })
      if (!this.hasValue(this.StkOut.CurrencyCode)) {
        this.StkOut.CurrencyCode = paramCurrencyCode.ParamValue
      }

      // 默认税率
      let paramTaxRate = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === 'TaxRate') {
          return paramjosn.ParamValue
        }
      })
      this.paramTax = paramTaxRate.ParamValue
      // defaultStkOut.TaxRate = paramTaxRate.ParamValue
      if (!this.hasValue(this.StkOut.TaxRate)) {
        this.StkOut.TaxRate = paramTaxRate.ParamValue
        // console.log(this.StkOut.TaxRate, ' this.StkOut.TaxRate')
      }

      let paramIsStkOut = _.find(this.ParamJosn, function (paramjosn) {
        // 是否允许负库存出库
        if (paramjosn && paramjosn.ParamCode === 'IsStkOut') {
          return paramjosn.ParamValue
        }
      })
      if (this.hasValue(paramIsStkOut) && paramIsStkOut.ParamValue === '0') { this.IsStkOut = false } else { this.IsStkOut = true }

      // 是否带出上次价格
      let paramIsTakeOutPrint = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === 'IsTakeOutPrint') {
          return paramjosn.ParamValue
        }
      })
      if (this.hasValue(paramIsTakeOutPrint) && paramIsTakeOutPrint.ParamValue === '0') { this.StkOut.IsTakeOutPrint = false } else { this.StkOut.IsTakeOutPrint = true }

      // 公司名称是否必填
      let paramIsCustomerName = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === 'IsCustomerName') {
          return paramjosn.ParamValue
        }
      })
      if (this.hasValue(paramIsCustomerName) && paramIsCustomerName.ParamValue === '0') { this.IsCustomerName = false } else { this.IsCustomerName = true }
      // 验证是否先做销售单
      let ExistSalesOrder = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === 'ExistSalesOrder') {
          return paramjosn.ParamValue
        }
      })
      if (this.hasValue(ExistSalesOrder) && ExistSalesOrder.ParamValue === '0') { this.StkOut.ExistSalesOrder = false } else { this.StkOut.ExistSalesOrder = true }

      // 如果设置默认值，加载时计算
      // this.CalcTaxRate(this.StkOut.TaxRate)
    },
    // 加载打印模板
    onInitPrintTemplate() {
      return this.$post(CONFIG.getPrintTemplatesURL, { Type: 'STK_Out' }, data => {
        let res = []
        _.each(data, item => {
          res.push({
            key: item.TableName,
            value: item.TName
          })
        })
        this.PrintTemplates = res
        // if (this.PrintTemplates.length > 0) {
        //   this.PrintTemplate = this.PrintTemplates[0].key
        // }
      })
    },

    // 绑定数据
    onBindData() {
      this.ParamJonsValue()
      this.StkOut.LineList = this.itemSource
      this.$emit('page-loading', false)
    },
    // 默认参数设置赋值
    ParamJonsValue() {
      _.each(this.itemSource, (item, i) => {
        if (this.hasValue(this.itemSource[i].StkOutLineGUID)) {
          !this.hasValue(this.itemSource[i].Unit) ? this.itemSource[i].Unit = this.paramQtyUnit : ''
          !this.hasValue(this.itemSource[i].InvType) ? this.itemSource[i].InvType = this.paramQtyType : ''
        }
      })
    },
    // 客户名称联想
    querySearch(queryString, cb) {
      if (new Date() - this.startTime > 1000) {
        this.$post('CompanyContacts/SearchCompanyContacts', {
          FieldWhereString: JSON.stringify({
            CompanyName: queryString
          }),
          PageIndex: 1,
          PageSize: 20,
          CompanyTypes: '1,3'
        }, (data) => {
          this.restaurants = data.ResultList
          let restaurants = this.restaurants
          let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
          // 调用 callback 返回建议列表的数据
          _.each(results, item => {
            item.value = item.CompanyName
          })
          cb(results)
          _.delay(() => {
            this.$refs.znlAutocomplete.$el.querySelector('.el-input__inner').focus()
          }, 100)
        })
      }
      this.startTime = new Date()
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.CompanyName.indexOf(queryString.toLowerCase()) === 0)
      }
    },
    suggestionsSelect(item) {
      // console.log(item)
      this.companyChecked([item])
    },
    newRowsStyle() {
      this.$nextTick(() => {
        _.delay(() => {
          let trs = this.$el ? this.$el.querySelectorAll('.znl-dialog .znlTop .el-table__body tr') : []
          _.each(trs, item => {
            item.classList.add('znl-addNewRow')
          })
          this.onBeforeAddRow()
        }, 1000)
      })
    },
    onIconClick() {
      this.dialogCompanySelectVisible = true
    },
    hasSpecialRes(code) {
      return _.some(this.specialResource, page => page.Code === code)
    },
    CurrencyCodeChange(val) {
      // 币种改变选项事件
      // let options = this.CurrencyCodeOptions
      if (!val) {
        return
      }
      let itemVal = val ? val.key : '' // 选中的值
      if (itemVal === 'RMB') {
        this.IsExchangeRateRMB = true
      } else {
        this.IsExchangeRateRMB = false
      }
      if (itemVal) {
        this.StkOut.ExchangeRate = val.ExchangeRate
      }
    },
    // 验证 GUID加载实体税点是否为空 如果为空则默认参数配置， 从新计算
    ResTaxRate() {
      if (!this.hasValue(this.StkOut.TaxRate)) {
        this.StkOut.TaxRate = this.paramTax
        this.CalcTaxRate()
      }
    },
    // 保存订单
    Save(isOut) {
      this.StkOut.IsAllowOut = isOut // 是否允许出库
      var vm = this
      vm.loading = true
      return this.$post(CONFIG.StkOutSaveURL, this.StkOut, (data, logic) => {
        this.loading = false
        if (logic.code !== 200) {
          this.$message({ message: logic.msg, type: 'error' })
        } else {
          this.$message({ message: '保存成功', type: 'success' })
          this.StkOut.stkOutGUID = data
          if (this.IsPrint) {
            this.onPrint()
          }
          if (!this.IsContinuousRecord) {
            this.$emit('close')
          } else {
            this.onResetStkout() // 重置
          }
        }
      }, () => {
        vm.loading = false
      }
      )
    },
    // 保存订单
    stkoutSave() {
      // 保存
      if (this.checkData()) {
        this.Save(false)
      }
    },
    // 保存并出库
    stkoutSaveAndOut() {
      if (this.checkData()) {
        this.Save(true)
      }
    },
    checkData() {
      // if (this.StkOut.ExistSalesOrder && this.StkOut.stkOutGUID === '') {
      //   return this.$message({ message: '请先做销售单', type: 'error' })
      // }
      let ret = true
      this.onSaleChange()
      if (this.$refs.flexGrid.hasErrForEdit > 0) {
        ret = false
        return this.$message({ message: '请修改填写错误的单元格内容后再保存', type: 'error' })
      } else if (this.StkOut.ShipTime === undefined || this.StkOut.ShipTime === '') {
        ret = false
        this.$refs.ShipTime.handleFocus()
        this.$message({ message: '请选择发货日期', type: 'warning' })
      } else if (this.StkOut.ShipUserID <= 0 || this.StkOut.ShipUserID === undefined ||
        this.StkOut.ShipUserID === '' || this.StkOut.ShipUserID === null
      ) {
        ret = false
        this.$refs.ShipUserID.handleFocus()
        this.$message({ message: '请选择发货员', type: 'warning' })
      } else if (this.IsCustomerName && this.StkOut.CustomerName === '') {
        ret = false
        this.$refs.znlAutocomplete.handleFocus()
        // console.log(this.$message({ message: '请填写客户名称', type: 'warning' }))
        this.$message({ message: '请填写客户名称', type: 'warning' })
      } else if (this.StkOut.CurrencyCode === undefined || this.StkOut.CurrencyCode === '' || this.StkOut.CurrencyCode === 0
      ) {
        ret = false
        this.$refs.CurrencyCode.handleFocus()
        this.$message({ message: '请选择币种', type: 'warning' })
      } else if (this.StkOut.ShippingNo !== '' && (this.StkOut.TransportCompany === undefined || this.StkOut.TransportCompany === '')
      ) {
        ret = false
        this.$refs.TransportCompany.handleFocus()
        this.$message({ message: '已填写快递单号,请选择快递公司', type: 'warning' })
      } else if (this.StkOut.TransportCompany !== '' && (this.StkOut.ShippingNo === undefined || this.StkOut.ShippingNo === '')
      ) {
        ret = false
        this.$refs.ShippingNo.handleFocus()
        this.$message({ message: '已选择快递公司,请填写快递单号', type: 'warning' })
      } else if (!isFinite(this.StkOut.DiscountAmount)) {
        ret = false
        this.$refs.DiscountAmount.handleFocus()
        this.$message({ message: '折扣金额必须为数字或者小数', type: 'warning' })
      } else if (!isFinite(this.StkOut.OtherFeeAmount)) {
        ret = false
        this.$refs.OtherFeeAmount.handleFocus()
        this.$message({ message: '其它费用必须为数字或者小数', type: 'warning' })
      } else if (!isFinite(this.StkOut.ExchangeRate)) {
        ret = false
        this.$refs.ExchangeRate.handleFocus()
        this.$message({ message: '汇率必须为数字或者小数', type: 'warning' })
      } else if (
        !this.isReadonly &&
        this.StkOut.CBGUID &&
        this.StkOut.CBGUID !== '' &&
        (this.StkOut.ReceivedAmount === '' || this.StkOut.ReceivedAmount === 0) && this.StkOut.ReceiptType !== '挂欠'
      ) {
        ret = false
        this.$refs.ReceivedAmount.handleFocus()
        this.$message({ message: '已选择收款账号,请填写实收额', type: 'warning' })
      } else if (!this.isReadonly &&
        this.StkOut.ReceivedAmount && this.StkOut.ReceivedAmount !== '' &&
        (this.StkOut.CBGUID === null || this.StkOut.CBGUID === '')
      ) {
        ret = false
        this.$refs.CBGUID.handleFocus()
        this.$message({ message: '已填写实收额,请选择收款账号', type: 'warning' })
      } else {
        let rows = this.itemSource
        if (rows.length > 0) {
          let rowId = 0
          let messageStr = ''
          _.find(rows, item => {
            rowId = rowId + 1

            if (!item['Model']) {
              messageStr = '单据详情第' + rowId + '行 请填写出货型号'
              ret = false
            } else if (!isFinite(item['PriceInTax']) || item['PriceInTax'] === '') {
              messageStr = '单据详情第' + rowId + '行 含税价必须为数值型且不能为空'
              ret = false
            } else if (!isFinite(item['Qty'])) {
              messageStr = '单据详情第' + rowId + '行 数量必须为数值型且不能为空'
              ret = false
              return
            } else if (!isFinite(item['Price']) || item['PriceInTax'] === '') {
              messageStr = '单据详情第' + rowId + '行 未税价必须为数值型且不能为空'
              ret = false
            } else if (!_.isUndefined(item['ShareFee']) && !isFinite(item['ShareFee'])
            ) {
              messageStr = '单据详情第' + rowId + '行 分摊费必须为数值型'
              ret = false
            } else if (!_.isUndefined(item['BuyPrice']) && !isFinite(item['BuyPrice'])
            ) {
              messageStr = '单据详情第' + rowId + '行 采购价必须为数值型'
              ret = false
            } else if (!this.IsstkOutGUIDLine) {
              // 如果参数配置 不允许负库存出库
              // if (!this.IsStkOut) {
              //   if (((!isFinite(item.Qty) || item.Qty === '') || item.Qty <= 0)) {
              //     messageStr = '单据详情第' + rowId + '行,不允许负库存出库'
              //     ret = false
              //     return
              //   } else if (this.itemQty[rowId] < 0 || (this.itemQty[rowId] > 0 && this.itemQty[rowId] - item.Qty < 0)) { // 如果是选择库存 已有库存量-要出库的库存
              //     messageStr = '单据详情第' + rowId + '行,不允许负库存出库'
              //     ret = false
              //     return
              //   }
              // }
            }
            // 检查出库时，出库数量不能大于可用的未出库数量
            // if (this.fromSalesOrderOpen) {
            //   let qtyInput = parseFloat(item['Qty'])
            //   let result = _.some(this.salesOrderLineListOrigin, itemOri => {
            //     if (
            //       itemOri.RowGuid === item['RowGuid'] &&
            //       itemOri.Qty < qtyInput
            //     ) {
            //       return true
            //     }
            //   })
            //   if (result) {
            //     messageStr = '单据详情第' + rowId + '行 发货数量大于可出库的数量'
            //     return true
            //   }
            // }
          })
          this.StkOut.LineList = rows
          if (messageStr.length > 0) {
            ret = false
            this.$message({ message: messageStr, type: 'warning' })
          }
        } else {
          ret = false
          this.$message({ message: '请添加出库单详情', type: 'warning' })
        }
      }
      return ret
    },

    // ***** 导入/加载数据******
    // 根据传入的stock对象加载明细列表
    // LoadListByMtlGuids: async function (mltGuids) {
    //   let orders = await this.$post(CONFIG.SearchByMtlGUIDS, {
    //     BDLineGuids: mltGuids
    //   })
    //   this.BindDataToLineList(orders)
    // },
    // 根据传入BOMGUIDS那整张需求单生成销售单
    LoadListByBOMGUIDS: async function (mltGuids) {
      let orders = await this.$post(CONFIG.SearchByBOMGUIDS, { BOMGUID: mltGuids })
      this.SalesOrderAdd = _.extend({}, this.SalesOrderAdd, orders)
      this.BindDataToLineList(orders)
    },
    BindDataToLineList(orders) {
      defaultStkOut.ShipUserID = this.currentUserID
      this.StkOut = _.extend({}, defaultStkOut, orders)
      this.isPopup = true

      this.$nextTick(function () {
        // this.onBindData()
        // 利润率=(未税单价*汇率-采购价)/采购价 * 100. 显示加上% ， 保留两位小数
        let rate = 1
        if (this.StkOut && this.StkOut.ExchangeRate) {
          rate = this.toFloat(this.StkOut.ExchangeRate)
        }
        this.itemSource = this.StkOut.LineList
        _.each(this.itemSource, item => {
          item.SalesModel = item.Model
          let price = this.toFloat(item.Price, 6)
          let buyPrice = this.toFloat(item.BuyPrice, 6)
          item.ProfitMargins = this.GetProfitRate(price, buyPrice, rate) // this.toFixed(price, 2) + '%'
          item.PurchaseAmount = this.CalcAmout(item.Qty, buyPrice)
          item.GrossProfit = this.CalcGrossProfit(this.ConvertNumber(item.Price), this.ConvertNumber(buyPrice), this.ConvertNumber(item.Qty))
        })
        // 计算利润
        this.CalcStkOutAmoutAndGrossProfit(false)
        this.StkOut.IsAdd = true
        this.ResTaxRate()
      })
      // this.isAllow = false // 编辑禁用选择
    },

    // 从销售单点击发货
    LoadSaleData(item) {
      let newlist = []
      _.each(item.LineList, data => {
        let newItem = data
        newItem.BeforeFirstQtyValue = data.Qty
        newlist.push(newItem)
      })
      item.LineList = newlist
      this.StkOut = _.extend({}, defaultStkOut, item)

      if (this.StkOut.ShipTime === null || this.StkOut.ShipTime === undefined) {
        this.StkOut.ShipTime = new Date().toLocaleDateString()
      }
      for (var i = 0; i < this.columns.length; i++) {
        if (
          this.columns[i].binding === 'Qty' ||
          this.columns[i].binding === 'Model' ||
          this.columns[i].binding === 'SupplierInfo' ||
          this.columns[i].binding === 'ShareFee' ||
          this.columns[i].binding === 'Remark' ||
          this.columns[i].binding === 'Remark2' ||
          this.columns[i].binding === 'Remark3'
        ) {
          this.columns[i].isReadOnly = false
        } else {
          this.columns[i].isReadOnly = true
        }
      }
      this.salesOrderLineListOrigin = _.map(this.itemSource, items => {
        return { Qty: items.Qty, RowGuid: items.RowGuid, BeforeFirstValue: items.Qty }
      })
      this.$nextTick(function () {
        this.itemSource = this.StkOut.LineList
        this.onBindData()
        this.CalcStkOutAmoutAndGrossProfit(true)
        this.StkOut.IsAdd = true
        this.StkOut.IsAllowOut = this.StkOut.Status !== 3 // 已出库的禁用保存并出库按钮
        this.ResTaxRate()
      })
    },

    // 编辑订单
    LoadEditData: async function (guid) {
      let stkOuts = await this.$post(CONFIG.StkOutSearchById, { StkOutGUID: guid })
      this.StkOut.IsAdd = false
      this.StkOut = stkOuts
      this.itemSource = stkOuts.LineList
      this.isReadonly = true
      this.StkOut.stkOutGUID = guid
      this.$nextTick(function () {
        this.onBindData()
        // this.isAllow = false // 编辑禁用选择
        this.StkOut.IsAllowOut = this.StkOut.Status !== 3 // 已出库的禁用保存并出库按钮
      })
    },

    // 根据出库单明细GUID加载实体
    LoadLineAddData: async function (guid, isreturn) {
      let stkOuts = await this.$post(CONFIG.StkOutSearchByLineguid, {
        OutLineGuids: guid,
        IsReturn: isreturn
      })
      this.StkOut = _.extend({}, defaultStkOut, stkOuts)
      this.$nextTick(function () {
        this.itemSource = stkOuts.LineList
        this.onBindData()
        this.CalcStkOutAmoutAndGrossProfit(true)
        this.StkOut.IsAdd = false
        // this.isAllow = false // 编辑禁用选择
        this.StkOut.IsAllowOut = this.StkOut.Status !== 3 // 已出库的禁用保存并出库按钮
      })
    },

    // 根据我的备货GUID加载实体 后续优化 合并
    LoadReserveLinePL: async function (guid) {
      let stkOuts = await this.$post(CONFIG.SearchByReserveGUIDSPL, {
        ReverseGuidPL: guid
      })
      this.StkOut = _.extend({}, defaultStkOut, stkOuts)
      this.itemSource = stkOuts.LineList
      this.$nextTick(function () {
        this.onBindData()
        this.CalcStkOutAmoutAndGrossProfit(true)
        this.StkOut.IsAdd = true
        this.isAllow = true // 新增
        if (!this.hasValue(this.StkOut.TaxRate) || this.StkOut.TaxRate === 0) {
          this.StkOut.TaxRate = this.paramTax
          this.ResTaxRate()
        }
      })
    },

    // 加载询价记录
    InquireModel: async function (item) {
      if (item.length > 0) {
        _.each(item, (stocks, i) => {
          let invQty = stocks.InvQty
          if (stocks.InvQty < 0) {
            invQty = -stocks.InvQty
          }
          // 判断是否是共享库存ID
          stocks.SalesPrice = this.ConvertNumber(stocks.Price)

          if (!_.isNaN(stocks.ShareGuid) || !_.isUndefined(stocks.ShareGuid) || stocks.ShareGuid !== null) {
            invQty = stocks.ShareQty
            if (stocks.ShareQty < 0) {
              invQty = -stocks.ShareQty
            }
            stocks.SalesPrice = this.ConvertNumber(stocks.SharePrice)
          }
          stocks.BuyPrice = this.ConvertNumber(stocks.Price)
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: 1,
            IsDelete: false,
            Model: stocks.Model,
            Qty: invQty,
            SalesQty: invQty,
            InvType: !this.hasValue(stocks.InvType) ? this.paramQtyType : stocks.InvType, // 库存类型
            Price: this.ConvertNumber(stocks.SalesPrice),
            SalesModel: stocks.Model,
            PriceInTax: this.CalcPriceInTax(
              this.ConvertNumber(stocks.SalesPrice)
            ), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(invQty),
              this.ConvertNumber(stocks.SalesPrice)
            ),
            GrossProfit: this.CalcGrossProfit(
              this.ConvertNumber(stocks.SalesPrice),
              this.ConvertNumber(stocks.BuyPrice),
              this.ConvertNumber(invQty),
              this.ConvertNumber(stocks.shareFee)
            ), // 毛利润
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            DeliveryDate: stocks.Leadtime,
            ProductCategory: stocks.ProductCategory,
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            Partner: stocks.Partner,
            SupplierInfo: stocks.SupplierName,
            BuyPrice: stocks.BuyPriceBuyPrice,
            InDate: stocks.StkInTime,
            BuyerName: stocks.BuyerName,
            Remark: stocks.Remark,
            Remark2: stocks.Remark1,
            Remark3: stocks.Remark2,
            ShareFee: stocks.shareFee,
            StorageName: stocks.InvPosition
          }
          if (this.isNullItemSource()) {
            this.itemSource = [outlinobj]
          } else {
            this.itemSource.push(outlinobj)
          }
        })
        this.onBindData()
        this.CalcStkOutAmoutAndGrossProfit(true)
      } else {
        this.$message({ message: '加载库存失败，请刷新后重试', type: 'error' })
      }
    },

    // 加载供应商库存
    LoadStkVMI: async function (rows) {
      if (rows.length > 0) {
        _.each(rows, stocks => {
          if (!this.IsOutQty) {
            stocks.InvQty = ''
          }
          let invQty = stocks.InvQty
          if (stocks.InvQty < 0) {
            invQty = -stocks.InvQty
          }
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: 1,
            IsDelete: false,
            Model: stocks.Model,
            Qty: invQty,
            SalesQty: invQty,
            Price: this.ConvertNumber(stocks.UnitPrice),
            SalesModel: stocks.Model,
            PriceInTax: this.CalcPriceInTax(
              this.ConvertNumber(stocks.UnitPrice)
            ), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(invQty),
              this.ConvertNumber(stocks.UnitPrice)
            ),

            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            DeliveryDate: stocks.Leadtime,
            ProductCategory: stocks.ProductCategory,
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            InvType: !this.hasValue(stocks.InvType) ? this.paramQtyType : stocks.InvType, // 库存类型
            Partner: stocks.Partner,
            SupplierInfo: stocks.SupplierName,
            BuyPrice: stocks.BuyPrice,
            InDate: stocks.StkInTime,
            BuyerName: stocks.BuyerName,
            Remark: stocks.Remark,
            Remark2: stocks.Remark1,
            Remark3: stocks.Remark2,
            ShareFee: stocks.shareFee,
            StorageName: stocks.InvPosition
          }
          if (this.isNullItemSource()) {
            this.itemSource = [outlinobj]
          } else {
            this.itemSource.push(outlinobj)
          }
        })
        this.onBindData()
        this.CalcStkOutAmoutAndGrossProfit(true)
      } else {
        this.$message({ message: '加载供应商库存失败，请刷新后重试', type: 'error' })
      }
    },

    // 加载供应商库存
    LoadStkVMIGUID: async function (guid) {
      let stkVMIS = await this.$post(CONFIG.GetStkVMIByGUIDS, {
        VMGUIDS: guid
      })
      this.$nextTick(function () {
        this.onResetStkout()
        _.each(stkVMIS, items => {
          this.$set(items, 'Qty', items.InvQty)
          this.$set(items, 'SupplierInfo', items.SupplierName)
        })
        this.itemSource = stkVMIS
        this.CalcStkOutAmoutAndGrossProfit(true)
        this.onBindData()
        this.isAllow = true // 编辑禁用选择
        this.ResTaxRate()
      })
    },

    // 加载历史发货明细
    loadStkOutLine(rows) {
      if (rows.length > 0) {
        _.each(rows, stocks => {
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: 1,
            IsDelete: false,
            Model: stocks.Model,
            Qty: stocks.Qty,
            SalesQty: stocks.Qty,
            Price: this.ConvertNumber(stocks.Price),
            SalesModel: stocks.Model,
            PriceInTax: this.CalcPriceInTax(
              this.ConvertNumber(stocks.Price)
            ), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(stocks.Qty),
              this.ConvertNumber(stocks.Price)
            ),
            InvType: !this.hasValue(stocks.InvType) ? this.paramQtyType : stocks.InvType, // 库存类型
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            ProductCategory: stocks.ProductCategory

          }
          if (this.isNullItemSource()) {
            this.itemSource = [outlinobj]
          } else {
            this.itemSource.push(outlinobj)
          }
        })
        this.onBindData()
        this.CalcStkOutAmoutAndGrossProfit(true)
      } else {
        this.$message({ message: '加载发货明细失败，请刷新后重试', type: 'error' })
      }
    },

    // 加载报价明细
    loadQuotedPriceLine(rows) {
      if (rows.length > 0) {
        _.each(rows, stocks => {
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: 1,
            IsDelete: false,
            Model: stocks.Model,
            Qty: stocks.Qty,
            SalesQty: stocks.Qty,
            Price: this.ConvertNumber(stocks.QuotePrice),
            SalesModel: stocks.Model,
            PriceInTax: this.CalcPriceInTax(
              this.ConvertNumber(stocks.QuotePrice)
            ), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(stocks.Qty),
              this.ConvertNumber(stocks.QuotePrice)
            ),
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            InvType: !this.hasValue(stocks.InvType) ? this.paramQtyType : stocks.InvType, // 库存类型
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            ProductCategory: stocks.ProductCategory

          }
          if (this.isNullItemSource()) {
            this.itemSource = [outlinobj]
          } else {
            this.itemSource.push(outlinobj)
          }
        })
        this.onBindData()
        this.CalcStkOutAmoutAndGrossProfit(true)
      } else {
        this.$message({ message: '加载报价明细失败，请刷新后重试', type: 'error' })
      }
    },
    isNullItemSource() {
      return this.itemSource.length === 0 || this.itemSource.length < 2 && this.itemSource[0].Model === '' && this.itemSource[0].Qty === null && this.itemSource[0].SalesModel === ''
    },
    // 我的库存出
    LoadListByStock: async function (stkline, isChecklist) {
      // 根据传入的stock对象加载明细列表
      if (stkline.length > 0) {
        _.each(stkline, stocks => {
          if (!this.IsOutQty) {
            stocks.InvQty = ''
          }
          let invQty = stocks.InvQty
          if (stocks.InvQty < 0) {
            invQty = -stocks.InvQty
          }
          if (!this.hasValue(invQty)) {
            invQty = stocks.Qty
            if (stocks.Qty < 0) {
              invQty = -stocks.InvQty
            }
          }
          let price = stocks.SalesPrice
          if (!this.hasValue(stocks.SalesPrice)) {
            price = stocks.Price
          }
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: 1,
            IsDelete: false,
            StkGUID: stocks.StkGUID, // 库存GUID
            Model: stocks.Model,
            Qty: invQty,
            SalesQty: invQty,
            InvType: !this.hasValue(stocks.InvType) ? this.paramQtyType : stocks.InvType, // 库存类型
            Price: this.ConvertNumber(price),
            SalesModel: stocks.Model,
            PriceInTax: this.CalcPriceInTax(
              this.ConvertNumber(price)
            ), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(invQty),
              this.ConvertNumber(price)
            ),
            GrossProfit: this.CalcGrossProfit(
              this.ConvertNumber(price),
              this.ConvertNumber(stocks.BuyPrice),
              this.ConvertNumber(invQty),
              this.ConvertNumber(stocks.shareFee)
            ), // 毛利润
            PurchaseAmount: this.CalcAmout(invQty, stocks.BuyPrice),
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            DeliveryDate: stocks.Leadtime,
            ProductCategory: stocks.ProductCategory,
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            Partner: stocks.Partner,
            SupplierInfo: stocks.SupplierName,
            BuyPrice: stocks.BuyPrice,
            InDate: stocks.StkInTime,
            BuyerName: stocks.BuyerName,
            Remark: stocks.Remark,
            Remark2: stocks.Remark1,
            Remark3: stocks.Remark2,
            ShareFee: stocks.shareFee,
            StorageName: stocks.InvPosition
          }
          if (this.isNullItemSource()) {
            this.itemSource = [outlinobj]
          } else {
            this.itemSource.push(outlinobj)
          }
        })

        this.onBindData()
        this.CalcStkOutAmoutAndGrossProfit(true)
      } else {
        this.$message({ message: '加载库存失败，请刷新后重试', type: 'error' })
      }
    },
    // ***** 导入/加载数据******

    onDeletedRows() {
      let rows = this.$refs.flexGrid.getSelectedRows()
      _.each(rows, data => {
        _.each(this.itemSource, (item, i) => {
          if (_.isEqual(_.omit(item, 'ShareFee'), _.omit(data, 'ShareFee'))) {
            this.itemSource.splice(i, 1)
            return
          }
        })
      })
      this.StkOut.LineList = this.itemSource
      this.CalcPay(false)
      this.CalcStkOutAmoutAndGrossProfit(false)
    },

    hasRes(code) {
      return _.some(this.resourceList, page => page.Code === code)
    },

    CalcPriceInTax(price) {
      // 计算含税价
      return this.toFloat(price * (1 + this.StkOut.TaxRate / 100), 6)
    },
    CalcPrice(priceintax) {
      // 计算未税
      return this.toFloat(priceintax / (1 + this.StkOut.TaxRate / 100), 6)
    },
    CalcAmout(qty, salesPrice) {
      // 计算金额
      return this.toFloat(qty * salesPrice, 2)
    },
    CalcPay(isEdit) {
      // 计算应付金额
      this.StkOut.Amount = 0
      _.each(isEdit ? this.StkOut.LineList : this.itemSource, (o, index) => {
        this.StkOut.Amount += this.toFloat(o.Amount, 2)
      })
      // this.StkOut.Amount = (this.StkOut.Amount).toFixed(2)
    },
    CalcGrossProfit(price, buyprice, qty, shareFee) {
      // 计算毛利润
      let rate = 1
      if (this.StkOut && this.StkOut.ExchangeRate) {
        rate = this.toFloat(this.StkOut.ExchangeRate)
      }
      let profit = (this.toFloat(price) * rate - this.toFloat(buyprice)) * qty
      profit = profit - this.toFloat(shareFee)
      return this.toFloat(profit, 2)
    },
    ConvertNumber(num) {
      // 转出数据格式
      return (_.isNaN(num) || _.isUndefined(num))
        ? null
        : this.toFloat(num, 6)
    },

    // 重置页面 清除数据
    onResetStkout() {
      this.StkOut.stkOutGUID = ''
      this.StkOut.BillNo = '' // 销售单号
      this.StkOut.Amount = null // 应收金额
      this.StkOut.CustomerGUID = '' // 客户guid
      this.StkOut.CustomerName = '' // 客户名称
      this.StkOut.ReceiptType = '' // 收款方式
      this.StkOut.ReceivedAmount = null // 实收金额
      this.StkOut.CustomerBankNo = '' // 收款帐户
      this.StkOut.Address = '' // 收货地址
      this.StkOut.ExpectReceiptDate = '' // 预计收款日期
      this.StkOut.GrossProfit = null // 利润总额
      this.StkOut.DiscountAmount = null // 折扣金额
      this.StkOut.Contact = '' // 联系人
      this.StkOut.Telephone = '' // 联系电话
      this.StkOut.Mobile = '' // 手机号码
      this.StkOut.CustomerOrderNo = '' // 客户订单号
      this.StkOut.OtherFeeAmount = null // 其它费用
      this.StkOut.TransportCompany = '' // 快递公司
      this.StkOut.ShippingNo = '' // 快递单号
      this.StkOut.Remark = '' // 销售备注
      this.StkOut.IsAdd = true // 是否新增
      this.StkOut.LineList = []
      this.itemSource = []
      this.StkOut.CBGUID = ''
    },

    // 单元格结束触发事件
    onCellEdited(aftervalue, beforevalue, colname, gridobj, rowindex, colindex) {
      // 1.修改后值 2.修改前值 3.列明 4.表格对象 5.行索引 6.列索引
      // 修改的行
      // let rowData = gridobj.rows[rowindex].dataItem
      // console.log(gridobj)
      if (!gridobj) return
      let rowData = gridobj.row
      // 金额
      let amountIndex = gridobj.getColumn('Amount').index
      // // 毛利润
      let grossIndex =
        !_.isUndefined(gridobj.getColumn('GrossProfit')) &&
        gridobj.getColumn('GrossProfit').index
      // // 含税
      let priceintaxIndex = this.getColumn('PriceInTax').index
      // // 未税
      let priceIndex = this.toFloat(this.getColumn('Price').index, 6)

      let numbers = this.ConvertNumber(rowData.Qty)
      let priceIntax = this.ConvertNumber(rowData.PriceInTax)
      let price = this.ConvertNumber(rowData.Price)
      let buyprice = this.ConvertNumber(rowData.BuyPrice)
      let shareFee = isNaN(this.ConvertNumber(rowData.ShareFee))
        ? 0
        : this.ConvertNumber(rowData.ShareFee)
      if (colname === 'Model') {
        if (this.StkOut.IsTakeOutPrint) {
          // 根据型号获取上次价格
          let data = _.extend({}, { Model: rowData.Model, CustomerName: this.StkOut.CustomerName })
          this.$post(CONFIG.GetPrice, data, (data) => {
            let price = this.toFloat(data, 6) // 未税价格

            let priceintaxSummary = this.CalcPriceInTax(price)

            gridobj.setCellData(rowindex, priceIndex, price) // 设置显示的值
            this.itemSource[rowindex]['Price'] = price // 设置对象的值

            gridobj.setCellData(rowindex, priceintaxIndex, priceintaxSummary) // 设置显示的值
            this.itemSource[rowindex]['PriceInTax'] = priceintaxSummary // 设置对象的值
          })
        }
        aftervalue = aftervalue.toUpperCase()
        this.itemSource[rowindex]['Model'] = aftervalue
        // 发货型号
        if (beforevalue === '' && aftervalue !== '') {
          this.itemSource[rowindex]['SalesModel'] = aftervalue
        } else if (beforevalue !== '' && aftervalue !== '') {
          this.itemSource[rowindex]['SalesModel'] = aftervalue
        } else if (beforevalue !== '' && aftervalue === '' && beforevalue !== undefined) {
          this.itemSource[rowindex]['SalesModel'] = aftervalue
        }
      }
      if (colname === 'SalesModel') {
        this.itemSource[rowindex]['SalesModel'] = aftervalue.toUpperCase()
      }
      if (colname === 'Price') {
        // 未税算含税
        let priceintaxSummary = this.toFloat(this.CalcPriceInTax(aftervalue), 6)
        gridobj.setCellData(
          rowindex,
          priceintaxIndex,
          this.ConvertNumber(priceintaxSummary).toString()
        )
        // this.$set(this.itemSource[rowindex], 'PriceInTax', this.CalcPriceInTax(price))
        priceIntax = priceintaxSummary
        this.itemSource[rowindex]['Price'] = this.toFloat(aftervalue, 6)
        this.itemSource[rowindex]['PriceInTax'] = priceintaxSummary // 设置对象的值
      }
      if (colname === 'PriceInTax') {
        // 含税算未税
        let priceSummary = this.toFloat(this.CalcPrice(aftervalue), 6)
        gridobj.setCellData(
          rowindex,
          priceIndex,
          this.ConvertNumber(priceSummary).toString()
        )
        price = priceSummary
        // this.itemSource[rowindex]['PriceInTax'] = this.toFloat(aftervalue, 6)
        this.itemSource[rowindex]['Price'] = price
      }
      if (colname === 'Qty') {
        numbers = this.ConvertNumber(aftervalue)
        if (!this.isAllow) {
          let BeforeFirstValue = this.itemSource[rowindex]['BeforeFirstQtyValue']  // 设置对象的值
          if (BeforeFirstValue && numbers > BeforeFirstValue) {
            this.$message({ message: '输入的数量已超出待发货的数量', type: 'warning' })
          }
        }
      }
      // 采购价算采购总额
      if (colname === 'BuyPrice') {
        this.itemSource[rowindex]['PurchaseAmount'] = this.CalcAmout(numbers, buyprice)
      }
      if (colname === 'BuyPrice') {
        buyprice = this.ConvertNumber(aftervalue)
      }
      let AmountSummary = this.toFloat(this.CalcAmout(numbers, priceIntax), 2)
      gridobj.setCellData(rowindex, amountIndex, AmountSummary) // 算总额
      this.itemSource[rowindex]['Amount'] = this.toFloat(AmountSummary, 2)

      let grossSummary = this.CalcGrossProfit(
        price,
        buyprice,
        numbers,
        shareFee
      )
      grossIndex !== false &&
        gridobj.setCellData(rowindex, grossIndex, grossSummary) // 算毛利润
      this.itemSource[rowindex]['GrossProfit'] = grossSummary

      this.CalcStkOutAmoutAndGrossProfit(false)
    },
    CalcStkOutAmoutAndGrossProfit(isEdit) {
      // 计算金额和毛利润, 分摊费
      this.StkOut.Amount = 0.0 // 应收
      this.StkOut.GrossProfit = 0.0 // 利润总额
      _.each(isEdit ? this.StkOut.LineList : this.itemSource, (o, index) => {
        this.StkOut.Amount += this.toFloat(o.Amount, 2)
        this.StkOut.GrossProfit += this.toFloat(o.GrossProfit, 2)
      })
      let GrossProfitSummary =
        this.StkOut.GrossProfit -
        this.ConvertNumber(this.StkOut.DiscountAmount) -
        this.ConvertNumber(this.StkOut.OtherFeeAmount)
      this.StkOut.GrossProfit = this.toFloat(GrossProfitSummary, 2)
      this.StkOut.Amount = this.toFloat(this.StkOut.Amount, 2)
      this.CalcProfitMargins()
    },
    getColumn(name) {
      let list = [{ name: '_Checked' }]
      let columns = this.columns
      _.each(columns, item => {
        if (item.visible) {
          list.push(item)
        }
      })
      for (let i = 0; i < list.length; i++) {
        if (name === list[i].name) {
          return {
            col: columns[i],
            index: i
          }
        }
      }
    },
    setCellData(r, c, v) {
      // console.log(r, c, v)
      this.$nextTick(() => {
        let trs = this.$el.querySelectorAll('.el-table .el-table__body-wrapper .el-table__body tbody tr')
        let containerOld = trs[r] && trs[r].querySelectorAll('td')[c] && trs[r].querySelectorAll('td')[c].querySelector('.cell div')
        let containerNew = trs[r] && trs[r].querySelectorAll('td')[c] && trs[r].querySelectorAll('td')[c].querySelector('.cell input')
        if (containerOld) {
          containerOld.innerText = v
        } else {
          if (containerNew) { containerNew.value = v }
        }
      })
    },

    CalcTaxRate(val) {
      // Qty 数量
      // Price 未税
      // PriceInTax 含税
      // Amount 小计金额
      // GrossProfit 利润
      //  console.log('this.StkOut.TaxRate2222 ', this.StkOut.TaxRate)
      val ? (this.StkOut.TaxRate = val.key) : (this.StkOut.TaxRate = null)
      let tempSource = this.itemSource
      if (tempSource.length > 0) {
        for (let i = 0; i < tempSource.length; i++) {
          tempSource[i].PriceInTax = this.CalcPriceInTax(
            tempSource[i].Price - 0
          )

          tempSource[i].Amount = this.CalcAmout(
            tempSource[i].Qty,
            tempSource[i].PriceInTax
          ) // 应收
          tempSource[i].GrossProfit = this.toFloat(this.CalcGrossProfit(
            tempSource[i].Price,
            tempSource[i].BuyPrice,
            tempSource[i].Qty,
            tempSource[i].ShareFee
          ), 2)// 利润总额
          if (tempSource[i].Price <= 0) {
            tempSource[i].Amount = null
            tempSource[i].PriceInTax = null
            tempSource[i].GrossProfit = null
            tempSource[i].Price = null
          }
        }
        this.itemSource = tempSource
        // _.each(tempSource, (item, i) => {
        //   this.getColumn('Price') && this.setCellData(i, this.getColumn('Price').index, item.Price || null)
        //   this.getColumn('PriceInTax') && this.setCellData(i, this.getColumn('PriceInTax').index, item.PriceInTax || null)
        //   this.getColumn('Amount') && this.setCellData(i, this.getColumn('Amount').index, item.Amount || null)
        //   this.getColumn('GrossProfit') && this.setCellData(i, this.getColumn('GrossProfit').index, item.GrossProfit || null)
        // })
        this.CalcStkOutAmoutAndGrossProfit(false)
      }
    },
    CalcProfitMargins() {
      let rate = 1
      if (this.StkOut && this.StkOut.ExchangeRate) {
        rate = this.toFloat(this.StkOut.ExchangeRate)
      }
      let tempSource = this.itemSource

      if (tempSource.length > 0) {
        for (let i = 0; i < tempSource.length; i++) {
          // 利润率=(未税单价*汇率-采购价)/采购价 * 100. 显示加上% ， 保留两位小数
          let price = this.toFloat(tempSource[i].Price)
          let buyPrice = this.toFloat(tempSource[i].BuyPrice)
          if (price > 0 && rate > 0) {
            // price = (price * rate - buyPrice) / price * 100
            tempSource[i].ProfitMargins = this.GetProfitRate(price, buyPrice, rate)
          } else {
            tempSource[i].ProfitMargins = ''
          }
        }
        this.itemSource = tempSource
        // _.each(tempSource, (item, i) => {
        //   this.setCellData(
        //     i,
        //     this.getColumn('ProfitMargins').index,
        //     item.ProfitMargins || ''
        //   )
        // })
      }
    },
    SearchCompany() {
      this.InTypes = '1,3' // 客户和供应商客户
      if (!this.fromSalesOrderOpen) {
        this.dialogCompanySelectVisible = true
      }
    },
    companyChecked(item) {
      if (this.hasValue(item.CompanyName)) {
        this.StkOut.CustomerName = item.CompanyName
      }
      if (this.hasValue(item.CompanyID)) {
        this.StkOut.CustomerGUID = item.CompanyID
      }
      if (this.hasValue(item.Address)) {
        this.StkOut.Address = item.Address
      }
      if (this.hasValue(item.ContactName)) {
        this.StkOut.Contact = item.ContactName
      }
      if (this.hasValue(item.Telephone)) {
        this.StkOut.Telephone = item.Telephone
      }
      if (item.FollowUserID !== null && item.FollowUserID !== undefined && item.FollowUserID > 0) {
        this.StkOut.ShipUserID = item.FollowUserID
      }
      if (this.hasValue(item.TaxRate)) {
        this.StkOut.TaxRate = item.TaxRate
      }
      if (this.hasValue(item.CurrencyCode)) {
        this.StkOut.CurrencyCode = item.CurrencyCode
      }
      this.StkOut.ReceiptType = item.PaymentType
    },
    hasValue(val) {
      if (val === null || val === undefined || val === '') {
        return false
      }
      return true
    },
    onSaleChange() {
      // 销售员选中事件
      let buyer = _.find(this.selectOptionsAllUserURL, item => {
        return item.key === Number(this.StkOut.ShipUserID)
      })
      if (!_.isUndefined(buyer)) { this.StkOut.Shipper = buyer.value }
    },
    onBillIconClick() {
      if (this.StkOut.IsAdd) {
        this.IsManual = !this.IsManual
        if (!this.IsManual) {
          this.StkOut.BillNo = ''
        }
      } else {
        this.$message({ message: '单据编号生成后不可以修改！', type: 'error' })
      }
    },
    autocompleteChange(val) {
      this.startTime = new Date()
      _.delay(() => {
        this.endTime = new Date()
        if (this.endTime - this.startTime >= 800) {
          this.autocompleteSearch(true, val)
        }
      }, 800)
    },
    autocompleteSearch(isSearch, data) {
      if (!isSearch) return
      this.autocompleteSearchData = data
      if (data.length > 0) {
        // this.autocompleteShow = true
        // this.$refs.autocompleteSelect.$el.style.left = getabsleft(this.$refs.znlAutocomplete.$el) + 65 + 'px'
        // this.$refs.autocompleteSelect.$el.style.top = getabstop(this.$refs.znlAutocomplete.$el) + 30 + 'px'
      } else {
        this.autocompleteShow = false
      }
    },
    selectionChanged(val) {
      this.autocompleteShow = false
      this.companyChecked([val])
    }
    // clickNative (e) {
    //   console.log(e)
    // }
  },
  mounted() {
    this.$nextTick(() => {
      _.delay(() => {
        if (this.editType === 'addNew' && this.autoAddNewRow) {
          this.$refs.flexGrid.addRow(true)
          this.$nextTick(() => {
            _.delay(() => {
              this.newRowsStyle()
            }, 400)
          })
        }
      }, 1500)
    })
  },

  created() {
    // 打印列表
    this.onInitPrintTemplate()

    this.specialResource = this.selectOptionsSpecialResource
  }
}
</script>
<style lang="scss">
</style>

