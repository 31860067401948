import { Login } from '~/views/Login/'

export default {
  path: '/login',
  name: 'LoginView',
  component: Login,
  meta: {
    title: '登录',
    closable: false,
    display: false,
    commonLayout: false
  }
}
