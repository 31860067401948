import {
  TradingCenter
} from '~/views/Trading'

export default {
  path: '/Trading',
  name: 'Trading',
  meta: {
    title: '交易中心',
    alias: '交易中心',
    icon: 'icon-left_nav_push_ic'
  },
  children: [
    {
      path: 'TradingCenter',
      name: '/Trading/TrandingCenter',
      component: TradingCenter,
      meta: {
        title: '交易中心'
      }
    }
  ]
}
