import { Home } from '~/views'

export default {
  path: '/',
  name: 'Home',
  component: Home,
  meta: {
    title: '首页',
    closable: false,
    display: false
  }
}
