<template>
  <div>
    <znl-dialog :visible="visible&&dialogShow"
              width="300px"
              height="260px"
              title="扫一扫登录 BOM.AI"
              :on-init="onInit"
              :is-footer-show="false"
              @close="()=>{this.dialogShow = false, this.isRefresh = !this.isRefresh, $emit('close')}">
    <div slot="znl-dialog" class="stock-extend-set">
      <el-row>
        <el-col style="text-align:center">
          <img id="bomID_ERPcodeimg"
            :src="imgSrc"
            style="width: 180px; height:180px;" />
        </el-col>
      </el-row>
    </div>
    </znl-dialog>
  </div>
</template>

<script>
import { mixin as propSyncMixin } from '@scripts/mixins/propSync'
import bindWechat from '~assets/scripts/mixins/bind_wechat'

export default {
  name: 'BomAILogin333',
  mixins: [propSyncMixin, bindWechat],
  components: {
  },
  data () {
    return {
      dialogShow: false,
      isRefresh: false,
      imgSrc: ''
    }
  },
  props: {
    loading: Boolean,
    visible: Boolean
  },
  watch: {
    visible (val) {
      if (val) {
        this.dialogShow = true
        this.isRefresh = !this.isRefresh
        this.createImage()
      }
    }
  },
  methods: {
    onInit: async function () {
    },
    onCancel () {
      this.p_dialogVisible = false
      this.$emit('close')
    },
    onConfirm () {
    },
    scanSuccess () {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
