<template>
  <znl-dialog
    :visible="visible"
    height="620px"
    width="920px"
    box-class="module-dialog"
    title="选择库存"
    v-loading="logLoading"
    element-loading-text="数据加载中,请稍后..."
    @close="()=>{$emit('close')}"
  >
    <!-- 库存选择模块 -->
    <select-stock-list
      ref="selectStockList"
      :init-data="true"
      page-position="top"
      :is-init="true"
      :multi-rows-check="true"
      :is-show-linked-qty-col="isShowLinkedQtyCol"
      @page-loading="d=>{logLoading = d}"
      @stock-check-row="val=>{this.ondblclick(val)}"
    ></select-stock-list>

    <div slot="footer">
      <znl-button @click="onCancel" :height="33" :width="60">关闭</znl-button>
      <znl-button @click="onConfrim" :height="33" :width="60" style-type="main">确定</znl-button>
    </div>
  </znl-dialog>
</template>

<script>
import SelectStockList from "@c_modules/Stk/SelectStockList";

export default {
  name: "DialogStockSelect",
  mixins: [],
  components: {
    SelectStockList
  },
  data() {
    return {
      logLoading: true
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      propsync: true
    },
    isShowLinkedQtyCol: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    p_visible(val) {
      if (val) {
        this.onLoaded = true;
      }
    }
  },
  methods: {
    ondblclick(val) {
      this.onConfrim(val);
    },
    onCancel() {
      this.$emit("close");
    },
    onConfrim(item) {
      let lists = [];
      if (!_.isUndefined(item)) {
        lists = this.$refs.selectStockList.onGetSelectedRows();
      } else {
        lists.unshift(item);
      }
      if (_.isUndefined(lists[0])) {
        return this.$message({
          message: "请至少选择一条数据进行操作",
          type: "error"
        });
      } else {
        this.$emit("confirm", lists);
        this.$emit("close");
      }
    }
  }
};
</script>

<style lang="scss">
</style>
