import Vue from 'vue'
const math = require('mathjs');

/* 重置 window number 的 toFixed 函数 */
const resetWindowNumberToFixed = () => {
  const _oldToFixedFunc = Number.prototype.toFixed;
  Number.prototype.toFixed = function(n) {
    if (n > 18 || n < 0) {
      throw new RangeError("toFixed() digits argument must be between 0 and 18");
    }

    const number = this;
    if (isNaN(number) || number >= Math.pow(10, 21)) {
      return number.toString();
    }
    if (typeof n == "undefined" || n == 0) {
      return Math.round(number).toString();
    }
    var sign = Math.sign(number);
    var num = Math.abs(number);
    var int = num < 1 ? 0 : parseInt(num);
    var digt = num - int;
    var pow = Math.pow(10, n);
    digt *= pow;
    digt += 0.0000000001;
    digt = Math.round(digt);
    num = int + digt / pow;
    num *= sign;
    return _oldToFixedFunc.call(num, n);
    //return num.toString();
  };

}
Vue.prototype.$evaluate = math.evaluate;


resetWindowNumberToFixed();

