import {
  SpmSpotSales
} from '~/views/Spm'

export default {
  path: '/spm',
  name: 'Spm',
  component: SpmSpotSales,
  meta: {
    title: '工作台',
    alias: '现货销售',
    authName: '工作台',
    icon: 'icon-left_nav_work_ic',
    display: false
  },
  children: [
    {
      path: 'spotsales',
      name: 'Spm/SpotSales',
      component: SpmSpotSales,
      meta: {
        title: '现货销售',
        authName: '现货销售',
        display: false
      }
    }
  ]
}
