
import Vue from 'vue'
import App from './App'
import {createRouter} from './router'
import {createStore} from './store'
import {authorize} from './security/auth'
import '~plugins'
import {setBomAiWebToken} from "./assets/scripts/utils/iframe";
import '@c_common/dialogs/dialogDrag.js'

let isBack = true

window.addEventListener('popstate', function (e) {
  isBack = false
}, false)

// import './router/auth'

function init() {
  const store = createStore()
  const router = createRouter()
  window.routerPush = router.push
  router.beforeEach((to, from, next) => {
    next(isBack)
    isBack = true
  })




  const app = new Vue({
    router,
    store,
    render: h => h(App)
  })

  authorize(app, router)


  // setBomAiWebToken(store)

  app.$mount('#znl')
}

init()

window.navi = (pathname) => {
  const e = new Event('navigate')
  e.url = pathname
  window.dispatchEvent(e)
}
