import VueRouter from 'vue-router'

/* 处理客户端  Error: Navigation cancelled from "/a/aa" to "/b/bb" with a new navigation. vue router 报错问题*/
const VueRouterErrCatch = function(){
  const originalPush = VueRouter.prototype.push;
  const originalReplace = VueRouter.prototype.replace;

  // push
  VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
      return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err);
  };

  //replace
  VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
      return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
  };
}();

/* 处理客户端 页面闪烁问题  */
const handleClientRouter = (list) => {

  const clientBool = !_.isUndefined(window.cefQuery);
  // const clientBool = true;
  const clientRouterHandleConfig = {
    'Crm': 'clearComponent', // 供应商
    'Purchase': 'clearComponent', // 库存
    'Stk': 'clearComponent', // 采购
    'Sales': 'clearComponent', // 销售
  };

  if (!clientBool) return list;

  list.forEach(item => {
    if (clientRouterHandleConfig[item.name]) {
      let handleKey = clientRouterHandleConfig[item.name];
      switch (handleKey) {
        case 'clearComponent':
          delete item.component;
          break;
      }
    }
  });
  return list;
}



export default handleClientRouter
