<template>
  <znl-gridmodule
                ref="flexGrid"
                layout-type="other"
                height="300px"
                slot="znl-dialog"
                :is-init="true"
                :has-znl-actions="false"
                :has-znl-btns="false"
                :is-fields-search="false"
                :columns="columns"
                :item-source="itemSource"
                :is-multi-rows-check="false"
                :search-fields="searchModel"
                :page-size="pageSize"
                :page-index="pageIndex"
                :total-count="totalCount"
                :role="role"
                :users="$store.state.users"
                :on-init="onInit"
                :show-add-row-right-menu="false"
                :show-delete-row-right-menu="false"
                :on-search="onSearch"
                v-show="visible"
                @selection-changed="(d)=>{$emit('selection-changed', d)}"
                :on-page-changed="onBindData"
                 @ondblclick="ondblclick"
                >
  </znl-gridmodule>
</template>
<script>
  export default {
    name: 'autocomplete',
    data () {
      return {
        columns: [],
        itemSource: [],
        pageSize: 10,
        pageIndex: 1,
        totalCount: 0,
        TotalPage: 0,
        role: '1'
      }
    },
    props: {
      searchModel: {
        type: Object,
        default () {
          return {}
        }
      },
      searchData: {
        type: String,
        default: ''
      },
      visible: {
        type: Boolean,
        default: false
      },
      CompanyTypes: String
    },
    watch: {
      searchData (val) {
        if (!val) return
        this.onBindData(val)
      },
      itemSource () {
        this.removeNews()
      },
      visible (val) {
        if (val) {
          this.$nextTick(() => {
            // this.$el.style.left = this.$el.parentElement.getBoundingClientRect().left + 'px'
            // this.$el.style.top = this.$el.parentElement.getBoundingClientRect().top + 10 + 'px'
          })
        }
      }
    },
    methods: {
      async onInit () {
        let gridConfig = [
          {CTitle: '跟进人', BindField: 'FollowUserName', IsShow: true, IsReadonly: true, ColumnWidth: 48, DisplayIndex: 5, DataType: 1, IsSystem: false, IsRequired: true},
          {CTitle: '公司GUID', BindField: 'CompanyID', IsShow: false, IsReadonly: true, ColumnWidth: 50, DisplayIndex: 30, DataType: 1, IsSystem: true, IsRequired: true},
          {CTitle: '公司名称', BindField: 'CompanyName', IsShow: true, IsReadonly: true, ColumnWidth: 180, DisplayIndex: 0, DataType: 1, IsSystem: false, IsRequired: true},
          {CTitle: '地址', BindField: 'Address', IsShow: true, IsReadonly: true, ColumnWidth: 150, DisplayIndex: 2, DataType: 1, IsSystem: false, IsRequired: true},
          {CTitle: '联系人', BindField: 'ContactName', IsShow: true, IsReadonly: true, ColumnWidth: 48, DisplayIndex: 3, DataType: 1, IsSystem: false, IsRequired: true},
          {CTitle: '联系人电话', BindField: 'Telephone', IsShow: true, IsReadonly: true, ColumnWidth: 80, DisplayIndex: 4, DataType: 1, IsSystem: false, IsRequired: true},
          {CTitle: '公司类型', BindField: 'CompanyTypeStr', IsShow: true, IsReadonly: true, ColumnWidth: 80, DisplayIndex: 5, DataType: 1, IsSystem: false, IsRequired: true}
        ]
        this.columns = this.flexGridColumnsHandler(gridConfig)
        // return this.onBindData()
      },
      onBindData (val) {
        this.$post('CompanyContacts/SearchCompanyContacts', {
          FieldWhereString: JSON.stringify({
            CompanyName: val || this.searchData
          }),
          PageIndex: 1,
          PageSize: 20,
          CompanyTypes: this.CompanyTypes
        }, (data) => {
          this.PageIndex = data.PageIndex
          this.PageSize = data.PageSize
          this.TotalCount = data.TotalCount
          this.TotalPage = data.TotalPage
          this.itemSource = data.ResultList
          if (this.itemSource.length === 0) {
            this.$emit('update:visible', false)
          } else {
            this.$emit('update:visible', true)
          }
          _.delay(() => {
            this.$parent.$children[0].$el.querySelector('.el-input__inner').focus()
          }, 100)
        })
      },
      ondblclick () {

      },
      onSearch () {

      },
      removeNews () {
        let trs = []
        trs = this.$el.querySelectorAll('.znl-addNewRow')
        if (trs.length > 0) {
          _.each(trs, item => {
            item.classList.remove('znl-addNewRow')
          })
        }
        trs = null
      }
    }
  }
</script>
<style lang="scss">
.znl-autocomplete-select{
  position: absolute;
  left: 60px;
  top: 30px;
  &.znl-grid-component{
    height: auto;
    max-height: 300px;
    overflow: auto;
  }
}
</style>

