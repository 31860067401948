const mutations = {
  setLoading (state, isLoading) {
    state.loading = isLoading
  },
  setPageLoading (state, isLoading) {
    state.pageLoading = isLoading
  },
  addTab (state, {name, params = {}, query = {}, components = [], title = '标题', closable = true, isIframe = false} = {}) {
    let target = _.find(state.tabs, tab => tab.name === name)
    let flag = true
    _.each(state.tabs, tab => {
      if (tab.name === name) {
        flag = false
      }
    })
    if (!target && flag) {
      state.tabs.push({
        name: name,
        params: params,
        query: query,
        components: components,
        title: title,
        closable: closable,
        isIframe: isIframe
      })
    }
    state.activeTab = name
  },
  removeTab (state, name) {
    let {tabs = state.tabs, activeName = state.activeTab} = {}
    activeName === name && _.each(tabs, (tab, index) => {
      if (tab.name === name) {
        let nextTab = tabs[index + 1] || tabs[index - 1]
        if (nextTab) {
          activeName = nextTab.name
        }
      }
    })
    state.tabs = tabs.filter(tab => tab.name !== name)
    state.activeTab = activeName
  },
  removeAllTab (state) {
    let {tabs = state.tabs} = {}
    state.tabs = tabs.filter(tab => tab.name === 'Home' || !tab.closable)
    state.activeTab = 'Home'
  },
  setAccountInfo (state, infos) {
    _.extend(state.accountInfo, infos)
  },
  setDomRect (state, rect) {
    _.extend(state.domRect, rect)
    state.domRect.contentWidth = state.siderbarHide ? state.domRect.clientWidth : state.domRect.clientWidth - state.domRect.siderWidth
    state.domRect.contentHeight = state.domRect.clientHeight - state.domRect.headerHeight - 10
    state.domRect.contentHeightWithoutTab = state.domRect.contentHeight - state.domRect.tabHeader
  },
  setParamOrder (state, paramOrder) {
    state.paramOrder = paramOrder
  },
  setUsers (state, users) {
    state.users = users
  },
  setCommonDatas (state, datas) {
    state['commonDatas'][datas.type] = datas.datas
  },
  setRequestStates (state, datas) {
    state['requestStates'][datas.type] = datas.datas
  },
  getCommonDatas (state) {
    return state.commonDatas
  },
  setLoginBomaiStatus (state, val) {
    state['isLoginBomaiSuccess'] = val
  },
  getUsers (state) {
    return state.users
  },
  setLinkAllInfo (state, linkAllInfo) {
    _.extend(state.linkAllInfo, linkAllInfo)
  },
  setShoppingData (state, data) {
    state.shoppingData = data
  },
  setJWTInfo (state, jwtinfo) {
    state[jwtinfo.name] = jwtinfo.value
  },
  // 用户资料
  setUseInfo (state, data) {
    state.useInfo = data;
  },

  // 设置bomAi 写入
  setIsBomAi(state, data) {
    state.webData.isBomAi = data
  }
}

export default mutations
