import Vue from 'vue'
import '~plugins/element-ui'
import {
  GridModule,
  ChartMini,
  Dialog,
  Input,
  Lists,
  Button,
  ButtonMenu,
  Tree,
  GridDialog,
  FloatList,
  DialogMini,
  Carousel,
  CarouselItem,
  LinkAll,
  CellRender,
  Switch,
  Multiples,
  Multiple,
  Spa,
  LeftRight,
  TopBottom
} from '@znl/vue-znl-ui';

// import ZnlTable from 'zznlerp-table'
import ZnlTable from '@znl/znlerp-table'
import VueSplit from 'vue-split-panel'
import 'znl-datatools/dist/data-tools.css'
// import 'znl-trading/dist/trading.css'
import ECharts from 'vue-echarts'

import Message from 'element-ui/lib/message.js';
Vue.prototype.$succMess = (message, duration = 1500, onClose) => {
  Message({
    message,
    customClass: 'bom_center_message',
    duration,
    type: 'success',
    onClose
  });
};
Vue.prototype.$erroMess = (message, duration = 2000) => {
  Message({
    message,
    customClass: 'bom_center_message',
    duration,
    type: 'warning'
  });
};


Vue.component('ZnlTable', ZnlTable)
Vue.component('v-chart', ECharts)
const DataTools = require('znl-datatools')
Vue.component('DataTools', DataTools)

// const Trading = require('znl-trading').default
// Vue.component('Trading', Trading)


// 组件(UI)
Vue.use(GridModule) // 表格
Vue.use(ChartMini)
Vue.use(Dialog) // 弹窗
Vue.use(DialogMini) // 弹窗
Vue.use(Input)
Vue.use(Lists)
Vue.use(GridDialog)
Vue.use(Button)
Vue.use(ButtonMenu)
Vue.use(LinkAll)
Vue.use(Tree) // 文件树
Vue.use(FloatList) // 浮动列表
Vue.use(CellRender)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Switch)

// 布局(View)
Vue.use(Multiples)
Vue.use(Multiple)
Vue.use(Spa)
Vue.use(LeftRight)
Vue.use(TopBottom)
Vue.use(VueSplit)


// let ZnlTrading =
//   'https://static.bom.ai/assets/vue/Trading/trading.umd.min.js';
// let ZnlTradingCss =
//   'https://static.bom.ai/assets/vue/Trading/trading.css';
// const scriptDom = document.createElement('script');
// scriptDom.src = ZnlTrading;
// document.body.appendChild(scriptDom);
// const linkDom = document.createElement('link');
// linkDom.rel = 'stylesheet';
// linkDom.href = ZnlTradingCss;
// document.body.appendChild(linkDom);

// scriptDom.onload = () => {
//   window.Vue = Vue
//   Vue.component('trading', window.trading.default)
// }
