<template>
  <div class="m-form-printer" :style="{ width: page.width }">
    <div v-for="(interval, index) in dataSlice" :key="index">
      <div
        class="page-box"
        ref="pageBox"
        :style="{
          width: page.width,
          height: page.height,
          paddingTop: page.paddingTop,
          paddingLeft: page.paddingLeft
        }"
      >
        <div class="content-box">
          <el-row
            v-for="(row, rowIndex) in row"
            :key="rowIndex"
            type="flex"
            :justify="row.align ? row.align : page.align"
            :class="[
              row.type ? row.type + '-row' : '',
              row.isFloat && 'row-float'
            ]"
            :style="{
              width: row.width,
              top: row.isFloat ? row.top : '0px',
              left: row.isFloat ? row.left : '0px',
              lineHeight:
                (row.rowHeight ? row.rowHeight : page.rowHeight) + 'px',
              fontSize: row.fontSize + 'px',
              fontFamily: row.fontFamily || pageConfig.fontFamily,
              fontWeight: row.fontWeight || 'normal',
              marginTop: row.marginTop + 'px',
              marginRight: row.marginRight + 'px',
              marginBottom: row.marginBottom + 'px',
              marginLeft: row.marginLeft + 'px'
            }"
          >
            <template v-if="row.type === 'image'">
              <div :style="{ width: row.width, height: row.height }">
                <img v-if="row.field" :src="info[row.field]" alt="" />
                <img v-if="row.src" :src="row.src" alt="" />
              </div>
            </template>
            <template v-else-if="row.type === 'textarea'">
              <div>
                <pre
                  :style="{
                    fontSize:
                      (row.fontSize ? row.fontSize : page.fontSize) + 'px',
                    fontFamily: row.fontFamily || page.fontFamily,
                    lineHeight:
                      (row.lineHeight
                        ? row.lineHeight
                        : pageConfig.lineHeight) + 'px'
                  }"
                  >{{ row.content }}</pre
                >
              </div>
            </template>
            <template v-else-if="row.type === 'table'">
              <table
                width="100%"
                border="1"
                cellspacing="0"
                cellpadding="0"
                border-spacing="0"
              >
                <tr :height="tableConfig.headerHeight">
                  <th v-if="tableConfig.showIndex">
                    {{ tableConfig.indexTitle }}
                  </th>
                  <th
                    v-for="(item, index) in rowConfig[rowIndex].colConfig"
                    :height="row.rowHeight || page.rowHeight + 'px'"
                    :key="index"
                  >
                    <div
                      class="header-box"
                      :style="{ lineHeight: tableConfig.headerHeight + 'px' }"
                    >
                      {{ item.headerName }}
                    </div>
                  </th>
                </tr>
                <tr
                  v-for="(data, index) in dataSources.slice(
                    interval[0],
                    interval[1]
                  )"
                  :key="index"
                  :height="tableConfig.rowHeight + 'px'"
                >
                  <td v-if="tableConfig.showIndex">{{ index + 1 }}</td>
                  <td
                    v-for="(col, colIndex) in row.colConfig"
                    :key="colIndex"
                    :style="{ padding: tableConfig.padding + 'px' }"
                  >
                    {{ data[col.field] }}
                  </td>
                </tr>
                <tr
                  class="table-footer"
                  :height="tableConfig.footerHeight"
                  v-if="countSlice.length && row.footer"
                >
                  <td
                    v-for="(footItem, footIndex) in row.footer"
                    :key="footIndex"
                    :colspan="footItem.colspan"
                  >
                    <span v-if="footItem.label">{{ footItem.label }}:</span>
                    <span v-if="footItem.type === 'capital'">
                      {{ countSlice[index][0] }}
                    </span>
                    <span v-if="footItem.type === 'count'">
                      {{ countSlice[index][1] }}
                    </span>
                    <span v-else-if="footItem.field">
                      {{ info[footItem.field] }}
                    </span>
                    <span v-else-if="footItem.text">
                      {{ footItem.text }}
                    </span>
                  </td>
                </tr>
              </table>
            </template>
            <template v-else>
              <el-col
                v-for="(col, colIndex) in row.content"
                :style="{
                  textAlign: row.textAlign ? row.textAlign : page.textAlign,
                  lineHeight: row.rowHeight
                }"
                :key="colIndex"
              >
                <div style="display: inline-flex" v-if="col.text || col.field">
                  <span v-if="col.text">{{ col.text }}</span>
                  <span v-if="info[col.field]">{{ info[col.field] }}</span>
                </div>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import html2Canvas from "html2canvas";
import JsPDF from "@znl/jspdf";

function chineseTransform(str) {
  var num = parseFloat(str);
  var strOutput = "",
    strUnit = "仟佰拾亿仟佰拾万仟佰拾元角分";
  num += "00";
  var intPos = num.indexOf(".");
  if (intPos >= 0) {
    num = num.substring(0, intPos) + num.substr(intPos + 1, 2);
  }
  strUnit = strUnit.substr(strUnit.length - num.length);
  for (var i = 0; i < num.length; i++) {
    strOutput +=
      "零壹贰叁肆伍陆柒捌玖".substr(num.substr(i, 1), 1) + strUnit.substr(i, 1);
  }
  return strOutput
    .replace(/零角零分$/, "整")
    .replace(/零[仟佰拾]/g, "零")
    .replace(/零{2,}/g, "零")
    .replace(/零([亿|万])/g, "$1")
    .replace(/零+元/, "元")
    .replace(/亿零{0,3}万/, "亿")
    .replace(/^元/, "零元");
}

export default {
  name: "FormPrinter",
  props: {
    page: Object,
    row: Array,
    dataSources: Array,
    info: Object,
    scale: {
      type: Number,
      default: 2
    },
    table: Object
  },
  computed: {
    pageConfig() {
      return this.page;
    },
    rowConfig() {
      const rowConfig = JSON.parse(JSON.stringify(this.row));
      rowConfig.map(item => {
        Object.keys(item).map(name => {
          if (typeof item[name] === "number") {
            item[name] += "px";
          }
        });
      });
      return rowConfig;
    },
    tableConfig() {
      return this.table;
    }
  },
  methods: {
    computedSlice() {
      const otherHeight = document.querySelector(".content-box").offsetHeight;
      const page = document.querySelector(".page-box");
      let tableHeight =
        parseInt(page.height) -
        otherHeight -
        parseInt(page.paddingTop + page.paddingBottom);
      tableHeight -= parseInt(this.tableConfig.rowHeight) * 2;
      const rowNum = parseInt(
        tableHeight / parseInt(this.tableConfig.rowHeight)
      );
      if (rowNum > 0) {
        this.dataSlice = [];
        for (let i = 0; i < this.dataSources.length; i += rowNum) {
          this.dataSlice.push([
            i,
            i + rowNum > this.dataSources.length
              ? this.dataSources.length
              : i + rowNum
          ]);
        }
      }
      this.$emit("mounted");
    },
    computedFooter() {
      const countSlice = [];
      this.dataSlice.map(item => {
        let count = 0;
        let maxLength = 2;
        this.dataSources
          .slice(item[0], item[1])
          .map(item => (count += item[this.tableConfig.countField]));
        const result = [
          chineseTransform(count.toFixed(maxLength)),
          count.toFixed(maxLength)
        ];
        return countSlice.push(result);
      });
      this.countSlice = countSlice;
    },
    async printPDF({
      dom = "#print-preview",
      type = "",
      name = "content.pdf"
    } = {}) {
      const element = document.querySelector(dom);
      console.log(element, "element");
      const page = document.querySelector(".page-box");
      const pageWidth = parseInt(page.offsetWidth);
      const pageHeight = parseInt(page.offsetHeight);
      var opts = {
        scale: 2,
        logging: true,
        width: element.clientWidth,
        height: element.clientHeight
      };
      const PDF = new JsPDF("l", "px", [
        pageWidth * this.scale,
        pageHeight * this.scale
      ]);
      return html2Canvas(element, opts).then(canvas => {
        const contentWidth = opts.width;
        const contentHeight = opts.height;
        const imgWidth = 595.28 * this.scale;
        const imgHeight = (imgWidth / contentWidth) * contentHeight;
        let leftHeight = element.offsetHeight;
        let position = 0;
        const pageData = canvas.toDataURL("image/jpeg", 1.0);
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= imgHeight / this.dataSlice.length;
            //避免添加空白页
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        if (type === "PDF") {
          PDF.save(name);
          return true;
        }
        return PDF.output("datauristring");
      });
    }
  },
  data() {
    return {
      dataSlice: [[]],
      countSlice: []
    };
  },
  mounted() {
    setTimeout(() => {
      this.computedSlice();
      this.computedFooter();
    }, 500);
  }
};
</script>
<style lang="scss" scoped>
.p-preview {
  transform-origin: 0 0 0;
  .el-row {
    display: flex;
    align-items: center;
  }
  table {
    text-align: center;
  }
}
.m-form-printer,
.m-form-maker {
  * {
    padding: 0;
    margin: 0;
    font-weight: inherit;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .page-box {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #fff;
    margin: 0;
  }
  .config-box {
    position: relative;
    > div {
      margin: 0 auto;
    }
  }
  .content-box {
    position: relative;
    overflow: hidden;
  }
  transform-origin: 0 0 0;
  .el-row {
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  table {
    text-align: center;
    border: 1px solid #000;
  }
  .row-float {
    position: absolute;
    z-index: 1;
  }
  pre {
    text-indent: 0;
  }
  .el-col span {
    white-space: nowrap;
  }
  .el-col p {
    white-space: nowrap;
  }
  .el-icon-close {
    position: absolute;
    right: 2px;
    display: none;
    color: #666;
    cursor: pointer;
  }
}
</style>
