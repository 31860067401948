export const SalesAndInvoice = () =>
  import('~/views/Ord/SalesAndInvoice' /* webpackChunkName: "pages/ord" */)

export const WaitStkInList = () =>
  import('~/views/Ord/WaitStkInList' /* webpackChunkName: "pages/ord" */)
export const WaitStkOutList = () =>
  import('~/views/Ord/WaitStkOutList' /* webpackChunkName: "pages/ord" */)

export const ExpressOrderAdd = () =>
  import('~/views/Ord/ExpressOrderAdd' /* webpackChunkName: "pages/stk" */)
export const ExpressOrderManage = () =>
  import('~/views/Ord/ExpressOrderManage' /* webpackChunkName: "pages/stk" */)

// export const ZNLTrack =() => import('~/views/Ord/Track' /* webpackChunkName: "pages/ord" */)
// export const OrdPurchaseOrderAdd = () => import('~/views/Ord/OrdPurchaseOrderAdd' /* webpackChunkName: "pages/ord" */)
// export const OrdBomMasterAdd = () => import('~/views/Ord/BomMasterAdd' /* webpackChunkName: "pages/ord" */)
// export const HisOfferPrice = () => import('~/views/Ord/HisOfferPrice' /* webpackChunkName: "pages/ord" */)
