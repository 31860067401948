import {
  CrmCustomers,
  CrmSuppliers,
  CrmTerminalCustomerListing
} from '~/views/Crm'

import {
  StsCustomerAly
} from '~/views/Sts'

export default {
  path: '/crm',
  name: 'Crm',
  component: CrmCustomers,
  meta: {
    title: 'CRM',
    authName: 'CRM',
    icon: 'icon-left_nav_crm_ic'
  },
  children: [{
    path: 'crmcustomers',
    name: 'Crm/CrmCustomers',
    component: CrmCustomers,
    meta: {
        title: '客户',
        authName: '客户管理'
      }
  },
  {
    path: 'CustomerAly',
    name: 'Sts/CustomerAly',
    component: StsCustomerAly,
    meta: {
        title: '客户分析',
        authName: '客户分析',
        display: false
      }
  },
  {
    path: 'crmsuppliers',
    name: 'Crm/CrmSuppliers',
    component: CrmSuppliers,
    meta: {
        title: '供应商',
        authName: '供应商管理'
      }
  },
  {
    path: 'terminalcustomerlisting',
    name: 'Crm/CrmTerminalCustomerListing',
    component: CrmTerminalCustomerListing,
    meta: {
        title: '终端客户名录',
        authName: '终端客户名录',
        display: false
      }
  }
  ]
}
