<template>
  <div>
    <znl-dialog
      :visible="visible"
      v-loading="pageLoading"
      element-loading-text=""
      width="700px"
      height="330px"
      class="batch-set-sales-price-box"
      title="一键设置销售价"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :is-footer-show="false"
      :close-on-press-escape="false"
      @close="
        () => {
            $emit('close', this.isRefresh);
        }
      "
    >
      <div slot="znl-dialog" class="stock-summary-sales-price-set-box">
        <el-row>
          <el-col>
            <span>单价来源：</span>
            <el-select v-model="salesPriceSource" placeholder="请选择" @change="changeSpecText()" size="mini">
              <el-option
                v-for="item in salesPriceOptions"
                :key="item.key"
                :label="item.value"
                :value="item.key">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <span>查询条件：</span>
          <el-checkbox v-model="matchType.model" @change="changeSpecText('model')" :disabled="true">型号</el-checkbox>
          <el-checkbox v-model="matchType.brand" @change="changeSpecText('brand')">品牌</el-checkbox>
          <el-checkbox v-model="matchType.packaging" @change="changeSpecText('packaging')">封装</el-checkbox>
          <el-checkbox v-model="matchType.makeYear" @change="changeSpecText('makeYear')">年份</el-checkbox>
          <span>
            (相同就去时间最近的那一条)
          </span>
        </el-row>
        <el-row>
          <el-col>
            <span>更新方式：</span>
            <label>
              <el-checkbox v-model="onlyUpdateNoSalesPrice" @change="changeSpecText()">
                只更新没有销售价的库存
              </el-checkbox>
            </label>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="spec-text">
            更新说明：<div v-html="specText" class="spec-text-content"></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="enter">
            <el-button type="danger" @click="onStartSet()" size="mini" style="padding: 8px 9px;" :loading="isPriceSetting">开始设置</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            当前进度：
            <br>
            <el-progress :percentage="percentage" :color="customColorMethod" :stroke-width="26" ></el-progress>
          </el-col>
        </el-row>
      </div>

    </znl-dialog>
  </div>
</template>

<script>
export default {
  name: "stock-summary-batch-sales-price",
  components: {
  },
  data() {
    return {
      config: {
        batchUpdateUrl: "StockSummary/BatchUpdateSalesPrice",
      },
      isRefresh: false,
      pageLoading: false,
      salesPriceOptions: [
        {key: "stkout", value: "发货单未税价"},
        {key: "stock", value: "批次库存销售定价"},
      ],
      salesPriceSource: "stkout",
      matchType: {
        model: true, brand: true, packaging: true, makeYear: true
      },
      onlyUpdateNoSalesPrice: true,
      specText: "根据型号+品牌+封装+年份，去发货单查询最近的销售价（未税），并更新到库存",
      isPriceSetting: false,
      percentage: 0,
      customColor: '#409eff',
      customColors: [
        {color: '#f56c6c', percentage: 20},
        {color: '#e6a23c', percentage: 40},
        {color: '#5cb87a', percentage: 60},
        {color: '#1989fa', percentage: 80},
        {color: '#6f7ad3', percentage: 100}
      ],
    };
  },
  props: {
    visible: Boolean,
  },
  mounted() {
    this.onInit();
  },
  methods: {
    async onInit() {
      this.changeSpecText()
    },
    onCancel() {
      this.$emit("close");
    },

    onMatchTypeChange(val) {
    },

    onStartSet () {
      let msg = "确定设置";
      if (this.onlyUpdateNoSalesPrice) {
        msg += "没有销售价的汇总库存";
      } else {
        msg += "全部汇总库存的销售价";
      }
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          SalesPriceSource: this.salesPriceSource,
          OnlyUpdateNoSalesPrice: this.onlyUpdateNoSalesPrice,
          LastSumId: 0,
          MatchBrand: this.matchType.brand,
          MatchPackaging: this.matchType.packaging,
          MatchMakeYear: this.matchType.makeYear,
          PageIndex: 0,
        }
        this.isPriceSetting = true;
        this.StartLoop(param);
      }).catch(() => {
        this.isPriceSetting = false;
      });
    },
    async StartLoop(param) {
      let cnt = 0;
      while (cnt < 100000) {
        param.PageIndex++;
        cnt++;
        var resp = await this.fetchSalesPrice(param);
        // 设置进度条
        let v = resp.PageIndex / resp.TotalPage * 100;
        this.percentage = Math.round(v);
        if (this.percentage > 100) this.percentage = 100;
        param.LastSumId = resp.LastSumId;

        if (resp && resp.TotalPage > resp.PageIndex) {
          // await new Promise(resolve => setTimeout(resolve, 1000));
        } else {
          this.$message({message: "更新完成", type:"success"});
          break;
        }
      }
      this.isPriceSetting = false;
    },
    async fetchSalesPrice(param) {
      try {
        let d;
        await this.$post(this.config.batchUpdateUrl, param, (data, logic) => {
          if (logic.code !== 200) {
            this.$message({
              message: logic.msg || '操作失败,请联系系统管理员',
              type: 'error'
            })
            return false;
          }
          d = data;
        })
        return d;
      } catch (e) {
        console.log(e, 1234);
        return null;
      }
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return '#909399';
      } else if (percentage < 70) {
        return '#e6a23c';
      } else {
        return '#67c23a';
      }
    },
    changeSpecText() {
      let text = "将根据<span>型号</span>";
      if (this.matchType.brand) {
        text += "+<span>品牌</span>";
      }
      if (this.matchType.packaging) {
        text += "+<span>封装</span>";
      }
      if (this.matchType.makeYear) {
        text += "+<span>年份</span>";
      }
      console.log(this.salesPriceSource, 'sales price source');
      if (this.salesPriceSource === "stock") {
        text += "去<span>批次库存</span>查询最近的销售定价";
      } else {
        text += "去<span>发货单</span>查询最近的销售价（未税）";
      }
      text += "，并";
      if(this.onlyUpdateNoSalesPrice) {
        text += "更新到<span>没有设置销售定价</span>的汇总库存";
      } else {
        text += "<span>更新所有</span>汇总库存";
      }
      this.specText = text;
    },
  },
};
</script>

<style lang="scss">
.export-format-box .el-dialog,
.export-format-box .znl-dialog {
  .mt20 {
    margin-top: 10px;
  }
  .stock-extend-set {
    .radios span {
      font-size: 12px;
    }
    .mleft {
      margin-left: 16px;
    }
    .col2 {
      margin-left: 20px;
    }
    .ml20 {
      margin-left: 20px;
    }
    .stk-hr {
      margin-top: 0;
      margin-bottom: 0;
    }
    .el-col-24 {
      padding: 5px 0;
    }
    .merge-conditions {
      padding: 10px 0;
      span {
        color: #606266;
        font-weight: 500;
        line-height: 1;
        white-space: nowrap;
        outline: 0;
      }
      .op-tips {
        color: #409eff;
      }
    }
  }
  .el-checkbox {
    margin-right: 10px !important;
  }
  .znl-dialog__footer {
    height: 50px !important;
  }
}
.stock-summary-sales-price-set-box{
  font-size: 12px;
  padding: 0 10px;
  .spec-text {
    padding: 10px;
    font-size: 16px;
    // border: dotted;
    // margin: 10px 0;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    .spec-text-content{
      display: contents;
      >span{
        font-weight: bolder;
      }
    }
  }
  .center{
    text-align: center;
  }
  .el-progress__text {
    position: absolute;
    right: -10px;
    padding-top: 3px;
  }
}
</style>
