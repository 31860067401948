<template>
  <div class="stocksum-set-subscript">
    <znl-dialog
      :visible="visible&&dialogShow"
      width="280px"
      height="280px"
      class="subscription-set-box"
      title="设置订阅库存类型"
      :on-init="onInit"
      @close="()=>{this.dialogShow = false, this.isRefresh = !this.isRefresh, $emit('close')}"
    >
      <div slot="znl-dialog" class="stock-extend-set">
        <el-row>
          <el-col>
            <el-row>
              <el-col>
                <el-radio
                  v-model="filterType"
                  label="1"
                  :fill="fillColor"
                >按已选择 (已选中 {{checkedRows.length}} 条)</el-radio>
              </el-col>
              <el-col>
                <el-radio
                  v-model="filterType"
                  label="2"
                  :fill="fillColor"
                >按搜索条件 (共 {{filterRowCount}} 条)</el-radio>
              </el-col>
            </el-row>
          </el-col>
          <el-col class="mt20">
            <el-row>
              <hr class="stk-hr">
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="mt20">
            <el-row class="title">
              <el-col>订阅类型</el-col>
            </el-row>
            <el-row>
              <!-- <el-col v-for="st in subscriptTypeOptions" :key="st.key">
              <el-radio v-model="subscriptType" :label="st.key" :class="st.class">{{st.value}}</el-radio>
              </el-col>-->
              <el-col class="center">
                <!-- <el-checkbox v-model="stypeALL" @change="typeChange(0)" class="ckall">所有</el-checkbox> -->
                <el-checkbox-group v-model="subscriptTypeChecked">
                  <el-checkbox
                    v-for="st in subscriptTypeOptions"
                    :label="st.key"
                    :key="st.key"
                  >{{st.value}}</el-checkbox>
                </el-checkbox-group>
              </el-col>
            </el-row>
            <el-row class="title center">
              <el-col>当没有选择类型时，表示取消订阅</el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <el-row slot="footer">
        <znl-button
          style-type="main"
          :height="30"
          :width="60"
          :disabled="loading"
          @click="onConfirm"
        >
          <span>确认设置</span>
        </znl-button>
      </el-row>
    </znl-dialog>
  </div>
</template>

<script>
import { mixin as propSyncMixin } from '@scripts/mixins/propSync'

export default {
  name: 'SubscriptionSet',
  mixins: [propSyncMixin],
  components: {
  },
  data() {
    return {
      config: {
        setSubscriptionURL: 'StockSummary/SetSubscription',
        getCountURL: 'StockSummary/GetCount'
      },
      dialogShow: false,
      isRefresh: false,
      stypeALL: false, // 全选
      subscriptTypeOptions: [{ key: 1, value: 'A', class: 'color-glod' }, { key: 2, value: 'B', class: 'color-silver' }, { key: 3, value: 'C', class: 'color-copper' }],
      subscriptTypeChecked: [],
      fillColor: '#F70',
      filterType: '1', // 设置条件 1: 已选择， 2: 按搜索条件
      filterRowCount: 0
    }
  },
  props: {
    height: String,
    loading: Boolean,
    dialogVisible: {
      type: Boolean,
      default: false,
      propsync: true
    },
    visible: Boolean,
    checkedRows: { // 选中的行
      type: Array,
      default: () => { return [] }
    },
    searchCondition: { // 查询条件
      type: Object,
      default: () => { return {} }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.dialogShow = true
        this.isRefresh = !this.isRefresh
        this.getCount()
      }
    }
  },
  methods: {
    onInit: async function () {
    },
    onCancel() {
      this.p_dialogVisible = false
      this.$emit('close')
    },
    onConfirm() {
      if (this.filterType === '1' && this.checkedRows.length === 0) {
        this.$message({ message: '请先选择库存', type: 'error' })
        return false
      }
      // if (this.subscriptTypeChecked.length === 0) {
      //   this.$message({ message: '请选择一个订阅类型', type: 'error' })
      //   return false
      // }
      let param = {
        FilterType: this.filterType,
        SubscriptionType: this.subscriptTypeChecked.join(',')
      }
      // 未勾选 表示取消订阅
      if (this.subscriptTypeChecked.length === 0) {
        param.SubscriptionType = '-1'
      }
      if (this.filterType === '1') {
        param.SUMGuids = _.map(this.checkedRows, m => { return m.SUMGuid })
      } else {
        param = _.extend(this.searchCondition, param)
      }
      this.$post(this.config.setSubscriptionURL, param, (data, logic) => {
        if (logic.code === 200) {
          let msg = '已成功订阅库存 ' + data + ' 条'
          if (param.SubscriptionType === '-1') {
            msg = '已成功取消 ' + data + ' 条订阅库存'
          }
          this.$message({ message: msg, type: 'success' })
        } else if (this.hasValue(logic.msg)) {
          this.$message({ message: logic.msg, type: 'error' })
        } else {
          this.$message({ message: '设置订阅库存失败', type: 'error' })
        }
        this.p_dialogVisible = false
        this.$emit('confirm', true)
      })
    },
    async getCount() {
      this.filterRowCount = 0
      let param = {
        FilterType: '2'
      }
      param = _.extend(this.searchCondition, param)
      this.filterRowCount = await this.$post(this.config.getCountURL, param)
    }
  },
  created() {
  }
}
</script>

<style lang="scss">
.stocksum-set-subscript {
  .mleft {
    padding-left: 20px;
  }
  .el-checkbox {
    margin: 0 10px !important;
  }
  .center {
    text-align: center;
  }
  .title {
    font-size: 12px;
  }

  .ckall {
    position: absolute;
    margin-left: 17px !important;
  }

  .el-checkbox-group {
    .el-checkbox:first-child {
      margin-left: 0 !important;
    }
  }
  .stock-extend-set {
    padding-left: 10px;
  }
}
</style>
