const info = {
  IsModifyInvQty: false,
  TotleGrossProfit: 0.0,
  OrderCount: 0,
  IsManual: false,
  IsAdd: false,
  IsAllowOut: false,
  PurchaseAmount: 0.0,
  TaxAmount: 0.0,
  StkOutID: 1289642,
  CompanyCode: 1289642,
  StkOutGUID: "4240267a-c945-41f8-9510-f00c143d570f",
  BillNo: "CK20190900007",
  SalCompany: "中国正能量有限公司",
  CompanyName: "中国正能量有限公司",
  SubCompanyName: "中国正能量有限公司",
  SupplierName: "中国正能量有限公司",
  CustomerGUID: "42d18d1a-b4e6-4978-b74a-088c6978b585",
  CustomerName: "test-output",
  Contact: "2",
  CurrencyCode: "HKD",
  ExchangeRate: 0.9133,
  TaxRate: 6.0,
  Address: "goods addresss",
  Email: "4152046432@qq.com",
  ShipTime: "2019-09-06 14:26:26",
  ReceiveTimeStr: "2019-09-06 14:26:26",
  ReconcileDate: "2019-09-06 14:26:26",
  ShipUserID: 59753,
  Shipper: "cxp123",
  CreatedTime: "2019-09-06 14:24:15",
  PODate: "2019-09-06 14:24:15",
  CreatedByID: 59753,
  CreatedByName: "cxp123",
  IsActive: 2,
  CompanyID: "ede45b8d-705b-4dec-a053-427c0e3bb0f7",
  Fax: "18718518397",
  Telephone: "82281988",
  Status: 3,
  GrossProfit: -895.21,
  Amount: 0.0,
  OtherFeeAmount: 0.0,
  ReceivedAmount: 0.0,
  DiscountAmount: 0.0,
  ReceiptType: "周结",
  NeedDeliveryDate: "随缘",
  ShareFeeTotal: 0.0,
  Mobile: "13712345678",
  UpdateTime: "2019-09-06 14:27:15",
  UpdateByName: "cxp123",
  UpdateByID: 59753,
  AllUpSalesAmount: 59753,
  StkOutLineID: 0,
  BillsType: 0,
  SalesPriceClause_CN:
    "备注：\n(1) 请在收到货物后如有疑问，请在当他收货验货提出， 否则视为验收合格。\n(2) 产品包装如果有拆过以及损坏或上机安装后拆回货物一律不予退换\n(3) 请检查产品后再上记，如有发现品质问题，请及时与公司有关负责人联系。"
};
const dataSources = [];
[...Array(1)].map((item, index) =>
  dataSources.push({
    PurchaseAmount: 895.21,
    TotalAmount: 0.0,
    AmountNoTax: 0.0,
    PrintAmount: 0.0,
    IsDelete: false,
    RowGuid: 0,
    ThisReceive: 0.0,
    ThisDiscount: 0.0,
    ProfitMargins: "",
    UnReceiveAmount: 0.0,
    StkOutLineID: 2751602,
    StkOutGUID: "4240267a-c945-41f8-9510-f00c143d570f",
    StkOutLineGUID: "70ab6ae5-bfbb-40dd-9840-5a595d86428b",
    SerialNo: 1,
    StkGUID: "d13a8f96-5a6c-42e9-9ef7-9f41c0565ae8",
    Model: "DUDU001" + index,
    SpModel: "DUDU001",
    Brand: "ST",
    Packaging: "TSSOP20",
    MakeYear: "19+",
    Qty: 25.0,
    Price: 35.8085,
    PriceInTax: 3.0,
    InDate: "2019-09-06",
    ShipTimeStr: "2019-09-06",
    BuyPrice: 35.8085,
    BuyerName: "cxp123",
    Amount: 35.8085,
    GrossProfit: -895.21,
    Unit: "PCS",
    SupplierInfo: "深圳市科美业电子发展有限公司",
    InvType: "自有",
    CompanyID: "ede45b8d-705b-4dec-a053-427c0e3bb0f7",
    IsActive: 2,
    SalesModel: "DUDU001",
    Status: 3,
    SalesQty: 23.0,
    DiscountAmount: 0.0
  })
);
export default { info, dataSources };
