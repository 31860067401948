export const StockVMI = () => import('~/views/Stk/StockVMI' /* webpackChunkName: "pages/stk" */ )
export const StkOut = () => import('~/views/Stk/Out' /* webpackChunkName: "pages/stk" */ )
export const StkIn = () => import('~/views/Stk/In' /* webpackChunkName: "pages/stk" */ )
export const Stock = () => import('~/views/Stk/StockSet' /* webpackChunkName: "pages/stk" */ )
export const StockAll = () => import('~/views/Stk/StockAll' /* webpackChunkName: "pages/stk" */ )

// export const StockManage = () => import('~/views/Stk/StockManage' /* webpackChunkName: "pages/stk" */)
export const StockManage = () => import('~/views/Stk/StockManage' /* webpackChunkName: "pages/stk" */ )

export const StockReserve = () => import('~/views/Stk/StkReserve' /* webpackChunkName: "pages/stk" */ )
export const StockPurchase = () => import('~/views/Stk/StkPurchase' /* webpackChunkName: "pages/stk" */ )
export const StkInquireRecord = () => import('~/views/Stk/StkInquireRecord' /* webpackChunkName: "pages/ord" */ )
export const TencentQDW = () => import('~/views/Stk/TencentQDW' /* webpackChunkName: "pages/ord" */ )
export const TencentQDW_Inquiry = () => import('~/views/Stk/TencentQDW_Inquiry' /* webpackChunkName: "pages/ord" */ )
export const StockSum = () => import('~/views/Stk/StockSum' /* webpackChunkName: "pages/ord" */ )
export const TencentQDW_About = () => import('~/views/Stk/TencentQDW_About' /* webpackChunkName: "pages/ord" */ )
export const StkFileLibrary = () => import('~/views/Stk/StkFileLibrary' /* webpackChunkName: "pages/ord" */ )
export const StkStockSummary = () => import('~/views/Stk/StkStockSummary' /* webpackChunkName: "pages/ord" */ )

