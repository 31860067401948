const CONFIG = {
  geUserAccountURL: 'CompanyAccount/GetAllUser',
  CurrencyCodeURL: 'SysCurrency/Search',
  SundryFeesTypeURL: 'DictItem/GetItemValue',
  DropDownURL: 'DictItem/GetItemValue',
  getWarehouseURL: 'StkWarehouse/Search', // 仓库列表
  getBankAccountURL: 'FinBankAccount/GetAccounts', // 银行账号
  getSpecialResourceURL: 'UserResource/GetSpecialResource', // 获取用户特殊权限获取用户特殊权限
  getCompanySetupParamURL: 'CompanyParamSetup/GetConfigs', // 获取公司配置
  getUserSubCompanyURL: 'UserSubCompany/GetSubCompanyList', // 获取子公司列表
  hasLoanServiceUrl: "Risk/HasLoanService", // 是否有正能量月结的资质
  getTransportCompanyUrl: "CompanyParamSetup/GetTransportCompany" // 获取快递公司
}
const mixin = {
  data() {
    return {
      // 推广库存展示类型
      selectOptionsSType: [{
        value: '正品企业',
        key: 4
      },
      {
        value: '正品物料',
        key: 8
      },
      {
        value: '保证有料',
        key: 6
      },
      {
        value: '正品期货',
        key: 5
      },
      {
        value: '优势推广',
        key: 9
      },
      {
        value: '云价格推广',
        key: 3
      }
      ],

      // 展示状态
      selectOptionsSStatus: [{
        value: '未展出',
        key: false
      },
      {
        value: '已展出',
        key: true
      }
      ]
    }
  },
  computed: {
    selectOptionsCurrencyCode() { // 币种
      if (this.$store.state.commonDatas.CurrencyCodeOptions.length === 0) {
        this.$store.state.requestStates.isGetCurrencyCodeEnd && this.getCurrencyCode()
      }
      return this.$store.state.commonDatas.CurrencyCodeOptions
    },
    async selectSundryFeesType() { // 杂费费用类型
      if (this.$store.state.commonDatas.SundryFeesType.length === 0) {
        this.$store.state.requestStates.isGetSundryFeesType && await this.getSundryFeesType()
      }
      return this.$store.state.commonDatas.SundryFeesType
    },
    getCurrencyCodeListAsync() { // 币种
      if (this.$store.state.commonDatas.CurrencyCodeOptions.length === 0) {
        this.$store.state.requestStates.isGetCurrencyCodeEnd && this.getCurrencyCode()
      }
      return this.$store.state.commonDatas.CurrencyCodeOptions
    },
    async selectStorageType() { // 库存类型
      if (this.$store.state.commonDatas.StorageType.length === 0) {
        this.$store.state.requestStates.isGetStorageTypeEnd && await this.getStorageType()
      }
      return await this.$store.state.commonDatas.StorageType
    },
    selectOptionsTaxRate() { // 税点
      if (this.$store.state.commonDatas.TaxRateOptions.length === 0) {
        this.$store.state.requestStates.isGetTaxRateEnd && this.getTaxRate()
      }
      return this.$store.state.commonDatas.TaxRateOptions
    },
    async getTaxRateListAsync() {
      if (this.$store.state.commonDatas.TaxRateOptions.length === 0) {
        this.$store.state.requestStates.isGetTaxRateEnd && await this.getTaxRate()
      }
      return this.$store.state.commonDatas.TaxRateOptions
    },
    selsectOptionsDemandSources() { // 需求来源
      if (this.$store.state.commonDatas.DemandSources.length === 0) {
        this.$store.state.requestStates.isDemandSources && this.getDemandSources()
      }
      return this.$store.state.commonDatas.DemandSources
    },
    selectOptionsAllUserURL() { // 人员列表
      if (this.$store.state.commonDatas.AllUserURL.length === 0) {
        this.$store.state.requestStates.fetchingAppUsers && this.getAllUserURL()
      }
      return this.$store.state.commonDatas.AllUserURL
    },

    selectOptionsAllAccount() {
      if (this.$store.state.commonDatas.AllAccount.length === 0) {
        this.$store.state.requestStates.fetchingAppUsers && this.getAllUserURL()
      }

      return this.$store.state.commonDatas.AllAccount
    },

    selectOptionsPaymentTypeOptions() { // 结算方式
      if (this.$store.state.commonDatas.PaymentTypeOptions.length === 0) {
        this.$store.state.requestStates.isGetReceiptTypeEnd && this.getReceiptType()
      }
      return this.$store.state.commonDatas.PaymentTypeOptions
    },
    getRecPayTypeListAsync() {
      if (this.$store.state.commonDatas.PaymentTypeOptions.length === 0) {
        this.$store.state.requestStates.isGetReceiptTypeEnd && this.getReceiptType()
      }
      return this.$store.state.commonDatas.PaymentTypeOptions
    },
    async selectOptionsQtyUnit() { // 单位
      if (this.$store.state.commonDatas.QtyUnit.length === 0) {
        this.$store.state.requestStates.isGetQtyUnitEnd && await this.getQtyUnit()
      }
      return await this.$store.state.commonDatas.QtyUnit
    },
    async selectOptionsQuality() { // 品质
      if (this.$store.state.commonDatas.Quality.length === 0) {
        this.$store.state.requestStates.isGetQualityEnd && await this.getQuality()
      }
      console.log(this.$store.state.commonDatas.Quality, '检查数据')
      return await this.$store.state.commonDatas.Quality
    },
    selectOptionsWarehouseOptions() { // 仓库列表
      if (this.$store.state.commonDatas.WarehouseOptions.length === 0) {
        this.$store.state.requestStates.isGetWarehouseEnd && this.getWarehouse()
      }
      return this.$store.state.commonDatas.WarehouseOptions
    },
    selectOptionsTransportCompany() { // 快递公司
      if (this.$store.state.commonDatas.TransportCompany.length === 0) {
        this.$store.state.requestStates.isGetTransportCompanyEnd && this.getTransportCompany()
      }
      return this.$store.state.commonDatas.TransportCompany
    },
    async selectOptionsTransportCompanyAsync() { // 快递公司，等待的
      if (this.$store.state.commonDatas.TransportCompany.length === 0) {
        this.$store.state.requestStates.isGetTransportCompanyEnd && await this.getTransportCompany()
      }
      return this.$store.state.commonDatas.TransportCompany
    },
    selectOptionsBankAccount() { // 银行账号
      if (this.$store.state.commonDatas.BankAccount.length === 0) {
        if (this.$store.state.requestStates.isGetBankAccountEnd) {
          this.$store.commit('setRequestStates', {
            type: 'isGetBankAccountEnd',
            datas: false
          })
          this.getBankAccount()
        }
      }
      return this.$store.state.commonDatas.BankAccount
    },

    selectOptionsBankAccountAndName() { // 银行账号
      let result = this.selectOptionsBankAccount;
      let bankAccontOptions = [];
      _.each(result, item => {
        bankAccontOptions.push({
          key: item.key,
          value: `${item.value}(${item.data})`
        });
      });
      return bankAccontOptions;
    },

    async getBankAccountListAsync() { // 银行账号
      if (this.$store.state.commonDatas.BankAccount.length === 0) {
        if (this.$store.state.requestStates.isGetBankAccountEnd) {
          this.$store.commit('setRequestStates', {
            type: 'isGetBankAccountEnd',
            datas: false
          })
          await this.getBankAccount()
        }
      }
      return await this.$store.state.commonDatas.BankAccount
    },

    selectOptionsSpecialResource() { // 特殊权限
      // if (!this.$store.state.accountInfo.IsMainAccount) {
      if (this.$store.state.commonDatas.SpecialResource.length === 0) {
        this.$store.state.requestStates.isGetSpecialResource && this.getSpecialResource()
      }
      return this.$store.state.commonDatas.SpecialResource
      // }
      // return []
    },
    selectStockSearchField() {
      if (this.$store.state.commonDatas.StockSearchField.length === 0) {
        this.$store.state.requestStates.isGetSearchFieldEnd && this.getStockSearchField()
      }
      return this.$store.state.commonDatas.StockSearchField
    },
    // 公司参数配置
    CompanyParamSetup() {
      if (this.$store.state.commonDatas.CompanyParamSetup.length === 0) {
        this.$store.state.requestStates.isGetCompanyParamSetup && this.getCompanySetup()
      }
      return this.$store.state.commonDatas.CompanyParamSetup
    },
    // 获取子公司列表
    selectOptionsUserSubCompany() {
      if (this.$store.state.commonDatas.UserSubCompany.length === 0) {
        this.$store.state.requestStates.isGetUserSubCompany && this.getUserSubCompany()
      }
      return this.$store.state.commonDatas.UserSubCompany
    },
    selectOptionsSalesCompanyInfo() {
      if (this.$store.state.commonDatas.SalesCompanyInfo.length === 0) {
        this.$store.state.requestStates.isGetSalesCompanyInfo && this.getSalesCompanyInfo()
      }
      return this.$store.state.commonDatas.SalesCompanyInfo
    },
    // crm -- 客户热度
    selectOptionsCustomerHeat() {
      if (this.$store.state.commonDatas.CustomerHeat.length === 0) {
        this.$store.state.requestStates.isGetCustomerHeat && this.getCustomerHeat()
      }
      return this.$store.state.commonDatas.CustomerHeat
    },
    // 出库仅允许从销售、采购生成
    onlySalesPurchaseOrder() {
      let val = this.getCompanySetupValue('NewStkOutViaSaleAndBuy')
      return (val === '1' || val === 1)
    },
    // 所属行业
    selectOptionsIndustry() {
      return [
        // { key: '', value: '-请选择-' },
        {
          key: '安防类',
          value: '安防类'
        },
        {
          key: '汽车电子类',
          value: '汽车电子类'
        },
        {
          key: '消费电子类',
          value: '消费电子类'
        },
        {
          key: '通讯类',
          value: '通讯类'
        },
        {
          key: '工控、PLC类',
          value: '工控、PLC类'
        },
        {
          key: '仪器仪表',
          value: '仪器仪表'
        },
        {
          key: '机械设备',
          value: '机械设备'
        },
        {
          key: '照明工业',
          value: '照明工业'
        },
        {
          key: '电源开发',
          value: '电源开发'
        },
        {
          key: '方案设计',
          value: '方案设计'
        },
        {
          key: '计算机与OA',
          value: '计算机与OA'
        },
        {
          key: 'OEM',
          value: 'OEM'
        },
        {
          key: '贸易商',
          value: '贸易商'
        },
        {
          key: '其它',
          value: '其它'
        }
      ]
    },
    // 货物来源
    selectOptionsGoodsSource() {
      return [
        // { key: '', value: '-请选择-' },
        {
          key: '制造',
          value: '制造'
        },
        {
          key: '代理',
          value: '代理'
        },
        {
          key: '现货',
          value: '现货'
        },
        {
          key: '市场',
          value: '市场'
        },
        {
          key: '补货',
          value: '补货'
        }
      ]
    },

    // 所处阶段、成交分类
    selectOptionsCloseClass() {
      return [{
        key: 1,
        value: '重点客户'
      },
      {
        key: 2,
        value: '优质客户'
      },
      {
        key: 3,
        value: '普通客户'
      },
      {
        key: 4,
        value: '意向客户'
      },
      {
        key: 5,
        value: '潜在客户'
      },
      {
        key: 6,
        value: '准客户'
      }
      ]
    },

    // 月结服务会员
    async hasLoanServiceAsync() {
      // 网页版不显示
      let isDebug = location.href.indexOf("://localhost") > 0 || location.href.indexOf("://192.168") > 0
      if (!isDebug && (window.cefQuery === null || window.cefQuery === undefined)) {
        return false;
      }
      if (this.$store.state.commonDatas.hasLoanService === null || this.$store.state.commonDatas.hasLoanService === undefined) {
        let result = await this.getHasLoanService();
        this.$store.commit('setCommonDatas', {
          type: 'hasLoanService',
          datas: result
        })
        return result;
      } else {
        return this.$store.state.commonDatas.hasLoanService;
      }
    }
  },
  methods: {
    async getSundryFeesType() { // 杂费费用类型
      return await this.$post(CONFIG.SundryFeesTypeURL, {
        DictCode: 'SundryFeesType'
      }, data => {
        let SundryFeesType = _.map(data, item => {
          return {
            key: item.ItemValue,
            value: item.ItemName
          }
        })
        this.$store.commit('setRequestStates', {
          type: 'isGetSundryFeesType',
          datas: false
        })
        this.$store.commit('setCommonDatas', {
          type: 'SundryFeesType',
          datas: SundryFeesType
        })
      })
    },
    async getCurrencyCode() { // 币种
      return await this.$post(CONFIG.CurrencyCodeURL, {
        CCode: ''
      }, data => {
        let CurrencyCodeOptions = []
        if (data.length === 0) {
          CurrencyCodeOptions = [{
            key: 'RMB',
            value: '人民币',
            ExchangeRate: 1
          }]
        } else {
          CurrencyCodeOptions = _.map(data, item => {
            return {
              key: item.CCode,
              value: item.CName,
              ExchangeRate: item.ExchangeRate
            }
          })
        }
        this.$store.commit('setRequestStates', {
          type: 'isGetCurrencyCodeEnd',
          datas: false
        })
        this.$store.commit('setCommonDatas', {
          type: 'CurrencyCodeOptions',
          datas: CurrencyCodeOptions
        })
      })
    },
    async getTaxRate() { // 税点
      return await this.$post(CONFIG.DropDownURL, {
        DictCode: 'TaxRate'
      }, data => {
        let TaxRateOptions = _.map(data, items => {
          return {
            key: items.ItemValue - 0,
            value: parseInt(items.ItemName) || items.ItemName
          }
        })
        TaxRateOptions = _.sortBy(TaxRateOptions, item => {
          return item.key
        })
        this.$store.commit('setRequestStates', {
          type: 'isGetTaxRateEnd',
          datas: false
        })
        this.$store.commit('setCommonDatas', {
          type: 'TaxRateOptions',
          datas: TaxRateOptions
        })
      })
    },
    async getStorageType() { // 库存类型
      return await this.$post(CONFIG.DropDownURL, {
        DictCode: 'StorageType'
      }, data => {
        let StorageType = _.map(data, items => {
          return {
            key: items.ItemValue,
            value: items.ItemName
          }
        })
        this.$store.commit('setRequestStates', {
          type: 'isGetStorageTypeEnd',
          datas: false
        })
        this.$store.commit('setCommonDatas', {
          type: 'StorageType',
          datas: StorageType
        })
      })
    },

    async getAllUserURL() { // 人员列表
      return await this.$post(CONFIG.geUserAccountURL, {}, data => {
        let users = _.filter(data, item => {
          return item.value !== ''
        })
        this.$store.commit('setRequestStates', {
          type: 'fetchingAppUsers',
          datas: false
        })
        let AllUserURL = _.map(users, items => {
          return {
            key: items.UserID,
            value: items.UserName
          }
        })
        let AllAccount = _.map(users, items => {
          return {
            key: items.UserGUID,
            value: items.UserName
          }
        })
        this.$store.commit('setCommonDatas', {
          type: 'AllUserURL',
          datas: AllUserURL
        })
        this.$store.commit('setCommonDatas', {
          type: 'AllAccount',
          datas: AllAccount
        })
      })
    },
    async getReceiptType() { // 结算方式
      return await this.$post(CONFIG.DropDownURL, {
        DictCode: 'ReceiptType'
      }, data => {
        let PaymentTypeOptions = _.map(data, items => {
          return {
            key: items.ItemValue,
            value: items.ItemName
          }
        })
        this.$store.commit('setRequestStates', {
          type: 'isGetReceiptTypeEnd',
          datas: false
        })
        this.$store.commit('setCommonDatas', {
          type: 'PaymentTypeOptions',
          datas: PaymentTypeOptions
        })
      })
    },
    async getQtyUnit() { // 单位
      return await this.$post(CONFIG.DropDownURL, {
        DictCode: 'QtyUnit'
      }, data => {
        let QtyUnit = _.map(data, items => {
          return {
            key: items.ItemValue,
            value: items.ItemName
          }
        })
        this.$store.commit('setRequestStates', {
          type: 'isGetQtyUnitEnd',
          datas: false
        })
        this.$store.commit('setCommonDatas', {
          type: 'QtyUnit',
          datas: QtyUnit
        })
      })
    },
    async getStockSearchField() { // 获取销售记录参数
      this.$post(CONFIG.DropDownURL, {
        DictCode: 'StockSearchField'
      }, data => {
        // 移除型号、品牌、封装
        // let removeFields = ['Model', 'Brand', 'Packaging']
        // let Fields = _.filter(data, item => !_.includes(removeFields, item.ItemValue))
        let StockSearchField = _.map(data, items => {
          return {
            key: items.ItemValue,
            value: items.ItemName
          }
        })
        this.$store.commit('setRequestStates', {
          type: 'isGetSearchFieldEnd',
          datas: false
        })
        this.$store.commit('setCommonDatas', {
          type: 'StockSearchField',
          datas: StockSearchField
        })
      })
    },
    async getQuality() { // 品质
      return await this.$post(CONFIG.DropDownURL, {
        DictCode: 'Quality'
      }, data => {
        let Quality = _.map(data, items => {
          return {
            key: items.ItemValue,
            value: items.ItemName
          }
        })
        this.$store.commit('setRequestStates', {
          type: 'isGetQualityEnd',
          datas: false
        })
        this.$store.commit('setCommonDatas', {
          type: 'Quality',
          datas: Quality
        })
      })
    },
    async getTransportCompany() { // 快递公司
      await this.$post(CONFIG.getTransportCompanyUrl, {}, data => {
        let TransportCompany = _.map(data, item => {
          return {
            key: item,
            value: item
          }
        })
        this.$store.commit('setRequestStates', {
          type: 'isGetTransportCompanyEnd',
          datas: false
        })
        this.$store.commit('setCommonDatas', {
          type: 'TransportCompany',
          datas: TransportCompany
        })
      })
    },
    async getWarehouse() { // 仓库列表
      return this.$post(CONFIG.getWarehouseURL, {}, (data) => {
        let WarehouseOptions = _.map(data, items => {
          return {
            key: items.WarehouseName,
            value: items.WarehouseName
          }
        })
        this.$store.commit('setRequestStates', {
          type: 'isGetWarehouseEnd',
          datas: false
        })
        this.$store.commit('setCommonDatas', {
          type: 'WarehouseOptions',
          datas: WarehouseOptions
        })
      })
    },
    async getBankAccount() { // 银行账号列表
      return await this.$post(CONFIG.getBankAccountURL, {}, (data) => {
        let bankAccount = _.map(data, items => {
          return {
            key: items.CBGUID,
            value: items.CBName,
            data: items.CBNo
          }
        })
        this.$store.commit('setCommonDatas', {
          type: 'BankAccount',
          datas: bankAccount
        })
      })
    },
    async getSpecialResource() { // 获取用户特殊权限
      return this.$post(CONFIG.getSpecialResourceURL, {}, (data) => {
        this.$store.commit('setRequestStates', {
          type: 'isGetSpecialResource',
          datas: false
        })
        this.$store.commit('setCommonDatas', {
          type: 'SpecialResource',
          datas: data
        })
      })
    },
    // OnlySelfStock 只看自己入库的库存
    // NoSeeProfit   不看利润
    // NoSeeStockLog  不看库存日志
    // NoSeeSupplier 不看供应商资料
    // NoSeeCustomer 不看客户资料
    // ViewBuyPrice 看采购价
    // ViewSalesPrice 看销售价
    // NoSeeProfitRate 不看利率
    // NoSeeSupplierContactInfo	不看供应商联系资料
    // NoSeeCustomerContactInfo	不看客户联系资料
    getSpecialResourceByCode(code) { // 根据code获取用户特殊权限.
      let result = _.some(this.selectOptionsSpecialResource, item => item.Code === code)
      return result
    },

    async getCompanySetup() { // 获取公司配置
      return this.$post(CONFIG.getCompanySetupParamURL, {}, (data) => {
        this.$store.commit('setRequestStates', {
          type: 'isGetCompanyParamSetup',
          datas: false
        })
        this.$store.commit('setCommonDatas', {
          type: 'CompanyParamSetup',
          datas: data
        })
      })
    },

    async getUserSubCompany() { // 获取子公司列表
      return this.$post(CONFIG.getUserSubCompanyURL, {}, (data) => {
        let SubCompanyInfo = _.map(data, items => {
          return {
            key: items.SubCompanyName,
            value: items.SubCompanyName
          }
        })
        this.SubCompanyInfo = _.filter(SubCompanyInfo, item => {
          return item.value !== ''
        })
        this.$store.commit('setRequestStates', {
          type: 'isGetUserSubCompany',
          datas: false
        })
        this.$store.commit('setCommonDatas', {
          type: 'UserSubCompany',
          datas: SubCompanyInfo
        })
      })
    },

    async getSalesCompanyInfo() { // 获取销售公司信息
      return this.$post(CONFIG.getUserSubCompanyURL, {}, (data) => {
        let salesCompanyInfo = _.map(data, items => {
          return {
            key: items.SubCompanyID,
            value: items.SubCompanyName,
            BankName: items.BankName, // 开户行
            BankAccount: items.BankAccount, // 银行账号
            TaxNumber: items.TaxNumber, // 纳税人识别号
            Address1: items.Address1, // 地址
            Telephone: items.Telephone // 电话
          }
        })
        this.SubCompanyInfo = _.filter(salesCompanyInfo, item => {
          return item.value !== ''
        })
        this.$store.commit('setRequestStates', {
          type: 'isGetSalesCompanyInfo',
          datas: false
        })
        this.$store.commit('setCommonDatas', {
          type: 'SalesCompanyInfo',
          datas: salesCompanyInfo
        })
      })
    },

    // 获取公司指定配置的value;  如果获取不到，则返回空字符串
    getCompanySetupValue(code) {
      let val = this.CompanyParamSetup[code]
      if (_.isUndefined(val) || val === null) {
        return ''
      }

      return val
    },

    async getDemandSources() { // 需求来源
      this.$store.commit('setRequestStates', {
        type: 'isDemandSources',
        datas: false
      })

      return await this.$post(CONFIG.DropDownURL, {
        DictCode: 'RequestSource'
      }, data => {
        let DemandSources = []
        DemandSources = _.map(data, item => {
          return {
            key: item.ItemValue,
            value: item.ItemName
          }
        })
        this.$store.commit('setRequestStates', {
          type: 'isDemandSources',
          datas: false
        })
        this.$store.commit('setCommonDatas', {
          type: 'DemandSources',
          datas: DemandSources
        })
      })
    },
    async getCustomerHeat() { // 获取客户热度
      return this.$post(CONFIG.DropDownURL, {
        DictCode: 'CustomerHeat'
      }, data => {
        let customerHeat = _.map(data, items => {
          return {
            key: items.ItemValue,
            value: items.ItemName
          }
        })
        this.$store.commit('setRequestStates', {
          type: 'isGetCustomerHeat',
          datas: false
        })
        this.$store.commit('setCommonDatas', {
          type: 'CustomerHeat',
          datas: customerHeat
        })
      })
    },

    // 获取单据搜索默认时间段(开始日期，结束日期)
    getOrderSearchDefaultDate() {
      let result = {
        start: null,
        end: null
      }
      let proid = this.getCompanySetupValue('OrderSearch_DefaultCreatedTimePeriod')
      if (proid === null || proid === undefined || proid === '-1' || proid === -1) {
        return result
      }

      result.end = new Date()

      let month = parseInt(proid)
      let d = new Date()
      d.setMonth(d.getMonth() - month)
      result.start = d

      return result
    },

    // 是否有正能量月结服务资质
    async getHasLoanService() {
      let result = false;
      await this.$post(CONFIG.hasLoanServiceUrl, {}, data => {
        result = data;
      })

      return result;
    }
  }
}
const taobao = {
  data() {
    return {
      // link: https://open.taobao.com/doc.htm?docId=102856&docType=1
      orderStatusMap: {
        WAIT_BUYER_PAY: "等待买家付款",
        WAIT_SELLER_SEND_GOODS: "等待卖家发货",
        SELLER_CONSIGNED_PART: "卖家部分发货",
        WAIT_BUYER_CONFIRM_GOODS: "等待买家确认收货",
        TRADE_BUYER_SIGNED: "买家已签收（货到付款专用）",
        TRADE_FINISHED: "交易成功",
        TRADE_CLOSED: "交易关闭",
        TRADE_CLOSED_BY_TAOBAO: "交易被淘宝关闭",
        TRADE_NO_CREATE_PAY: "没有创建外部交易（支付宝交易）",
        WAIT_PRE_AUTH_CONFIRM: "余额宝0元购合约中",
        PAY_PENDING: "外卡支付付款确认中",
        ALL_WAIT_PAY: "所有买家未付款的交易", //（包含：WAIT_BUYER_PAY、TRADE_NO_CREATE_PAY）
        ALL_CLOSED: "所有关闭的交易", //（包含：TRADE_CLOSED、TRADE_CLOSED_BY_TAOBAO）
        PAID_FORBID_CONSIGN: "该状态代表订单已付款但是处于禁止发货状态"
      }
    }
  },
  computed: {
    orderStatusOptions() {
      let arry = []
      for (var key in this.orderStatusMap) {
        let item = this.orderStatusMap[key]
        arry.push({
          value: key,
          key: item
        })
      }
      return arry
    },
    orderStatusOptions2() {
      return [
        // { key: '', value: '--'},
        {
          key: 'WAIT_BUYER_PAY',
          value: '等待买家付款'
        },
        {
          key: 'WAIT_SELLER_SEND_GOODS',
          value: '等待卖家发货'
        },
        {
          key: 'WAIT_BUYER_CONFIRM_GOODS',
          value: '等待买家确认收货'
        },
        {
          key: 'TRADE_FINISHED',
          value: '交易成功'
        },
        {
          key: 'TRADE_CLOSED',
          value: '交易关闭'
        },
        {
          key: 'TRADE_CLOSED_BY_TAOBAO',
          value: '交易被淘宝关闭'
        }
      ]
    }
  }
}
export {
  mixin,
  taobao
}
