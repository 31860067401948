<template>
  <el-dialog
    :visible="visible"
    width="1300px"
    height="850px"
    title="模板设置"
    class="dialog-log-grid-box dialog-grid-footer-box m-operate-log"
    :footer-height="0"
    :is-footer-show="false"
    :append-to-body="true"
    :fullscreen="true"
    :close-on-click-modal="false"
    @close="()=>{isShowEdit = false, $emit('close')}"
  >
    <!-- <form-printer
      ref="formPrinter"
      :page-config="pageConfig"
      :row-config="rowConfig"
      :silent="false"
      :data-sources="dataLine"
      :info="dataInfo"
      @mounted="onPrintPDF"
      id="print-preview"
    ></form-printer> -->
    <form-maker
      v-if="isShowEdit"
      ref="formMaker"
      :pageConfig="pageConfig"
      :rowConfig="rowConfig"
      :tableConfig="tableConfig"
    ></form-maker>
    <!-- <znl-button style-type="main" @click="getData()">
      <span>
        <i class="iconfont icon-add_btn_ic"></i>获取
      </span>
    </znl-button>
    <znl-button style-type="main" @click="saveData()">
      <span>
        <i class="iconfont icon-add_btn_ic"></i>保存
      </span>
    </znl-button> -->
  </el-dialog>
</template>
<script>
import FormPrinter from "@c_common/print/FormPrinter";
import PrintConfig from "@c_common/print/config";
import Data from "@c_common/print/data";
import FormMaker from "@c_common/print/FormMaker";
import { timeout } from "q";
import { setTimeout } from "timers";

const CONFIG = {
  GetUrl: 'PrintTemplate/Get',
  SaveUrl: 'PrintTemplate/SaveV2'
}

export default {
  name: "printTemplateSetting",
  config: CONFIG,
  components: {
    FormPrinter,
    FormMaker
  },
  data () {
    return {
      rowConfig: {},
      isShowEdit: false,
      pageConfig: PrintConfig.pageA5,
      tableConfig: {
        rowHeight: 26,
        headerHeight: 26
      },
      template: {
        PTID: 0,
        ConfigValue: '',
        TableName: '',
        TemplateName: ''
      }
      // dataInfo: Data.info,
      // dataLine: Data.dataSources
    };
  },
  props: {
    refreshLog: Boolean,
    visible: Boolean,
    dataInfo: {},
    dataLine: Array,
    PTID: Number,
    Type: String
    // title: "打印单据"
  },
  watch: {
    visible (value) {
      if (value) {
        this.getData()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {})
  },
  created() {},
  methods: {
    onPrintPDF() {
    },
    getData () {
      if (this.PTID > 0) {
        this.$post(CONFIG.GetUrl, {PTID: this.PTID}, (data, logic) => {
          if (data) {
            this.template = data
            this.rowConfig = JSON.parse(this.template.ConfigValue || {}).row
            this.isShowEdit = true
          } else if (logic.code !== 200) {
            this.$message({message: logic.msg || '', type: 'error'})
          }
        })
      }
    },

    saveData () {
      if (!this.hasValue(this.template.TableName)) {
        this.$message({message:'TableName不能为空', type: 'error'})
        return false
      }
      if (!this.hasValue(this.template.TemplateName)) {
        this.$message({message:'模板名称不能为空', type: 'error'})
        return false
      }
      if (!this.hasValue(this.template.ConfigValue)) {
        this.$message({message:'配置信息不能为空', type: 'error'})
        return false
      }

      this.$post(CONFIG.SaveUrl, this.template, (data, logic) => {
        if (data && data.PTID > 0) {
          this.$message({message: '保存成功', type: 'success'})
        } else {
          this.$message({message: logic.msg || '保存失败', type: 'error'})
        }
      })
    }
  }
};
</script>

<style lang="scss">
.m-operate-log {
  .el-pagination--small {
    height: auto !important;
  }
}
</style>

