<template>
  <znl-dialog
    class="znl-print"
    :title="title"
    width="460"
    :height="height"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    v-loading="loading"
  >
    <div slot="znl-dialog">
      <div class="znl-print_template znl-print_componet">
        <div class="znl-print_componet-header">请选择打印模板</div>
        <div class="znl-print_radio-group" :style="showSubCompany?'':'height:470px'">
          <div v-for="item in PrintTemplates" :key="item.PTID">
            <el-checkbox v-model="item.IsDefault" @change="isChecked => onTemplateChange(item, isChecked)">{{ item.TemplateName }}</el-checkbox>

            <a class="template-edit-btn" @click="onDelete(item.PTID, item.TemplateName)" v-if="canDel && item.TemplateType === 2" >删除</a>
            <a class="template-edit-btn" @click="onEdit(item.PTID, item.TemplateName, item.TableName)" v-if="canDel && item.TemplateType === 2" >编辑</a>
          </div>
        </div>
      </div>
      <div class="znl-print_company znl-print_componet" v-if="showSubCompany">
        <div class="znl-print_componet-header">请选择模板抬头</div>
        <div class="znl-print_radio-group">
          <div v-for="item in printData.titles" :key="item.key">
            <el-checkbox v-model="item.selected" @change="isChecked => onTitleChange(item, isChecked)">{{ item.value }}</el-checkbox>
          </div>
          <div v-if="printData.titles == null || printData.titles == undefined || printData.titles.length === 0" class="znl-print_radio-group" style="color: red;">
            暂无抬头，请联系主账号增加或者授权
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="znl-print_footer">
      <div style="text-align: left; float: left;">
        <div style="height: 20px; margin-top: -14px;"><span class="selected-span">已选模板：</span>{{ printInfo.templateName }}</div>
        <div><span class="selected-span">已选抬头：</span>{{ printInfo.titleName }}</div>
      </div>
      <div>
        <znl-button :height="30" style="margin-right:5px;" @click.stop="SetDefault()">设为默认</znl-button>
        <znl-button :width="70" :height="30" style-type="main" @click.stop="confirmPrint"><i class="iconfont icon-print_btn_ic"></i>打印</znl-button>
      </div>
    </div>

    <!-- 自定义界面的原因： z-index的bug, this.$confirm产生的z-index会比弹窗的小，导致确认删除的弹窗被当前弹窗遮盖 -->
    <div style="z-index:3000">
      <znl-dialog
        title="提示"
        :visible="showConfirmDlg"
        subhead
        width="360"
        class="dialog-confirm-box"
        :close-on-click-modal="false"
        :is-footer-show="true"
        @close="() => showConfirmDlg=false"
      >
        <div>
          <div class="el-message-box__content">
            <div class="el-message-box__status el-icon-warning"></div>
            <div class="el-message-box__message">
              <p v-html="deleteConfirmMsg">
              </p>
            </div>
          </div>
        </div>
        <div slot="footer" class="confirm-footer footer">
          <znl-button
            style-type="main"
            @click="doDelete()"
            :height="30"
          >
            <span>确定</span>
          </znl-button>

          <znl-button
            style-type="mac"
            @click="() => showConfirmDlg=false"
            :height="30"
          >
            <span>取消</span>
          </znl-button>
        </div>
      </znl-dialog>
    </div>

  </znl-dialog>
</template>
<script>
import printTemplateSetting from "@c_common/print/printTemplateSetting";
import {mapState} from 'vuex';
import { isClient } from '~/lib/runtime';
import znlConfirm from "@c_common/dialogs/confirm";
const CONFIG = {
  GetPrintTemplatesURL: 'PrintTemplate/SearchV2',
  GetSubCompanyListURL: 'UserSubCompany/GetSubCompanyListForPrint', // 获取关联公司信息，用公司名做title
  GetPageParamURL: 'PageParam/Get', // 获取页面桉树配置
  SavePageParamURL: 'PageParam/Save', // 保存页面参数配置
  DelPrintTemplatesURL: 'PrintTemplate/Delete' // 删除模板
}

export default {
  name: 'ZnlPrints',
  components: {
    printTemplateSetting,
    znlConfirm
  },

  config: CONFIG,

  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '打印设置'
    },
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 600
    },
    // 打印模板类型  Quotation：报价单打印，
    printType: {
      type: String,
      default: ''
    },
    isGetDefaultParam: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    initParam: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    pageCode: {
      type: String,
      default: () => {
        return ''
      }
    },
    showSubCompany: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },

  watch: {
    visible(val) {
      this.dialogVisible = val
    },
    async isGetDefaultParam(val) {
      if (!this.isGetDefault) {
        await this.GetParamJson()
        this.confirmPrint()
      }
    },

    async dialogVisible(val) {
      this.$emit('update:visible', val)
      if (val === true && !this.IsInitSelect) {
        await this.getPrintTemplates()
        await this.GetCompanyTitle()
        await this.GetParamJson()
        this.IsInitSelect = true
        this.isGetDefault = true
      }
    },
    // 打印模板类型。通过mapping去匹配GCode，然后通过GCode去接口查询该打印类型的默认值
    printType(val) {
      let o = _.find(this.printTypeMapping, a => a.type === val)
      if (!_.isUndefined(o)) {
        this.GCode = o.code
      } else if(val !== '') {
        return this.$message({ message: '引用打印模块的参数设置有误，请联系管理员', type: 'warning' })
      }
    }
  },
  computed:{
    ...mapState(["useInfo"]),
  },

  data() {
    return {
      dialogVisible: false,
      printData: {
        titles: []
        // fileType: 1
      },
      printInfo: {
        template: '',
        templateType: 1,
        templateName: "",
        title: 0,
        titleName: ""
      },
      // 打印初始化的默认值。如果用户没有设置过默认值，则取列表中的第一条
      printDefaultData: {
        template: '',
        templateType: 1,
        templateName: "",
        title: 0,
        titleName: ""
      },
      PrintTemplates: [],
      IsDefault: false,
      GCode: 'PrintQuotation', // 打印模板参数设置保存默认值对应的GCode
      printTypeMapping: [
        { type: 'Quotation', code: 'PrintQuotation' }, // 报价单打印
        { type: 'STK_Out', code: 'StkOut' }, // 发货单打印
        { type: 'STK_In', code: 'StkIn' }, // 入库单打印
        { type: 'ORD_SalesOrder', code: 'SalesOrder' }, // 销售单打印
        { type: 'ORD_PurchaseOrder', code: 'OrdPurchaseOrder' }, // 采购单打印
        { type: 'Statement', code: 'Statement' }, // 销售对账单打印
        { type: 'PurchaseStatement', code: 'PurchaseStatement' }, // 采购对账单打印
        { type: 'POStatement', code: 'PurchaseStatement' }, // 采购对账单打印
        { type: 'StkOutLabel', code: 'StkOutLabel' } // 发货标签打印
      ],
      IsInitSelect: false, // 是否已经初始化列表，默认不进行初始化。待弹窗显示的时候才进行初始化。
      isGetDefault: false, // 是否已经获取过默认打印参数
      isShowEdit: false,
      canDel: false, // 是否可以删除打印模板
      PTID: 0,
      showConfirmDlg: false,
      deleteConfirmMsg: ""
    }
  },

  methods: {
    async init() {
      this.$emit('update:loading', true)
      this.initGCode()
      this.canDel = this.$store.state.accountInfo.IsMainAccount;

      if (this.initParam) {
        await this.GetParamJson()
        this.isGetDefault = true
        this.$emit('init-param-finished')
      }
    },
    initGCode() {
      if (this.pageCode && this.pageCode !== '') {
        this.GCode = this.pageCode
        return true
      }
      let o = _.find(this.printTypeMapping, a => a.type === this.printType)
      if (!_.isUndefined(o)) {
        this.GCode = o.code
      } else {
        return this.$message({ message: '引用打印模块的参数设置有误，请联系管理员', type: 'warning' })
      }
    },
    // 获取打印模板
    async getPrintTemplates() {
      this.PrintTemplates = await this.$post(this.$options.config.GetPrintTemplatesURL, { Type: this.printType })
      _.each(this.PrintTemplates, item => {
        item.IsDefault = item.TableName === this.printInfo.template;
      });
      this.printInfo.templateName = (_.find(this.PrintTemplates, a => a.TableName == this.printInfo.template) || {}).TemplateName;

      this.$emit('update:loading', false)
    },
    // 获取打印报价单子公司列表（抬头）
    async GetCompanyTitle() {
      let data = await this.$post(this.$options.config.GetSubCompanyListURL, {})
      let res = []
      _.each(data, item => {
        res.push({
          key: item.SubCompanyID,
          value: item.SubCompanyName,
          selected: false
        })
      })
      this.printData.titles = res
      this.$emit('update:loading', false)
      this.printInfo.titleName = (_.find(res, a=> a.key == this.printInfo.title) || {}).value;
    },
    // 获取默认值
    async GetParamJson() {
      let data = await this.$post(CONFIG.GetPageParamURL, { GCode: this.GCode })
      if (data.length > 0) {
        this.printInfo.title = this.toInt(this.findVal(data, a => a.ParamCode === 'PrintTitle').ParamValue) || 0
        this.printInfo.template = this.findVal(data, a => a.ParamCode === 'PrintTemplate').ParamValue || ""
        this.printInfo.templateType = this.toInt(this.findVal(data, a => a.ParamCode === 'PrintTemplateType').ParamValue) || 1

        this.$emit('set-print-param', this.printInfo)
      }

      // 设置复选框
      if (this.hasValue(this.printInfo.template) && this.PrintTemplates.length > 0) {
        let obj = _.find(this.PrintTemplates, a => a.TableName == this.printInfo.template);
        if (obj) {
          this.printInfo.templateName = obj.TemplateName;
          obj.IsDefault = true;
        }
      }

      if (this.printInfo.title > 0 && this.printData.titles && this.printData.titles.length > 0) {
        let obj = _.find(this.printData.titles, a => a.key === this.printInfo.title);
        if (obj) {
          obj.selected = true;
          this.printInfo.titleName = obj.value;
        }
      }

      this.$emit('set-print-param', this.printInfo)
    },
    SetDefault() {
      let o = _.find(this.PrintTemplates, row => row.TableName == this.printInfo.template)
      if (o) {
        this.printInfo.templateType = o.TemplateType || 1
      }
      let datas = [{
        ParamText: '模板抬头',
        ParamCode: 'PrintTitle',
        ParamValue: this.printInfo.title,
        ParamType: 0
      }, {
        ParamText: '默认打印模板',
        ParamCode: 'PrintTemplate',
        ParamValue: this.printInfo.template,
        ParamType: 0
      }, {
        ParamText: '默认打印模板类型',
        ParamCode: 'PrintTemplateType',
        ParamValue: this.printInfo.templateType || 1,
        ParamType: 0
      }]
      this.$post(CONFIG.SavePageParamURL, { GCode: this.GCode, ParamJson: datas }, (data, logic) => {
        if (logic.code === 200) {
          this.$message({ message: '设置成功', type: 'success' })
        } else {
          this.$message({ message: logic.msg, type: 'error' })
        }
      })
    },
    onTemplateChange (obj, isSelected) {
      if (!isSelected) { // 取消勾选
        this.printInfo.template = ''
        this.printInfo.templateName = ''
        this.$emit('set-print-param', this.printInfo)
        return true;
      }
      for (var i in this.PrintTemplates) {
        let item = this.PrintTemplates[i];
        if (obj.PTID === item.PTID) { // 选中
          this.printInfo.templateType = item.TemplateType || 1;
          this.printInfo.template = item.TableName;
          this.printInfo.templateName = item.TemplateName;
          continue;
        }
        item.IsDefault = false;
      }
      this.$emit('set-print-param', this.printInfo)
    },
    onTitleChange (obj, isSelected) {
      if (!isSelected) {
        this.printInfo.title = 0;
        this.printInfo.titleName = '';
        this.$emit('set-print-param', this.printInfo)
        return true;
      }
      for (const key in this.printData.titles) {
        let item = this.printData.titles[key]
        if (obj.key === item.key) { // 选中
          this.printInfo.title = item.key;
          this.printInfo.titleName = item.value;
          continue;
        }
        item.selected = false;
      }
      this.$emit('set-print-param', this.printInfo)
    },
    // 通知调用的模块，进行打印操作
    confirmPrint() {
      if(this.printInfo.template == "") {
        this.$message({message:"请选择打印模板", type: "error"});
        return false;
      }
      this.dialogVisible = false
      this.$emit('confirm-print', this.printData, this.printInfo)
    },

    //编辑打印模板
    onEdit (ptid, templateName, tableName) {
      this.PTID = ptid
      //客户端嵌套网页走客户端的编辑页面
      if(isClient())
      {
        this.editFastPrintTemplate(ptid, templateName, tableName);
      }
      else{
        this.openEidtPage(ptid,tableName);
      }
    },
    /* 打开新标签页显示快速模板编辑页面 */
    openEidtPage(ptid,tableName){
       let production = process.env.NODE_ENV === "production";

       let token = this.useInfo['AccessToken'];
       token = encodeURIComponent(token);

       let url = 'http://sz8.znlerp.com:9361';
       if(!production)  url = 'http://192.168.1.10:9528';

       let openUrl = `${url}/edit?id=${ptid}&code=${tableName}&token=${token}`;

       window.open(openUrl);
    },

    async onDelete (ptid, templateName) {
      this.PTID = ptid
      this.deleteConfirmMsg = "确定要删除模板 <strong> [" + templateName + "] </strong> 吗?";
      this.showConfirmDlg = true;
      return false;
    },

    async doDelete () {
      let ptid = this.PTID;
      this.$post(CONFIG.DelPrintTemplatesURL, {PTID: ptid}, (data, logic) => {
        if (logic.code === 200) {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.showConfirmDlg = false;
          let index = -1;
          this.PrintTemplates.map((item, i) => {
            if (item.PTID === ptid) {
              index = i;
            }
          });
          this.PrintTemplates.splice(index, 1);
        } else {
          this.$message({
            message: logic.msg || '删除失败',
            type: 'error'
          })
        }
      })
    }
  },

  mounted () {
    this.init()
  }

}
</script>

<style lang="scss">
.znl-print {
  .el-radio + .el-radio {
    margin-left: 0 !important;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #ee7700;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #ee7700;
    background: #ee7700;
  }

  .el-radio__inner {
    width: 10px;
    height: 10px;
  }

  .el-checkbox__inner:hover,
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #ee7700;
  }

  .template-edit-btn {
    float: right;
    color: #409EFF;
    text-decoration: underline;
    font-size: 12px;
    margin-right: 5px;
  }

  .znl-print_row {
    display: block;
    line-height: 30px;
    border-bottom: 1px solid #e6e6e6;
  }

  .znl-print_radio-group {
    display: block;
    height: 222px;
    overflow-y: auto;
  }

  .znl-print_componet {
    border: 1px solid #e6e6e6;
    padding: 0 10px;
    margin-bottom: 5px;
    overflow-y: auto;
  }

  .znl-print_componet-header {
    font-size: 12px;
    font-weight: bold;
    color: #333;
  }

  .znl-print_footer {
    position: relative;
    top: 20px;
    .znl-button {
      margin-left: 7px;
    }
    .selected-span {
      color: #666;
    }
  }

  .znl-print_footer-radio {
    position: absolute;
    top: 7px;
    left: 0;
    .el-radio:first-child {
      margin-right: 5px;
    }
  }
  .znl-print-delete-confirm .znl-dialog__footer{
    margin-bottom: 10px;
  }
}


</style>

