<template>
  <div>
    <znl-dialog
      :visible="visible && dialogShow"
      v-loading="pageLoading"
      element-loading-text=" 正在导出数据,请稍后..."
      width="500px"
      class="export-format-box"
      title="库存导出格式 (按查询条件导出)"
      @close="
        () => {
          (this.dialogShow = false),
            (this.isRefresh = !this.isRefresh),
            $emit('close');
        }
      "
    >
      <div slot="znl-dialog" class="stock-extend-set">
        <el-row class="mleft">
          <el-col>
            <el-radio v-model="filterType" label="default" :fill="fillColor"
              >我的默认格式</el-radio
            >
            <znl-button
              :height="22"
              :width="80"
              style="margin-left: 10px"
              :disabled="loading"
              @click="OnExportColumn"
            >
              <span>设置导出列</span>
            </znl-button>
          </el-col>
          <el-col v-for="(item, index) in Formats" :key="index">
            <el-radio v-model="filterType" :label="index" :fill="fillColor">{{
              item.Name
            }}</el-radio>
          </el-col>
        </el-row>
        <el-row v-if="isShowMergeCondition" class="mleft merge-conditions">
          <span>库存合并条件：</span>
          <el-checkbox v-model="mergeConditions.model" @change="onModelChecked"
            >型号</el-checkbox
          >
          <el-checkbox v-model="mergeConditions.brand" @change="onBrandChecked"
            >品牌</el-checkbox
          >
          <el-checkbox
            v-model="mergeConditions.packaging"
            @change="onPackagingChecked"
            >封装</el-checkbox
          >
          <el-checkbox
            v-model="mergeConditions.makeyear"
            @change="onMakeyearChecked"
            >年份</el-checkbox
          >
          <span>
            (相同就合成一条并将数量累加，不勾选就表示不做合并)
            <br />
            <span class="op-tips">合并库存的过程有点慢，请耐心等待...</span>
          </span>
        </el-row>
      </div>
      <el-row slot="footer">
        <znl-button
          style-type="main"
          :height="30"
          :width="60"
          :disabled="loading"
          @click="onConfirm"
        >
          <span>开始导出</span>
        </znl-button>
      </el-row>
    </znl-dialog>
    <export-column
      :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="setConfigColumns"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="
        () => {
          setExportColumnVisible = false;
        }
      "
      @confirm="
        (v) => {
          setExportColumnVisible = false;
        }
      "
    ></export-column>
  </div>
</template>

<script>
import { mixin as propSyncMixin } from "@scripts/mixins/propSync";
import ExportColumn from "@c_modules/Crm/setExportColumn";

import cecb2bExploreFormat from "static/StkExploreFormat/cecb2b";
import dzscExploreFormat from "static/StkExploreFormat/dzsc";
import hqewExploreFormat from "static/StkExploreFormat/hqew";
import icnetExploreFormat from "static/StkExploreFormat/icnet";
import ihqbuyExploreFormat from "static/StkExploreFormat/ihqbuy";
import bomaiExploreFormat from "static/StkExploreFormat/bomai";
import { exportData } from "@scripts/methods/common";

import * as urls from "~/lib/urls";
export default {
  name: "ExportFormat",
  mixins: [propSyncMixin, exportData],
  components: {
    ExportColumn,
  },
  data() {
    return {
      config: {
        GetServerItemURL: "PubStkStock/GetServerItem",
        SetStockExtendURL: "StockSummary/SetStockExtend",
        getCountURL: "StockSummary/GetCount",
        apiBaseUrl: urls.getErpApiBaseUrl(),
      },
      dialogShow: false,
      isRefresh: false,
      stype: "4",
      fillColor: "#F70",
      filterType: "default",
      filterRowCount: 0,
      addColumnsList: [],
      setConfigColumns: {},
      setExportColumnVisible: false,
      setSaveConfigURL: "",
      downloadUrl: "",
      pageLoading: false,
      mergeConditions: {
        model: false,
        brand: false,
        packaging: false,
        makeyear: false,
      },
      MergeStockConditions: "",
      Formats: [], //导出格式
    };
  },
  props: {
    loading: Boolean,
    dialogVisible: {
      type: Boolean,
      default: false,
      propsync: true,
    },
    visible: Boolean,
    //是否显示合并条件
    isShowMergeCondition: {
      type: Boolean,
      default: true,
    },
    searchCondition: {
      // 查询条件
      type: Object,
      default: () => {
        return {};
      },
    },
    defaultConfigColumns: {
      type: Object,
      default: () => {
        return {};
      },
    },
    saveConfigURL: {
      type: String,
      default: () => {
        return "";
      },
    },
    actionServiceName: String,
  },
  watch: {
    visible(val) {
      if (val) {
        this.dialogShow = true;
        this.isRefresh = !this.isRefresh;
      }
    },
  },
  mounted() {
    this.onInit();
  },
  methods: {
    async onInit() {
      this.Formats = await this.$post("DictItem/GetExportColumns", {
        Code: "OwnStockExportFieldSet",
      });
    },
    OnExportColumn() {
      this.setExportColumnVisible = true;
      this.setConfigColumns = this.defaultConfigColumns;
      this.setSaveConfigURL = this.saveConfigURL;
    },
    onCancel() {
      this.$emit("close");
    },
    async onConfirm() {
      let exportColumns = [];
      let exportFileName = "";
      let { Formats, filterType } = this;

      switch (filterType) {
        case "default":
          exportColumns = this.defaultConfigColumns.ColumnConfigs;
          break;
        default:
          exportFileName = Formats[filterType].Name;
          exportColumns = Formats[filterType].Columns;
          console.log("Formats[filterType] is ", Formats[filterType]);
          break;
      }
      console.log(exportFileName, exportColumns);

      exportFileName = exportFileName + "库存";
      //筛选存在的列
      if (
        this.filterType !== "default" &&
        this.defaultConfigColumns.ColumnConfigs
      ) {
        exportColumns = _.filter(exportColumns, (item) => {
          return _.includes(
            this.defaultConfigColumns.ColumnConfigs,
            _.find(this.defaultConfigColumns.ColumnConfigs, (col) => {
              return col.BindField === item.BindField;
            })
          );
        });
      }
      if (
        this.mergeConditions.model ||
        this.mergeConditions.brand ||
        this.mergeConditions.packaging ||
        this.mergeConditions.MakeYear
      ) {
        let mergelist = [];
        if (this.mergeConditions.model) {
          mergelist.push("Model");
        }
        if (this.mergeConditions.brand) {
          mergelist.push("Brand");
        }
        if (this.mergeConditions.packaging) {
          mergelist.push("Packaging");
        }
        if (this.mergeConditions.makeyear) {
          mergelist.push("MakeYear");
        }
        if (mergelist.length <= 0) {
          return;
        }
        _.extend(this.searchCondition, {
          MergeStockConditions: mergelist.join(),
        });
      }
      this.pageLoading = true;
      console.log("exportColumns", exportColumns);
      await this.exportData(
        this.actionServiceName,
        this.searchCondition,
        exportColumns,
        exportFileName
      ).finally(() => {
        this.pageLoading = false;
      });
    },

    // #region 合并条件只有型号勾选上才会有意义
    onModelChecked(val) {
      if (!val) {
        this.mergeConditions.brand = false;
        this.mergeConditions.packaging = false;
        this.mergeConditions.makeyear = false;
      }
    },
    onBrandChecked(val) {
      if (val && !this.mergeConditions.model) {
        this.mergeConditions.model = true;
      }
    },
    onPackagingChecked(val) {
      if (val && !this.mergeConditions.model) {
        this.mergeConditions.model = true;
      }
    },
    onMakeyearChecked(val) {
      if (val && !this.mergeConditions.model) {
        this.mergeConditions.model = true;
      }
    },
  },
};
</script>

<style lang="scss">
.export-format-box .el-dialog,
.export-format-box .znl-dialog {
  .mt20 {
    margin-top: 10px;
  }
  .stock-extend-set {
    .radios span {
      font-size: 12px;
    }
    .mleft {
      margin-left: 16px;
    }
    .col2 {
      margin-left: 20px;
    }
    .ml20 {
      margin-left: 20px;
    }
    .stk-hr {
      margin-top: 0;
      margin-bottom: 0;
    }
    .el-col-24 {
      padding: 5px 0;
    }
    .merge-conditions {
      padding: 10px 0;
      span {
        color: #606266;
        font-weight: 500;
        line-height: 1;
        white-space: nowrap;
        outline: 0;
      }
      .op-tips {
        color: #409eff;
      }
    }
  }
  .el-checkbox {
    margin-right: 10px !important;
  }
  .znl-dialog__footer {
    height: 50px !important;
  }
}
</style>
