<template>
  <div class="m-form-maker">
    <section class="g-top-bar">
      <div class="item">
        <el-button
          @click="
            $emit('save', {
              page: pageConfig,
              row: rowConfig,
              table: tableConfig
            })
          "
          >保存</el-button
        >
        <el-button
          @click="
            $emit('saveAs', {
              page: pageConfig,
              row: rowConfig,
              table: tableConfig
            })
          "
          >另存为</el-button
        >
      </div>
      <div class="item">
        <el-button style="font-weight: bold" type="text">B</el-button>
        <el-button style="font-style: italic" type="text">I</el-button>
        <el-button style="text-decoration: underline" type="text">U</el-button>
      </div>
      <div class="item">
        <div class="input-box">
          <span>纸宽:</span>
          <el-input v-model="pageConfig.width"></el-input>
        </div>
        <div class="input-box">
          <span>纸高:</span>
          <el-input v-model="pageConfig.height"></el-input>
        </div>
        <div class="input-box">
          <span>左边距:</span>
          <el-input v-model="pageConfig.paddingLeft"></el-input>
        </div>
        <div class="input-box">
          <span>上边距:</span>
          <el-input v-model="pageConfig.paddingTop"></el-input>
        </div>
      </div>
    </section>
    <div class="g-main">
      <div class="tool">
        <el-collapse v-model="activeNames">
          <el-collapse-item
            title="常用操作"
            name="1"
            v-if="activeRowIndex !== -1"
          >
            <div class="item">
              <el-button @click="addRow" :disabled="activeRowIndex === -1"
                >新增一行</el-button
              >
              <el-button :disabled="activeRowIndex === -1">上移</el-button>
              <el-button :disabled="activeRowIndex === -1">下移</el-button>
            </div>
            <div class="item">
              <span>字体大小：</span>
              <el-input-number
                controls-position="right"
                v-model="activeRowItem.fontSize"
              ></el-input-number>
            </div>
            <div class="item">
              <span>上边距：</span>
              <el-input-number
                :disabled="activeRowIndex === -1"
                controls-position="right"
                v-model="activeRowItem.marginTop"
              ></el-input-number>
            </div>
            <div class="item">
              <span>下边距：</span>
              <el-input-number
                :disabled="activeRowIndex === -1"
                controls-position="right"
                v-model="activeRowItem.marginBottom"
              ></el-input-number>
            </div>
            <div class="item">
              <span>左边距：</span>
              <el-input-number
                :disabled="activeRowIndex === -1"
                controls-position="right"
                v-model="activeRowItem.marginLeft"
              ></el-input-number>
            </div>
            <div class="item">
              <span>右边距：</span>
              <el-input-number
                :disabled="activeRowIndex === -1"
                controls-position="right"
                v-model="activeRowItem.marginRight"
              ></el-input-number>
            </div>
            <div class="item">
              <el-input>
                <template slot="prepend">
                  文本
                </template>
              </el-input>
              <el-input>
                <template slot="prepend">
                  附加文本
                </template>
              </el-input>
            </div>
          </el-collapse-item>
        </el-collapse>

        <el-collapse>
          <el-collapse-item class="fields-box" title="字段">
            <el-tag
              v-for="(item, index) in fields"
              :key="index"
              size="medium"
              class="field-tag"
              draggable="true"
              @dragstart.native="dragElement({ data: item, event: $event })"
            >
              {{ item.label }}
            </el-tag>
          </el-collapse-item>
        </el-collapse>
      </div>
      <section class="g-maker-view">
        <div
          class="page-box"
          @click="activeRowIndex = -1"
          :style="{
            width: page.width,
            height: page.height,
            paddingTop: page.paddingTop,
            paddingLeft: page.paddingLeft
          }"
        >
          <div
            class="content-box"
            @drop.stop="e => dropDownHandle({ e })"
            @dragover.prevent.stop
          >
            <el-row
              @click.native="clickRow({ row, rowIndex })"
              v-for="(row, rowIndex) in rowConfig"
              :key="rowIndex"
              justify="center"
              :class="[
                row.isFloat && 'row-float',
                activeRowIndex === rowIndex ? 'row-active' : '',
                row.type ? row.type + '-row' : ''
              ]"
              :style="{
                width: row.width,
                top: row.isFloat ? row.top : '0px',
                left: row.isFloat ? row.left : '0px',
                lineHeight:
                  (row.rowHeight ? row.rowHeight : page.rowHeight) + 'px',
                fontSize: row.fontSize + 'px',
                fontFamily: row.fontFamily || pageConfig.fontFamily,
                fontWeight: row.fontWeight || 'normal',
                marginTop: row.marginTop + 'px',
                marginRight: row.marginRight + 'px',
                marginBottom: row.marginBottom + 'px',
                marginLeft: row.marginLeft + 'px'
              }"
              :draggable="row.isFloat"
              @dragstart.native="
                dragElement({
                  data: { row, rowIndex },
                  event: $event
                })
              "
              @drop.native.stop="e => dropDownHandle({ e, row, rowIndex })"
            >
              <template v-if="row.type === 'image'">
                <img v-if="row.field" :src="info[row.field]" alt="" />
                <img v-if="row.src" :src="row.src" alt="" />
              </template>
              <template v-else-if="row.type === 'textarea'">
                <div
                  v-if="row.content"
                  :style="{ height: row.rowHeight + 'px', width: '1000%' }"
                >
                  <i class="el-icon-close" @click.stop="removeRow()"></i>
                  <textarea
                    @click.stop="clickRow({ rowIndex, toggle: false })"
                    v-model="row.content"
                    :style="{
                      fontSize:
                        (row.fontSize ? row.fontSize : page.fontSize) + 'px',
                      fontFamily: row.fontFamily || page.fontFamily,
                      lineHeight:
                        (row.lineHeight
                          ? row.lineHeight
                          : pageConfig.lineHeight) + 'px'
                    }"
                  >
                  </textarea>
                </div>
              </template>
              <template v-else-if="row.type === 'table'">
                <table
                  width="100%"
                  border="1"
                  cellspacing="0"
                  cellpadding="0"
                  border-spacing="0"
                  @click.native="clickRow({ rowIndex, colIndex })"
                >
                  <tr :height="tableConfig.headerHeight">
                    <th v-if="tableConfig.showIndex">
                      {{ tableConfig.indexTitle }}
                    </th>
                    <th
                      v-for="(item, index) in rowConfig[rowIndex].colConfig"
                      :height="row.rowHeight || page.rowHeight + 'px'"
                      :key="index"
                    >
                      <div
                        class="header-box"
                        draggable="true"
                        @dragstart.native="
                          dragElement({ data: item, event: $event })
                        "
                        :style="{ lineHeight: tableConfig.headerHeight + 'px' }"
                      >
                        <i class="el-icon-close" @click.stop="removeRow()"></i>
                        <input-el :value.sync="item.headerName"></input-el>
                      </div>
                    </th>
                  </tr>
                  <tr
                    v-for="(data, index) in dataSources.slice(
                      interval[0],
                      interval[1]
                    )"
                    :key="index"
                    :height="tableConfig.rowHeight + 'px'"
                  >
                    <td v-if="tableConfig.showIndex">{{ index + 1 }}</td>
                    <td
                      v-for="(col, colIndex) in rowConfig[rowIndex].colConfig"
                      :key="colIndex"
                      :style="{ padding: tableConfig.padding + 'px' }"
                    >
                      {{ data[col.field] }}
                    </td>
                  </tr>
                  <tr
                    class="table-footer"
                    :height="tableConfig.footerHeight"
                    v-if="countSlice.length && row.footer"
                  >
                    <td
                      v-for="(footItem, footIndex) in row.footer"
                      :key="footIndex"
                      :colspan="footItem.colspan"
                    >
                      <span v-if="footItem.label">{{ footItem.label }}:</span>
                      <span v-if="footItem.type === 'capital'">
                        {{ countSlice[index][0] }}
                      </span>
                      <span v-if="footItem.type === 'count'">
                        {{ countSlice[index][1] }}
                      </span>
                      <span v-else-if="footItem.field">
                        {{ info[footItem.field] }}
                      </span>
                      <span v-else-if="footItem.text">
                        {{ footItem.text }}
                      </span>
                    </td>
                  </tr>
                </table>
              </template>
              <template v-else>
                <el-col
                  v-for="(col, colIndex) in rowConfig[rowIndex].content"
                  @click.native.stop="clickRow({ rowIndex, colIndex })"
                  :style="{
                    textAlign: row.textAlign ? row.textAlign : page.textAlign,
                    lineHeight: row.rowHeight
                  }"
                  :key="colIndex"
                >
                  <div
                    @click.stop="
                      clickRow({ rowIndex, colIndex, toggle: false })
                    "
                    style="display: inline-flex"
                    v-if="col.text || col.field"
                  >
                    <input-el :value.sync="col.text"></input-el>
                    <span v-if="info[col.field]">{{ info[col.field] }}</span>
                    <i
                      @click.stop="removeCol(colIndex)"
                      class="el-icon-close"
                    ></i>
                  </div>
                </el-col>
              </template>
            </el-row>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import Data from "./data";
import InputEl from "./InputEl";

function chineseTransform(str) {
  var num = parseFloat(str);
  var strOutput = "",
    strUnit = "仟佰拾亿仟佰拾万仟佰拾元角分";
  num += "00";
  var intPos = num.indexOf(".");
  if (intPos >= 0) {
    num = num.substring(0, intPos) + num.substr(intPos + 1, 2);
  }
  strUnit = strUnit.substr(strUnit.length - num.length);
  for (var i = 0; i < num.length; i++) {
    strOutput +=
      "零壹贰叁肆伍陆柒捌玖".substr(num.substr(i, 1), 1) + strUnit.substr(i, 1);
  }
  return strOutput
    .replace(/零角零分$/, "整")
    .replace(/零[仟佰拾]/g, "零")
    .replace(/零{2,}/g, "零")
    .replace(/零([亿|万])/g, "$1")
    .replace(/零+元/, "元")
    .replace(/亿零{0,3}万/, "亿")
    .replace(/^元/, "零元");
}

export default {
  props: {
    page: Object,
    row: Array,
    table: Object
  },
  data() {
    return {
      activeRowIndex: -1,
      activeColIndex: -1,
      activeRowItem: {},
      activeColItem: {},
      rowConfig: [],
      pageConfig: {},
      tableConfig: {},
      activeNames: "1",
      textarea: "多行文本",
      text: "单行文本",
      proportion: 37.795275591,
      countSlice: [],
      dataSlice: [],
      fields: [
        {
          text: "公司名:",
          label: "公司名",
          field: "SalCompany"
        }
      ],
      interval: [[0, 10]],
      info: Data.info,
      dataSources: Data.dataSources
    };
  },
  components: { InputEl },
  watch: {
    row: {
      deep: true,
      immediate: true,
      handler(nVal) {
        this.rowConfig = Object.assign([], nVal);
      }
    },
    page: {
      deep: true,
      immediate: true,
      handler(nVal) {
        this.pageConfig = Object.assign([], nVal);
      }
    },
    table: {
      deep: true,
      immediate: true,
      handler(nVal) {
        this.tableConfig = Object.assign([], nVal);
      }
    }
  },
  methods: {
    init() {
      this.computedSlice();
    },
    dragElement({ data, event }) {
      event.dataTransfer.setData("data", JSON.stringify(data));
    },
    removeCol(index) {
      if (this.activeRowItem.content.length === 1) {
        this.removeRow();
        return true;
      }
      this.activeRowItem.content.splice(index, 1);
    },
    removeRow() {
      this.rowConfig.splice(this.activeRowIndex, 1);
      this.activeRowIndex = -1;
    },
    computedSlice() {
      const otherHeight = document.querySelector(".content-box").offsetHeight;
      const elPage = document.querySelector(".page-box");
      let tableHeight = elPage.offsetHeight - otherHeight;
      tableHeight -= parseInt(this.tableConfig.rowHeight) * 2;
      const rowNum = parseInt(
        tableHeight / parseInt(this.tableConfig.rowHeight)
      );
      if (rowNum > 0) {
        this.dataSlice = [];
        for (let i = 0; i < this.dataSources.length; i += rowNum) {
          this.dataSlice.push([
            i,
            i + rowNum > this.dataSources.length
              ? this.dataSources.length
              : i + rowNum
          ]);
        }
      }
    },
    computedFooter() {
      const countSlice = [];
      this.dataSlice.map(item => {
        let count = 0;
        let maxLength = 2;
        this.dataSources
          .slice(item[0], item[1])
          .map(item => (count += item[this.tableConfig.countField]));
        const result = [
          chineseTransform(count.toFixed(maxLength)),
          count.toFixed(maxLength)
        ];
        return countSlice.push(result);
      });
      this.countSlice = countSlice;
    },
    dropDownHandle({ e, row }) {
      // 拖拽到指定区域执行的方法
      const data = JSON.parse(e.dataTransfer.getData("data"));
      const type =
        e.dataTransfer.getData("type") &&
        JSON.parse(e.dataTransfer.getData("type"));
      if (type === "table") {
        const params = {
          type,
          colConfig: this.colConfig
        };
        this.rowConfig.push(params);
        return true;
      }
      row.content.push(data);
    },
    clickRow({ rowIndex, colIndex, toggle = true }) {
      if (toggle === false && this.activeRowIndex === rowIndex) {
        return null;
      }
      this.activeRowIndex = this.activeRowIndex !== rowIndex ? rowIndex : -1;
      this.activeColIndex = this.activeColIndex !== colIndex ? colIndex : -1;
      this.activeRowItem = this.rowConfig[rowIndex];
      this.activeColItem = this.rowConfig[rowIndex].content[colIndex];
    },
    addRow() {
      this.rowConfig.splice(this.activeRowIndex + 1, 0, {
        content: [{ text: "请输入/替换内容" }]
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style lang="scss" scoped>
.m-form-maker {
  > .g-top-bar {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #eee;

    > .item {
      padding: 0 10px;
      margin-bottom: 5px;
      display: inline-flex;
      border-right: 1px solid #eee;
    }

    .input-box {
      .el-input {
        padding: 0 5px;
        width: 70px;
      }

      .el-input__inner {
        padding: 0 5px;
      }

      span {
        padding: 0 5px;
        font-size: 12px;
      }
    }

    .font-style {
      .el-button {
        margin: 0 5px;
      }

      .el-select {
        margin: 0 5px;
        width: 100px;
      }
    }

    .page-input {
      display: flex;
      align-items: center;

      span {
        font-size: 13px;
        color: #333;
        margin-right: 5px;
      }

      .el-input {
        margin-right: 10px;
        width: 100px;
      }
    }
  }

  .g-main {
    display: flex;

    > .attr {
      width: 300px;
    }

    > .tool {
      width: 300px;

      .el-textarea {
        margin-top: 10px;
      }

      .el-card {
        margin-bottom: 10px;
      }
      .el-collapse-item__content {
        > .item {
          > span {
            display: inline-block;
            width: 80px;
          }
          margin-bottom: 5px;
        }
      }
    }
  }

  .g-maker-view {
    background-color: #eee;
    border: 1px solid #eee;
    box-sizing: border-box;
    overflow: auto;
  }
  .attr-box {
    .item {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
    }
  }

  th {
    position: relative;

    i {
      position: absolute;
      top: 0;
      z-index: 10;
    }
  }
  .header-box {
    .el-icon-close {
      display: block;
    }

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .el-icon-close {
    font-size: 14px !important;
  }
  .row-active {
    border: 1px dotted #999;
    border-radius: 5px;
    .el-icon-close {
      display: block;
    }
    .el-col {
      * {
        font-family: inherit;
        font-size: inherit;
      }
      > div {
        position: relative;
        border-right: 1px dotted #999;
        border-left: 1px dotted #999;
        border-radius: 3px;
        padding: 0px 20px 0px 10px;
      }
    }
  }
  .textarea-row {
    box-sizing: border-box;
    display: block;
    textarea {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      resize: none;
      overflow: hidden;
    }
  }
  .row-active {
    &.textarea-row {
      padding: 10px 15px;
    }
  }
  .table-row {
    margin: 5px 0;
    padding: 5px 0;
    border-top: 1px dashed #999;
    border-bottom: 1px dashed #999;
  }
}
.m-form-printer,
.m-form-maker {
  * {
    transform-origin: 0 0 0;
    font-weight: inherit;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .page-box {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #fff;
    margin: 0;
  }
  .content-box {
    position: relative;
    overflow: hidden;
  }
  .el-row {
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  table {
    text-align: center;
    border: 1px solid #000;
  }
  .row-float {
    position: absolute;
    z-index: 1;
  }
  pre {
    text-indent: 0;
  }
  .el-col span {
    white-space: nowrap;
  }
  .el-col p {
    white-space: nowrap;
  }
  .el-icon-close {
    position: absolute;
    right: 2px;
    display: none;
    color: #666;
    cursor: pointer;
  }
}
</style>
