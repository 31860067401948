import {
  OrdPurchaseOrder,
  InquireSearch,
  GoodsSearch
} from '~/views/Purchase'

export default {
  path: '/Purchase',
  name: 'Purchase',
  component: OrdPurchaseOrder,
  meta: {
    title: '采购',
    alias: '采购',
    authName: '采购管理',
    icon: 'icon-left_nav_purchase_ic'
  },
  children: [
    {
      path: 'OrderManage',
      name: 'Purchase/OrderMange',
      component: OrdPurchaseOrder,
      meta: {
        title: '采购管理',
        authName: '采购管理界面'
      }
    },
    {
      path: 'InquireSearch',
      name: 'Inquire/Search',
      component: InquireSearch,
      meta: {
        title: '询价记录',
        authName: '询价记录'
      }
    },
    {
      path: 'GoodsSearch',
      name: 'GoodsSearch',
      component: GoodsSearch,
      meta: {
        title: '查货',
        authName: '采购管理界面'
      }
    },
  ]
}
