<template>
  <div class="znl-stk-summary-tree" v-bind:style="{height:height+'px'}">
    <div class="option-box-tree">
      <znl-input from-type="input"
        :disable="false"
        placeholder="请输入标记"
        size="mini"
        class="ipt_box"
        layout="left"
        width="95px"
        inp-perc="100%"
        :clearable="true"
        :border="true"
        v-model="ModelValue"
        @keyup.enter.native="onSearch"
        type="text">
      </znl-input>
      <znl-button style-type="mac" :height='22' :width='25' tip="搜索" @click="onSearch" style="margin-left:2px;">
        <span> <i class="iconfont icon-search_ic"></i>  </span>
      </znl-button>
      <znl-button style-type="mac" :height='22' :width='25' tip="刷新" @click="onRefresh()">
        <span><i class="iconfont icon-table_refresh_btn_n"></i></span>
      </znl-button>
    </div>
    <znl-tree
      :datas="treeData"
      v-loading="loading"
      loading-type="animation"
      @node-click="onNodeClick"
      ref="znlTree"
      height="100%"
      :reFreshTree="reFreshTreeData"
      >
    </znl-tree>
  </div>
</template>
<script>
const CONFIG = {
  searchURL: 'StkStockMarker/Search'
}
export default {
  name: 'stkSummaryStockTree',
  data () {
    return {
      loading: false,
      treeData: [],
      ModelValue: '',
      searchTreeList: []
    }
  },
  props: {
    reFreshTreeData: String,
    height: Number
  },
  watch: {
    reFreshTreeData (val) {
      if (this.reFreshTreeData !== 'on' && this.reFreshTreeData !== 'off') {
        let node = _.filter(this.searchTreeList, item => {
          return item.Name === '<所有>'
        })[0]
        this.$refs.znlTree.dropUpdownClick(node)
      } else {
        this.onRefresh()
      }
    }
  },
  methods: {
    async onSearch () {
      let node = _.filter(this.searchTreeList, item => {
        return item.MarkerName === this.ModelValue
      })[0]
      if (node) {
        this.$emit('folder-change', node)
        this.$refs.znlTree.dropUpdownClick(node)
      }
    },
    async onRefresh () {
      this.loading = false
      this.onBindData(1)
    },
    async onSaveSetting (columns, pageSize) {
      await this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, this.defaultConfig)
      )
    },
    onBindData () {
      this.loading = true
      this.$post(CONFIG.searchURL, data => {
        let newHasChildlist = []
        _.each(data, (item, index) => {
          newHasChildlist.push({
            FolderId: item.MID,
            HasAdd: false,
            HasChild: false,
            HasDelete: false,
            HasRename: false,
            IsFolder: false,
            IsUnfold: false,
            Name: item.MarkerName + ' (' + (item.StockCount || 0) + ')',
            MarkerName: item.MarkerName,
            MarkValue: item.MarkerValue || '',
            ParentId: 0,
            StockCount: item.StockCount || 0,
            Seq: 1
          })
        })

        let notMarkingList = _.filter(newHasChildlist, (item) => { return item.MarkValue === -1 && item.MarkerName !== '<未打标记>' })
        let notMarkingObj = _.filter(newHasChildlist, (item) => { return item.MarkerName === '<未打标记>' })[0]

        let notMarkingName = '<未打标记> (0)'
        if (this.hasValue(notMarkingObj)) { notMarkingName = notMarkingObj.Name }

        let extensionMarkList = _.filter(newHasChildlist, (item) => { return item.MarkValue === 1 || item.MarkValue === 2 || item.MarkValue === 3 })
        // let extensionMarkCount = 0
        let extensionMarkValue = ''
        _.each(extensionMarkList, (item, index) => {
          // extensionMarkCount = extensionMarkCount + item.StockCount
          extensionMarkValue += item.MarkValue + ','
        })

        let subscriptionMarkupList = _.filter(newHasChildlist, (item) => { return item.MarkValue === 4 || item.MarkValue === 6 || item.MarkValue === 9 })
        // let subscriptionMarkupCount = 0
        let subscriptionMarkupValue = ''
        _.each(subscriptionMarkupList, (item, index) => {
          // subscriptionMarkupCount = subscriptionMarkupCount + item.StockCount
          subscriptionMarkupValue += item.MarkValue + ','
        })

        let customMarkList = _.filter(newHasChildlist, (item) => { return item.MarkValue !== -1 && item.MarkValue !== 1 && item.MarkValue !== 2 && item.MarkValue !== 3 && item.MarkValue !== 4 && item.MarkValue !== 6 && item.MarkValue !== 9 })
        // let customMarkCount = 0
        // _.each(customMarkList, (item, index) => {
        //   customMarkCount = customMarkCount + Number(item.StockCount || 0)
        // })
        let setTreelist = []
        setTreelist.unshift(
          {
            FolderId: 0,
            HasAdd: true,
            HasChild: false,
            HasDelete: true,
            HasRename: true,
            IsFolder: true,
            IsUnfold: true,
            Name: '<所有>',
            MarkerName: '',
            MarkValue: '',
            ParentId: 0,
            Seq: 0
          },
          {
            FolderId: 0,
            HasAdd: true,
            HasChild: notMarkingList.length > 0,
            Children: notMarkingList,
            HasDelete: true,
            HasRename: true,
            IsFolder: true,
            IsUnfold: true,
            Name: notMarkingName,
            MarkerName: '<未打标记>',
            MarkValue: '-1',
            ParentId: 0,
            Seq: 0
          },
          {
            FolderId: 0,
            HasAdd: true,
            HasChild: extensionMarkList.length > 0,
            Children: extensionMarkList,
            HasDelete: true,
            HasRename: true,
            IsFolder: true,
            IsUnfold: true,
            Name: '订阅标记',
            MarkerName: '订阅标记',
            MarkValue: extensionMarkValue.replace(/,$/gi, ''),
            ParentId: 0,
            Seq: 0
          },
          {
            FolderId: 0,
            HasAdd: true,
            HasChild: subscriptionMarkupList.length > 0,
            Children: subscriptionMarkupList,
            HasDelete: true,
            HasRename: true,
            IsFolder: true,
            IsUnfold: true,
            Name: '推广标记',
            MarkerName: '推广标记',
            MarkValue: subscriptionMarkupValue.replace(/,$/gi, ''),
            ParentId: 0,
            Seq: 0
          },
          {
            FolderId: 0,
            HasAdd: true,
            HasChild: customMarkList.length > 0,
            Children: customMarkList,
            HasDelete: true,
            HasRename: true,
            IsFolder: true,
            IsUnfold: true,
            Name: '自定义标记',
            MarkerName: '',
            MarkValue: '-ZNL-CUSTOM-MARK-',
            ParentId: 0,
            Seq: 0
          }
        )

        this.treeData = setTreelist
        this.searchTreeList = newHasChildlist.concat(setTreelist)
        _.delay(() => {
          this.loading = false
        }, 200)
      })
    },
    onNodeClick (node) {
      this.$emit('folder-change', node)
    },
    bindTreeFromOldData () {
      this.treeData = JSON.parse(JSON.stringify(this.oldTreeData))
    }
  },
  mounted () {
    this.onBindData()
  }
}
</script>
<style lang="scss">
.znl-stk-summary-tree {
  border-right: 1px solid #d8d8d8;
  height: 100%;
  display: flex;
  flex-direction: column;
  .znl-tree {
    overflow: hidden;
    flex: 1;
    margin-top: 40px;
    margin-bottom: 0px;
  }
  .znl-tree [forcefallback="true"] {
    padding-left: 2px;
  }
  .option-box-tree {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 170px;
    background: #e6e6e6;
    height: 32px;
    line-height: 32px;
    border-bottom: 1px solid #bbb9b9;
    border-top: 1px solid #bbb9b9;
    .ipt_box {
      margin-left: 10px;
    }
  }
  .znl-tree {
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 40px;
  }
  .znl-tree .folder-name:hover {
    background-color: transparent;
  }
  .znl-tree .folder-name {
    cursor: pointer;
    padding-left: 15px;
  }
  .znl-tree .icon-arrow-drop-updown {
    // z-index: 9009;
  }
  .icon-left_nav_show_btn {
    display: none !important;
  }
  .folder-name .folder-svg {
    display: none;
  }
  .tree-row {
    padding-left: 0;
  }
  .znl-tree .folder-name.active {
    background: transparent;
  }
  .znl-tree .folder-name .itemname {
    display: inline-block;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 0 4px 0px 4px;
    height: 20px;
    line-height: 22px;
  }
  .znl-tree .folder-name.active span {
    background: #ffff00;
    border: 1px solid #d9d9d9;
    padding: 0 4px 0px 4px;
    border-radius: 4px;
    height: 20px;
    line-height: 22px;
  }
}
</style>
