export const isClient = () => window.cefQuery !== undefined

export function nativeInvoke(service, data = {}) {
  if (!isClient()) {
    // TODO: 更新提示消息
    return Promise.reject('操作失败，该操作需要快捷版软件协助')
  }

  try {
    return new Promise((resolve, reject) => {
      const content = JSON.stringify(data)
      window.cefQuery({
        request: service,
        data: content,
        onSuccess: res => resolve(JSON.parse(res)),
        onFailure: (code, err) => reject({
          code,
          err,
          type: 'cefQuery',
          request: {
            service,
            data
          }
        }),
        persistent: false
      })
    })
  } catch (e) {
    return Promise.reject(e)
  }
}
