import { RegisterView } from '~/views/Register/index'

export default {
  path: '/register',
  name: 'register',
  component: RegisterView,
  meta: {
    title: '注册',
    closable: false,
    display: false,
    anonymous: true
  }
}
