<template>
  <znl-dialog
      :visible="visible&&dialogShow"
      height="345px"
      width="400px"
      class="offical-price-box"
      :title="title"
      :loading="saveloading"
      :loading-text="elementLoadingText"
      @close="()=>{$emit('close')}"
      @confirm="onSave">
      <div slot="znl-dialog">
          <el-row type="flex" slot="znl-dialog" class="box-body">
            <el-col>
              <el-row class="form_Row_W100">
                <znl-input form-type="input"
                        :disabled="false"
                        style=" text-align: left;"
                        placeholder="销售定价"
                        title="销售定价："
                        size="mini"
                        layout="left"
                        :clearable="true"
                        :border="true"
                        inp-perc="100%"
                        width='180px'
                        title-width="60px"
                        @blur="salesPriceBlur"
                        v-model="itemModel.SalesPrice">
                </znl-input>
              </el-row>
              <el-row class="form_Row_W100">
                <div class="o-price">
                  <table style="width:340px">
                    <tr style="font-size: 12px;">
                      <td width='110'>数量</td>
                      <td width='70'>折扣率</td>
                      <td width='110'>未税价(¥)</td>
                      <td></td>
                    </tr>
                    <tr v-for="(item, index) in dataSource" :key="index" class="item">
                      <td>
                        <znl-input
                            form-type="input"
                            placeholder=""
                            size="mini"
                            layout="left"
                            :is-must-fill="false"
                            width='95%'
                            :border="true"
                            :clearable="true"
                            v-model="item.Qty"
                            type="text">
                        </znl-input>
                      </td>
                      <td>
                        <znl-input
                            form-type="input"
                            placeholder=""
                            size="mini"
                            layout="left"
                            :is-must-fill="false"
                            width='90%'
                            :clearable="true"
                            :border="true"
                            v-model="item.DiscountRate"
                            @blur="reCalcPrice(item)"
                            type="text">
                        </znl-input>
                      </td>
                      <td>
                        <znl-input
                            form-type="input"
                            placeholder=""
                            size="mini"
                            layout="left"
                            :is-must-fill="false"
                            width='100%'
                            :clearable="true"
                            v-model="item.Price"
                            :border="true"
                            @blur="formatPrice(item)"
                            type="text">
                        </znl-input>
                      </td>
                      <td style="width:30px">
                        <znl-button :height='22' @click="removePrice(index)" tip="移除" v-show="showDelBtn">
                        <i class="el-icon-delete"></i>
                        </znl-button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="4" style="text-align:center">
                        <znl-button :height='30' style="width:190px; margin-top: 15px;" @click="addPrice()">
                        增加阶梯报价
                        </znl-button>
                      </td>
                    </tr>
                  </table>
                </div>
              </el-row>
            </el-col>
          </el-row>
      </div>

      <el-row align="middle"  type="flex" justify="end" slot="footer" class="dialog-footer">
        <el-col style="display: flex;justify-content: flex-end;align-items: center">
          <znl-button :height='30' :width='60' @click="onCancel()">取消
          </znl-button>
          <znl-button :height='30' :width='60' @click="onSave()" style="margin-left: 8px;" style-type="main">确定
          </znl-button>
        </el-col>
      </el-row>
  </znl-dialog>
</template>
<script>

export default {
  name: 'PubStkStockOfficalPrice',
  components: {
  },
  data () {
    return {
      dialogShow: false,
      // 默认值，默认折扣率
      defaultPriceList: [
        {
          Qty: 100,
          Price: 0,
          DiscountRate: 9
        },
        {
          Qty: 1000,
          Price: 0,
          DiscountRate: 8
        },
        {
          Qty: 10000,
          Price: 0,
          DiscountRate: 6
        },
        {
          Qty: 100000,
          Price: 0,
          DiscountRate: 5
        }
      ],
      itemModel: {
        Model: '',
        Brand: '',
        MakeYear: '',
        Packaging: '',
        ExpirationTime: '',
        SalesPrice: null,
        StepPrice: '',
        PriceList: []
      },
      showDelBtn: false,
      standardPriceIndex: 0,
      radio: false,
      config: {
        AddUrl: 'PubStkStock/NormalQuotedAdd',
        SaveUrlStockSummary: 'StockSummary/SaveStepPrice'
      },
      defaultDS: { IsStandQuote: false, Qty: null, Price: null, DiscountRate: null },
      dataSource: [{ IsStandQuote: false, Qty: null, Price: null, DiscountRate: null }],
      iscontinueSave: false,
      saveloading: false,
      elementLoadingText: '正在保持数据,请稍后...'
    }
  },
  props: {
    title: {
      type: String,
      default: () => {
        return '官方报价'
      }
    },
    Modek: String,
    Brand: String,
    MakeYear: String,
    Packaging: String,
    officalPriceParam: Object,
    visible: Boolean,
    isRefresh: Boolean, // 是否刷新
    openFrom: String, // 从哪里打开的官方报价
    sumGuid: String, // 汇总库存guid
    salesPrice: Number
  },
  watch: {
    isRefresh: {
      handler (value) {
        this.onBindData()
      },
      immediate: true
    },
    dataSource: {
      handler (newVal) {
        if (newVal.length > 1) {
          this.showDelBtn = true
        } else {
          this.showDelBtn = false
        }
      },
      immediate: true
    },
    visible (val) {
      if (val) {
        this.onBindData()
        this.dialogShow = true
      }
    },
    officalPriceParam (val) {
      this.onBindData()
    }
  },
  methods: {
    isInt (val) {
      return /^\d+$/.test(val)
    },
    isEmpty (val) { return val === null || val === '' || val === undefined },
    onBindData () {
      this.itemModel.SalesPrice = this.salesPrice
      if (this.officalPriceParam.StepPrice !== null && this.officalPriceParam.StepPrice !== undefined && this.officalPriceParam.StepPrice !== '') {
        let json = JSON.parse(this.officalPriceParam.StepPrice)
        if (json.length < 4) {
          let newlist = []
          for (let i = 0; i < 4 - json.length; i++) {
            newlist.push({
              IsStandQuote: false, Qty: null, Price: null })
          }
          this.dataSource = json.concat(newlist)
        } else {
          this.dataSource = json
        }
      } else {
        // let newlist = []
        // for (let i = 0; i < 4; i++) {
        //   newlist.push({
        //     IsStandQuote: i === 0, Qty: null, Price: null})
        // }
        // this.dataSource = newlist
        this.dataSource = []
        _.each(this.defaultPriceList, item => {
          let m = {
            Qty: item.Qty,
            DiscountRate: item.DiscountRate,
            Price: null
          }
          if (this.itemModel.SalesPrice !== null && !_.isUndefined(this.itemModel.SalesPrice) && this.itemModel.SalesPrice !== 0) {
            m.Price = this.toFloat(this.itemModel.SalesPrice * m.DiscountRate / 10, 6)
          }
          this.dataSource.push(m)
        })
      }
    },
    onCancel () {
      this.dialogShow = false
      this.$emit('confirm', true)
    },
    onSave () { // 保存
      let list = this.CheckData()
      if (list.length === 0) {
        return
      }
      this.itemModel.Model = this.officalPriceParam.Model
      this.itemModel.Brand = this.officalPriceParam.Brand
      // this.itemModel.MakeYear = this.officalPriceParam.MakeYear
      this.itemModel.Packaging = this.officalPriceParam.Packaging
      this.itemModel.StepPrice = JSON.stringify(list)
      this.itemModel.SUMGuid = this.sumGuid
      this.saveloading = true
      this.$post((this.openFrom === 'StockSummary' ? this.config.SaveUrlStockSummary : this.config.AddUrl), this.itemModel, (data) => {
        this.saveloading = false
        if (data > 0) {
          this.$message({ message: '保存成功', type: 'success' })
          this.$emit('confirm', true)
        } else {
          this.$message({ message: '保存失败', type: 'error' })
        }
      })
    },
    CheckData () { // 检查数据的正确性，并返回
      // if (this.itemModel.ExpirationTime === '') {
      //   this.$message({ message: '请选择一个报价有效时间', type: 'error' })
      //   return []
      // }

      let msg = ''
      let list = []
      _.each(this.dataSource, (item) => {
        if (item.Qty !== null || item.Price !== null) {
          if (!this.isInt(item.Qty)) {
            msg = '数量必须为数字'
            return
          }
          if (this.isEmpty(item.Price) || !isFinite(item.Price)) {
            msg = '报价必须为数字或者小数'
            return
          }
          if (this.isEmpty(item.DiscountRate) || !isFinite(item.DiscountRate)) {
            msg = '折扣率必须为数字或者小数'
            return
          }
          item.Price = this.toFloat(item.Price, 6)
          item.DiscountRate = this.toFloat(item.DiscountRate, 6)
          list.push(item)
        }
      })
      if (msg !== '') {
        this.$message({ message: msg, type: 'error' })
        return []
      }

      if (list === 0) {
        this.$message({ message: '请至少增加一个阶梯报价', type: 'error' })
        return []
      }
      // let isSeleted = false
      // _.each(list, (item) => {
      //   if (item.IsStandQuote) {
      //     isSeleted = true
      //     return
      //   }
      // })
      // if (isSeleted === false) {
      //   this.$message({ message: '请勾选其中一个阶梯报价作为标准报价', type: 'error' })
      //   return []
      // }

      return list
    },
    addPrice () {
      let newItem = _.extend({}, this.defaultDS)
      this.dataSource.push(newItem)
    },
    removePrice (index) {
      this.dataSource.splice(index, 1)
    },
    standardPriceChange (index) {
      this.standardPriceIndex = index
      _.each(this.dataSource, (item, i) => {
        if (index === i) {
          item.IsStandQuote = true
        } else {
          item.IsStandQuote = false
        }
      })
    },
    addMonth () {
      var now = new Date()
      now.setDate(now.getDate() + 30)
      return now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate()
    },
    formatPrice (item) {
      item.Price = this.toFloat(item.Price, 6)
    },

    // 重新计算未税价
    reCalcPrice (item) {
      if (item.DiscountRate && this.itemModel.SalesPrice) {
        item.Price = this.itemModel.SalesPrice * item.DiscountRate / 10
        item.Price = this.toFloat(item.Price, 6)
      }
    },

    // sales price change
    salesPriceBlur () {
      _.each(this.dataSource, item => {
        this.reCalcPrice(item)
      })
    }
  }
}
</script>

<style lang="scss">
@import "app/assets/styles/_form";
.offical-price-box {
  .znl-dialog__body {
    overflow: auto;
  }
  .box-body {
    padding-left: 20px;
  }
}
</style>
