import { Forbidden } from '~/views'

export default {
  path: '/Forbidden',
  name: 'Forbidden',
  component: Forbidden,
  meta: {
    title: '权限限制',
    closable: false,
    display: false
  }
}
