import { switchTab } from '~/lib/native'

export async function stockInToSCM(OrderGuid) {
  if (window.cefQuery === null || window.cefQuery === undefined) {
    window.open("https://znlscm.com")
    return false;
  }
  let date = new Date()
  switchTab('SupplyChain', {
    Action: 'Order',
    OrderGuid: OrderGuid,
    OrderType: 'StockIn',
    _t: date.getTime()
  })
}

export async function purchaseToSCM(OrderGuid) {
  if (window.cefQuery === null || window.cefQuery === undefined) {
    window.open("https://znlscm.com")
    return false;
  }
  let date = new Date()
  switchTab('SupplyChain', {
    Action: 'addOrder',
    OrderGuid: OrderGuid,
    OrderType: 'purchase',
    _t: date.getTime()
  })
}

//打开供应链
export async function openSCM(opt) {
  if (window.cefQuery === null || window.cefQuery === undefined) {
    window.open("https://znlscm.com")
    return false;
  }
  switchTab('SupplyChain', opt)
}

// 切换到月结服务
export async function openLoanService(stkInBillNo, from) {
  let date = new Date()
  // if (window.cefQuery === null || window.cefQuery === undefined) {
  //   this.$message({ message: "网页版暂不支持直接切换页签", type: "warning" });
  //   return false;
  // }
  switchTab('LoanService', {
    orderNo: stkInBillNo,
    from: from,
    _t: date.getTime()
  })
}
