<template>
  <!-- 报价记录 -->
  <znl-table
    ref="table"
    :header="header"
    height="100%"
    layoutType="other"
    loading-type="animation"
    :columns="columns"
    :item-source="itemSource"
    :column-colors="columnColors"
    :row-colors="rowColors"
    :summary-columns="summaryColumns"
    :search-fields="onSearch"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :role="role"
    :users="$store.state.users"
    :on-init="onInit"
    :is-init="true"
    :loading="isMini"
    :on-search="onSearch"
    :show-header="false"
    :is-show-rightbtns="false"
    :on-refresh="onRefresh"
    @on-show-trigger="e => {hideShowColTrigger(moduleCode, e.col)}"
    :drag-done="col=>setTableColWidth(moduleCode, col)"
    @drag-col-position="(cols)=>{columns = cols, saveErpTableConfig(moduleCode, cols,true)}"
    @on-config-save="(cols)=>{columns = cols, saveErpTableConfig(moduleCode, cols,false)}"
    @on-reset-click="resetTableConfig(moduleCode)"
    @ondblclick="val=>$emit('ondblclick', val)"
  ></znl-table>
</template>
<script>

import { mixin as znlMethodsMixin, watchMixin as znlMethodswatch } from '@c_common/znlGrid/scripts/znlMethods'
import { erpTableSetHandler } from '@scripts/methods/common'

const CONFIG = {
  configURL: 'HisOfferPrice/GetConfig',
  saveConfigURL: 'HisOfferPrice/SaveConfig',
  resetConfigURL: 'HisOfferPrice/ResetConfig',
  searchURL: 'HisOfferPrice/Search'
}

const CONFIGCustomerRequire = {
  configURL: 'CustomerRequire/GetConfig',
  saveConfigURL: 'CustomerRequire/SaveConfig',
  resetConfigURL: 'CustomerRequire/ResetConfig',
  searchURL: 'CustomerRequire/Search'
}

export default {
  name: 'HisofferpriceByModel',
  mixins: [znlMethodsMixin, znlMethodswatch, erpTableSetHandler], // 第二个方法需要添加后才能监控到外部条件改变也可以刷新页面
  config: CONFIG,
  components: {

  },
  data() {
    return {
      config: {},
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      searchFields: {},
      filterFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1'
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    isMini: {
      type: Boolean,
      default: false
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    },
    header: {
      type: String,
      default: () => {
        return ''
      }
    },
    isFieldsSearch: {
      type: Boolean,
      default: true
    },
    height: String,
    parentModule: String
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage(this.moduleCode, this.config.configURL)
      }
      this.defaultConfig = config
      this.columns = config.ColumnConfigs
      this.pageSize = config.PageSize
      this.role = config.Role
      this.searchFields = this.searchModel
      this.$refs.table.init()
      this.initData && this.onBindData()
      // this.onBindData(1)
    },
    onSearch(params) {
      this.searchFields = params
      this.onBindData()
    },
    async onHeadSearch(item) {
      this.searchFields = _.extend(this.searchFields, {
        Model: item.Model,
        CustomerName: item.CustomerName,
        CustomerGUID: item.CustomerID
      })
      await this.onBindData(1)
    },
    onBindData(pageIndex) {
      var vm = this
      let params = _.extend({}, this.searchModel, this.searchFields)
      var jsonData = _.extend({}, {
        FieldWhereString: JSON.stringify(params),
        PageIndex: pageIndex || 1,
        PageSize: this.pageSize
      }, this.customerModel)
      return this.$post(this.config.searchURL, jsonData, (data) => {
        vm.$emit('page-loading', false)
        vm.$emit('set-total-count', data.TotalCount)
        vm.pageIndex = jsonData.PageIndex
        vm.itemSource = data.ResultList
        vm.totalCount = data.TotalCount
      })
    }
  },

  computed: {
    moduleCode () {
      if (this.parentModule === 'customer') {
        return 'CustomerRequire'
      } else {
        return 'HisOfferPrice'
      }
    }
  },

  async mounted() {
    if (this.parentModule === 'customer') {
      this.config = _.extend({}, CONFIGCustomerRequire)
    } else {
      this.config = _.extend({}, CONFIG)
    }
    await this.onInit()
  }
}
</script>

<style lang="scss">
</style>
