import {
  FinAccountManage,
  FinCapitalFlow,
  FinInAndExpDetails,
  FinPayInAdvance,
  FinRecInAdvance,
  FinManagement
} from '~/views/Fin'

export default {
  path: '/fin',
  name: 'Fin',
  component: FinAccountManage,
  meta: {
    title: '财务',
    alias: '银行账户',
    authName: '财务',
    icon: 'icon-left_nav_money_ic'
  },
  children: [
  // {
  //   path: 'accountmanage',
  //   name: 'Fin/AccountManage',
  //   component: FinAccountManage,
  //   meta: {
  //       title: '银行账户',
  //       authName: '银行账户'
  //     }
  // },
  // {
  //   path: 'capitalflow',
  //   name: 'Fin/FinCapitalFlow',
  //   component: FinCapitalFlow,
  //   meta: {
  //       title: '资金流水',
  //       authName: '资金流水'
  //     }
  // },
  // {
  //   path: 'inandexpdetails',
  //   name: 'Fin/FinInAndExpDetails',
  //   component: FinInAndExpDetails,
  //   meta: {
  //       title: '收支明细',
  //       authName: '收支明细'
  //     }
  // },
  // {
  //   path: 'payinadvance',
  //   name: 'Fin/FinPayInAdvance',
  //   component: FinPayInAdvance,
  //   meta: {
  //       title: '预付款',
  //       authName: '预付款'
  //     }
  // },
  // {
  //   path: 'recinadvance',
  //   name: 'Fin/FinRecInAdvance',
  //   component: FinRecInAdvance,
  //   meta: {
  //       title: '预收款',
  //       authName: '预收款'
  //     }
  // },
  {
    path: 'FinManage',
    name: 'Fin/FinManage',
    component: FinManagement,
    meta: {
        title: '财务'

      }
  }
  ]
}
