import {
  isClient
} from '~/lib/runtime'
import {
  getSessionId as getSessionIdFromClient
} from '~/lib/native'

export async function getSessionId () {
  const debug = process.env.NODE_ENV !== 'production'
  // console.log(debug, 'is debug')
  if (debug) {
    // 从url获取sessionid
    let queryStr = location.search
    let sessionId = ''
    if (queryStr) {
      if (queryStr.substr(queryStr.length - 1) === "/") {
        queryStr = queryStr.substr(0, queryStr.length - 1)
      }
      queryStr = queryStr.substr(1)
      let array = queryStr.split("&")

      for(var i in array) {
        var pair = array[i].split('=')

        if (pair[0] === 'session') {
          sessionId = pair[1]
          break;
        }
      }
    }
    if (sessionId) {
      console.log('get sessionid from url. sessionid=' + sessionId);
      return sessionId
    }
  }
  // 检测到客户端模式时，优先从客户端读取sessionId
  if (isClient()) {
    return await getSessionIdFromClient()
  }

  let sessionId = getSessionIdFromHtml();
  if (sessionId) {
    return sessionId;
  }

  return await getSessionIdFromClient()
}

function getSessionIdFromHtml () {
  const el = document.getElementById('__SESSION_ID__')
  if (!el) {
    return '';
  }

  return el.innerHTML.trim();
}
