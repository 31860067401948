<template>
<div>
  <znl-dialog :visible="visible"
              width="800px"
              height="600px"
              :title="title2"
              :is-footer-show="false"
              :append-to-body="true"
              class="m-show-image"
              @close="()=>{this.isRefresh = !this.isRefresh, $emit('close', this.deletedImgGuids)}">
    <div slot="znl-dialog" class="img-box">
      <znl-carousel :autoplay="false" :initial-index="initialIndex" arrow="always">
        <znl-carousel-item v-for="item in imageDataSource" :key="item.ImgGuid">
          <div :class="viewMode">
            <div>
              <img class="img-item" :src="item.FileUrl" />
            </div>
          </div>
          <div class="btn-box">
            <znl-button style-type="mac"
              tip="切换查看模式"
              class="download-btn"
              v-show="showBtns"
              @click="changeView()">
              <span>{{viewModeText}}</span>
            </znl-button>

           <znl-button style-type="mac"
              tip="上传图片"
              class="download-btn"
              v-show="showBtns"
              @click="onUpload()">
              <span> <i class="iconfont icon-uploading-wh_btn_ic"></i> </span>
            </znl-button>

            <znl-button style-type="mac"
              tip="下载图片"
              class="download-btn"
              v-show="showBtns"
              @click="onDownload(item.FileName, item.FileUrl)">
              <span>
                <i class="iconfont icon-download_btn_ic"></i> </span>
            </znl-button>

            <znl-button style-type="mac"
              class="delete-btn"
              tip="删除图片"
              v-if="showBtns&&hasDelete"
              @click="onDelete(item.ImgGuid)">
              <span>
                <i class="iconfont icon-delete_btn_ic"></i> </span>
            </znl-button>
          </div>
        </znl-carousel-item>
      </znl-carousel>
    </div>
    <!--上传图片-->
    <image-upload
    :visible="imageUploadVisible"
    :imageStkGUID='this.StkGUID'
    @close="()=>{this.imageUploadVisible = false}"
    @confirm="()=>{this.imageUploadVisible = false,$emit('close', this.deletedImgGuids)}">
    </image-upload>

  </znl-dialog>
</div>
</template>

<script>
import ImageUpload from '@c_modules/StkSum/ImageUpload'
export default {
  name: 'StockImageUpload',
  mixins: [],
  components: {
    ImageUpload
  },
  data () {
    return {
      config: {
        deleteImageURL: 'StockImage/Delete'
      },
      imageUploadVisible: false, // 上传图片
      // title2: this.title,
      isRefresh: false,
      showBtns: true,
      autoPlay: true,
      StkGUID: '',
      imageDataSource: [],
      deletedImgGuids: [], // 已删除的图片
      initialIndex: 0, // 初始状态激活的幻灯片的索引，从 0 开始
      refreshClick: false, // 刷新上传组件的上次的单击动作
      viewMode: 'img-item-div',
      viewModeText: '原图'
    }
  },
  props: {
    loading: Boolean,
    visible: Boolean,
    title: String,
    hasDelete: Boolean,
    hasUpload: {
      type: Boolean,
      default: false
    },
    imageStkGUID: String,
    imageList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    title2 () {
      return this.title
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.isRefresh = !this.isRefresh
      }
      _.each(this.imageList, item => {
        if (item.FileUrl.indexOf('https:') !== 0) {
          item.FileUrl = `https:${item.FileUrl}`
        }
      })
      this.imageDataSource = JSON.parse(JSON.stringify(this.imageList))
    }
  },
  methods: {
    async onInit () {
    },

    onCancel () {
      this.$emit('close', this.deletedImgGuids)
    },

    onDownload (fileName, fileUrl) {
      this.downFile({ FileName: fileName, FileUrl: fileUrl }, (res) => { })
    },
    onUpload () {
      this.StkGUID = this.imageStkGUID
      this.imageUploadVisible = true
    },

    onDelete (imgGuid) {
      let _this = this
      this.autoPlay = false
      this.$confirm('是否删除该图片?', '提示', {
        confirmButtonText: '确认删除',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.confirmDelete(imgGuid)
        }).catch(() => {
          _this.autoPlay = true
        })
    },
    confirmDelete (imgGuid) {
      let _this = this
      this.$post(this.config.deleteImageURL, { ImgGuids: [imgGuid] }, (data, logic) => {
        if (logic.code !== 200) {
          this.$message({ message: logic.msg, type: 'error' })
        } else {
          _this.autoPlay = true
          this.$message({ message: '删除成功', type: 'success' })
          this.deletedImgGuids.push(imgGuid)
          // 移除数组内的数据
          let index = _.findIndex(_this.imageDataSource, m => m.ImgGuid === imgGuid)
          if (index > -1) {
            _this.imageDataSource.splice(index, 1)
          }
          if (_this.imageDataSource.length === 0) {
            _this.onCancel()
          }
          _this.initialIndex = _this.initialIndex - 1
        }
      })
    },

    changeView () {
      if (this.viewMode === 'img-item-div') {
        this.viewMode = 'img-item-div2'
        this.viewModeText = '等比压缩'
      } else {
        this.viewMode = 'img-item-div'
        this.viewModeText = '原图'
      }
    },

    getImageSize() {
      // 记录当前时间戳
      var start_time = new Date().getTime();
      // 图片地址 后面加时间戳是为了避免缓存
      var img_url = 'https://znl-erp-upload.oss-cn-shenzhen.aliyuncs.com/erp-stock-img/ede45b8d-705b-4dec-a053-427c0e3bb0f7/20190228/GfC7sR_1551333956316.png'
      // 创建对象
      var img = new Image()
      // 改变图片的src
      img.src = img_url;
      // 定时执行获取宽高
      var check = function() {
        // 只要任何一方大于0
        // 表示已经服务器已经返回宽高
        if (img.width > 0 || img.height > 0) {
          var diff = new Date().getTime() - start_time;

          var str =
            "from:check : width:" +
            img.width +
            ",height:" +
            img.height +
            ", time:" +
            diff +
            "ms"
          clearInterval(set)
          console.log(str)
        }
      }
      var set = setInterval(check, 40)
    }
  },
  created () {
    this.onInit()
  }
}
</script>

<style lang="scss">
.m-show-image {
  .znl-carousel__container{
    height: 555px;
  }
  .znl-carousel__indicators{
    display: none;
  }
  .img-box {
    height: 600px;
    &:hover{
      .btn-box{
        display: inline;
      }
    }
  }
  .img-item-div {
    width:100%;
    height:100%;
    overflow:auto;
    text-align: center;
    vertical-align: middle;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .img-item-div2 {
    width:100%;
    height:100%;
    overflow:auto;
    text-align: center;
    vertical-align: middle;
    div {
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
  .btn-box{
    position: absolute;
    display: none;
    top: 0;
    right: 10px;
    .znl-button{
      margin-right: 5px;
    }
  }
}

.el-message-box__wrapper {
  z-index: 9999 !important;
}
</style>
