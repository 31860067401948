<template>
  <div class="module-dialog">
    <znl-table
      ref="table"
      gridtype="action"
      checkboxBindingKey="StkGUID"
      :showCheck="true"
      box-class="module-dialog"
      :columns="columns"
      :item-source="itemSource"
      v-loading="loading"
      :element-loading-text="loadingText"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :on-page-changed="onBindData"
      :is-fields-search="isShowFiedsSearch"
      :search-fields="onSearch"
    >
      <div slot="header" class="v-table-toolbar search-box">
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="请输入型号"
          size="mini"
          layout="left"
          width="150px"
          inp-perc="100%"
          :is-upper-case="true"
          :clearable="true"
          :border="true"
          v-model="searchFields.Model"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>
        <znl-button type="multiple" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
        <el-checkbox v-model="isZero" size="small">包含零库存</el-checkbox>
      </div>
    </znl-table>
  </div>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
const CONFIG = {
  searchURL: "StkStockMini/Search",
  configURL: "StkStock/GetConfig"
};

export default {
  name: "SelectStockList",
  mixins: [getCommonDataMixin],
  config: CONFIG,
  components: {},
  data() {
    return {
      isCheck: false,
      isZero: false,
      columns: [],
      itemSource: [],
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      searchFields: {
        Model: ""
      },
      IsOutQty: true,
      GCode: "StkStock",
      loading: false,
      loadingText: "数据加载中,请稍后...",
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      isShowFiedsSearch: false,
      invTypeList: [], // 库存类型集合
      permission: {
        viewBuyPrice: true, // 看采购价
        viewSalesPrice: true, // 看销售价
        noSeeSupplier: false // 不看供应商
      }
    };
  },
  watch: {},
  computed: {},
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isShowLinkedQtyCol: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInit: async function() {
      this.$emit("page-loading", true);
      let config = await this.isSaveStorage("stkStockCheck", CONFIG.configURL);

      let columns = config.ColumnConfigs;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;

      this.resourceList = config.ResourceList;

      this.isShowLinkedQtyCol &&
        columns.unshift({
          ColumnWidth: 80,
          BindField: "LinkStockQty",
          CTitle: "填写配货量",
          isFrozen: true,
          IsReadonly: false,
          IsRequired: false,
          DataType: 2
        });

      if (!this.IsMainAccount) {
        this.setHideColumns(columns);
      }

      let colIndex = _.findIndex(columns, item => item.BindField === "InvType");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 58,
          options: this.invTypeList
        });
      }

      this.columns = columns;
      this.pageSize = config.PageSize;

      this.$refs.table.init();
      this.$nextTick(() => {
        if (this.initData) {
          this.onBindData();
        }
        this.loading = false;
        this.$emit("page-loading", false);
      });
    },
    //  /** **********权限控制start*************/
    setHideColumns(columns) {
      // 成本价权限控制BuyPrice 采购价未税BuyPrice 和TaxBuyPrice 采购价(含税)  和 成本金额TotalCostAmount
      let viewBuyPrice = this.getSpecialResourceByCode("ViewBuyPrice");

      // 成本价权限控制 SalesPrice 销售定价 FollowPrice 建议销售价
      let viewSalesPrice = this.getSpecialResourceByCode("ViewSalesPrice");

      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      let noSeeSupplier = this.getSpecialResourceByCode("NoSeeSupplier");

      _.remove(columns, item => {
        if (item.BindField === "StockImage" || item.BindField === "AdoptQty") {
          return true;
        }

        // 不看成本价
        if (!viewBuyPrice) {
          switch (item.BindField) {
            case "BuyPrice": // 未税采购价
            case "TaxBuyPrice": // 含税采购价
            case "TotalCostAmount":
            case "CostAmountInTax":
              this.permission.viewBuyPrice = false;
              return true;
          }
        }
        // 不看销售价
        if (
          !viewSalesPrice &&
          (item.BindField === "SalesPrice" || item.BindField === "FollowPrice")
        ) {
          this.permission.viewSalesPrice = false;
          return true;
        }
        // 不看供应商
        if (noSeeSupplier && item.BindField === "SupplierName") {
          this.permission.noSeeSupplier = true;
          return true;
        }
        // 看备注1 没有这个权限隐藏列
        if (item.BindField === "Remark" && !this.hasRes("ViewRemark1")) {
          return true;
        }
        // 看备注2 没有这个权限隐藏列
        if (item.BindField === "Remark1" && !this.hasRes("ViewRemark2")) {
          return true;
        }
        // 看备注3 没有这个权限隐藏列
        if (item.BindField === "Remark2" && !this.hasRes("ViewRemark3")) {
          return true;
        }
        // 不看替代型号，有这个权限隐藏列
        if (
          item.BindField === "ReplaceModel" &&
          this.hasRes("NoSeeReplaceModel")
        ) {
          return true;
        }
        if (item.BindField !== "LinkStockQty") {
          item.IsReadonly = true;
        }
      });
    },
    async onBindData(pageIndex = 1) {
      let filterFields = {
        // 外部搜索条件
        isZero: this.isZero // 零库存
      };
      let data = _.extend(
        {},
        {
          fieldWhereString: JSON.stringify(this.searchFields),
          pageIndex: pageIndex,
          pageSize: this.pageSize
        },
        filterFields
      );
      this.loading = true;
      return this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 300);
        this.$emit("page-loading", false);
        // 要放nextTick函数里面执行
        this.$nextTick(() => {});
      });
    },

    stockCheckRow(item) {
      this.$emit("stock-check-row", item);
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.searchFields = _.extend({}, this.searchFields, params);
      }

      return this.onBindData(1);
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    onGetSelectedRows() {
      let rows = this.$refs.table.getCheckedRows();
      if (rows && rows.length > 0) {
        rows.forEach(a => {
          if (!this.permission.viewBuyPrice) {
            a.BuyPrice = null;
            a.TaxBuyPrice = null;
            a.TotalCostAmount = null;
            a.CostAmountInTax = null;
          }

          if (!this.permission.viewSalesPrice) {
            a.SalesPrice = null;
            a.FollowPrice = null;
          }
        });
      }
      return rows;
    },

    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true;
      } else {
        this.isShowFiedsSearch = false;
      }
      // let datas = _.find(this.ParamJosn, function(paramjosn) {
      //   if (paramjosn && paramjosn.ParamCode === "IsQueryBarStatus") {
      //     return paramjosn;
      //   }
      // });
      // if (this.hasValue(datas)) {
      //   datas.ParamValue = this.isShowFiedsSearch ? "1" : "0";
      //   this.$post(
      //     CONFIG.SavePageParamURL,
      //     { GCode: "StkOut", ParamJson: [datas] },
      //     data => {
      //       localStorage.removeItem("stkOut");
      //     }
      //   );
      // }
    }
  },
  async mounted() {
    this.invTypeList = await this.selectStorageType;
    await this.onInit();
  }
};
</script>

<style lang="scss">
</style>
