<template>
  <div>
    <!--采购单编辑框-->
    <znl-dialog
      title="编辑采购单"
      :visible="editvisible"
      subhead="(橙色底为必填项)"
      height="640px"
      width="1000px"
      class="dialog-ordpurchaseorderedit-box m-border-box"
      :close-on-click-modal="false"
      :is-footer-show="false"
      v-loading="dialogLoading"
      element-loading-text="数据加载中,请稍后..."
      @close="
        (val) => {
          $emit('close', val), onResetPage();
        }
      "
    >
      <znl-gridmodule
        gridtype="action"
        ref="flexGrid"
        class="grid-min-box"
        header="编辑采购单"
        height="100%"
        :from-height="150"
        :has-znl-btns="false"
        layout-type="other"
        element-loading-text="正在保存,请稍等..."
        :from-btns="fromBtns"
        :columns="columns"
        :item-source="itemSource"
        :hasDefaultBeforeNewRow="true"
        :edit-row-index="editRowIndex"
        :column-colors="columnColors"
        :row-colors="rowColors"
        :summary-columns="summaryColumns"
        :page-size="pageSize"
        :page-index="pageIndex"
        :total-count="totalCount"
        :on-init="onInit"
        :users="$store.state.users"
        :is-alter-ver="true"
        :showAddRowButton="showAddRowButton"
        :on-delete-checked-rows="onDeletedRows"
        :on-before-add-row="onBeforeAddRow"
        :loading="loading"
        @click.native.stop="autocompleteShow = false"
        @page-loading="
          (d) => {
            $emit('page-loading', d);
          }
        "
        @edit:cell-ended="onCellEdited"
        :is-multi-rows-check="true"
      >
        <!-- 编辑内容 -->
        <div slot="from-module" style="border: 1px solid #d8d8d8">
          <el-row
            class="formContainer"
            type="flex"
            justify="space-around"
            style="
              border-bottom: 1px solid #e6e6e6;
              height: 40px;
              line-height: 40px;
            "
          >
            <el-col :span="12" class="min-preboderleftnone-box">
              <el-row class="form_Row_W100" type="flex">
                <el-col>
                  <znl-input
                    title-width="70px"
                    form-type="input"
                    ref="znlAutocomplete"
                    @change="autocompleteChange"
                    custom-item="my-item-zh"
                    :fetch-suggestions="querySearch"
                    @select="suggestionsSelect"
                    :disabled="fromSalesOrderOpen"
                    placeholder="输入供应商简称或拼音字母,并按回车键查询"
                    size="mini"
                    layout="left"
                    :is-must-fill="true"
                    title="*供应商："
                    :border="true"
                    width="100%"
                    tabindex="1"
                    v-model="PurchaseOrderAdd.SupplierName"
                    :on-icon-click="SearchCompany"
                    @keyup.enter.native="SearchCompany"
                    type="text"
                  >
                    <i
                      slot="suffix"
                      class="iconfont icon-edit_btn_ic"
                      @click="SearchCompany"
                    ></i>
                  </znl-input>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="20" class="min-preboder-box">
              <el-row class="form_Row_W100">
                <el-col :span="8">
                  <znl-input
                    title-width="70px"
                    form-type="input"
                    :disabled="!IsManual || !PurchaseOrderAdd.IsAdd"
                    placeholder="默认自动生成 "
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="单据编号："
                    :border="true"
                    tabindex="100"
                    width="80%"
                    v-model="PurchaseOrderAdd.BillNo"
                    :on-icon-click="onBillIconClick"
                    type="text"
                  ></znl-input>

                  <el-checkbox
                    width="20%"
                    size="small"
                    v-model="IsManual"
                    :disabled="!PurchaseOrderAdd.IsAdd"
                    >手动</el-checkbox
                  >
                </el-col>

                <el-col :span="8">
                  <znl-input
                    title-width="70px"
                    layout="left"
                    ref="PODate"
                    form-type="datepicker"
                    :readonly="false"
                    :disabled="false"
                    :border="true"
                    :is-must-fill="true"
                    v-model="PurchaseOrderAdd.PODate"
                    width="100%"
                    tabindex="6"
                    title="*采购日期："
                  ></znl-input>
                </el-col>

                <el-col :span="8">
                  <znl-input
                    title-width="60px"
                    layout="left"
                    form-type="select"
                    ref="BuyerID"
                    :disabled="false"
                    width="100%"
                    :border="true"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="*采购员："
                    tabindex="7"
                    :is-must-fill="true"
                    :select-options="selectOptionsAllUserURL"
                    @change="onChangeUser"
                    v-model="PurchaseOrderAdd.BuyerID"
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row
            class="formContainer m-borderbg-box"
            type="flex"
            justify="space-around"
          >
            <el-col :span="12" class="min-boderleftnone-box">
              <el-row
                class="form_Row_W100"
                type="flex"
                style="margin-bottom: 2px"
              >
                <el-col>
                  <el-row class="form_Row_W100" type="flex">
                    <el-col>
                      <znl-input
                        ref="Address"
                        title-width="70px"
                        layout="left"
                        form-type="select"
                        width="100%"
                        placeholder="供应商地址"
                        popper-class="popperClass"
                        title="地址："
                        :disabled="false"
                        :clearable="true"
                        :border="true"
                        tabindex="100"
                        :allow-create="true"
                        :is-must-fill="false"
                        :select-options="selectOptionsAddress"
                        @blur="changeAddressInputHandler"
                        v-model="PurchaseOrderAdd.Address"
                        :filterable="true"
                      ></znl-input>
                    </el-col>
                  </el-row>
                  <el-row class="form_Row_W100" type="flex">
                    <el-col :span="12">
                      <znl-input
                        ref="Contact"
                        title-width="70px"
                        form-type="input"
                        :disabled="fromSalesOrderOpen"
                        placeholder
                        layout="left"
                        :is-must-fill="false"
                        :border="true"
                        title="联系人："
                        tabindex="3"
                        width="100%"
                        v-model="PurchaseOrderAdd.Contact"
                        type="text"
                      ></znl-input>
                    </el-col>
                    <el-col :span="12">
                      <znl-input
                        ref="Telephonenumb"
                        title-width="70px"
                        :border="true"
                        form-type="input"
                        :disabled="fromSalesOrderOpen"
                        placeholder
                        layout="left"
                        tabindex="4"
                        :is-must-fill="false"
                        title="联系电话："
                        width="100%"
                        v-model="PurchaseOrderAdd.Telephone"
                        type="text"
                      ></znl-input>
                    </el-col>
                  </el-row>

                  <el-row class="form_Row_W100" type="flex">
                    <el-col :span="24">
                      <znl-input
                        title-width="70px"
                        ref="PurCompany"
                        layout="left"
                        form-type="select"
                        :disabled="false"
                        width="100%"
                        placeholder="请选择"
                        popper-class="popperClass"
                        title="采购公司："
                        :border="true"
                        tabindex="5"
                        :clearable="true"
                        :is-must-fill="false"
                        :select-options="selectOptionsUserSubCompany"
                        v-model="PurchaseOrderAdd.PurCompany"
                      ></znl-input>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>

            <el-col
              :span="20"
              class="min-boder-box"
              style="border-bottom: none"
            >
              <el-row class="form_Row_W100">
                <el-col :span="8">
                  <znl-input
                    title-width="70px"
                    form-type="input"
                    :disabled="disabledInput"
                    :readonly="false"
                    placeholder
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="应付金额："
                    :border="true"
                    width="100%"
                    tabindex="13"
                    v-model="PurchaseOrderAdd.Amount"
                    type="text"
                  ></znl-input>

                  <znl-input
                    title-width="70px"
                    layout="left"
                    form-type="select"
                    :disabled="false"
                    width="70%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="*币 种："
                    :border="true"
                    :is-must-fill="true"
                    tabindex="11"
                    @change="CurrencyCodeChange"
                    :select-options="selectOptionsCurrencyCode"
                    v-model="PurchaseOrderAdd.CurrencyCode"
                  ></znl-input>

                  <znl-input
                    ref="CurrencyCode"
                    title-width="70px"
                    form-type="input"
                    :disabled="this.IsExchangeRateRMB"
                    placeholder
                    size="mini"
                    layout="left"
                    :border="true"
                    :is-must-fill="false"
                    title
                    width="28%"
                    tabindex="12"
                    v-model="PurchaseOrderAdd.ExchangeRate"
                    type="text"
                  ></znl-input>

                  <znl-input
                    ref="PurchaseOrderTaxRate"
                    title-width="70px"
                    layout="left"
                    :disabled="fromSalesOrderOpen"
                    form-type="select"
                    width="100%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="税 点："
                    :is-mut-fill="false"
                    :border="true"
                    tabindex="16"
                    @change="CalcTaxRate"
                    :select-options="selectOptionsTaxRate"
                    v-model="PurchaseOrderAdd.TaxRate"
                  ></znl-input>
                </el-col>

                <el-col :span="8">
                  <znl-input
                    title-width="70px"
                    layout="left"
                    form-type="select"
                    width="100%"
                    :disabled="false"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="付款方式："
                    :clearable="true"
                    tabindex="8"
                    :border="true"
                    :select-options="selectOptionsPaymentTypeOptions"
                    v-model="PurchaseOrderAdd.PaymentType"
                  ></znl-input>

                  <znl-input
                    title-width="70px"
                    layout="left"
                    form-type="datepicker"
                    :is-must-fill="false"
                    v-model="PurchaseOrderAdd.ExpectPayDate"
                    width="100%"
                    :border="true"
                    tabindex="15"
                    placeholder="预付款日期"
                    title="预付日期："
                  ></znl-input>

                  <znl-input
                    title-width="70px"
                    form-type="input"
                    :disabled="fromSalesOrderOpen"
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    placeholder
                    title="采购PO号："
                    :border="true"
                    width="100%"
                    tabindex="11"
                    v-model="PurchaseOrderAdd.PONumber"
                    type="text"
                  ></znl-input>
                </el-col>

                <el-col :span="8">
                  <znl-input
                    title-width="70px"
                    form-type="input"
                    :disabled="fromSalesOrderOpen"
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    placeholder
                    title="结算天数："
                    :border="true"
                    width="100%"
                    tabindex="9"
                    v-model="PurchaseOrderAdd.ClearFormDay"
                    type="text"
                  ></znl-input>
                  <znl-input
                    title-width="70px"
                    layout="left"
                    form-type="datepicker"
                    :border="true"
                    :is-must-fill="false"
                    v-model="PurchaseOrderAdd.NeedDeliveryDate"
                    width="100%"
                    tabindex="10"
                    @change="NeedDeliveryDateChange"
                    placeholder="应到货日期"
                    title="应到日期："
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row
            class="formContainer m-borderbg-box"
            type="flex"
            justify="space-around"
          >
            <el-col :span="12" class="min-borderleftbottommnone-box">
              <el-row class="form_Row_W100" type="flex">
                <el-col>
                  <znl-input
                    title-width="70px"
                    class="m-txt-remark"
                    form-type="input"
                    placeholder
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="采购备注："
                    :border="true"
                    :rows="2"
                    tabindex="5"
                    width="100%"
                    v-model="PurchaseOrderAdd.Remark"
                    type="textarea"
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="20" class="min-boderbottomnone-box">
              <el-row class="form_Row_W100"></el-row>
            </el-col>
          </el-row>
        </div>

        <div slot="from-content">
          <!-- <el-checkbox
            size="small"
            v-model="useLoanService"
            @change="onLoanServiceChange"
            v-if="hasLoanService && hasRes('LoanService')"
            >使用月结服务</el-checkbox
          >
          <span
            style="margin-right: 8px; color: #ee7700; cursor: pointer"
            title="什么是月结服务？"
            v-if="hasLoanService && hasRes('LoanService')"
            @click="
              () => {
                monthlyServiceIntroVisible = true;
              }
            "
          >
            <i class="iconfont icon-table_error_ic"></i>
          </span> -->
          <a
            @click="onImportDeclaration"
            style="color: orange; margin-right: 5px"
            >进口报关>></a
          >
          <a
            href="https://www.bom.ai/escrow"
            target="_blank"
            style="color: orange; margin-right: 5px"
            >申请担保交易>></a
          >

          <el-checkbox
            size="small"
            @change="onContinuousRecordValue()"
            v-model="IsContinueInputMode"
          >
            <label for="IsContinueInputMode">连续录单模式</label>
          </el-checkbox>

          <el-checkbox
            size="small"
            v-model="IsPrint"
            @change="onPrintValue()"
            :isShow="hasRes('Print')"
          >
            <label for="IsPrint">保存后并打印</label>
          </el-checkbox>

          <znl-button
            style-type="mac"
            :height="22"
            :isShow="hasRes('Print')"
            type="multiple"
          >
            <znl-button-menu @click="onPrint">
              <span> <i class="iconfont icon-print_btn_ic"></i>打印 </span>
            </znl-button-menu>
            <znl-button-menu
              :width="16"
              tip="打印设置"
              @click="znlPrintVisible = true"
            >
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>

          <znl-button
            @click="purchaseOrderSave(false)"
            :height="22"
            :style-type="useLoanService ? 'main' : 'minor'"
            :disabled="isAudit && !useLoanService"
            :isShow="hasRes('Print')"
          >
            <span>
              <i class="iconfont icon-save_btn_ic"></i
              >{{
                useLoanService
                  ? PurchaseOrderAdd.IsAudited
                    ? " 申请月结服务 "
                    : " 保存并申请月结服务 "
                  : "保存"
              }}
            </span>
          </znl-button>

          <znl-button
            @click="purchaseOrderSave(true)"
            :height="22"
            style-type="main"
            :disabled="isAudit"
            v-show="!useLoanService"
            :isShow="hasRes('Print')"
          >
            <span> <i class="iconfont icon-save_btn_ic"></i>保存并关闭 </span>
          </znl-button>

          <!-- 提交到供应链 -->
          <!-- <znl-button @click="onSaveToSCM" :height='22' style="margin-left:5px">
                  <span><i class="iconfont icon-save_btn_ic"></i>保存并加入报关单</span>
          </znl-button>-->
        </div>
        <!-- 打印模块 -->
        <znl-print
          :visible.sync="znlPrintVisible"
          :printInfo="printInfo"
          :loading="printLoading"
          :init-param="false"
          :is-get-default-param="printInfo.isGetDefaultParam"
          print-type="ORD_PurchaseOrder"
          title="采购单打印设置"
          @set-print-param="setPrintParam"
          @confirm-print="confirmPrint"
        ></znl-print>
      </znl-gridmodule>
    </znl-dialog>

    <znl-dialog
      title="从Excel粘贴"
      :visible="isBulkAddShow"
      height="325px"
      width="900px"
      class="pasteDataAcquisitionLog"
      :close-on-click-modal="false"
      :is-footer-show="false"
      @close="isBulkAddShow = false"
    >
      <pasteDataAcquisition
        slot="znl-dialog"
        :columns="setExcelColumns"
        @add-excel-data="addExcelData"
      ></pasteDataAcquisition>
    </znl-dialog>

    <d-company-contact-select
      :visible="companyVisible"
      v-if="companyVisible"
      :CompanyName="PurchaseOrderAdd.SupplierName"
      :searchModel="SelectSearchModel"
      companyType="2,3"
      title="选择供应商"
      @close="
        () => {
          companyVisible = false;
        }
      "
      @update:visible="
        (val) => {
          companyVisible = val;
        }
      "
      @confirm="companyChecked"
    ></d-company-contact-select>

    <dialog-stock-select
      slot="znl-dialog"
      :visible="StkStockCheckVisible"
      v-if="StkStockCheckVisible"
      @confirm="setGridData"
      @close="
        () => {
          StkStockCheckVisible = false;
        }
      "
    ></dialog-stock-select>

    <!-- 月结服务介绍 -->
    <monthly-service-intro
      v-if="monthlyServiceIntroVisible"
      @close="
        () => {
          monthlyServiceIntroVisible = false;
        }
      "
    ></monthly-service-intro>
  </div>
</template>

<script>
import { mixin as znlMethodsMixin } from "~components/common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { mixin as moveToMixin } from "~assets/scripts/directives/moveTo";
import { renderRequiredColumn } from "~assets/scripts/methods/common";
import {
  purchaseToSCM,
  openLoanService,
  openSCM,
} from "~/assets/scripts/methods/clientTabPage";

import dCompanyContactSelect from "../Crm/DCompanyContactSelect";
// import { getabsleft, getabstop } from '~assets/scripts/utils/dom'
import autocomplete from "@c_common/znlGrid/autocomplete";
import ClientSelect from "@c_modules/Stk/ClientSelect";
import pasteDataAcquisition from "@c_common/znlGrid/commonComponents/pasteDataAcquisition";
import DialogStockSelect from "@c_modules/Stk/DialogStockSelect";
import ZnlPrint from "@c_common/znlGrid/commonComponents/printTemplates";
import MonthlyServiceIntro from "@c_modules/Pub/MonthlyServiceIntro.vue";

const CONFIG = {
  configURL: "OrdPurchaseOrderLine/GetConfig",
  PurchaseOrferSaveURL: "OrdPurchaseOrderAdd/ModifyOrder",
  PurchaseOrferSearchById: "OrdPurchaseOrderAdd/Search",
  SearchByStkGUIdS: "StkStock/GetStockListByGuids", // 根据库存加载库存列表
  SearchByInquireGUID: "StkInquireRecord/GenerateOrdPurchase", // 根据询价记录guid查询实体
  GetPrintTemplatesURL: "PrintTemplate/Search",
  configJsonURL: "OrdPurchaseOrder/GetConfig",
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  GetPrice: "OrdPurchaseOrderAdd/GetPrice", // 获取上次的价格
  GetSupplierInfoURL: "CompanyContacts/SearchCompanyContacts",
  AddInfoCollectLogUrl: "InfoCollection/AddLog", //添加点击记录日志
  GetCompanySetupUrl: "CompanyParamSetup/GetValue", //获取公司配置
};

export default {
  name: "OrdPurchaseOrderAdd",
  mixins: [
    znlMethodsMixin,
    moveToMixin,
    getCommonDataMixin,
    renderRequiredColumn,
  ],
  config: CONFIG,
  components: {
    dCompanyContactSelect,
    autocomplete,
    ClientSelect,
    pasteDataAcquisition,
    DialogStockSelect,
    ZnlPrint,
    MonthlyServiceIntro,
  },
  data() {
    return {
      hasOperationColumn: {
        StockQuery: false,
        StockVMIMini: false,
        HisOfferPriceByModel: false,
      },
      isRead: false,
      selectOptionsAddress: [],
      isAudit: false,
      autocompleteShow: false,
      autocompleteSearchData: "",
      clientSelectLoading: true,
      dialogLoading: true,
      IsContinueInputMode: false, // 连续录单模式
      isBulkAddShow: false,
      StkStockCheckVisible: false,
      startTime: 0,
      currentUserName: this.$store.state.accountInfo.UserName,
      loading: false,
      companyVisible: false,
      accountReceivables: [],
      IncrementType: false,
      GeneralType: false,
      SelectSearchModel: {
        CompanyName: "",
      },
      PurchaseOrderAdd: {
        BillNo: "",
        PODate: new Date().toLocaleDateString(),
        PONumber: "",
        Address: "",
        BuyerID: this.$store.state.accountInfo.UserID, // 采购员ID
        BuyerName: this.$store.state.accountInfo.UserName,
        SupplierName: "",
        SupplierGUID: "",
        Contact: "",
        Telephone: "",
        Phone: "",
        PaymentType: "",
        ClearFormDay: "",
        CurrencyCode: "RMB",
        ExchangeRate: "1",
        TaxRate: null,
        Amount: "",
        DepositAmount: "",
        ExpectPayDate: "",
        IsInvoiced: false,
        InvoiceType: "",
        TicketAmount: "",
        TichetNo: "",
        TransportCompany: "",
        ShippingNo: "",
        FreightClearForm: "",
        Freight: "",
        NeedDeliveryDate: "",
        FactDeliveryDate: "",
        Remark: "",
        StkInStatus: 0,
        StkOutStatus: 0,
        ReturnStatus: 0,
        PaymentStatus: 0,
        PaymentAmount: "",
        DiscountAmount: "",
        RealDepositAmount: "",
        ConEntry: "",
        IsAdd: true,
        LineList: [],
        FreightClearFormStr: "",
        POGuid: null,
        POID: 0,
        PurchaseOrderStatus: null,
      },

      IsTakeOutPrint: false, // 是否带出上次价格
      IsSupplierName: false, // 供应商名称是否必填
      parmTaxRate: null,
      IsPrint: true, // 保存后继续打印
      BindBillNo: "",
      BindInvoiceType: "",
      BindTicketAmount: "",
      BindTichetNo: "",
      disabledInput: true, // 禁用采购单
      disabledInvoiceType: false,
      disabledTicketAmount: false,
      disabledTichetNo: false,
      IsManual: false, // 手动
      showAddRowButton: true,
      CurrencyCodeOptions: [], // 币种
      toptions: [], // 测试CODE
      // PaymentTypeOptions: [], // 付款方式
      UsableOptions: [], // 可用用户
      // 绑定列表中的下拉框
      InvoiceTypeOptions: [],
      TransportCompanyOptions: [],
      FreightClearFormOptions: [],
      IsExchangeRateRMB: true,
      paramQtyType: "",
      paramQtyUnit: "",
      ParamJosn: [],
      ParamJosnLine: [],
      resourceList: [],
      fromSalesOrderOpen: false,
      pickerOptions0: {},
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      setExcelColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      usableUser: [],
      role: "1",
      inTypes: "",
      isAllow: true,
      isPopup: false,
      gridObj: {},
      fromBtns: [
        {
          name: "新增一行",
          iconName: "icon-add_btn_ic",
          click: () => {
            this.$refs.flexGrid.addRow(true);
          },
        },
        {
          name: "删除选择行",
          iconName: "icon-delete_btn_ic",
          click: () => {
            _.each(this.$refs.flexGrid.getSelectedRows(), (data) => {
              _.each(this.itemSource, (item, i) => {
                if (_.isEqual(item, data)) {
                  this.itemSource.splice(i, 1);
                  return;
                }
              });
            });
            let Amount = 0;
            _.each(this.itemSource, (item, i) => {
              if (item.Amount) Amount += Number(item.Amount);
            });
            this.PurchaseOrderAdd.Amount = Amount;
          },
        },
        // {
        //   name: '选择库存',
        //   iconName: 'icon-alarm-wh_btn_ic',
        //   click: () => { this.selectStocklist() }
        // }
        {
          name: "导入数据",
          iconName: "icon-add_btn_ic",
          isShow: true,
          click: () => {
            this.setExcelColumns = _.filter(this.columns, (item) => {
              return item.isReadOnly === false;
            });
            this.isBulkAddShow = !this.isBulkAddShow;
          },
        },
        {
          name: "我的库存",
          iconName: "icon-save_btn_ic",
          isShow: true,
          click: () => {
            this.StkStockCheckVisible = true;
          },
        },
      ],
      GCode: "OrdPurchaseOrder",
      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      printInfo: {
        isGetDefaultParam: true, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: "ORD_PurchaseOrder", // 打印模板类型
      }, // end 打印模块参数
      returnPOGuid: null, // 保存成功后，服务端返回的采购单guid(供应链使用)
      hasLoanService: false, // 是否有月结资质
      useLoanService: false, // 是否使用月结服务
      saveButtonText: "保存",
      monthlyServiceIntroVisible: false,
      TaxRate: null, //单据原始税点，用于判断是否可以申请月结服务
      //公司配置
      CompanySetup: {
        EnableNotUpperCase: false, //单据的型号、品牌﹑封装、年份默认为非大写格式
      },
    };
  },
  props: {
    editType: String,
    editvisible: {
      type: Boolean,
      defaule: false,
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    POGuid: String,
    BDLineGuids: Array,
    isRefresh: Boolean, // 根据询价记录刷新页面
    InquireModel: String, // 询价记录实体类
    shoppingCartItem: {
      type: Object,
      defaule: () => {
        return {};
      },
    },
  },
  watch: {
    POGuid: {
      handler(value) {
        // 根据传入参数加载页面
        if (!_.isEmpty(value)) {
          this.LoadEditData(value);
        }
      },
      immediate: true,
    },
    isRefresh: {
      handler(value) {
        if (!_.isUndefined(this.InquireModel)) {
          this.LoadPurchaseByInquire(this.InquireModel);
        }
      },
      immediate: true,
    },
    IncrementType() {
      if (this.IncrementType) {
        this.GeneralType = false;
        this.PurchaseOrderAdd.InvoiceType = 2;
      }
    },
    GeneralType() {
      if (this.GeneralType) {
        this.IncrementType = false;
        this.PurchaseOrderAdd.InvoiceType = 1;
      }
    },
  },
  methods: {
    onInit: async function () {
      let config = await this.isSaveStorage(
        "OrdPurchaseOrderLine",
        CONFIG.configURL
      );

      this.resourceList = config.ResourceList.map((item) => item.Code);
      let configs = await this.isSaveStorage(
        "ordPurchaseOrder",
        CONFIG.configJsonURL
      );

      // 获取参数配置
      this.ParamJosn = configs.ParamJosn;
      // 获取订单详情参数配置
      this.ParamJosnLine = config.ParamJosn;
      this.paramConfiguration();
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);

      let invTypeList = await this.selectStorageType;
      // 库存类型
      let stkTypeColumn = _.find(
        columns,
        (column) => column.name === "InvType"
      );
      stkTypeColumn &&
        _.extend(stkTypeColumn, {
          dataMap: invTypeList,
          showDropDown: true,
        });
      // 仓库
      // let WarehouseOptions = this.selectOptionsWarehouseOptions
      // _.extend(
      //   _.find(columns, function (column) {
      //     return column.binding === 'Warehouse'
      //   }),
      //   { dataMap: WarehouseOptions, showDropDown: true }
      // )
      // 品质
      let QualityDropDown = await this.selectOptionsQuality;
      _.extend(
        _.find(columns, function (column) {
          return column.binding === "Quality";
        }),
        { dataMap: QualityDropDown, showDropDown: true }
      );
      // 单位
      let QtyUnitDropDown = await this.selectOptionsQtyUnit;
      _.extend(
        _.find(columns, function (column) {
          return column.binding === "Unit";
        }),
        { dataMap: QtyUnitDropDown, showDropDown: true }
      );
      // 付款账号
      let bankAccount = _.find(this.selectOptionsBankAccount, (item) => {
        return item.key === this.PurchaseOrderAdd.CBGUID;
      });
      if (!_.isUndefined(bankAccount)) {
        this.PurchaseOrderAdd.CBName = bankAccount.value;
      }
      columns = this.renderRequiredColumn(columns);

      // 默认税点
      let value = this.getCompanySetupValue("DefaultTaxRate");
      if (value) {
        this.PurchaseOrderAdd.TaxRate = value;
      }

      this.defaultConfig = config;
      this.columns = columns;
      this.role = config.Role;
      this.fromSalesOrderOpen = false;
      this.pageSize = config.PageSize;

      if (this.$store.state.paramOrder && this.$store.state.paramOrder.POGuid) {
        let poguid = this.$store.state.paramOrder.POGuid;
        this.$store.commit("setParamOrder", {});
        await this.LoadEditData(poguid);
      }
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.InquireModel
      ) {
        let inquireModel = this.$store.state.paramOrder.InquireModel;
        // console.log(222, inquireModel)
        this.$store.commit("setParamOrder", {});
        this.PurchaseOrderAdd.SupplierGUID = inquireModel.SupplierID;
        this.PurchaseOrderAdd.SupplierName = inquireModel.SupplierName;

        // this.PurchaseOrderAdd.Address = inquireModel.Address
        this.getAddress(inquireModel);
        this.PurchaseOrderAdd.Contact = inquireModel.Contact;
        this.PurchaseOrderAdd.Telephone = inquireModel.Telephone;
        this.PurchaseOrderAdd.Mobile = inquireModel.Mobile;

        this.LoadPurchaseByInquire(inquireModel.IQGUID);
      }
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.shoppingCartItem
      ) {
        let shoppingCartItem = this.$store.state.paramOrder.shoppingCartItem;
        this.LoadListByShoppingCar(shoppingCartItem);
        this.$store.commit("setParamOrder", {});
      }

      this.$nextTick(() => {
        this.dialogLoading = false;
      });
      this.hasLoanService = await this.hasLoanServiceAsync;
      await this.initCompanySetup();
    },
    //初始化公司配置
    async initCompanySetup() {
      this.$post(
        CONFIG.GetCompanySetupUrl,
        { SCode: "EnableNotUpperCase" },
        (data, logic) => {
          if (logic.code === 200) {
            data = data.toString().toUpperCase();
            this.CompanySetup.EnableNotUpperCase =
              data === "1" || data === "Y" || data === "YES" || data === "TRUE";
          }
        }
      );
    },
    onResetPage() {
      let info = {
        BillNo: "",
        PODate: new Date().toLocaleDateString(),
        PONumber: "",
        Address: "",
        BuyerID: this.$store.state.accountInfo.UserID, // 采购员ID
        BuyerName: this.$store.state.accountInfo.UserName,
        SupplierName: "",
        SupplierGUID: "",
        Contact: "",
        Telephone: "",
        Phone: "",
        PaymentType: "",
        ClearFormDay: "",
        CurrencyCode: "RMB",
        ExchangeRate: "1",
        TaxRate: null,
        Amount: "",
        DepositAmount: "",
        ExpectPayDate: "",
        IsInvoiced: false,
        InvoiceType: "",
        TicketAmount: "",
        TichetNo: "",
        TransportCompany: "",
        ShippingNo: "",
        FreightClearForm: "",
        Freight: "",
        NeedDeliveryDate: "",
        FactDeliveryDate: "",
        Remark: "",
        StkInStatus: 0,
        StkOutStatus: 0,
        ReturnStatus: 0,
        PaymentStatus: 0,
        PaymentAmount: "",
        DiscountAmount: "",
        RealDepositAmount: "",
        ConEntry: "",
        IsAdd: true,
        LineList: [],
        FreightClearFormStr: "",
        POGuid: null,
        POID: 0,
        PurchaseOrderStatus: null,
      };
      this.PurchaseOrderAdd = _.extend({}, this.PurchaseOrderAdd, info);
      this.itemSource = [];
    },
    // 多页签 双击事件回调
    async ondblclick(row, typeName) {
      if (_.isUndefined(row)) {
        return;
      }
      let rows = [row];

      if (typeName === "StockQuery") {
        // 我的库存
        this.loadListByStockBring(rows);
      } else if (typeName === "StockVMIMini") {
        // 供应商库存
        this.loadStkVMI(rows);
      } else if (typeName === "HisOfferPriceByModel") {
        // 报价记录
        this.loadQuotedPriceLine(rows);
      }
    },
    // 我的库存出
    loadListByStockBring: async function (stkline, isChecklist) {
      // 根据传入的stock对象加载明细列表
      if (stkline.length > 0) {
        _.each(stkline, (stocks) => {
          let invQty = stocks.InvQty;
          if (stocks.InvQty < 0) {
            invQty = -stocks.InvQty;
          }
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: 1,
            IsDelete: false,
            SOLineID: 0,
            SOLineGuid: null,
            StkGUID: stocks.StkGUID, // 库存GUID
            ProductCategory: stocks.ProductCategory,
            Model: stocks.Model,
            SpModel: stocks.Model,
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            InvType: !this.hasValue(stocks.InvType)
              ? this.paramQtyType
              : stocks.InvType, // 库存类型
            StorageName: stocks.InvPosition,
            Qty: invQty,
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            DeliveryDate: stocks.Leadtime,
            Price: this.ConvertNumber(stocks.SalesPrice),
            PriceInTax: this.CalcPriceInTax(
              this.ConvertNumber(stocks.SalesPrice)
            ), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(invQty),
              this.ConvertNumber(stocks.SalesPrice)
            ),
            FactDeliveryDate: null,
            ShareFee: stocks.shareFee,
            BuyPrice: stocks.BuyPrice,
            BuyerName: stocks.BuyerName,
            PBillNo: null,
            InDate: stocks.StkInTime,
            ProfitAmount: null,
            IsCancel: null,
            IsActive: 2,
            IsSystem: null,
          };
          if (this.isNullItemSource()) {
            this.PurchaseOrderAdd.LineList = [outlinobj];
          } else {
            this.PurchaseOrderAdd.LineList.push(outlinobj);
          }
        });
        this.$nextTick(function () {
          if (this.PurchaseOrderAdd.LineList.length > 0) {
            let tempSource = isChecklist ? this.itemSource : []; // 如果是库存选择后的结果则叠加
            let retList = tempSource;
            _.each(this.PurchaseOrderAdd.LineList, (i) => {
              retList.push(i);
            });
            this.PurchaseOrderAdd.LineList = retList;
            this.onBindData();
            this.CalcOrderAmountAndGrossProfit(true);
          }
        });
      } else {
        this.$message({ message: "加载库存失败，请刷新后重试", type: "error" });
      }
    },
    // 加载供应商库存
    loadStkVMI: async function (rows) {
      if (rows.length > 0) {
        _.each(rows, (stocks) => {
          let invQty = stocks.InvQty;
          if (stocks.InvQty < 0) {
            invQty = -stocks.InvQty;
          }
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: 1,
            IsDelete: false,
            SOLineID: 0,
            SOLineGuid: null,
            StkGUID: stocks.StkGUID, // 库存GUID
            ProductCategory: stocks.ProductCategory,
            Model: stocks.Model,
            SpModel: stocks.Model,
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            InvType: !this.hasValue(stocks.InvType)
              ? this.paramQtyType
              : stocks.InvType, // 库存类型
            StorageName: stocks.InvPosition,
            Qty: invQty,
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            DeliveryDate: stocks.Leadtime,
            Price: this.ConvertNumber(stocks.SalesPrice),
            PriceInTax: this.CalcPriceInTax(
              this.ConvertNumber(stocks.SalesPrice)
            ), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(invQty),
              this.ConvertNumber(stocks.SalesPrice)
            ),
            FactDeliveryDate: null,
            ShareFee: stocks.shareFee,
            BuyPrice: stocks.BuyPrice,
            BuyerName: stocks.BuyerName,
            PBillNo: null,
            InDate: stocks.StkInTime,
            ProfitAmount: null,
            IsCancel: null,
            IsActive: 2,
            IsSystem: null,
          };
          if (this.isNullItemSource()) {
            this.PurchaseOrderAdd.LineList = [outlinobj];
          } else {
            this.PurchaseOrderAdd.LineList.push(outlinobj);
          }
        });
        this.onBindData();
        this.CalcOrderAmountAndGrossProfit(true);
      } else {
        this.$message({
          message: "加载供应商库存失败，请刷新后重试",
          type: "error",
        });
      }
    },

    // 加载供应商库存
    LoadStkVMIGUID: async function (guid) {
      let stkVMIS = await this.$post(CONFIG.GetStkVMIByGUIDS, {
        VMGUIDS: guid,
      });
      this.$nextTick(function () {
        _.each(stkVMIS, (items) => {
          this.$set(items, "Qty", items.InvQty);
          this.$set(items, "SupplierInfo", items.SupplierName);
        });
        this.PurchaseOrderAdd.LineList = stkVMIS;
        this.CalcOrderAmountAndGrossProfit(true);
        this.onBindData();
        this.isAllow = true; // 编辑禁用选择
        this.ResTaxRate();
      });
    },
    isNullItemSource() {
      return (
        this.itemSource.length === 0 ||
        (this.itemSource.length < 2 &&
          this.itemSource[0].Model === "" &&
          this.itemSource[0].Qty === null)
      );
    },
    addExcelData(data) {
      let index = 0;
      if (this.itemSource && this.itemSource.length > 0) {
        index = this.itemSource.length - 1;
        let info = this.itemSource[index];
        if (!this.hasValue(info.Model) && !this.hasValue(info.Qty)) {
          this.itemSource.pop();
          index--;
        }
      }
      _.each(data, (item) => {
        this.getValue(item);
      });

      _.each(data, (item) => {
        index++;
        item.ID = index;
        this.itemSource.push(item);
      });
      this.isBulkAddShow = false;
    },
    getValue(item) {
      // 如果单位为空 则默认参数配置的默认单位
      if (!this.hasValue(item.Unit)) {
        item.Unit = this.paramQtyUnit;
      }
      // 如果库存类型为空则默认参数配置的默认库存类型
      if (!this.hasValue(item.InvType)) {
        item.InvType = this.paramQtyType;
      }
      // 计算未税价和含税价和金额
      if (this.hasValue(item.Price)) {
        // 计算含税
        let _price = this.CalcPriceInTax(item.Price);
        item.PriceInTax = _price === 0 ? null : _price;
      } else if (this.hasValue(item.PriceInTax)) {
        // 含税算未税
        item.Price = this.CalcPrice(item.PriceInTax);
      }

      item.Amount = this.CalcAmout(item.Qty, item.PriceInTax).toFixed(2);
      this.CalcOrderAmountAndGrossProfit(false);
    },
    // 加载历史发货明细
    loadStkOutLine(rows) {
      if (rows.length > 0) {
        _.each(rows, (stocks) => {
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: 1,
            IsDelete: false,
            Model: stocks.Model,
            Qty: stocks.Qty,
            SalesQty: stocks.Qty,
            Price: this.ConvertNumber(stocks.Price),
            SalesModel: stocks.Model,
            PriceInTax: this.CalcPriceInTax(this.ConvertNumber(stocks.Price)), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(stocks.Qty),
              this.ConvertNumber(stocks.Price)
            ),
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            ProductCategory: stocks.ProductCategory,

            Unit: stocks.Unit,
          };
          if (this.isNullItemSource()) {
            this.PurchaseOrderAdd.LineList = [outlinobj];
          } else {
            this.PurchaseOrderAdd.LineList.push(outlinobj);
          }
        });
        this.onBindData();
        this.CalcOrderAmountAndGrossProfit(true);
      } else {
        this.$message({
          message: "加载发货明细失败，请刷新后重试",
          type: "error",
        });
      }
    },

    // 加载报价明细
    loadQuotedPriceLine(rows) {
      if (rows.length > 0) {
        _.each(rows, (stocks) => {
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: 1,
            IsDelete: false,
            Model: stocks.Model,
            Qty: stocks.Qty,
            SalesQty: stocks.Qty,
            Price: this.ConvertNumber(stocks.QuotePrice),
            SalesModel: stocks.Model,
            PriceInTax: this.CalcPriceInTax(
              this.ConvertNumber(stocks.QuotePrice)
            ), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(stocks.Qty),
              this.ConvertNumber(stocks.QuotePrice)
            ),
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            ProductCategory: stocks.ProductCategory,
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            InvType: !this.hasValue(stocks.InvType)
              ? this.paramQtyType
              : stocks.InvType, // 库存类型
          };
          this.PurchaseOrderAdd.LineList.push(outlinobj);
        });
        this.onBindData();
        this.CalcOrderAmountAndGrossProfit(true);
      } else {
        this.$message({
          message: "加载询价明细失败，请刷新后重试",
          type: "error",
        });
      }
    },

    onChangeUser(val) {
      if (val) {
        this.PurchaseOrderAdd.BuyerName = val.value;
      }
    },
    paramConfiguration() {
      // 单据保存后立即打印 0是 1否
      let paramIsAutoPrint = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsAutoPrint") {
          return paramjosn.ParamValue;
        }
      });
      if (paramIsAutoPrint && paramIsAutoPrint.ParamValue === "1") {
        this.IsPrint = true;
      } else {
        this.IsPrint = false;
      }
      // 连续录单模式
      let paramIsContinuous = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsContinuousRecord") {
          return paramjosn.ParamValue;
        }
      });
      if (paramIsContinuous && paramIsContinuous.ParamValue === "1") {
        this.IsContinueInputMode = true;
      } else {
        this.IsContinueInputMode = false;
      }
      // 默认单位
      let paramQtyUnit = _.find(this.ParamJosnLine, function (paramjosn) {
        if (paramjosn.ParamCode === "QtyUnit") {
          return paramjosn.ParamValue;
        }
      });
      if (paramQtyUnit && paramQtyUnit.ParamValue) {
        this.paramQtyUnit = paramQtyUnit.ParamValue;
      }
      let defaultUnit = this.getCompanySetupValue("DefaultUnit");
      if (defaultUnit) {
        this.paramQtyUnit = defaultUnit;
      }

      // 默认库存类型
      let paramQtyType = _.find(this.ParamJosnLine, function (paramjosn) {
        if (paramjosn.ParamCode === "QtyType") {
          return paramjosn.ParamValue;
        }
      });
      if (paramQtyType && paramQtyType.ParamValue) {
        this.paramQtyType = paramQtyType.ParamValue;
      }
      // 默认币种
      let defaultCurrencyCode = this.getCompanySetupValue(
        "DefaultPurchaseCurrencyCode"
      );
      if (defaultCurrencyCode) {
        this.PurchaseOrderAdd.CurrencyCode = defaultCurrencyCode;
        let exchangeObj = _.find(
          this.selectOptionsCurrencyCode,
          (m) => m.key === defaultCurrencyCode
        );
        if (exchangeObj && exchangeObj.ExchangeRate) {
          this.PurchaseOrderAdd.ExchangeRate = exchangeObj.ExchangeRate;
        }
      }

      // 默认税率
      let paramTaxRate = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "TaxRate") {
          return paramjosn.ParamValue;
        }
      });
      if (paramTaxRate && paramTaxRate.ParamValue) {
        this.parmTaxRate = paramTaxRate.ParamValue;
        this.PurchaseOrderAdd.TaxRate = paramTaxRate.ParamValue;
      }
      let defaultTaxRate = this.getCompanySetupValue("DefaultTaxRate");
      if (defaultTaxRate) {
        this.PurchaseOrderAdd.TaxRate = defaultTaxRate;
      }

      // 是否带出上次价格
      let paramIsTakeOutPrint = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsTakeOutPrint") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(paramIsTakeOutPrint) &&
        paramIsTakeOutPrint.ParamValue === "0"
      ) {
        this.IsTakeOutPrint = false;
      } else {
        this.IsTakeOutPrint = true;
      }

      // 供应商名称是否必填
      let paramIsSupplierName = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsSupplierName") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(paramIsSupplierName) &&
        paramIsSupplierName.ParamValue === "0"
      ) {
        this.IsSupplierName = false;
      } else {
        this.IsSupplierName = true;
      }

      // 打印单据模板
      let paramPrintTemplate = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "PrintTemplate") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(paramPrintTemplate) &&
        this.hasValue(paramPrintTemplate.ParamValue)
      ) {
        this.printInfo.template = paramPrintTemplate.ParamValue;
      }
      // 打印单据模板的抬头
      let paramPrintTitle = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "PrintTitle") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(paramPrintTitle) &&
        this.hasValue(paramPrintTitle.ParamValue)
      ) {
        this.printInfo.title = paramPrintTitle.ParamValue;
      }
    },
    onBeforeAddRow: async function () {},
    onBindData(pageIndex = 1) {
      this.ParamJonsValue();
      this.$emit("page-loading", false);
    },
    // 默认参数设置赋值
    ParamJonsValue() {
      this.itemSource = this.PurchaseOrderAdd.LineList;
      _.each(this.itemSource, (item, i) => {
        !this.hasValue(this.itemSource[i].Unit)
          ? (this.itemSource[i].Unit = this.paramQtyUnit)
          : "";
        !this.hasValue(this.itemSource[i].InvType)
          ? (this.itemSource[i].InvType = this.paramQtyType)
          : "";
      });
    },
    // 应发日期同步到详情
    NeedDeliveryDateChange(val) {
      function formatTen(num) {
        return num > 9 ? num + "" : "0" + num;
      }
      if (!val) {
        return;
      }
      // console.log(this.itemSource, 'this.itemSource')
      let d = new Date(val);
      let NeedDeliveryDate =
        d.getFullYear() + "-" + formatTen(d.getMonth() + 1) + "-" + d.getDate();
      _.each(this.itemSource, (item, i) => {
        this.itemSource[i].NeedDeliveryDate = NeedDeliveryDate;
        this.itemSource[i].FactDeliveryDate = NeedDeliveryDate;
      });
      this.PurchaseOrderAdd.LineList = this.itemSource;
    },

    setGridData(list) {
      let rowID = 0;
      _.each(list, (item) => {
        let invQty = item.InvQty;
        if (item.InvQty < 0) {
          invQty = -item.InvQty;
        }
        item.RowGuid = rowID++;
        item.InvType = !this.hasValue(item.InvType)
          ? this.paramQtyType
          : item.InvType; // 库存类型
        item.Unit = !this.hasValue(item.Unit) ? this.paramQtyUnit : item.Unit; // 单位
        item.Price = this.ConvertNumber(item.BuyPrice);
        item.PriceInTax = this.CalcPriceInTax(
          this.ConvertNumber(item.BuyPrice)
        ); // 含税价
        item.Amount = this.CalcAmout(
          this.ConvertNumber(invQty),
          this.ConvertNumber(item.BuyPrice)
        );
      });
      if (this.itemSource && this.itemSource.length > 0) {
        let info = this.itemSource[this.itemSource.length - 1];
        if (!this.hasValue(info.Model) && !this.hasValue(info.Qty)) {
          this.itemSource.pop();
        }
      }
      this.itemSource = this.itemSource.concat(list);
      this.CalcOrderAmountAndGrossProfit(false);
    },
    onPrint() {
      var tableName = this.printInfo.template; // 模板ID
      var poGuid = this.PurchaseOrderAdd.POGuid; // 销售单ID
      if (!this.hasValue(tableName)) {
        this.$message({ message: "请先选择打印模板", type: "error" });
        this.znlPrintVisible = true;
        return false;
      } else if (poGuid === "" || poGuid === null || poGuid === undefined) {
        this.$message({ message: "先保存后才能打印!", type: "error" });
        return false;
      } else {
        let printParm = {
          TableName: tableName,
          Guid: poGuid,
          SubCompanyId: this.printInfo.title,
        };
        this.printPurchaseOrder(printParm, () => {});
        return true;
      }
    },
    hasRes(code) {
      return _.some(this.resourceList, (page) => page === code);
    },
    querySearch(queryString, cb) {
      if (new Date() - this.startTime > 1000) {
        this.$post(
          "CompanyContacts/SearchCompanyContacts",
          {
            FieldWhereString: JSON.stringify({
              CompanyName: queryString,
            }),
            PageIndex: 1,
            PageSize: 20,
            CompanyTypes: "2,3",
          },
          (data) => {
            this.restaurants = data.ResultList;
            let restaurants = this.restaurants;
            let results = queryString
              ? restaurants.filter(this.createFilter(queryString))
              : restaurants;
            // 调用 callback 返回建议列表的数据
            _.each(results, (item) => {
              item.value = item.CompanyName;
            });
            cb(results);
            _.delay(() => {
              this.$refs.znlAutocomplete.$el
                .querySelector(".el-input__inner")
                .focus();
            }, 100);
          }
        );
      }
      this.startTime = new Date();
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.CompanyName.indexOf(queryString.toLowerCase()) === 0;
      };
    },
    suggestionsSelect(item) {
      this.companyChecked([item]);
    },
    onDeletedRows() {
      _.each(this.$refs.flexGrid.getSelectedRows(), (data) => {
        _.each(this.itemSource, (item, i) => {
          if (
            _.isEqual(
              _.pick(item, (value) => {
                return !_.isUndefined(value);
              }),
              _.pick(data, (value) => {
                return !_.isUndefined(value);
              })
            )
          ) {
            this.itemSource.splice(i, 1);
            return;
          }
        });
      });
      this.PurchaseOrderAdd.LineList = this.itemSource;
      this.CalcOrderAmountAndGrossProfit(false);
    },
    checkData() {
      let ret = true;
      if (this.$refs.flexGrid.hasErrForEdit > 0) {
        ret = false;
        return this.$message({
          message: "请修改填写错误的单元格内容后再保存",
          type: "error",
        });
      } else if (!this.hasValue(this.PurchaseOrderAdd.SupplierName)) {
        ret = false;
        this.$refs.znlAutocomplete.handleFocus();
        this.$message({ message: "供应商名不能为空", type: "warning" });
      } else if (!this.hasValue(this.PurchaseOrderAdd.PODate)) {
        ret = false;
        this.$refs.PODate.handleFocus();
        this.$message({ message: "请选择合同日期", type: "warning" });
      } else if (
        !this.hasValue(
          this.PurchaseOrderAdd.BuyerID && this.PurchaseOrderAdd.BuyerID <= 0
        )
      ) {
        ret = false;
        this.$refs.BuyerID.handleFocus();
        this.$message({ message: "请选择采购员", type: "warning" });
      } else if (
        !this.hasValue(this.PurchaseOrderAdd.CurrencyCode) &&
        this.PurchaseOrderAdd.CurrencyCode === 0
      ) {
        ret = false;
        this.$refs.CurrencyCode.handleFocus();
        this.$message({ message: "请选择币种", type: "warning" });
      } else if (
        this.useLoanService &&
        this.PurchaseOrderAdd.CurrencyCode !== "RMB"
      ) {
        this.$message({
          message: "币种为人民币的单据才能申请正能量月结服务",
          type: "warning",
        });
        this.$refs.CurrencyCode.handleFocus();
        ret = false;
      } else if (this.useLoanService && this.PurchaseOrderAdd.TaxRate !== 13) {
        this.$refs.PurchaseOrderTaxRate.handleFocus();
        this.$message({
          message: "税率为13的单据才能申请正能量月结服务",
          type: "warning",
        });
        ret = false;
      } else if (
        this.PurchaseOrderAdd.TicketAmount !== undefined &&
        !isFinite(this.PurchaseOrderAdd.TicketAmount)
      ) {
        ret = false;
        this.$refs.TicketAmount.handleFocus();
        this.$message({
          message: "开票金额必须为数字或者小数",
          type: "warning",
        });
      } else if (
        this.PurchaseOrderAdd.ClearFormDay !== undefined &&
        this.PurchaseOrderAdd.ClearFormDay !== "" &&
        (isNaN(this.PurchaseOrderAdd.ClearFormDay) ||
          this.PurchaseOrderAdd.ClearFormDay < 0)
      ) {
        ret = false;
        //结算天数聚焦不了？先注释，否则不会弹出提示
        // this.$refs.ClearFormDay.handleFocus();
        this.$message({
          message: "结算天数必须为大于或等于0的数字",
          type: "warning",
        });
      } else if (
        !this.isRead &&
        this.hasValue(this.PurchaseOrderAdd.PaymentType) &&
        this.PurchaseOrderAdd.PaymentType.indexOf("月结") !== -1 &&
        !this.hasValue(this.PurchaseOrderAdd.ExpectPayDate)
      ) {
        ret = false;
        this.$message({ message: "请输入预付款日期", type: "warning" });
      } else {
        //删除为空的最后一行
        if (this.itemSource.length > 1) {
          var lastCol = this.itemSource.length - 1;
          var lastRow = this.itemSource[lastCol];
          if (
            !this.hasValue(lastRow["Model"]) &&
            !this.hasValue(lastRow["Qty"]) &&
            !this.hasValue(lastRow["Price"])
          )
            this.itemSource = this.itemSource.splice(0, lastCol);
        }
        let rows = this.itemSource;
        if (rows.length > 0) {
          let rowId = 0;
          let messageStr = "";
          _.some(rows, (item) => {
            rowId = rowId + 1;
            if (!this.hasValue(item["Model"])) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 型号不能为空";
            } else if (
              this.hasValue(item["Model"]) &&
              item["Model"].length > 200
            ) {
              ret = false;
              messageStr =
                "单据详情第" + rowId + "行 型号长度不能超过200个字符";
            } else if (!this.hasValue(item["Price"])) {
              ret = false;
              messageStr =
                "单据明细第" + rowId + "行 未税价必须为数值型且不能为空";
            } else if (!this.hasValue(item["Qty"])) {
              ret = false;
              messageStr =
                "单据明细第" + rowId + "行 数量必须为数值型且不能为空";
            } else if (
              this.hasValue(item["CustomerPartNo"]) &&
              item["CustomerPartNo"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 客户料号不能超过50个字符";
            } else if (
              this.hasValue(item["ProductCategory"]) &&
              item["ProductCategory"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 产品类型不能超过50个字符";
            } else if (
              this.hasValue(item["Brand"]) &&
              item["Brand"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 品牌长度不能超过50个字符";
            } else if (
              this.hasValue(item["Packaging"]) &&
              item["Packaging"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 封装长度不能超过50个字符";
            } else if (
              this.hasValue(item["MakeYear"]) &&
              item["MakeYear"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 年份长度不能超过50个字符";
            } else if (
              this.hasValue(item["DeliveryDate"]) &&
              item["DeliveryDate"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 货期长度不能超过50个字符";
            } else if (this.hasValue(item["MPQ"]) && item["MPQ"].length > 50) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 包装长度不能超过50个字符";
            } else if (
              this.hasValue(item["Demands"]) &&
              item["Demands"].length > 50
            ) {
              ret = false;
              messageStr =
                "单据明细第" + rowId + "行 采购要求长度不能超过50个字符";
            } else if (
              this.hasValue(item["Remark"]) &&
              item["Remark"].length > 200
            ) {
              ret = false;
              messageStr =
                "单据明细第" + rowId + "行 备注长度不能超过200个字符";
            }
          });
          if (messageStr.length > 0) {
            ret = false;
            this.$message({ message: messageStr, type: "warning" });
          }
        } else {
          ret = false;
          this.$message({ message: "请添加单据详情", type: "warning" });
        }
        this.PurchaseOrderAdd.LineList = rows;
      }
      return ret;
    },
    checkLength() {
      let ret = true;
      if (
        this.hasValue(this.PurchaseOrderAdd.SupplierName) &&
        this.PurchaseOrderAdd.SupplierName.length > 100
      ) {
        ret = false;
        this.$refs.znlAutocomplete.handleFocus();
        this.$message({
          message: "供应商名长度不能超过100个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.PurchaseOrderAdd.Contact) &&
        this.PurchaseOrderAdd.Contact.length > 50
      ) {
        ret = false;
        this.$refs.Contact.handleFocus();
        this.$message({
          message: "联系人长度不能超过50个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.PurchaseOrderAdd.Telephone) &&
        this.PurchaseOrderAdd.Telephone.length > 50
      ) {
        ret = false;
        this.$refs.Telephonenumb.handleFocus();
        this.$message({
          message: "联系电话长度不能超过50个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.PurchaseOrderAdd.Phone) &&
        this.PurchaseOrderAdd.Phone.length > 50
      ) {
        ret = false;
        this.$refs.Telephonenumb2.handleFocus();
        this.$message({
          message: "手机号码长度不能超过50个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.PurchaseOrderAdd.Address) &&
        this.PurchaseOrderAdd.Address.length > 200
      ) {
        ret = false;
        this.$refs.Address.handleFocus();
        this.$message({
          message: "地址长度不能超过200个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.PurchaseOrderAdd.Remark) &&
        this.PurchaseOrderAdd.Remark.length > 1000
      ) {
        ret = false;
        this.$message({
          message: "采购备注不能超过1000个字符",
          type: "warning",
        });
      }
      return ret;
    },
    async save(isClose) {
      this.loading = true;
      var vm = this;

      return this.$post(
        CONFIG.PurchaseOrferSaveURL,
        this.PurchaseOrderAdd,
        (data, logic) => {
          this.loading = false;
          if (logic.code === 200) {
            this.returnPOGuid = data.POGuid;
            let jGUIDList = _.uniq(
              _.map(this.PurchaseOrderAdd.LineList, (item) => item.JGUID)
            );
            this.$message({ message: "保存成功", type: "success" });
            this.$emit("save-success", true);

            this.PurchaseOrderAdd = {};
            this.PurchaseOrderAdd = data;
            this.itemSource = data.LineList;
            this.PurchaseOrderAdd.IsAdd = false;
            if (vm.IsPrint) {
              vm.onPrint();
            }
            if (isClose) {
              this.$emit("close");
            } else if (this.IsContinueInputMode) {
              this.onResetPage(); // 重置
            }
            this.$emit("clear-cart", jGUIDList);

            if (this.useLoanService) {
              openLoanService(data.BillNo, "purchase");
            }
          } else {
            let msg = logic.msg || "保存失败";
            this.showError(msg);
          }
        }
      )
        .catch((e) => {
          console.log("e:", e);
        })
        .finally(() => {
          vm.loading = false;
        });
    },

    async purchaseOrderSave(isClose) {
      if (this.useLoanService) {
        isClose = true;
        //已审核的不能编辑只能申请月结服务
        if (this.PurchaseOrderAdd.IsAudited) {
          if (this.TaxRate !== 13) {
            this.$message({
              message:
                "税点为13的采购单，才能申请月结服务。该采购单已审核，请先反审，修改税点为13再申请",
              type: "warning",
            });
            return;
          }
          //打开月结服务
          openLoanService(this.PurchaseOrderAdd.BillNo, "purchase");
          //关闭当前弹窗
          this.$emit("close");
          return;
        }
      }
      // 保存
      if (this.checkData() && this.checkLength()) {
        return await this.save(isClose);
      }
      return false;
    },

    // 保存并提交到供应链
    async onSaveToSCM() {
      await this.purchaseOrderSave();
      if (this.returnPOGuid !== null) {
        this.$message({ message: "正在跳转到供应链", type: "warning" });
        purchaseToSCM(this.returnPOGuid);
      }
    },

    CalcOrderAmountAndGrossProfit(isEdit) {
      // 计算金额和毛利润
      this.PurchaseOrderAdd.Amount = 0;
      this.PurchaseOrderAdd.DiscountAmount = 0;

      _.each(
        isEdit ? this.PurchaseOrderAdd.LineList : this.itemSource,
        (o, index) => {
          this.PurchaseOrderAdd.Amount += this.toFloat(o.Amount, 2);
          this.PurchaseOrderAdd.DiscountAmount += this.toFloat(
            o.DiscountAmount,
            2
          );
        }
      );
      this.PurchaseOrderAdd.Amount = this.toFloat(
        this.PurchaseOrderAdd.Amount - this.PurchaseOrderAdd.DiscountAmount,
        2
      );
    },
    LoadEditData: async function (guid) {
      // 根据出库单GUID加载实体
      let data = await this.$post(CONFIG.PurchaseOrferSearchById, {
        POGuid: guid,
      });

      console.log("data:", data);
      this.TaxRate = data.TaxRate; //单据原始税点

      _.each(data.LineList, (item) => {
        if (item.NeedDeliveryDate === undefined) {
          item.NeedDeliveryDate = "";
        }
        if (item.FactDeliveryDate === undefined) {
          item.FactDeliveryDate = "";
        }
      });
      this.PurchaseOrderAdd = _.extend({}, this.defaultPurchaseOrder, data);
      this.getAddress(this.PurchaseOrderAdd);
      this.$nextTick(function () {
        this.onBindData();
        this.CalcOrderAmountAndGrossProfit(true);
        this.PurchaseOrderAdd.IsAdd = false;
        this.PurchaseOrderAdd.POGuid = guid;
        this.isAudit = data.IsAudited;
        this.isAllow = false; // 编辑禁用选择
        this.isRead = true;
        this.ResTaxRate();
      });
    },

    LoadListByStock: async function (items) {
      // 根据库存加载
      let datas = await this.$post(CONFIG.SearchByStkGUIdS, {
        StkGuids: items,
      });
      if (datas.length > 0) {
        this.PurchaseOrderAdd.LineList = datas;
        this.$nextTick(function () {
          let tempSource = this.itemSource; // 如果是库存选择后的结果则叠加
          let retList = tempSource;

          _.each(this.PurchaseOrderAdd.LineList, (i) => {
            let list = _.map(retList, (item) => {
              return { model: item.Model, brand: item.Brand };
            });
            if (!_.some(list, { model: i.Model, brand: i.Brand })) {
              i.StkGUID = "";
              retList.push(i);
            }
          });
          this.PurchaseOrderAdd.LineList = retList;
          this.itemSource = this.PurchaseOrderAdd.LineList;
          this.ResTaxRate();
        });
      } else {
        this.$message({ message: "加载库存失败，请刷新后重试", type: "error" });
      }
    },
    LoadListByShoppingCar: async function (carItem) {
      if (!carItem || !carItem.list) {
        this.PurchaseOrderAdd.LineList = [];
        this.itemSource = [];
        return;
      }
      this.PurchaseOrderAdd.SupplierName = carItem.CompanyName;
      this.PurchaseOrderAdd.SupplierGUID = carItem.CompanyGuid;
      if (carItem.CompanyName && carItem.CompanyName.length > 0) {
        let isMySupplies =
          this.getCompanySetupValue("StockInOnlyMySupplier") === 1;
        let gsParam = _.extend(
          {},
          {
            FieldWhereString: JSON.stringify({
              CompanyName: carItem.CompanyName,
            }),
            PageIndex: 1,
            PageSize: 10,
            CompanyTypes: "2,3",
            IsMySupplier: isMySupplies,
          }
        );
        this.$post(CONFIG.GetSupplierInfoURL, gsParam, (data) => {
          if (data && data.TotalCount > 0 && data.ResultList) {
            this.PurchaseOrderAdd.SupplierName = data.ResultList[0].CompanyName;
            this.PurchaseOrderAdd.SupplierGUID = data.ResultList[0].CompanyID;
            this.getAddress(data.ResultList[0]);
            // this.PurchaseOrderAdd.Address = data.ResultList[0].Address
            this.PurchaseOrderAdd.Contact = data.ResultList[0].ContactName;
            this.PurchaseOrderAdd.Telephone = data.ResultList[0].Telephone;
            this.PurchaseOrderAdd.Phone = data.ResultList[0].Mobile;
          }
        });
      }

      this.PurchaseOrderAdd.LineList = [];
      this.itemSource = [];
      // console.log(carItem.list, "carItem.list");
      carItem.list.forEach((item) => {
        this.PurchaseOrderAdd.LineList.push({
          JGUID: item.JGUID,
          Brand: item.Brand,
          Qty: item.InvQty,
          Price: item.Price === undefined ? 0 : item.Price,
          PriceInTax: this.CalcPriceInTax(
            item.Price === undefined ? 0 : item.Price - 0
          ),
          Amount: this.CalcAmout(
            item.Qty,
            this.CalcPriceInTax(item.Price === undefined ? 0 : item.Price - 0)
          ),
          Quality: item.Quality,
          MakeYear: item.MakeYear,
          Model: item.Model,
          Packaging: item.Packaging,
          SOGuid: item.SOGuid,
          SOLineGUID: item.SOLineGUID,
          CustomerPartNo: item.CustomerPartNo,
          SOBillNo: item.SOBillNo,
          ProductCategory: item.ProductCategory,
          DeliveryDate: item.DeliveryDate,
          NoticeGUID: item.NoticeGUID,
          IAGUID: item.IAGUID,
          MPQ: item.MPQ,
          Unit: item.Unit,
          NeedDeliveryDate: "",
          FactDeliveryDate: "",
          CONumber: item.CONumber,
        });
      });
      this.PurchaseOrderAdd.PurchaseOrderStatus = "待审核";
      this.itemSource = this.PurchaseOrderAdd.LineList;
      this.$nextTick(function () {
        this.CalcTaxRate();
        this.CalcOrderAmountAndGrossProfit(false);
      });
    },
    // 验证 GUID加载实体税点是否为空 如果为空则默认参数配置， 从新计算
    ResTaxRate() {
      if (!this.hasValue(this.PurchaseOrderAdd.TaxRate)) {
        this.PurchaseOrderAdd.TaxRate = this.parmTaxRate;
        this.CalcTaxRate();
      }
    },
    // 2017-12-97
    CalcProfitMargins() {
      let rate = 1;
      if (this.PurchaseOrderAdd && this.PurchaseOrderAdd.ExchangeRate) {
        rate = this.toFloat(this.PurchaseOrderAdd.ExchangeRate);
      }
      let tempSource = this.itemSource;
      if (tempSource.length > 0) {
        for (let i = 0; i < tempSource.length; i++) {
          // 利润率=(未税单价*汇率-采购价)/采购价 * 100. 显示加上% ， 保留两位小数
          let price = this.toFloat(tempSource[i].Price);
          let buyPrice = this.toFloat(tempSource[i].BuyPrice);
          if (price > 0 && buyPrice > 0) {
            price = ((price * rate - buyPrice) / buyPrice) * 100;
            tempSource[i].ProfitMargins = this.toFloat(price, 2) + "%";
          } else {
            tempSource[i].ProfitMargins = "";
          }
        }
        this.itemSource = tempSource;
        _.each(tempSource, (item, i) => {
          this.setCellData(
            i,
            this.getColumn("ProfitMargins").index,
            item.ProfitMargins || ""
          );
        });
      }
    },
    LoadPurchaseByInquire: async function (guid) {
      // 根据询价记录生成采购单
      let data = await this.$post(CONFIG.SearchByInquireGUID, { IQGUID: guid });
      this.$nextTick(function () {
        if (!_.isUndefined(data)) {
          // console.log(data.TaxRate, 'data.TaxRate')
          this.PurchaseOrderAdd.TaxRate =
            data.TaxRate === undefined ? 0 : data.TaxRate;
          this.PurchaseOrderAdd.CurrencyCode = data.CurrencyCode;
          this.PurchaseOrderAdd.LineList = [];
          this.$set(data, "QuotePrice", data.Price); // 添加一个供应商报价
          this.$set(data, "Unit", "PCS");
          data.PriceInTax = this.CalcPriceInTax(this.ConvertNumber(data.Price));
          data.Amount = this.CalcAmout(
            this.ConvertNumber(data.Qty),
            this.ConvertNumber(data.PriceInTax)
          );
          this.PurchaseOrderAdd.LineList.push(data);
          this.itemSource = this.PurchaseOrderAdd.LineList;
          this.$nextTick(function () {
            // this.CalcTaxRate()
            this.CalcOrderAmountAndGrossProfit(false);
          });
        } else {
          this.$message({
            message: "加载询价记录失败，请刷新后重试",
            type: "error",
          });
        }
      });
    },
    CurrencyCodeChange(val) {
      // console.log(val, 'val')
      // 币种改变选项事件
      let itemVal = val ? val.key : this.PurchaseOrderAdd.CurrencyCode; // 选中的值
      if (!this.hasValue(itemVal)) {
        itemVal = "RMB";
      }
      if (itemVal === "RMB") {
        this.IsExchangeRateRMB = true;
      } else {
        this.IsExchangeRateRMB = false;
      }
      var info = _.find(this.selectOptionsCurrencyCode, function (item) {
        return item.key === itemVal;
      });
      if (this.hasValue(info)) {
        this.PurchaseOrderAdd.ExchangeRate = info.ExchangeRate;
      } else {
        this.PurchaseOrderAdd.ExchangeRate = "1";
      }
    },

    getColumn(name) {
      let list = [{ name: "_Checked" }];
      let columns = this.columns;
      _.each(columns, (item) => {
        if (item.visible) {
          list.push(item);
        }
      });
      for (let i = 0; i < list.length; i++) {
        if (name === list[i].name) {
          return {
            col: columns[i],
            index: i,
          };
        }
      }
    },
    setCellData(r, c, v) {
      this.$nextTick(() => {
        let trs = this.$el.querySelectorAll(
          ".el-table .el-table__body-wrapper .el-table__body tbody tr"
        );
        let containerOld =
          trs[r] && trs[r].querySelectorAll("td")[c].querySelector(".cell div");
        let containerNew =
          trs[r] &&
          trs[r].querySelectorAll("td")[c].querySelector(".cell input");
        // if (!containerNew || !containerOld) return
        if (containerOld) {
          containerOld.innerText = v;
        } else {
          if (containerNew) {
            containerNew.value = v;
          }
        }
      });
    },
    CalcTaxRate(val) {
      val
        ? (this.PurchaseOrderAdd.TaxRate = val.key)
        : (this.PurchaseOrderAdd.TaxRate = null);
      if (this.itemSource.length > 0) {
        const source = this.itemSource;
        for (let i = 0; i < source.length; i++) {
          // 计算未税价和含税价和金额
          if (this.hasValue(source[i].Price)) {
            // 计算含税
            let _priceIntax = this.CalcPriceInTax(source[i].Price);
            source[i].PriceInTax = _priceIntax;
          } else if (this.hasValue(source[i].PriceInTax)) {
            // 含税算未税
            let _price = this.CalcPrice(source[i].PriceInTax);
            source[i].Price = _price;
          }
          source[i].Amount = this.CalcAmout(
            source[i].Qty,
            source[i].PriceInTax
          ).toFixed(2);
        }
        this.itemSource = JSON.parse(JSON.stringify(source));
        this.CalcOrderAmountAndGrossProfit(false);
      }
    },
    NeedDateChange(value) {
      this.PurchaseOrderAdd.NeedDeliveryDate = value;
    },
    SearchCompany() {
      this.InTypes = "2,3";
      this.companyVisible = true;
      this.SelectSearchModel.CompanyName = this.PurchaseOrderAdd.SupplierName;
    },
    companyChecked(item) {
      this.PurchaseOrderAdd.SupplierGUID = item.CCompanyID;
      this.PurchaseOrderAdd.SupplierName = item.CompanyName;
      this.getAddress(item);
      this.PurchaseOrderAdd.Contact = item.ContactName;
      this.PurchaseOrderAdd.Telephone = item.Telephone;

      // 默认跟进人，跟进人为空，默认当前登录用户 ,跟进人为多个取第一个。
      const regex = /,|，/; // 逗号
      if (this.hasValue(item.FollowUserName)) {
        let idList = item.FollowUserName.split(regex);
        let followUserName = "";
        if (idList.length > 1) {
          followUserName = idList[0];
        } else {
          followUserName = item.FollowUserName;
        }
        if (this.hasValue(followUserName)) {
          let info = _.find(this.selectOptionsAllUserURL, (item) => {
            return item.value === followUserName;
          });
          if (this.hasValue(info) && this.hasValue(info.key)) {
            this.PurchaseOrderAdd.BuyerID = info.key;
            this.PurchaseOrderAdd.BuyerName = info.value;
          }
        }
      }
      if (!this.hasValue(this.PurchaseOrderAdd.BuyerID)) {
        this.PurchaseOrderAdd.BuyerID = this.$store.state.accountInfo.UserID;
        this.PurchaseOrderAdd.BuyerName =
          this.$store.state.accountInfo.UserName;
      }
      if (this.hasValue(item.CurrencyCode)) {
        this.PurchaseOrderAdd.CurrencyCode = item.CurrencyCode;
      }
      this.PurchaseOrderAdd.PaymentType = item.PaymentType;
      this.CurrencyCodeChange();
      if (
        this.hasValue(item.TaxRate) &&
        this.PurchaseOrderAdd.TaxRate !== item.TaxRate
      ) {
        this.PurchaseOrderAdd.TaxRate = item.TaxRate;
        this.CalcTaxRate({ key: item.TaxRate });
      }
    },
    hasValue(val) {
      if (val === null || val === undefined || val === "") {
        return false;
      }
      return true;
    },
    onDeletedRow(d) {
      // 删除单行数据
    },
    onDeletedCheckedRow(d) {
      // 删除多条数据
    },
    // 计算方法集合
    CalcPriceInTax(price) {
      // 计算含税价
      return this.toFloat(price * (1 + this.PurchaseOrderAdd.TaxRate / 100), 6);
    },
    CalcPrice(priceintax) {
      // 计算未税
      return this.toFloat(
        priceintax / (1 + this.PurchaseOrderAdd.TaxRate / 100),
        6
      );
    },
    CalcAmout(qty, salesPrice) {
      // 计算金额
      return this.toFloat((qty >= 0 ? qty : 0) * salesPrice, 2);
    },
    CalcPay(isEdit) {
      // 计算应付金额
      this.PurchaseOrderAdd.Amount = 0;
      _.each(
        isEdit ? this.PurchaseOrderAdd.LineList : this.itemSource,
        (o, index) => {
          this.PurchaseOrderAdd.Amount += Number(this.ConvertNumber(o.Amount));
        }
      );
      this.PurchaseOrderAdd.Amount = this.toFloat(
        this.PurchaseOrderAdd.Amount,
        2
      );
    },
    ConvertNumber(num) {
      // 转出数据格式
      return _.isNaN(num) || _.isUndefined(num) || num == null
        ? 0
        : this.toFloat(num);
    },
    onCellEdited(
      aftervalue,
      beforevalue,
      colname,
      gridobj,
      rowindex,
      colindex
    ) {
      // 1.修改后值 2.修改前值 3.列明 4.表格对象 5.行索引 6.列索引
      // 修改的行
      if (!this.hasValue(aftervalue)) {
        return;
      }
      let rowData = gridobj.row;

      /*************处理大小写配置start*************/
      //要处理大小写的列
      const handleColumns = ["Model", "Brand", "Packaging", "MakeYear"];
      if (
        !this.CompanySetup.EnableNotUpperCase &&
        handleColumns.includes(colname)
      ) {
        aftervalue = aftervalue.toUpperCase();
        rowData[colname] = aftervalue;
      }
      /*************处理大小写配置end***************/

      if (colname === "Price") {
        // 未税算含税
        let _price = this.CalcPriceInTax(aftervalue);
        rowData["PriceInTax"] = _price; // 设置对象的值
      }
      if (colname === "PriceInTax") {
        // 含税算未税
        let _priceIntax = this.CalcPrice(aftervalue);
        rowData["Price"] = _priceIntax; // 设置对象的值
      }
      if (
        colname === "Qty" ||
        colname === "PriceInTax" ||
        colname === "Price"
      ) {
        let numbers = this.ConvertNumber(rowData.Qty);
        let priceIntax = this.ConvertNumber(rowData.PriceInTax);
        rowData["Amount"] = this.CalcAmout(numbers, priceIntax); // 设置对象的值
        this.CalcOrderAmountAndGrossProfit(false);
      }
      //判断是否为最后一行，自动添加空行
      if (rowindex == gridobj.rows.length - 1) {
        this.$refs.flexGrid.addRow(true);
      }
    },
    onBillIconClick() {
      if (this.PurchaseOrderAdd.IsAdd) {
        this.IsManual = !this.IsManual;
        if (!this.IsManual) {
          this.PurchaseOrderAdd.BillNo = "";
        }
      } else {
        this.$message({
          message: "单据编号生成后不可以修改！",
          type: "warning",
        });
      }
    },
    autocompleteChange(val) {
      this.startTime = new Date();
      _.delay(() => {
        this.endTime = new Date();
        if (this.endTime - this.startTime >= 800) {
          this.autocompleteSearch(true, val);
        }
      }, 800);
    },
    autocompleteSearch(isSearch, data) {
      if (!isSearch) return;
      this.autocompleteSearchData = data;
      if (data.length > 0) {
        // this.autocompleteShow = true
        // this.$refs.autocompleteSelect.$el.style.left = getabsleft(this.$refs.znlAutocomplete.$el) + 65 + 'px'
        // this.$refs.autocompleteSelect.$el.style.top = getabstop(this.$refs.znlAutocomplete.$el) + 30 + 'px'
      } else {
        this.autocompleteShow = false;
      }
    },
    selectionChanged(val) {
      this.autocompleteShow = false;
      this.companyChecked([val]);
    },
    confirmPrint(data) {
      this.onPrint();
    },
    // 打印参数，选中的打印模板
    setPrintParam(printInfo) {
      this.printInfo.title = printInfo.title;
      this.printInfo.template = printInfo.template;
    },
    // 连续录单模式保存到参数配置
    onContinuousRecordValue() {
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsContinuousRecord") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.IsContinueInputMode ? 1 : 0;
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: this.GCode, ParamJson: [datas] },
          (data) => {
            localStorage.removeItem("ordPurchaseOrder");
          }
        );
      }
    },
    getAddress(item) {
      this.selectOptionsAddress = [];
      // 获取地址信息组合下拉框数据
      if (this.hasValue(item.DeliveryAddress)) {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: item.DeliveryAddress,
          value: item.DeliveryAddress,
        });
      }
      if (this.hasValue(item.Address)) {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: item.Address,
          value: item.Address,
        });
      }
      if (this.hasValue(item.Address2)) {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: item.Address2,
          value: item.Address2,
        });
      }
      if (this.hasValue(item.Address3)) {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: item.Address3,
          value: item.Address3,
        });
      }
      // 获取地址信息 如果存在货运地址默认货运地址
      if (this.hasValue(item.DeliveryAddress)) {
        this.PurchaseOrderAdd.Address = item.DeliveryAddress;
      }
      // 获取地址信息 如果没有货运地址默认公司地址
      if (!this.hasValue(item.DeliveryAddress) && this.hasValue(item.Address)) {
        this.PurchaseOrderAdd.Address = item.Address;
      }
      // 获取地址信息 如果公司地址地址默认Address1
      if (!this.hasValue(item.Address) && this.hasValue(item.Address2)) {
        this.PurchaseOrderAdd.Address = item.Address2;
      }
      // 获取地址信息 如果没有Address1默认Address2
      if (!this.hasValue(item.Address2) && this.hasValue(item.Address3)) {
        this.PurchaseOrderAdd.Address = item.Address3;
      }
      // 如果没有地址信息，清空上次输入的地址信息
      if (
        !this.hasValue(item.Address) &&
        !this.hasValue(item.Address2) &&
        !this.hasValue(item.Address3) &&
        !this.hasValue(item.DeliveryAddress)
      ) {
        this.$refs.Address.clear();
      }
    },
    // 自动打印保存到参数配置
    onPrintValue() {
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsAutoPrint") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.IsPrint ? 1 : 0;
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: this.GCode, ParamJson: [datas] },
          (data) => {
            localStorage.removeItem("ordPurchaseOrder");
          }
        );
      }
    },
    changeAddressInputHandler(value) {
      const selectValue = value.target.value;
      let addres = _.find(this.selectOptionsAddress, function (item) {
        return item.key === selectValue;
      });
      if (!this.hasValue(addres) && _.trim(selectValue) !== "") {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: selectValue,
          value: selectValue,
        });
      }
      this.PurchaseOrderAdd.Address = selectValue;
    },

    // 月结服务勾选
    onLoanServiceChange() {
      if (this.useLoanService) {
        this.PurchaseOrderAdd.TaxRate = 13;
        this.$message({ message: "税点已改为13" });
      }
    },
    //进口报关
    onImportDeclaration() {
      //打开供应链
      openSCM({ key1: 1, key2: "test" });
      try {
        var param = {
          collectionType: "供应链入口",
          eventName: "编辑采购单-进口报关",
        };

        //记录日志
        this.$post(CONFIG.AddInfoCollectLogUrl, param, (data, logic) => {});
      } catch (error) {
        console.log("进口报关记录失败:", error);
      }
    },
  },
  created() {
    var a = [
      { ItemValue: "1", ItemName: "我方支付" },
      { ItemValue: "2", ItemName: "我方垫付" },
      { ItemValue: "3", ItemName: "客户支付" },
      { ItemValue: "4", ItemName: "供应商支付" },
    ];
    this.FreightClearFormOptions = _.map(a, (item) => {
      return { key: item.ItemValue, value: item.ItemName };
    });

    var b = [
      { ItemValue: 1, ItemName: "普通发票" },
      { ItemValue: 2, ItemName: "增值税票" },
    ];
    this.InvoiceTypeOptions = _.map(b, (item) => {
      return { key: item.ItemValue, value: item.ItemName };
    });
  },
  mounted() {
    this.$nextTick(() => {
      if (this.editType === "addNew") {
        this.$refs.flexGrid.addRow(true);
      }
    });
  },
};
</script>


<style lang="scss">
.dialog-ordpurchaseorderedit-box {
  .grid-min-box {
    background: transparent !important;
  }
}
</style>
