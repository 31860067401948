import { render, staticRenderFns } from "./BomAILogin.vue?vue&type=template&id=6047b5a0&scoped=true&"
import script from "./BomAILogin.vue?vue&type=script&lang=js&"
export * from "./BomAILogin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6047b5a0",
  null
  
)

export default component.exports