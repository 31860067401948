var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-form-printer",style:({ width: _vm.page.width })},_vm._l((_vm.dataSlice),function(interval,index){return _c('div',{key:index},[_c('div',{ref:"pageBox",refInFor:true,staticClass:"page-box",style:({
        width: _vm.page.width,
        height: _vm.page.height,
        paddingTop: _vm.page.paddingTop,
        paddingLeft: _vm.page.paddingLeft
      })},[_c('div',{staticClass:"content-box"},_vm._l((_vm.row),function(row,rowIndex){return _c('el-row',{key:rowIndex,class:[
            row.type ? row.type + '-row' : '',
            row.isFloat && 'row-float'
          ],style:({
            width: row.width,
            top: row.isFloat ? row.top : '0px',
            left: row.isFloat ? row.left : '0px',
            lineHeight:
              (row.rowHeight ? row.rowHeight : _vm.page.rowHeight) + 'px',
            fontSize: row.fontSize + 'px',
            fontFamily: row.fontFamily || _vm.pageConfig.fontFamily,
            fontWeight: row.fontWeight || 'normal',
            marginTop: row.marginTop + 'px',
            marginRight: row.marginRight + 'px',
            marginBottom: row.marginBottom + 'px',
            marginLeft: row.marginLeft + 'px'
          }),attrs:{"type":"flex","justify":row.align ? row.align : _vm.page.align}},[(row.type === 'image')?[_c('div',{style:({ width: row.width, height: row.height })},[(row.field)?_c('img',{attrs:{"src":_vm.info[row.field],"alt":""}}):_vm._e(),(row.src)?_c('img',{attrs:{"src":row.src,"alt":""}}):_vm._e()])]:(row.type === 'textarea')?[_c('div',[_c('pre',{style:({
                  fontSize:
                    (row.fontSize ? row.fontSize : _vm.page.fontSize) + 'px',
                  fontFamily: row.fontFamily || _vm.page.fontFamily,
                  lineHeight:
                    (row.lineHeight
                      ? row.lineHeight
                      : _vm.pageConfig.lineHeight) + 'px'
                })},[_vm._v(_vm._s(row.content))])])]:(row.type === 'table')?[_c('table',{attrs:{"width":"100%","border":"1","cellspacing":"0","cellpadding":"0","border-spacing":"0"}},[_c('tr',{attrs:{"height":_vm.tableConfig.headerHeight}},[(_vm.tableConfig.showIndex)?_c('th',[_vm._v("\n                  "+_vm._s(_vm.tableConfig.indexTitle)+"\n                ")]):_vm._e(),_vm._l((_vm.rowConfig[rowIndex].colConfig),function(item,index){return _c('th',{key:index,attrs:{"height":row.rowHeight || _vm.page.rowHeight + 'px'}},[_c('div',{staticClass:"header-box",style:({ lineHeight: _vm.tableConfig.headerHeight + 'px' })},[_vm._v("\n                    "+_vm._s(item.headerName)+"\n                  ")])])})],2),_vm._l((_vm.dataSources.slice(
                  interval[0],
                  interval[1]
                )),function(data,index){return _c('tr',{key:index,attrs:{"height":_vm.tableConfig.rowHeight + 'px'}},[(_vm.tableConfig.showIndex)?_c('td',[_vm._v(_vm._s(index + 1))]):_vm._e(),_vm._l((row.colConfig),function(col,colIndex){return _c('td',{key:colIndex,style:({ padding: _vm.tableConfig.padding + 'px' })},[_vm._v("\n                  "+_vm._s(data[col.field])+"\n                ")])})],2)}),(_vm.countSlice.length && row.footer)?_c('tr',{staticClass:"table-footer",attrs:{"height":_vm.tableConfig.footerHeight}},_vm._l((row.footer),function(footItem,footIndex){return _c('td',{key:footIndex,attrs:{"colspan":footItem.colspan}},[(footItem.label)?_c('span',[_vm._v(_vm._s(footItem.label)+":")]):_vm._e(),(footItem.type === 'capital')?_c('span',[_vm._v("\n                    "+_vm._s(_vm.countSlice[index][0])+"\n                  ")]):_vm._e(),(footItem.type === 'count')?_c('span',[_vm._v("\n                    "+_vm._s(_vm.countSlice[index][1])+"\n                  ")]):(footItem.field)?_c('span',[_vm._v("\n                    "+_vm._s(_vm.info[footItem.field])+"\n                  ")]):(footItem.text)?_c('span',[_vm._v("\n                    "+_vm._s(footItem.text)+"\n                  ")]):_vm._e()])}),0):_vm._e()],2)]:_vm._l((row.content),function(col,colIndex){return _c('el-col',{key:colIndex,style:({
                textAlign: row.textAlign ? row.textAlign : _vm.page.textAlign,
                lineHeight: row.rowHeight
              })},[(col.text || col.field)?_c('div',{staticStyle:{"display":"inline-flex"}},[(col.text)?_c('span',[_vm._v(_vm._s(col.text))]):_vm._e(),(_vm.info[col.field])?_c('span',[_vm._v(_vm._s(_vm.info[col.field]))]):_vm._e()]):_vm._e()])})],2)}),1)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }