export const StsQuoted = () => import('~/views/Sts/Quoted' /* webpackChunkName: "pages/sts" */ )
export const StsSaleOut = () => import('~/views/Sts/SaleOut' /* webpackChunkName: "pages/sts" */ )
export const StsInquire = () => import('~/views/Sts/Inquire' /* webpackChunkName: "pages/sts" */ )
export const StsPurchaseIn = () => import('~/views/Sts/PurchaseIn' /* webpackChunkName: "pages/sts" */ )
export const StsUnReceive = () => import('~/views/Sts/UnReceive' /* webpackChunkName: "pages/sts" */ )
export const StsUnPayment = () => import('~/views/Sts/UnPayment' /* webpackChunkName: "pages/sts" */ )
export const StsCustFollows = () => import('~/views/Sts/CustFollows' /* webpackChunkName: "pages/sts" */ )
export const StsHotQuoteModels = () => import('~/views/Sts/HotQuoteModels' /* webpackChunkName: "pages/sts" */ )
export const HotSalesCustomer = () => import('~/views/Sts/HotSalesCustomer' /* webpackChunkName: "pages/sts" */ )
export const HotInBillSupplier = () => import('~/views/Sts/HotInBillSupplier' /* webpackChunkName: "pages/sts" */ )
export const StsHotSalesModel = () => import('~/views/Sts/HotSalesModel' /* webpackChunkName: "pages/sts" */ )
export const StsModelAly = () => import('~/views/Sts/ModelAly' /* webpackChunkName: "pages/sts" */ )
export const StsCustomerAly = () => import('~/views/Sts/CustomerAly' /* webpackChunkName: "pages/sts" */ )
export const StkStockStatisticSearchView = () => import('~/views/Sts/StkStockStatisticSearchView' /* webpackChunkName: "pages/sts" */ )
export const StockWarningStatisticSearchView = () => import('~/views/Sts/StockWarningStatisticSearchView' /* webpackChunkName: "pages/sts" */ )
export const StsDataStatistics = () => import('~/views/Sts/StsDataStatistics' /* webpackChunkName: "pages/sts" */ )
