<template>
  <znl-dialog
    :title="title"
    height="500px"
    width="870px"
    :visible.sync="visibleCom"
    :loading="loadingState"
    :loading-text="LoadingText"
    :append-to-body="true"
    class="DCompanyContactSelect"
    :confirm-call-back="confirmCallBack"
    @close="()=>{$emit('close')}"
  >
    <znl-gridmodule
      ref="flexGrid"
      :columns="columns"
      :has-znl-actions="false"
      :on-init="onInit"
      :item-source="itemSource"
      :page-index="pageIndex"
      :page-size="pageSize"
      :total-count="totalCount"
      :is-fields-search="isShowHeaderSearch"
      :is-multi-rows-check="false"
      :CompanyName="CompanyName"
      :on-search="onSearch"
      :search-fields="searchModel"
      :on-page-changed="onBindData"
      :is-footer-show="true"
      @ondblclick="(val)=>{$emit('update:visible', false),$emit('confirm', val)}"
    >
      <div slot="action-form" class="m-accurate-checbox znl-component-input">
        <znl-input
          from-type="input"
          title="名称："
          title-width="30px"
          placeholder="客户名称"
          size="mini"
          layout="left"
          width="200px"
          inp-perc="100%"
          class="box_input_checkbox"
          :border="true"
          :clearable="true"
          v-model="searchFields.CompanyName"
          @keyup.enter.native="onSearch(1)"
          type="text"
        ></znl-input>
        <znl-input
          form-type="input"
          placeholder="地址"
          title="地址："
          title-width="35px"
          width="200px"
          size="mini"
          layout="left"
          inp-perc="100%"
          :border="true"
          v-model="searchFields.Address"
          :clearable="true"
          @keyup.enter.native="onSearch(1)"
          type="text"
        ></znl-input>
        <znl-input
          form-type="input"
          placeholder="(联系人、电话、QQ、邮箱)"
          title="联系方式："
          width="250px"
          title-width="70px"
          size="mini"
          :border="true"
          layout="left"
          inp-perc="100%"
          v-model="searchFields.ContactInfo"
          :clearable="true"
          @keyup.enter.native="onSearch(1)"
          type="text"
        ></znl-input>

        <znl-button
          type="multiple"
          :height="22"
          :width="70"
          class="box-left-btn search-btn"
          style-type="mac"
        >
          <znl-button-menu @click="onBindData()">
            <span>
              <i class="iconfont icon-search_ic"></i>搜索
            </span>
          </znl-button-menu>
          <znl-button-menu
            :width="20"
            tip="高级搜索"
            @click="() => {isShowHeaderSearch = !isShowHeaderSearch}"
          >
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
      </div>
    </znl-gridmodule>
  </znl-dialog>
</template>

<script>
import { mixin as propSyncMixin } from "~assets/scripts/mixins/propSync";
import { mixin as moveToMixin } from "~assets/scripts/directives/moveTo";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
const CONFIG = {};
export default {
  name: "DCompanySelect",
  mixins: [propSyncMixin, moveToMixin, getCommonDataMixin],
  config: CONFIG,
  components: {
    // ZnlSelectDlg
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      loadingState: false,
      LoadingText: "数据加载中,请稍后...",
      companyStr: [],
      searchFields: {
        CompanyName: "",
        Address: "",
        ContactInfo: ""
      },
      IsMySupplier: false, // 是否只选择我的供应商,
      isShowHeaderSearch: false,
      hideContact: false, // 隐藏供应商、客户联系资料
      gridConfig: [
        {
          CTitle: "跟进人",
          BindField: "FollowUserName",
          IsShow: true,
          ColumnWidth: 60,
          DataType: 1,
          IsSystem: false
        },
        {
          CTitle: "跟进人ID",
          BindField: "FollowUserID",
          IsShow: false,
          ColumnWidth: 60,
          DataType: 1,
          IsSystem: false
        },
        {
          CTitle: "公司GUID",
          BindField: "CompanyID",
          IsShow: false,
          ColumnWidth: 50,
          DataType: 1,
          IsSystem: true
        },
        {
          CTitle: "纳税人识别号",
          BindField: "TaxNumber",
          IsShow: false,
          ColumnWidth: 100,
          DataType: 1,
          IsSystem: false
        },
        {
          CTitle: "公司名称",
          BindField: "CompanyName",
          IsShow: true,
          ColumnWidth: 180,
          DataType: 1,
          IsSystem: false
        },
        {
          CTitle: "地址",
          BindField: "Address",
          IsShow: true,
          ColumnWidth: 180,
          DataType: 1,
          IsSystem: false
        },
        {
          CTitle: "收/发货地址",
          BindField: "DeliveryAddress",
          IsShow: true,
          ColumnWidth: 150,
          DataType: 1,
          IsSystem: false
        },
        {
          CTitle: "地址1",
          BindField: "Address2",
          IsShow: false,
          ColumnWidth: 150,
          DataType: 1,
          IsSystem: false
        },
        {
          CTitle: "地址2",
          BindField: "Address3",
          IsShow: false,
          ColumnWidth: 150,
          DataType: 1,
          IsSystem: false
        },
        {
          CTitle: "联系人",
          BindField: "ContactName",
          IsShow: true,
          ColumnWidth: 60,
          DataType: 1,
          IsSystem: false
        },
        {
          CTitle: "联系人电话",
          BindField: "Telephone",
          IsShow: true,
          ColumnWidth: 100,
          DataType: 1,
          IsSystem: false
        },
        {
          CTitle: "公司类型",
          BindField: "CompanyType",
          IsShow: true,
          ColumnWidth: 80,
          DataType: 1,
          IsSystem: false
        },
        {
          CTitle: "开户银行",
          BindField: "BankName",
          IsShow: false,
          ColumnWidth: 120,
          DataType: 1,
          IsSystem: false,
          IsRequired: false
        },
        {
          CTitle: "开户银行账号",
          BindField: "BankAccount",
          IsShow: false,
          ColumnWidth: 170,
          DataType: 1,
          IsSystem: false,
          IsRequired: false
        }
      ]
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    visible: {
      type: Boolean,
      default: false,
      propsync: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {}
        };
      }
    },
    height: Number,
    companyType: String,
    title: String,
    CompanyName: String,
    isCanCheckColumns: Boolean,
    openFrom: String
  },
  computed: {
    visibleCom: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      }
    }
  },
  watch: {
    companyType: {
      handler: function(val) {
        this.companyType = val;
      }
    },
    visible(val) {
      if (val) {
        this.searchFields.CompanyName = this.searchModel.CompanyName;
      }
    }
  },
  methods: {
    async onInit() {
      this.searchFields.CompanyName = this.CompanyName;
      this.loadingState = true;
      let value = this.getCompanySetupValue("StockInOnlyMySupplier");
      console.log(value, 'StockInOnlyMySupplier');
      // 是否只选择我的供应商
      if (value === "1" && !this.$store.state.accountInfo.IsMainAccount) {
        this.IsMySupplier = true;
      }

      let invoice =
        ",TaxNumber,CompanyName,Address,Telephone,BankName,BankAccount,";

      _.each(this.gridConfig, (cfg, index) => {
        cfg.IsReadonly = true;
        cfg.DisplayIndex = index;
        cfg.IsRequired = false;

        if (this.openFrom === "invoice") {
          cfg.IsShow = invoice.indexOf(`,${cfg.BindField},`) > -1;
        }
      });

      let hideSupplierContact = this.getSpecialResourceByCode('NoSeeSupplierContactInfo');
      let hideCustomerContact = this.getSpecialResourceByCode('NoSeeCustomerContactInfo');
      this.columns = this.flexGridColumnsHandler(this.gridConfig);
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function(
        obj,
        index
      ) {
        return { key: obj.value, value: obj.value };
      });
      // 跟进人
      _.extend(
        _.find(this.columns, function(column) {
          return column.binding === "FollowUserName";
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      );

      if (this.companyType === "1,3") {
        this.companyStr = [
          { key: 1, value: "客户" },
          { key: 3, value: "供应商客户" }
        ];
        this.hideContact = hideCustomerContact;
      } else {
        this.hideContact = hideSupplierContact;
        this.companyStr = [
          { key: 2, value: "供应商" },
          { key: 3, value: "供应商客户" }
        ];
      }
      _.extend(
        _.find(this.columns, function(column) {
          return column.binding === "CompanyType";
        }),
        { dataMap: this.companyStr, showDropDown: true }
      );
      // TODO: 不看联系资料先不处理
      this.hideContact = false;
      // 不看联系资料
      if (this.hideContact) {
        _.extend(
          _.find(this.columns, function(column) {
            return column.binding === "ContactName";
          }),
          { IsShow: false }
        );

        _.extend(
          _.find(this.columns, function(column) {
            return column.binding === "Telephone";
          }),
          { IsShow: false }
        );
      }

      _.delay(() => {
        this.onBindData();
      }, 150);
    },
    onBindData(pageIndex = 1) {
      this.fieldsFilter = _.extend(this.fieldsFilter, {
        CompanyName: this.searchFields.CompanyName,
        Address: this.searchFields.Address
      });
      var jsonData = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize,
          CompanyTypes: this.companyType,
          IsMySupplier: this.IsMySupplier,
          ContactInfo: this.searchFields.ContactInfo
        }
      );
      if (this.hideContact) {
        jsonData.ContactInfo = ''
      }
      return this.$post(
        "CompanyContacts/SearchCompanyContacts",
        jsonData,
        data => {
          this.pageIndex = jsonData.PageIndex;
          this.itemSource = data.ResultList;
          this.totalCount = data.TotalCount;
          if (this.hideContact && data.ResultList && data.ResultList.length > 0) {
            _.each(data.ResultList, item => {
              item.ContactName = ''
              item.Telephone = ''
              item.Mobile = ''
              item.Email = ''
              item.QQ = ''
            })
          }
        }
      ).finally(() => {
        this.loadingState = false;
      });
    },
    onSearch(params = null) {
      if (params) {
        this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      }
      return this.onBindData(1);
    },
    onSelectionChange(currentItem) {
      this.$emit("selection-changed", currentItem);
    },
    onRefresh() {
      return this.onBindData(this.pageIndex);
    },
    confirmCallBack() {
      this.$nextTick(() => {
        this.$emit("confirm", this.$refs.flexGrid.getSelectedRows()[0]);
        this.$emit("update:visible", false);
      });
    }
  }
};
</script>

<style lang="scss">
</style>
