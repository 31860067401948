export const getErpApiBaseUrl = () => getUrl('apiBaseUrl')
export const getBomaiApiBaseUrl = () => getUrl('bomaiApiBaseUrl')

export const bomaiUcBaseUrl = () => getUrl('bomaiUcBaseUrl')

export const linkAllBaseUrl = () => getUrl('linkAllBaseUrl')

// BOMAI 订阅库存设置
export const getBomaiBasicSetupkUrl = () => bomaiUcBaseUrl() + 'open/OpenBasicsetup'
// BOMAI 可订阅库存
export const getBomaiAvailableSubscriptionUrl = () => bomaiUcBaseUrl() + 'open/OpenCansubscribe'
// BOMAI 订阅库存待审批客户
export const getBomaiApprovalCustomerUrl = () => bomaiUcBaseUrl() + 'open/OpenSubaudit'
// BOMAI 上传推广库存
export const getBomaiUploadpubstockUrl = () => bomaiUcBaseUrl() + 'open/Stocklist'

function getUrl(id, raiseEmpty = true) {
  if (location.href.indexOf("http://test.znlbd.com/") === 0) {
    return 'http://test.znlbd.com/api/'
  }

  const el = document.getElementById(id)
  if (!el) {
    throw new Error(`没有找到URL配置的HTML节点: #${id}`)
  }

  const url = el.innerHTML.trim()
  if (raiseEmpty && !url) {
    throw new Error(`URL配置的HTML节点不可为空: #${id}`)
  }

  if (id == "apiBaseUrl") {
    if (window.cefQuery) { // 嵌套在客户端
      return url.replace("https://", "http://");
    }

    // 使用浏览器访问
    return url.replace("https://", "//").replace("http://", "//");
  }

  return url
}
