// import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'

import * as urls from '~/lib/urls'

export const mixin = {
  data() {
    return {}
  },
  computed: {},
  methods: {
    // 计算毛利率
    getProfitRate(salesPriceNoTax, buyPriceNoTax) { // 销售未税价，采购未税价，汇率
      let val = this.getCompanySetupValue('ProfitMarginMode')
      if (val === '2') {
        if (this.hasValue(buyPriceNoTax) && buyPriceNoTax !== 0) {
          let rate = (salesPriceNoTax - buyPriceNoTax) / buyPriceNoTax
          return (rate * 100).toFixed(2)
        } else {
          return ''
        }
      } else {
        if (this.hasValue(salesPriceNoTax) && salesPriceNoTax !== 0) {
          let rate = (salesPriceNoTax - buyPriceNoTax) / salesPriceNoTax
          return (rate * 100).toFixed(2)
        } else {
          return ''
        }
      }
    },

    // 保存页面配置信息
    onSavePageConfig(cfgs, pageCode, onSuccess, onError) {
      // 设置页面配置信息
      let pageUrl = this.$store.state.accountInfo.savePageConfigUrl
      let param = {
        PageCode: pageCode,
        LayoutType: cfgs.Type,
        Modules: _.map(cfgs.Menus, item => {
          return {
            MCode: item.name,
            IsShow: item.visible,
            DisplayIndex: item.index
          }
        })
      }

      let _this = this
      this.$post(pageUrl, param, (data, logic) => {
        if (logic.code !== 200) {
          let msg = '保存失败'
          if (_this.hasValue(logic.msg)) {
            msg = logic.msg
          } else if (_this.hasValue(data.msg)) {
            msg = data.msg
          }
          return this.$message({
            message: msg,
            type: 'error'
          })
        } else {
          if (typeof (onSuccess) === 'function') {
            onSuccess()
          }
          this.$message({
            message: '已设置成功,请重新登录系统!',
            type: 'success'
          })
        }
      })
    },

    dragingChange(val) {
      !val && this.$message({
        message: '保存成功',
        type: 'success'
      })
    },

    // 获取页面配置信息
    async onGetPageConfig(pageCode, val) {
      let isSearchRoleMenu = false
      if (val) {
        isSearchRoleMenu = val
      }
      let pageUrl = this.$store.state.accountInfo.getPageConfigUrl || 'UserResource/GetPageConfig'
      let configs = await this.$post(pageUrl, {
        PageCode: pageCode,
        IsSearchRoleMenu: isSearchRoleMenu
      })

      // return configs
      let cfgs = {
        Type: configs.LayoutType,
        // 权限列表
        RoleMenu: _.map(configs.RoleModule, item => {
          return {
            code: item.Code,
            name: item.Name
          }
        }),
        // 模块列表
        Menu: _.map(configs.ModulesList, item => {
          return {
            name: item.MCode,
            visible: item.IsShow,
            title: item.MTitle,
            index: item.DisplayIndex
          }
        })
      }
      return cfgs
    },
    onSetDefaultConfig(arraryleg, obj) {
      let configlist = []
      for (let i = 0; i < arraryleg; i++) {
        configlist.push({
          allowDeploy: true,
          hasRight: true,
          index: i,
          name: i === 0 ? obj.name : '',
          title: i === 0 ? obj.title : '',
          visible: false
        })
      }
      return configlist
    },
    // 重置页面配置
    async onResetPageConfig(pageCode) {
      let cfgs = {}
      let pageUrl = this.$store.state.accountInfo.resetPageConfigUrl
      await this.$post(pageUrl, {
        PageCode: pageCode
      }, (data, logic) => {
        if (logic.code !== 200) {
          this.$message({
            message: logic.msg,
            type: 'error'
          })
        } else {
          cfgs = {
            Type: data.LayoutType,
            Menu: _.map(data.ModulesList, item => {
              return {
                name: item.MCode,
                visible: item.IsShow,
                title: item.MTitle,
                index: item.DisplayIndex,
                allowDeploy: true,
                hasRight: true
                // hasRight: this.hasRight(item.MCode)
              }
            })
          }
          this.$emit('close')
          this.$message({
            message: '已重置成功,请重新登录系统!',
            type: 'success'
          })
        }
      })
      return cfgs
    },

    getPrintInfo(parameterConfig) {
      let template = _.find(parameterConfig, item => item.ParamCode === 'PrintTemplate')
      let title = _.find(parameterConfig, item => item.ParamCode === 'PrintTitle')
      let result = {
        template: '',
        title: ''
      }

      if (template) {
        result.template = template.ParamValue || ''
      }
      if (title) {
        result.title = title.ParamValue || ''
      }

      return result
    },

    /*
      重置元素高度
      opts.tableDom : 目标元素选择器 string
      opts.parentDom : 目标元素 父元素 选择器 string
      opts.brother : 目标元素 兄弟元素 选择器 array
      opts.surplus : 剩余需要裁剪的px number
      opts.styleConfig : 需要额外修改的css属性
    */
    resetTableHeight(opts) {
        let tableDom = document.querySelector(opts.dom);

        let parentDom = document.querySelector(opts.parentDom);

        let parentDomHeight = parseFloat(
          window.getComputedStyle(parentDom).height
        );

        // console.log('opts.dom is ',opts.dom,'tableDom is ',tableDom,'parentDomHeight is ',parentDomHeight)

        let surplus = opts.surplus || 0;
        opts.brother.forEach((item) => {
          let itemDom = document.querySelector(item);
          if(itemDom) surplus += parseFloat(window.getComputedStyle(itemDom).height);
          // console.log('兄弟元素高度为', parseFloat(window.getComputedStyle(itemDom).height))
        });

        let maxHeight = (parentDomHeight -  surplus) + "px";

        tableDom.style.maxHeight = maxHeight;

        // console.log('resetTableHeight init is ',maxHeight);

        if(opts.styleConfig){
          for (const key in opts.styleConfig) {
            tableDom.style[key] = opts.styleConfig[key];
          }
        }

        if(opts.heightSet){
          tableDom.style.height = maxHeight;
        }

        return maxHeight;

    },

    /*
      获取 设备窗口 vh 的 px 值
      value: vh 的数值 number
    */

    handleVhToPx(value){

        let num = 2000;

        return num;
    }

  }
}

export const openLinkAll = {
  methods: {
    openLinkAll(column, cellHide) {
      return false;
      // return (h, obj) => {
      //   let __this = this
      //   if (obj.row[obj.column['binding']] !== undefined && obj.row[obj.column['binding']] !== '') {
      //     return h(
      //       'span',
      //       [
      //         h('span', cellHide === true ? '' : obj.row[column.binding]),
      //         h('i', {
      //           attrs: {
      //             class: 'iconfont icon-linkall__ic znl-linkAllBtn',
      //             title: '打开LinkAll'
      //           },
      //           on: {
      //             click() {
      //               __this.$store.commit('setLinkAllInfo', {
      //                 topicName: obj.row[column.binding]
      //               })
      //             }
      //           }
      //         })
      //       ])
      //   } else {
      //     return null
      //   }
      // }
    },
    addHeaderColor(column, color) {
      return (h, obj) => {
        return h('span', {
            attrs: {
              style: 'color:' + color
            }
          },
          [
            obj.header
          ])
      }
    }
  }
}

export const renderRequiredColumn = {
  methods: {
    renderRequiredColumn(columns) {
      let cols = columns
      _.each(cols, col => {
        if (col.IsRequired) {
          _.extend(col, {
            renderHeader: (h, columnObj) => {
              return h('span', {
                attrs: {
                  class: 'm-edittable-required-column'
                }
              }, `*${columnObj.header}`)
            }
          })
        }
      })
      return cols
    }
  }
}

// 导出数据公共方法
// 1.表名 2.搜索条件 3.导出列 4.导出的文件名
export const exportData = {
  methods: {
    async exportData(tableName, searchWeher, exportCols, fileName) {
      let showLoading = true
      let exportColumns = []
      let col = _.filter(exportCols, function (item) {
        var isSystem = item.IsSystem || item.isSystem;
        return !isSystem && item.IsExprot
      })
      _.each(col, item => {
        exportColumns.push({
          'Name': item.BindField || item.binding,
          'DisplayName': item.SCTitle || item.CTitle || item.header
        })
      })
      let postData = {
        'Name': tableName,
        'Conditions': searchWeher,
        'Columns': exportColumns
      }

      console.log(postData)
      await this.$post('export/excel', postData, (data, logic) => {
        if (logic.code === 200) {
          let downloadUrl = `${urls.getErpApiBaseUrl()}export/download?id=` + data.Id
          if (window.cefQuery !== undefined) {
            this.downFile({
              FileName: fileName + ".xls",
              FileUrl: downloadUrl
            }, function (res) {})
          } else {
            window.open(downloadUrl) // 下载文件
          }
        } else {
          this.$message({
            message: logic.msg,
            type: 'error'
          })
        }
      }).finally(e => {
        showLoading = false
        return showLoading
      })
      return showLoading
    }
  }
}

// v-table表格设置的事件
export const erpTableSetHandler = {
  data() {
    return {
      timmer: null,
      timmer1: null,
      dragWidthCols: []
    }
  },
  computed: {
    headerMenuItems() {
      // 如果是主账号，使用组件内置的配置
      if (this.$store.state.accountInfo.IsMainAccount) {
        return undefined
      }
      return [{
        icon: 'mu-table-reset',
        text: '重置列宽及位置',
        handle: e => {
          this.resetTableConfigSubAccount(e.col)
        }
      }]
    }
  },
  methods: {
    postSaveErpTableConfig(moduleName, cols) {
      if (!this.hasValue(moduleName)) {
        this.$message({
          message: 'moduleName不能为空,请联系系统管理员',
          type: 'warning'
        })
        return
      }
      if (!this.hasValue(cols) || cols.Length <= 0) {
        this.$message({
          message: '列配置不能为空,请联系系统管理员',
          type: 'warning'
        })
        return
      }
      let _userCols = []
      _.each(cols, item => {
        if (this.hasValue(item) && this.hasValue(item.GCGuid)) {
          let info = {
            CTitle: item.CTitle,
            SCTitle: item.SCTitle,
            GCGuid: item.GCGuid,
            ColumnWidth: item.ColumnWidth,
            DisplayIndex: item.DisplayIndex,
            IsShow: item.IsShow
          }
          _userCols.push(info)
        }
      })
      let _config = {
        PGGuid: cols[0].PGGuid,
        GCode: moduleName,
        ColumnConfigs: _userCols
      }
      this.$post(moduleName + "/SaveConfig", _config)

      // 清除本次缓存
      if (localStorage[moduleName]) {
        localStorage.removeItem(moduleName)
      }
    },
    // 拉动列的宽度
    async setTableColWidth(moduleName, col) {
      if (!this.hasValue(col) || !this.hasValue(moduleName)) {
        return;
      }
      // _.remove(this.dragWidthCols, item => {
      //   return item.GCGuid === col.GCGuid
      // })
      // this.dragWidthCols.push(col)
      clearTimeout(this.timmer1)
      this.timmer1 = setTimeout(() => {
        // this.postSaveErpTableConfig(moduleName, this.dragWidthCols)
        // this.dragWidthCols = null

        this.$post('PageGrid/SaveColumnWidth', col, (data, logic) => {
          if (data) {
            // this.$message({
            //   message: '列宽保存成功',
            //   type: 'success'
            // })
          } else {
            // this.$message({
            //   message: (logic && logic.msg) || '列宽保存失败',
            //   type: 'error'
            // })
          }
        })
      }, 10000);
    },
    // 隐藏列 和 显示列
    async hideShowColTrigger(moduleName, col) {
      if (!this.hasValue(col) || !this.hasValue(moduleName)) {
        return;
      }
      this.postSaveErpTableConfig(moduleName, [col])
    },
    // 重置表格
    async resetTableConfig(moduleName, url = null) {
      if (!this.hasValue(moduleName)) {
        this.$message({
          message: 'moduleName不能为空,请联系系统管理员',
          type: 'warning'
        })
        return
      }
      this.$confirm('确定重置当前表格列的宽度及位置为系统默认值?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (url === null || url === undefined || url === '') {
          url = moduleName + "/ResetConfig"
        }
        this.$post(url, (data, logic) => {
          if (logic.code === 200) {
            this.$message({
              message: '重置操作成功,需重新登录才生效!',
              type: 'success'
            })
          } else {
            this.$message({
              message: '操作失败,请联系系统管理员',
              type: 'error'
            })
          }
        })
      })
    },
    // 保存表格配置
    async saveErpTableConfig(moduleName, cols, isDelay) {
      if (!this.hasValue(cols) || cols.Length <= 0 || !this.hasValue(moduleName)) {
        return;
      }
      let _cols = cols
      if (isDelay) {
        clearTimeout(this.timmer)
        this.timmer = setTimeout(() => {
          this.postSaveErpTableConfig(moduleName, _cols)
        }, 10000);
      } else {
        this.postSaveErpTableConfig(moduleName, _cols)
      }
    },

    // 查询当前用户是否有指定模块(MouleName), 指定权限点(Code)(如果赋值)的权限
    async hasResource(moduleName, code = null) {
      let result = await this.$post('Account/HasResource', {
        ModuleName: moduleName,
        Code: code
      })
      return result
    },

    // 子账号重置列宽
    async resetTableConfigSubAccount(col, gCode) {
      this.$confirm('确定重置当前表格列的宽度及位置为系统默认值?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          PGGuid: (col && col.PGGuid) || null,
          GCode: gCode
        }
        this.$post("PageGrid/ResetColumnConfig", param, (data, logic) => {
          if (logic.code === 200) {
            this.$message({
              message: '重置操作成功,需重新登录才生效!',
              type: 'success'
            })
          } else {
            this.$message({
              message: logic.msg || '操作失败,请联系系统管理员',
              type: 'error'
            })
          }
        })
      })
    },

    getResetTableHeaderMenu(gCode) {
      // 如果是主账号，使用组件内置的配置
      if (this.$store.state.accountInfo.IsMainAccount) {
        return undefined
      }
      return [{
        icon: 'mu-table-reset',
        text: '重置列宽及位置',
        handle: e => {
          this.resetTableConfigSubAccount(e.col, gCode)
        }
      }]
    }

  }
}

export const dateTimeFormat = {
  methods: {
    formatDate(dateStr, format = 'yyyy-MM-dd') {
      return formatDate(dateStr, format)
    },

    formatDateTime(date, format) {
      return formatDateTime(date, format)
    }
  }
}

const formatDate = (dateStr, format = 'yyyy-MM-dd') => {
  if (dateStr === undefined || dateStr === null || dateStr === '') {
    return null
  }
  var date = new Date(dateStr)

  return formatDateTime(date, format)
}

const formatDateTime = (date, format) => {
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds() //毫秒
  }
  if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length))
  for (var k in o)
    if (new RegExp("(" + k + ")").test(format)) format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)))
  return format
}

export const itemsHandle = {
  methods: {
    removeTime(itemSource, fieldsArry = []) {
      if (itemSource === undefined ||
        itemSource === null ||
        itemSource.length === 0 ||
        fieldsArry === undefined ||
        fieldsArry === null ||
        fieldsArry.length === 0
      ) {
        return false
      }

      _.each(itemSource, item => {
        _.each(fieldsArry, colName => {
          let val = item[colName]
          if (val) {
            let newVal = formatDate(val)
            if (newVal) {
              item[colName] = newVal
            }
          }
        })
      })
    }
  }
}

export const numberFormat = {
  methods: {
    moneyFormat(value) {
      return this.number_format(value, 2, '.', ',', 'ceil')
    },

    number_format(number, decimals, dec_point, thousands_sep, roundtag) {
      /*
       * 参数说明：
       * number：要格式化的数字
       * decimals：保留几位小数
       * dec_point：小数点符号
       * thousands_sep：千分位符号
       * roundtag:舍入参数，默认 "ceil" 向上取,"floor"向下取,"round" 四舍五入
       * */
      number = (number + '').replace(/[^0-9+-Ee.]/g, '');
      roundtag = roundtag || "ceil"; //"ceil","floor","round"
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {

          var k = Math.pow(10, prec);
          console.log();

          return '' + parseFloat(Math[roundtag](parseFloat((n * k).toFixed(prec * 2))).toFixed(prec * 2)) / k;
        };
      s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
      var re = /(-?\d+)(\d{3})/;
      while (re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
      }

      if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
      }
      return s.join(dec);
    }
  }
}
