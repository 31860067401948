import Vue from 'vue'
import {
 Table,
 EditTable
} from '@znl/znl-gather-table'
import "@znl/znl-gather-table/theme-chalk/index.css"
import "./reset-znl-gather-table.scss"
Vue.use(Table,{
    user:'znlsoft'
})
Vue.use(EditTable,{
    user:'znlsoft'
})
