export const BaseConfig = () => import('~/views/Account/BaseConfig' /* webpackChunkName: "pages/account" */)
export const AccountManage = () => import('~/views/Account/AccountManage' /* webpackChunkName: "pages/account" */)
export const AccountMng = () => import('~/views/Account/AccountMng' /* webpackChunkName: "pages/account" */)
export const SysConfiguration = () => import('~/views/Account/SysConfiguration' /* webpackChunkName: "pages/account" */)
export const BackupAndInit = () => import('~/views/Account/BackupAndInit' /* webpackChunkName: "pages/account" */)
export const RegisterInfo = () => import('~/views/Account/RegisterInfo' /* webpackChunkName: "pages/account" */)
export const TemplateOfPrint = () => import('~/views/Account/TemplateOfPrint' /* webpackChunkName: "pages/account" */)
export const MultiCompanies = () => import('~/views/Account/MultiCompanies' /* webpackChunkName: "pages/account" */)
export const DataRecycle = () => import('~/views/Account/DataRecycle' /* webpackChunkName: "pages/account" */)
export const RoleManage = () => import('~/views/Account/RoleManage' /* webpackChunkName: "pages/account" */)
export const UserSubCompany = () => import('~/views/Account/UserSubCompany' /* webpackChunkName: "pages/account" */)
export const NumRegist = () => import('~/views/Account/NumRegist' /* webpackChunkName: "pages/account" */)
export const UserInfo = () => import('~/views/Account/UserInfo' /* webpackChunkName: "pages/account" */)
