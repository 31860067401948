<template>
  <div class="pasteDataAcquisitionContainer" v-loading="isLoading" element-loading-text="数据处理中，请稍后...">
    <el-row class="pasteDataAcquisition">
      <el-row class="columnsSelect">
        <!-- :style="{ width: scollWidth }" -->
        <div class="scoll" :style="{ width: scollWidth }">
          <znl-input
              title-width="0px"
              layout="left"
              width="100%"
              form-type="select"
              placeholder="选择列"
              popper-class="popperClass"
              :is-must-fill="false"
              :clearable="false"
              :border="true"
              :select-options="customizes"
              v-for="(item, index) in customizeColumns"
              :key="index"
              @change="val=>{changeOption(val, index)}"
              v-model="item.key">
              </znl-input>
        </div>
      </el-row>
      <el-row >
        <el-input
          class="table-textarea"
          type="textarea"
          :rows="rows"
          style="word-wrap:normal"
          placeholder="请粘贴要导入到表格的数据 (如下所示)
型号 品牌 数量
LM358 ST 5000"
          resize="none"
          @change="textChange"
          v-model="textarea">
        </el-input>
      </el-row>
    </el-row>
    <el-row class="footer">
        <el-select
          v-model="userFormat"
          size="mini"
          >
          <el-option
            v-for="(name,i) in formatSelete"
            :key="i"
            :label="name.value"
            :value="name.key"
            >
          </el-option>
        </el-select>
          <span class="text">已粘贴 <span class="text-oriange">{{dataCount}}</span> 条 / {{this.maxCount}} 条<span class="text-red" v-if="dataCount > this.maxCount"> (已超出)</span>
          </span>
        <div class="footer-btns">
          <znl-button style-type="mac" :height='22' @click="textarea='',splitOperation()">
            <span>清空文本</span>
          </znl-button>
          <znl-button style-type="mac" :height='22' @click="automaticallyColName()" v-if="autoMappingColumn">
            <span>自动匹配列名</span>
          </znl-button>
          <znl-button style-type="minor" :height='22' @click="addToItemSource">
            <span>添加到表格</span>
          </znl-button>
        </div>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'pasteDataAcquisition',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    maxCount: {
      type: Number,
      default: 200
    },
    columns: {
      type: Array,
      default () {
        return []
      }
    },
    rows: {
      type: Number,
      default: 9
    },
    showColumns: {
      type: Array,
      default () {
        return []
      }
    },
    autoMappingColumn: {
      type: Boolean,
      default () {
        return true
      }
    },
    showLoading: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  computed: {
    scollWidth () {
      return this.customizeColumns.length * 80 + 'px'
    },
    RegExpBinding () {
      this.computedReg()
      return this.userFormat
    }
  },
  watch: {
    columns (val) {
      this.setCloumns()
    },
    textarea (val) {
      this.textChange(val)
    }
  },
  data () {
    return {
      dataCount: 0,
      customizeColumns: [], // 列配置
      customizes: [], // 下拉选项
      textarea: '', // 文本内容
      userFormat: '\\t', // 默认导入方式来源
      userHeader: [],
      hasUserHeader: true, // 默认用户输入有表头,
      userItemSource: [],
      headerIndexs: [],
      noheaderColumns: [],
      noheaderChangeState: true,
      formatSelete: [ // 导入方式来源
        {
          key: '\\t',
          value: 'TAB/(EXCEL)分割'
        },
        {
          key: '\\s',
          value: '空格分割'
        },
        {
          key: ',',
          value: '逗号分割'
        }
      ],
      userData: [], // 转换后数据
      isLoading: false
    }
  },
  methods: {
    computedReg () {
      let nLength = { length: this.textarea.split(new RegExp('\\n')).length, binding: '\\n' }
      let tLength = { length: this.textarea.split(new RegExp('\\t')).length, binding: '\\t' }
      let sLength = { length: this.textarea.split(new RegExp('\\s')).length, binding: '\\s' }
      let cLength = { length: this.textarea.split(new RegExp(',')).length, binding: ',' }

      if (tLength.length > nLength.length) {
        this.userFormat = tLength.binding
      } else {
        this.userFormat = _.max([tLength, sLength, cLength], (item) => {
          return item.length
        }).binding
      }
    },

    textChange (val) {
      this.computedReg()
      this.dataCount = val.split('\n').length - 1
    },
    changeOption (item) {
      this.noheaderChangeState = false
      this.noheaderColumns = this.customizeColumns
    },
    setTextareaData (val) {
      this.textarea = val
    },
    addToItemSource () {
      this.userData = []
      if (this.dataCount > this.maxCount) {
        return this.$message({ message: '已超出当前最大处理条数 [' + this.maxCount + '] 条', type: 'warning' })
      }
      this.automaticallyColName()
      // this.judgeHasHeaderRow()
      this.splitOperation()
      let settedCols = this.outputColumnOrder()

      if (this.showLoading) {
      this.isLoading = true
        setTimeout(() => {
          this.$emit('add-excel-data', this.userData)
          this.$emit('set-column-order', settedCols)
          this.textarea = ''
          this.dataCount = 0
          this.isLoading = false
        }, 100)
      } else {
        this.$emit('add-excel-data', this.userData)
        this.$emit('set-column-order', settedCols)
        this.textarea = ''
        this.dataCount = 0
      }

    },

    splitOperation () {
      let keys = _.filter(this.customizeColumns, item => {
        return !!item.key
      })
      let strType = ['Qty', 'BuyPrice']
      // 拆分操作
      _.each(this.userItemSource, (item, i) => {
        this.userData[i] = {}
        _.each(item, (data, j) => {
          if (keys[j] && keys[j].key) {
            if (strType.indexOf(keys[j].key) === -1) {
              keys[j] && keys[j].key && (this.userData[i][keys[j].key] = data)
            } else {
              if (data === '') {
                data = 0
              } else {
                data = Number(data.replace(/,/g, '').replace(/(^\s*)|(\s*$)/g, ''))
              }
              keys[j] && keys[j].key && (this.userData[i][keys[j].key] = data)
            }
          }
        })
      })
    },

    judgeHasHeaderRow () { // 判断表头
      let rows = this.textarea.split('\n')
      if (rows.length > 0) {
        let headerRow = rows[0].split(new RegExp(this.userFormat))
        let hasUserHeader = false

        _.find(headerRow, item => {
          if (_.find(this.customizes, column => {
            return column.value === item
          })) {
            hasUserHeader = true
            return
          }
        })
        this.hasUserHeader = hasUserHeader
      }
    },

    automaticallyColName () { // 自动匹配列名
      this.setCloumns()
      this.judgeHasHeaderRow()
      let values = this.textarea.trim()
      let rows = values.split('\n')
      if (!this.hasUserHeader) {
        _.each(rows, (row, index) => {
          this.userItemSource.push(row.split(new RegExp(this.userFormat)))
        })
      }
      let headerRow = []
      if (this.hasUserHeader) {
        rows[0] = rows[0].replace(/（/g,"(").replace(/）/g,")");//客户输入列名中的中文括号替换为英文括号
        headerRow = rows[0].split(new RegExp(this.userFormat))
      } else {
        if (this.noheaderChangeState) {
          this.noheaderColumns = this.customizeColumns
        }
        let newcustomizeColumnslist = _.filter(this.noheaderColumns, (item) => { return item.key !== 0 && item.key !== '' })
        _.each(newcustomizeColumnslist, (item, index) => {
          let info = _.filter(this.customizes, (data) => {
            return data.key === item.key
          })
          if (info !== null && info.length > 0) {
            headerRow.push(info[0].value)
          }
        })
      }
      // 匹配用户导入的列名与系统的列名
      // 比较列的标题
      let headerobjlist = []
      _.each(headerRow, (item, e) => {
        let headerlistobj = _.find(this.customizes, (nitem) => {
          return nitem.value === item
        })
        if (headerlistobj !== undefined) {
          headerobjlist.push(
            headerlistobj.value
          )
        } else headerobjlist.push(''); // 如果没找到则添加空列 by zilong 20211123
      })
      this.userHeader = headerobjlist // headerRow
      this.userItemSource = []

      _.each(rows, (row, index) => {
        if (this.hasUserHeader) {
          if (index > 0) {
            this.userItemSource.push(row.split(new RegExp(this.userFormat)))
          }
        } else {
          this.userItemSource.push(row.split(new RegExp(this.userFormat)))
        }
      })

      this.customizeColumns = _.map(_.filter(this.columns, (data) => {
        return data.visible && !data.isReadOnly && !data.isSystem
      }), (item, index) => {
        return {
          key: _.some(this.userHeader, val => { return val === item.header }) ? item.binding : 0
        }
      })

      this.headerIndexs = []
      _.each(this.customizeColumns, (item, num) => {
        let hasHeader = false
        _.each(this.userHeader, (header, index) => {
          if (this.customizes[num].value === header) {
            hasHeader = true
          } else {
            item.index = 1
          }
        })
        if (hasHeader) {
          item.key = this.customizes[num].key
          this.headerIndexs.push(_.indexOf(this.userHeader, this.customizes[num].value))
        } else {
          item.key = 0
        }
      })
      _.each(this.userItemSource, (item, index) => {
        for (let i = 0; i < this.userHeader.length; i++) {
          if (!_.some(this.headerIndexs, m => {
            return m === i
          })) {
            item.splice(i, 1)
          }
        }
      })
      // 原来的this.customizeColumns 赋值方法
      // this.customizeColumns = _.sortBy(_.map(this.customizeColumns, item => {
      //   return {
      //     key: item.key,
      //     value: item.value,
      //     index: item.key ? 0 : 1
      //   }
      // }), item => { return item.index })
      if (this.hasUserHeader) {
        let newlist = []
        _.each(this.userHeader, (item, e) => {
          let objlist = _.find(this.customizes, (nitem) => {
            return nitem.value === item
          })
          if (objlist !== undefined) {
            newlist.push({
              value: objlist.value,
              key: objlist.key,
              index: 1
            })
          } else {
            newlist.push({
              value: 0,
              key: 0,
              index: 0
            })
          }
        })

        newlist = _.uniq(newlist, (item) => { return item.key })
        for (let i = 0; i < (this.customizes.length - newlist.length); i++) {
          newlist.push({
            value: 0,
            key: 0,
            index: 0
          })
        }
        this.customizeColumns = newlist
      } else {
        let noheadlist = []
        _.each(this.noheaderColumns, (item, e) => {
          let noheaderobj = _.filter(this.customizes, (nitem) => {
            return nitem.key === item.key
          })[0]
          if (noheaderobj !== undefined) {
            noheadlist.push({
              value: noheaderobj.value,
              key: noheaderobj.key,
              index: 1
            })
          } else {
            noheadlist.push({
              value: 0,
              key: 0,
              index: 0
            })
          }
        })
        this.customizeColumns = noheadlist
      }
    },

    setCloumns () {
      if (this.showColumns.length > 0) {
        this.customizeColumns = _.map(_.filter(this.columns, (data) => {
          return data.visible && !data.isReadOnly && !data.isSystem
        }), (item, index) => {
          return {
            key: _.some(this.showColumns, key => { return key === item.binding }) ? item.binding : 0
          }
        })
      } else {
        let selectlist = []
        _.each(this.columns, (item, index) => {
          if (item.visible && !item.isReadOnly && !item.isSystem) {
            selectlist.push({ key: item.binding })
          }
        })
        this.customizeColumns = selectlist
      }
      this.customizes = JSON.parse(JSON.stringify(_.map(_.filter(this.columns, (data) => {
        return data.visible && !data.isReadOnly && !data.isSystem
      }), (item, index) => {
        return {
          key: item.binding,
          value: item.header
        }
      })))
    },

    // 获取列头的顺序，并传给父级
    outputColumnOrder () {
      // customizeColumns
      let settedCols = []
      let index = 0
      _.each(this.customizeColumns, col => {
        if (typeof (col.key) === 'string') {
          index++
          settedCols.push({
            ColumnCode: col.key,
            ColumnName: col.value,
            SerialNo: index
          })
        }
      })
      return settedCols
    }
  },
  mounted () {
    this.setCloumns()
  }
}
</script>
<style lang="scss" scoped>
.pasteDataAcquisitionContainer {
  .pasteDataAcquisition {
    .columnsSelect {
      overflow-x: auto;
      padding: 4px 1px 0px 1px;
      background-color: #f0f3f8;
      align-items: center;
      height: 45px;
      margin-bottom: 3px !important;
      border: 1px solid #dcdfe6;
      .scoll {
        display: flex;
        align-items: center;
        // width: 100%;
        .znl-component-input {
          margin-left: 5px;
        }
        .el-input__suffix {
          top: -2px;
        }
      }
    }
    .el-textarea {
      // margin-top: 3px !important;
      .el-textarea__inner {
        &::placeholder {
          font-size: 12px;
        }
      }
    }
  }
  .footer {
    position: relative;
    height: 35px;
    .el-select {
      position: absolute;
      left: 0;
      top: 3px;
      .el-input__inner {
        width: 150px;
        height: 25px;
        line-height: 25px;
        border: 1px solid #e6e6e6;
        font-size: 12px;
      }
    }
    .text {
      position: absolute;
      left: 156px;
      top: 7px;
      font-size: 12px;
      .text-oriange {
        color: #ee7700;
      }
      .text-red {
        color: red;
      }
    }
    .footer-btns {
      position: absolute;
      right: 0;
      top: 3px;
    }
    .el-input__icon {
      line-height: 28px;
    }
  }

  .table-textarea{
    height:170px;

    ::v-deep textarea{
      height:170px;
    }
  }

}
</style>

